// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "./styles.mjs";

function IconImport(Props) {
  var colorOpt = Props.color;
  var sizeOpt = Props.size;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light12;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              fill: "none",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M12 14L11.5119 14.5694C11.7928 14.8102 12.2072 14.8102 12.4881 14.5694L12 14ZM11.25 5V14H12.75V5H11.25ZM12.4881 14.5694L15.9881 11.5694L15.0119 10.4306L11.5119 13.4306L12.4881 14.5694ZM12.4881 13.4306L8.98809 10.4306L8.01191 11.5694L11.5119 14.5694L12.4881 13.4306Z",
                  fill: Styles.Color.toString(color)
                }), React.createElement("path", {
                  d: "M5.5 8V7.25C5.08579 7.25 4.75 7.58579 4.75 8H5.5ZM5.5 16.5H4.75C4.75 16.9142 5.08579 17.25 5.5 17.25V16.5ZM18.5 16.5V17.25C18.9142 17.25 19.25 16.9142 19.25 16.5H18.5ZM18.5 8H19.25C19.25 7.58579 18.9142 7.25 18.5 7.25V8ZM8.5 7.25H5.5V8.75H8.5V7.25ZM4.75 8V16.5H6.25V8H4.75ZM5.5 17.25H18.5V15.75H5.5V17.25ZM19.25 16.5V8H17.75V16.5H19.25ZM18.5 7.25H15.5V8.75H18.5V7.25Z",
                  fill: Styles.Color.toString(color)
                }));
}

var make = IconImport;

export {
  make ,
  
}
/* react Not a pure module */
