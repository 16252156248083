// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconX(Props) {
  var color = Props.color;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var tmp = {
    width: String(size),
    viewBox: "0 0 16 16",
    xmlns: "http://www.w3.org/2000/svg"
  };
  var tmp$1 = Belt_Option.map(color, (function (color) {
          return Curry._1(Css.style, {
                      hd: Css_Legacy_Core.SVG.fill(color),
                      tl: /* [] */0
                    });
        }));
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("svg", tmp, React.createElement("g", {
                  strokeWidth: "1"
                }, React.createElement("line", {
                      fill: "none",
                      strokeLinecap: "round",
                      x1: "13.5",
                      x2: "2.5",
                      y1: "2.5",
                      y2: "13.5"
                    }), React.createElement("line", {
                      fill: "none",
                      strokeLinecap: "round",
                      x1: "2.5",
                      x2: "13.5",
                      y1: "2.5",
                      y2: "13.5"
                    })));
}

var make = IconX;

export {
  make ,
  
}
/* Css Not a pure module */
