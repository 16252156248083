// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var context = React.createContext(undefined);

var provider = context.Provider;

function use(param) {
  return Belt_Option.getExn(React.useContext(context));
}

function useOpenModal(param) {
  var globalSend = Belt_Option.getExn(React.useContext(context));
  return React.useCallback((function (modal) {
                return Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: modal
                          });
              }), [globalSend]);
}

function GlobalSendContext(Props) {
  var globalSend = Props.globalSend;
  var children = Props.children;
  return React.createElement(provider, {
              value: globalSend,
              children: children
            });
}

var make = GlobalSendContext;

export {
  context ,
  provider ,
  use ,
  useOpenModal ,
  make ,
  
}
/* context Not a pure module */
