// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoModel from "./avoModel.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getId(namedBranch) {
  if (namedBranch) {
    return namedBranch._0;
  } else {
    return "master";
  }
}

function getName(namedBranch) {
  if (namedBranch) {
    return namedBranch._1;
  } else {
    return "main";
  }
}

function toUnnamed(namedBranch) {
  if (namedBranch) {
    return {
            NAME: "Branch",
            VAL: namedBranch._0
          };
  } else {
    return "Master";
  }
}

function fromUnnamed(model, unnamedBranch) {
  if (typeof unnamedBranch !== "object") {
    return /* Master */0;
  }
  var id = unnamedBranch.VAL;
  var name = Belt_Option.getWithDefault(AvoModel.getBranchName(model, id), "");
  return /* Branch */{
          _0: id,
          _1: name
        };
}

export {
  getId ,
  getName ,
  toUnnamed ,
  fromUnnamed ,
  
}
/* AvoModel Not a pure module */
