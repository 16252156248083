// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as MappedModules from "../../../shared/MappedModules.mjs";

var empty_newEvents = [];

var empty_updatedEvents = [];

var empty_warnings = {
  parseWarnings: [],
  filteredOutEventInfo: [],
  filteredOutPropertyInfo: []
};

var empty_newProperties = [];

var empty_updatedProperties = [];

var empty_allParsedProperties = [];

var empty_sources = [];

var empty_propertyBundles = [];

var empty = {
  formatName: "",
  newEvents: empty_newEvents,
  updatedEvents: empty_updatedEvents,
  warnings: empty_warnings,
  newProperties: empty_newProperties,
  updatedProperties: empty_updatedProperties,
  allParsedProperties: empty_allParsedProperties,
  sources: empty_sources,
  propertyBundles: empty_propertyBundles
};

function getId(param) {
  return param.name;
}

function eq(a, b) {
  return Caml.caml_string_compare(getId(a), getId(b)) === 0;
}

var PropertiesByName = MappedModules.MakeOrderedMapModule({
      getId: getId,
      eq: eq
    });

var Mapped = {
  PropertiesByName: PropertiesByName
};

export {
  empty ,
  Mapped ,
  
}
/* PropertiesByName Not a pure module */
