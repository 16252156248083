// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Zustand from "../Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as SnippetTypes from "../detailviews/SnippetTypes.mjs";

var diffViewTypeKey = "branchImplementationDiffViewType";

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      return {
              diffViewType: Belt_Option.getWithDefault(Belt_Option.flatMap(Dom_storage.getItem(diffViewTypeKey, localStorage), SnippetTypes.DiffViewType.tFromJs), "unified"),
              setDiffViewType: (function (diffViewType) {
                  return set(function (state) {
                              return {
                                      diffViewType: diffViewType,
                                      setDiffViewType: state.setDiffViewType
                                    };
                            });
                })
            };
    });

function useSetDiffViewType(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.setDiffViewType;
              }));
}

function useDiffViewType(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.diffViewType;
              }));
}

export {
  diffViewTypeKey ,
  StoreConfig ,
  ZustandStore ,
  store ,
  useSetDiffViewType ,
  useDiffViewType ,
  
}
/* ZustandStore Not a pure module */
