// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Domain__Types from "./Domain__Types.mjs";

function encodeItems(items) {
  return Json_encode.object_({
              hd: [
                "events",
                Json_encode.array((function (prim) {
                        return prim;
                      }), items.events)
              ],
              tl: {
                hd: [
                  "eventVariants",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), items.eventVariants)
                ],
                tl: /* [] */0
              }
            });
}

function encodeDomainItem(item) {
  if (item.NAME === "event") {
    return Json_encode.object_({
                hd: [
                  "type",
                  "event"
                ],
                tl: {
                  hd: [
                    "eventId",
                    item.VAL
                  ],
                  tl: /* [] */0
                }
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "type",
                  "eventVariant"
                ],
                tl: {
                  hd: [
                    "eventVariantId",
                    item.VAL
                  ],
                  tl: /* [] */0
                }
              });
  }
}

function encodeDomainItems(items) {
  return Json_encode.array(encodeDomainItem, items);
}

function encode(domain) {
  return Json_encode.object_({
              hd: [
                "id",
                domain.id
              ],
              tl: {
                hd: [
                  "name",
                  domain.name
                ],
                tl: {
                  hd: [
                    "description",
                    domain.description
                  ],
                  tl: {
                    hd: [
                      "items",
                      encodeItems(domain.items)
                    ],
                    tl: {
                      hd: [
                        "createdAt",
                        Json_encode.date(domain.createdAt)
                      ],
                      tl: {
                        hd: [
                          "lastUpdatedAt",
                          Json_encode.date(domain.lastUpdatedAt)
                        ],
                        tl: {
                          hd: [
                            "lastUpdatedBy",
                            domain.lastUpdatedBy
                          ],
                          tl: {
                            hd: [
                              "archived",
                              domain.archived
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function decodeItems(json) {
  return {
          events: Json_decode.field("events", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          eventVariants: Json_decode.field("eventVariants", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

function decodeDomainItem(json) {
  var match = Json_decode.field("type", Json_decode.string, json);
  switch (match) {
    case "event" :
        return {
                NAME: "event",
                VAL: Json_decode.field("eventId", Json_decode.string, json)
              };
    case "eventVariant" :
        return {
                NAME: "eventVariant",
                VAL: Json_decode.field("eventVariantId", Json_decode.string, json)
              };
    default:
      return ;
  }
}

function decodeDomainItems(json) {
  return Belt_Array.keepMapU(Json_decode.array(decodeDomainItem, json), (function (i) {
                return i;
              }));
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          items: Json_decode.field("items", decodeItems, json),
          createdAt: Json_decode.field("createdAt", Json_decode.date, json),
          lastUpdatedAt: Json_decode.oneOf({
                hd: (function (param) {
                    return Json_decode.field("lastUpdatedAt", Json_decode.date, param);
                  }),
                tl: {
                  hd: (function (param) {
                      return Json_decode.field("updatedAt", Json_decode.date, param);
                    }),
                  tl: /* [] */0
                }
              }, json),
          lastUpdatedBy: Json_decode.field("lastUpdatedBy", Json_decode.string, json),
          archived: Json_decode.field("archived", Json_decode.bool, json)
        };
}

function encode$1(member) {
  return Json_encode.object_({
              hd: [
                "role",
                Domain__Types.Member.roleToJs(member.role)
              ],
              tl: {
                hd: [
                  "userId",
                  member.id
                ],
                tl: {
                  hd: [
                    "domainId",
                    member.domainId
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$1(json) {
  var role = Domain__Types.Member.roleFromJs(Json_decode.field("role", Json_decode.string, json));
  if (role !== undefined) {
    return {
            id: Json_decode.field("userId", Json_decode.string, json),
            role: role,
            domainId: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                        return Json_decode.field("domainId", Json_decode.string, param);
                      }), json), "")
          };
  } else {
    return {
            id: Json_decode.field("userId", Json_decode.string, json),
            role: "member",
            domainId: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                        return Json_decode.field("domainId", Json_decode.string, param);
                      }), json), "")
          };
  }
}

var Member = {
  encode: encode$1,
  decode: decode$1
};

export {
  encodeItems ,
  encodeDomainItem ,
  encodeDomainItems ,
  encode ,
  decodeItems ,
  decodeDomainItem ,
  decodeDomainItems ,
  decode ,
  Member ,
  
}
/* No side effect */
