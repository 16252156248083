// Generated by ReScript, PLEASE EDIT WITH CARE

import Shortid from "shortid";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function eventsParser(parserOutput, csv) {
  var allProperties = Belt_Array.getExn(parserOutput.events, 0).properties;
  var firstEventColumnIndex = "Q".charCodeAt(0) - 65 | 0;
  var propertyNameColumnIndex = "D".charCodeAt(0) - 65 | 0;
  var avoEventNamesRow = Belt_Option.getWithDefault(Belt_Array.get(csv, 2), []);
  var getCellValue = function (columnNumber, rowNumber) {
    return Belt_Array.getExn(Belt_Array.getExn(csv, rowNumber - 1 | 0), columnNumber - 1 | 0).trim();
  };
  var events = Belt_Array.keepMap(Belt_Array.mapWithIndex(avoEventNamesRow.slice(firstEventColumnIndex), (function (columnIndex, eventName) {
              var columnNumber = (firstEventColumnIndex + columnIndex | 0) + 1 | 0;
              var eventName$1 = eventName.trim();
              if (eventName$1 !== "") {
                return {
                        id: Shortid(),
                        name: eventName$1,
                        description: getCellValue(columnNumber, 6),
                        categories: Belt_Array.keep([
                              getCellValue(columnNumber, 14).toLowerCase() === "true" ? "Automatic" : "",
                              getCellValue(columnNumber, 15).toLowerCase() === "true" ? "Recommended" : "",
                              getCellValue(columnNumber, 16).toLowerCase() === "true" ? "Ecommerce" : "",
                              getCellValue(columnNumber, 17).toLowerCase() === "true" ? "Custom" : "",
                              getCellValue(columnNumber, 18).toLowerCase() === "true" ? "Custom" : ""
                            ], (function (value) {
                                return value !== "";
                              })),
                        tags: Belt_Array.concatMany([
                              Belt_Array.map(Belt_Array.keep(getCellValue(columnNumber, 11).split(","), (function (tag) {
                                          return tag !== "";
                                        })), (function (tag) {
                                      return "owner:" + tag.trim();
                                    })),
                              Belt_Array.map(Belt_Array.keep(getCellValue(columnNumber, 12).split(","), (function (tag) {
                                          return tag !== "";
                                        })), (function (tag) {
                                      return "feature:" + tag.trim();
                                    })),
                              Belt_Array.map(Belt_Array.keep(getCellValue(columnNumber, 13).split(","), (function (tag) {
                                          return tag !== "";
                                        })), (function (tag) {
                                      return "stakeholder:" + tag.trim();
                                    }))
                            ]),
                        properties: Belt_Array.keepMap(csv.slice(19), (function (propertyRow) {
                                if (Belt_Array.getExn(propertyRow, columnNumber - 1 | 0).trim() === "") {
                                  return ;
                                }
                                var propertyName = Belt_Array.getExn(propertyRow, propertyNameColumnIndex).trim();
                                return Belt_Array.getBy(allProperties, (function (property) {
                                              return property.name === propertyName;
                                            }));
                              })),
                        propertyBundleNames: [],
                        sources: Belt_Array.keep([
                              getCellValue(columnNumber, 7).toLowerCase() === "true" ? "Android" : "",
                              getCellValue(columnNumber, 8).toLowerCase() === "true" ? "iOS" : "",
                              getCellValue(columnNumber, 9).toLowerCase() === "true" ? "Web - JavaScript" : ""
                            ], (function (value) {
                                return value !== "";
                              })),
                        nameMapping: Belt_Array.keep([
                              [
                                getCellValue(columnNumber, 4).trim(),
                                "Firebase Analytics"
                              ],
                              [
                                getCellValue(columnNumber, 5).trim(),
                                "Google Analytics"
                              ]
                            ], (function (param) {
                                return param[0] !== "";
                              }))
                      };
              }
              
            })), (function ($$event) {
          return $$event;
        }));
  return {
          formatName: parserOutput.formatName,
          events: Belt_Array.concat(parserOutput.events, events),
          propertiesWithoutEvent: parserOutput.propertiesWithoutEvent,
          warnings: parserOutput.warnings,
          propertyBundles: parserOutput.propertyBundles
        };
}

export {
  eventsParser ,
  
}
/* shortid Not a pure module */
