// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as DateFns from "../../app/src/DateFns.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(t) {
  if (typeof t === "number") {
    return Json_encode.object_({
                hd: [
                  "type",
                  "Never"
                ],
                tl: /* [] */0
              });
  }
  switch (t.TAG | 0) {
    case /* CurrentAppVersion */0 :
        return Json_encode.object_({
                    hd: [
                      "type",
                      "CurrentAppVersion"
                    ],
                    tl: {
                      hd: [
                        "appVersion",
                        t._0
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* NextAppVersion */1 :
        return Json_encode.object_({
                    hd: [
                      "type",
                      "NextAppVersion"
                    ],
                    tl: {
                      hd: [
                        "appVersion",
                        t._0
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* CustomAppVersion */2 :
        return Json_encode.object_({
                    hd: [
                      "type",
                      "CustomAppVersion"
                    ],
                    tl: {
                      hd: [
                        "appVersion",
                        t._0
                      ],
                      tl: /* [] */0
                    }
                  });
    case /* Date */3 :
        return Json_encode.object_({
                    hd: [
                      "type",
                      "Date"
                    ],
                    tl: {
                      hd: [
                        "date",
                        t._0.toISOString()
                      ],
                      tl: /* [] */0
                    }
                  });
    
  }
}

function decode(json) {
  var unexpectedType = Json_decode.field("type", Json_decode.string, json);
  switch (unexpectedType) {
    case "CurrentAppVersion" :
        return {
                TAG: /* CurrentAppVersion */0,
                _0: Json_decode.field("appVersion", Json_decode.string, json)
              };
    case "CustomAppVersion" :
        return {
                TAG: /* CustomAppVersion */2,
                _0: Json_decode.field("appVersion", Json_decode.string, json)
              };
    case "Date" :
        return {
                TAG: /* Date */3,
                _0: Json_decode.field("date", Json_decode.date, json)
              };
    case "Never" :
        return /* Never */0;
    case "NextAppVersion" :
        return {
                TAG: /* NextAppVersion */1,
                _0: Json_decode.field("appVersion", Json_decode.string, json)
              };
    default:
      return Pervasives.failwith("InspectorIssueStatus.ValidateIn.decode: Unexpected issue status validateIn type: " + unexpectedType);
  }
}

var ValidateIn = {
  encode: encode,
  decode: decode
};

function toStringWithDetails(t) {
  var dateToString = function (date) {
    return DateFns.format("d MMM, yyyy", date) + " at " + DateFns.format("HH:mm", date);
  };
  var validateInToString = function (validateIn) {
    if (typeof validateIn === "number") {
      return "";
    }
    switch (validateIn.TAG | 0) {
      case /* NextAppVersion */1 :
          return " in next release after " + validateIn._0;
      case /* CurrentAppVersion */0 :
      case /* CustomAppVersion */2 :
          return " in " + validateIn._0;
      case /* Date */3 :
          return " after " + dateToString(validateIn._0);
      
    }
  };
  if (typeof t === "number") {
    return "Unresolved";
  } else if (t.TAG === /* Ignored */0) {
    return "Ignored" + validateInToString(t._0);
  } else {
    return "Resolved" + validateInToString(t._0);
  }
}

function encode$1(t) {
  if (typeof t === "number") {
    return Json_encode.object_({
                hd: [
                  "type",
                  "Unresolved"
                ],
                tl: /* [] */0
              });
  } else if (t.TAG === /* Ignored */0) {
    return Json_encode.object_({
                hd: [
                  "type",
                  "Ignored"
                ],
                tl: {
                  hd: [
                    "validateIn",
                    encode(t._0)
                  ],
                  tl: /* [] */0
                }
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "type",
                  "Resolved"
                ],
                tl: {
                  hd: [
                    "validateIn",
                    encode(t._0)
                  ],
                  tl: /* [] */0
                }
              });
  }
}

function decode$1(json) {
  var unexpectedIssueStatus = Json_decode.field("type", Json_decode.string, json);
  switch (unexpectedIssueStatus) {
    case "Ignored" :
        return {
                TAG: /* Ignored */0,
                _0: Json_decode.field("validateIn", decode, json)
              };
    case "Resolved" :
        return {
                TAG: /* Resolved */1,
                _0: Json_decode.field("validateIn", decode, json)
              };
    case "Unresolved" :
        return /* Unresolved */0;
    default:
      return Pervasives.failwith("IssueStatus.Status.decode: Unexpected issue status: " + unexpectedIssueStatus);
  }
}

function encodeToPostgresColumns(includeNullColumnsOpt, t) {
  var includeNullColumns = includeNullColumnsOpt !== undefined ? includeNullColumnsOpt : false;
  if (typeof t === "number") {
    return Belt_Array.concat([[
                  "issue_status",
                  "Unresolved"
                ]], includeNullColumns ? [[
                    "issue_status_validate_in",
                    null
                  ]] : []);
  } else if (t.TAG === /* Ignored */0) {
    return [
            [
              "issue_status",
              "Ignored"
            ],
            [
              "issue_status_validate_in",
              JSON.stringify(encode(t._0))
            ]
          ];
  } else {
    return [
            [
              "issue_status",
              "Resolved"
            ],
            [
              "issue_status_validate_in",
              JSON.stringify(encode(t._0))
            ]
          ];
  }
}

function decodeFromPostgres(json) {
  var issueStatus = Json_decode.optional((function (param) {
          return Json_decode.field("issue_status", Json_decode.string, param);
        }), json);
  if (issueStatus === undefined) {
    return /* Unresolved */0;
  }
  switch (issueStatus) {
    case "Ignored" :
        var validateIn = Json_decode.optional((function (param) {
                return Json_decode.field("issue_status_validate_in", decode, param);
              }), json);
        if (validateIn !== undefined) {
          return {
                  TAG: /* Ignored */0,
                  _0: validateIn
                };
        } else {
          return Pervasives.failwith("IssueStatus.Status.decodeFromPostgres: Ignored issue missing validateIn config, issueId=" + Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                return Json_decode.field("issue_id", Json_decode.string, param);
                              }), json), "N/A"));
        }
    case "Resolved" :
        var validateIn$1 = Json_decode.optional((function (param) {
                return Json_decode.field("issue_status_validate_in", decode, param);
              }), json);
        if (validateIn$1 !== undefined) {
          return {
                  TAG: /* Resolved */1,
                  _0: validateIn$1
                };
        } else {
          return Pervasives.failwith("IssueStatus.Status.decodeFromPostgres: Resolved issue missing validateIn config, issueId=" + Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                                return Json_decode.field("issue_id", Json_decode.string, param);
                              }), json), "N/A"));
        }
    case "Unresolved" :
        return /* Unresolved */0;
    default:
      return Pervasives.failwith("IssueStatus.Status.decodeFromPostgres: Resolved issue missing validateIn config, issueId=" + Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                            return Json_decode.field("issue_id", Json_decode.string, param);
                          }), json), "N/A") + " issueStatus=" + issueStatus);
  }
}

var Status = {
  toStringWithDetails: toStringWithDetails,
  encode: encode$1,
  decode: decode$1,
  encodeToPostgresColumns: encodeToPostgresColumns,
  decodeFromPostgres: decodeFromPostgres
};

function toString(t) {
  switch (t) {
    case /* Unresolved */0 :
        return "Unresolved";
    case /* Ignored */1 :
        return "Ignored";
    case /* Resolved */2 :
        return "Resolved";
    
  }
}

function fromString(value) {
  var match = value.toLowerCase();
  switch (match) {
    case "ignored" :
        return /* Ignored */1;
    case "resolved" :
        return /* Resolved */2;
    case "unresolved" :
        return /* Unresolved */0;
    default:
      return Pervasives.failwith("IssueStatus.StatusQueryParam.fromString: Unexpected string: " + value);
  }
}

var StatusQueryParam = {
  toString: toString,
  fromString: fromString
};

function comparisonOrder(issueStatus) {
  var match = issueStatus.status;
  if (typeof match === "number") {
    return 0;
  } else if (match.TAG === /* Ignored */0) {
    return 1;
  } else {
    return 2;
  }
}

function compare(a, b) {
  return Caml.caml_int_compare(comparisonOrder(a), comparisonOrder(b));
}

function encode$2(t) {
  var issueStatus = encode$1(t.status);
  return Json_encode.object_({
              hd: [
                "status",
                issueStatus
              ],
              tl: {
                hd: [
                  "updatedAt",
                  Json_encode.nullable(Json_encode.date, t.updatedAt)
                ],
                tl: {
                  hd: [
                    "updatedBy",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), t.updatedBy)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$2(json) {
  return {
          status: Json_decode.field("status", decode$1, json),
          updatedAt: Json_decode.optional((function (param) {
                  return Json_decode.field("updatedAt", Json_decode.date, param);
                }), json),
          updatedBy: Json_decode.optional((function (param) {
                  return Json_decode.field("updatedBy", Json_decode.string, param);
                }), json)
        };
}

function decodeFromPostgres$1(json) {
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("issue_status_updated_at", Json_decode.string, param);
        }), json);
  return {
          status: decodeFromPostgres(json),
          updatedAt: Belt_Option.map(__x, (function (prim) {
                  return new Date(prim);
                })),
          updatedBy: Json_decode.optional((function (param) {
                  return Json_decode.field("issue_status_updated_by", Json_decode.string, param);
                }), json)
        };
}

function encodeToPostgresColumns$1(includeNullColumnsOpt, t) {
  var includeNullColumns = includeNullColumnsOpt !== undefined ? includeNullColumnsOpt : false;
  var issueStatusColumns = encodeToPostgresColumns(includeNullColumns, t.status);
  return Belt_Array.concatMany([
              issueStatusColumns,
              Belt_Option.mapWithDefault(t.updatedAt, [], (function (updatedAt) {
                      return [[
                                "issue_status_updated_at",
                                updatedAt.toISOString()
                              ]];
                    })),
              Belt_Option.mapWithDefault(t.updatedBy, [], (function (updatedBy) {
                      return [[
                                "issue_status_updated_by",
                                updatedBy
                              ]];
                    }))
            ]);
}

var updatedBySystem = "system";

var emptyUnresolved = {
  status: /* Unresolved */0,
  updatedAt: undefined,
  updatedBy: undefined
};

export {
  ValidateIn ,
  Status ,
  StatusQueryParam ,
  updatedBySystem ,
  emptyUnresolved ,
  compare ,
  encode$2 as encode,
  decode$2 as decode,
  decodeFromPostgres$1 as decodeFromPostgres,
  encodeToPostgresColumns$1 as encodeToPostgresColumns,
  
}
/* DateFns Not a pure module */
