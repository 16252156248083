// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../bs-case/src/case.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

var toUniqueName = Case.camel;

function parseIndexFromUniqueName(uniqueName) {
  return Belt_Option.flatMapU(uniqueName, (function (uniqueName) {
                return Belt_Option.flatMapU(Belt_Option.flatMapU(Caml_option.null_to_opt(uniqueName.match(/\((\d+)\)$/)), (function (matches) {
                                  return Belt_Array.get(matches, 1);
                                })), Pervasives.int_of_string_opt);
              }));
}

function maxPositiveIntInList(list) {
  return Belt_List.reduceU(list, undefined, (function (maxUniqueIndex, uniqueIndex) {
                if (uniqueIndex !== undefined) {
                  if (maxUniqueIndex !== undefined && uniqueIndex <= maxUniqueIndex) {
                    return maxUniqueIndex;
                  } else {
                    return uniqueIndex;
                  }
                } else {
                  return maxUniqueIndex;
                }
              }));
}

function getUniqueNameIndex(uniqueNameToIds, name, id, startIndex) {
  return Belt_Option.flatMapU(Belt_MapString.get(uniqueNameToIds, Case.camel(name)), (function (idsForUniqueName) {
                var hasDuplicates = idsForUniqueName.length > 1;
                if (hasDuplicates) {
                  if (startIndex === undefined) {
                    return Belt_Option.mapU(Belt_Array.getIndexBy(idsForUniqueName, (function (item) {
                                      return item === id;
                                    })), (function (uniqueIndex) {
                                  return uniqueIndex;
                                }));
                  }
                  
                } else {
                  if (startIndex === undefined) {
                    return ;
                  }
                  if (startIndex === 0) {
                    return ;
                  }
                  
                }
                return Belt_Option.mapU(Belt_Array.getIndexBy(idsForUniqueName, (function (item) {
                                  return item === id;
                                })), (function (uniqueIndex) {
                              return uniqueIndex + startIndex | 0;
                            }));
              }));
}

function getPropertiesWithUniqueName(name, model) {
  return Belt_List.keepU(model.properties, (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return false;
                } else {
                  return Case.camel(property._0.name) === Case.camel(name);
                }
              }));
}

function nextUniqueIndexInModelForPropertyName(name, model) {
  var modelPropertiesWithSameName = getPropertiesWithUniqueName(name, model);
  var numberOfModelPropertiesWithSameName = Belt_List.length(modelPropertiesWithSameName);
  var maxUniqueIndexInPropertiesWithSameName = maxPositiveIntInList(Belt_List.mapU(modelPropertiesWithSameName, (function (property) {
              var prop = ModelUtils.resolveProperty(model, property);
              return parseIndexFromUniqueName(Belt_Option.flatMapU(prop, (function (prop) {
                                return prop.uniqueName;
                              })));
            })));
  if (maxUniqueIndexInPropertiesWithSameName !== undefined) {
    return Math.max(numberOfModelPropertiesWithSameName === 0 ? 0 : 1, maxUniqueIndexInPropertiesWithSameName + 1 | 0);
  } else if (numberOfModelPropertiesWithSameName !== 0) {
    return numberOfModelPropertiesWithSameName;
  } else {
    return ;
  }
}

function assignUniqueIndexesToPropertiesWithSameName(parsedProperties, model) {
  var globalPropertyIdsGroupedByUniqueName = Belt_Array.reduceU(parsedProperties, undefined, (function (propertyNameToIdsArray, property) {
          return Belt_MapString.update(propertyNameToIdsArray, Case.camel(property.name), (function (maybeIds) {
                        if (maybeIds !== undefined) {
                          return Belt_Array.concat(maybeIds, [property.id]);
                        } else {
                          return [property.id];
                        }
                      }));
        }));
  return Belt_Array.mapU(parsedProperties, (function (property) {
                return {
                        id: property.id,
                        name: property.name,
                        uniqueNameIndex: getUniqueNameIndex(globalPropertyIdsGroupedByUniqueName, property.name, property.id, nextUniqueIndexInModelForPropertyName(property.name, model)),
                        description: property.description,
                        type_: property.type_,
                        list: property.list,
                        matches: property.matches,
                        warnings: property.warnings,
                        presence: property.presence,
                        sendAs: property.sendAs,
                        nameMapping: property.nameMapping
                      };
              }));
}

var Properties = {
  getPropertiesWithUniqueName: getPropertiesWithUniqueName,
  nextUniqueIndexInModelForPropertyName: nextUniqueIndexInModelForPropertyName,
  assignUniqueIndexesToPropertiesWithSameName: assignUniqueIndexesToPropertiesWithSameName
};

function getEventsWithUniqueName(name, model) {
  return Belt_List.keepU(model.events, (function ($$event) {
                return Case.camel($$event.name) === Case.camel(name);
              }));
}

function nextUniqueIndexInModelForEventName(name, model) {
  var modelEventsWithSameName = getEventsWithUniqueName(name, model);
  var numberOfModelEventsWithSameName = Belt_List.length(modelEventsWithSameName);
  var maxUniqueIndexInEventsWithSameName = maxPositiveIntInList(Belt_List.mapU(modelEventsWithSameName, (function ($$event) {
              return parseIndexFromUniqueName($$event.uniqueName);
            })));
  if (maxUniqueIndexInEventsWithSameName !== undefined) {
    return Math.max(numberOfModelEventsWithSameName === 0 ? 0 : 1, maxUniqueIndexInEventsWithSameName + 1 | 0);
  } else if (numberOfModelEventsWithSameName !== 0) {
    return numberOfModelEventsWithSameName;
  } else {
    return ;
  }
}

function assignUniqueIndexesToEventsWithSameName(parsedEvents, model) {
  var eventsGroupedByUniqueName = Belt_Array.reduceU(parsedEvents, undefined, (function (eventNameToIdsArray, $$event) {
          return Belt_MapString.update(eventNameToIdsArray, Case.camel($$event.name), (function (maybeIds) {
                        if (maybeIds !== undefined) {
                          return Belt_Array.concat(maybeIds, [$$event.id]);
                        } else {
                          return [$$event.id];
                        }
                      }));
        }));
  return Belt_Array.mapU(parsedEvents, (function ($$event) {
                var startIndex = nextUniqueIndexInModelForEventName($$event.name, model);
                return {
                        id: $$event.id,
                        name: $$event.name,
                        uniqueNameIndex: getUniqueNameIndex(eventsGroupedByUniqueName, $$event.name, $$event.id, startIndex),
                        description: $$event.description,
                        categories: $$event.categories,
                        tags: $$event.tags,
                        propertyIds: $$event.propertyIds,
                        updatedPropertyIds: $$event.updatedPropertyIds,
                        propertyBundleIds: $$event.propertyBundleIds,
                        sources: $$event.sources,
                        warnings: $$event.warnings,
                        nameMapping: $$event.nameMapping
                      };
              }));
}

var Events = {
  getEventsWithUniqueName: getEventsWithUniqueName,
  nextUniqueIndexInModelForEventName: nextUniqueIndexInModelForEventName,
  assignUniqueIndexesToEventsWithSameName: assignUniqueIndexesToEventsWithSameName
};

export {
  toUniqueName ,
  parseIndexFromUniqueName ,
  maxPositiveIntInList ,
  getUniqueNameIndex ,
  Properties ,
  Events ,
  
}
/* Case Not a pure module */
