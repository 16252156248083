// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Number from "../Number.mjs";
import * as Semver from "../../../shared/bindings/semver.mjs";
import * as Semver$1 from "semver";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as InspectorIssueType from "../../../shared/models/InspectorIssueType.mjs";
import * as InspectorIssueStatus from "../../../shared/models/InspectorIssueStatus.mjs";

function compareRelease(a, b) {
  if (a === b) {
    return 0;
  }
  var match = Semver.coerceAndValidate(a);
  var match$1 = Semver.coerceAndValidate(b);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return Semver$1.compare(match, match$1);
    } else {
      return 1;
    }
  } else if (match$1 !== undefined) {
    return -1;
  } else {
    return Caml.caml_string_compare(a, b);
  }
}

function compare(a, b) {
  var a$1 = a.release;
  var b$1 = b.release;
  return compareRelease(a$1, b$1);
}

var compareU = compare;

function encode(t) {
  return Json_encode.object_({
              hd: [
                "release",
                t.release
              ],
              tl: {
                hd: [
                  "lastSeen",
                  Json_encode.nullable(Json_encode.date, t.lastSeen)
                ],
                tl: {
                  hd: [
                    "issueCount",
                    t.issueCount
                  ],
                  tl: {
                    hd: [
                      "eventCount",
                      t.eventCount
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

var cmp = compare;

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

var Version = {
  compareRelease: compareRelease,
  compare: compare,
  compareU: compareU,
  encode: encode,
  Comparator: Comparator
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "id",
                t.id
              ],
              tl: {
                hd: [
                  "name",
                  t.name
                ],
                tl: {
                  hd: [
                    "versions",
                    Json_encode.array(encode, t.versions)
                  ],
                  tl: {
                    hd: [
                      "impactedVersions",
                      Json_encode.array(encode, t.impactedVersions)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

var Source = {
  encode: encode$1
};

function getIssuePercentage(eventCount, issueCount) {
  if (issueCount !== 0) {
    if (eventCount !== 0) {
      return issueCount / eventCount;
    } else {
      return 0.0;
    }
  } else {
    return 0.0;
  }
}

function getIssueDisplayPercentage(eventCount, issueCount) {
  return getIssuePercentage(eventCount, issueCount) * 100.0;
}

function getDisplayPercentageString(eventCount, issueCount) {
  return $$Number.getRoughPercentageString(getIssueDisplayPercentage(eventCount, issueCount));
}

function fromIssue(categoryIdsByEventIdMap, eventsByNameMap, sourceNamesByIdMap, issue) {
  var versions = Belt_SortArray.stableSortByU(Belt_Array.map(Js_dict.values(issue.appVersions), (function (appVersion) {
              return {
                      release: appVersion.appVersion,
                      lastSeen: appVersion.lastSeen,
                      issueCount: appVersion.issueCount | 0,
                      eventCount: appVersion.eventCount | 0
                    };
            })), (function (a, b) {
          return compareU(b, a);
        }));
  var match = Belt_MapString.get(eventsByNameMap, issue.eventName);
  var match$1 = Belt_MapString.get(eventsByNameMap, issue.eventName);
  return {
          id: issue.issueId,
          sharedId: issue.sharedIssueId,
          issueType: issue.issueType,
          issueStatus: issue.issueStatus,
          eventName: issue.eventName,
          propertyName: issue.propertyName,
          source: {
            id: issue.sourceId,
            name: Belt_MapString.getWithDefault(sourceNamesByIdMap, issue.sourceId, "Unknown source"),
            versions: versions,
            impactedVersions: Belt_Array.keepU(versions, (function (param) {
                    return param.issueCount > 0;
                  }))
          },
          categories: match !== undefined ? Belt_MapString.getWithDefault(categoryIdsByEventIdMap, match.id, []) : [],
          tags: match$1 !== undefined ? Belt_List.toArray(match$1.tags) : [],
          firstSeen: issue.firstSeen,
          lastSeen: issue.lastSeen,
          eventCount: issue.eventCount | 0,
          issueCount: issue.issueCount | 0,
          newestAppVersion: issue.newestAppVersion,
          oldestAppVersion: issue.oldestAppVersion,
          regression: issue.regression
        };
}

function fromIssues(model, issues) {
  var eventsByNameMap = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(model.events, (function ($$event) {
                  return [
                          $$event.name,
                          $$event
                        ];
                }))));
  var categoryIdsByEventIdMap = Belt_List.reduce(model.goals, undefined, (function (map, param) {
          var categoryId = param.id;
          return Belt_List.reduce(param.events, map, (function (map, eventId) {
                        return Belt_MapString.update(map, eventId, (function (maybeItem) {
                                      if (maybeItem !== undefined) {
                                        return Belt_Array.concat([categoryId], maybeItem);
                                      } else {
                                        return [categoryId];
                                      }
                                    }));
                      }));
        }));
  var archivedSourceNamesById = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(model.archive.sources, (function (param) {
                  return [
                          param.id,
                          Belt_Option.mapWithDefault(param.name, "Untitled source (archived)", (function (name) {
                                  return name + " (archived)";
                                }))
                        ];
                }))));
  var sourceNamesByIdMap = Belt_MapString.mergeMany(archivedSourceNamesById, Belt_List.toArray(Belt_List.map(model.sources, (function (param) {
                  return [
                          param.id,
                          Belt_Option.getWithDefault(param.name, "Untitled source")
                        ];
                }))));
  return Belt_Array.map(issues, (function (param) {
                return fromIssue(categoryIdsByEventIdMap, eventsByNameMap, sourceNamesByIdMap, param);
              }));
}

function getSourceReport(issues) {
  var sources = {};
  var sourceTotalEventCount = {};
  Belt_Array.forEachU(issues, (function (issue) {
          var existingEventCount = Js_dict.get(sourceTotalEventCount, issue.source.id);
          if (existingEventCount !== undefined) {
            sourceTotalEventCount[issue.source.id] = existingEventCount + issue.eventCount | 0;
          } else {
            sourceTotalEventCount[issue.source.id] = issue.eventCount;
          }
          var releases = Js_dict.get(sources, issue.source.id);
          if (releases !== undefined) {
            var releases$1 = Caml_option.valFromOption(releases);
            Belt_Array.forEachU(Belt_Array.mapU(issue.source.versions, (function (param) {
                        return [
                                param.release,
                                param.eventCount
                              ];
                      })), (function (param) {
                    var eventCount = param[1];
                    var release = param[0];
                    var existingEventCount = Js_dict.get(releases$1, release);
                    if (existingEventCount !== undefined) {
                      releases$1[release] = existingEventCount + eventCount | 0;
                    } else {
                      releases$1[release] = eventCount;
                    }
                    
                  }));
            sources[issue.source.id] = releases$1;
            return ;
          }
          var versions = Js_dict.fromArray(Belt_Array.mapU(issue.source.versions, (function (param) {
                      return [
                              param.release,
                              param.eventCount
                            ];
                    })));
          sources[issue.source.id] = versions;
          
        }));
  return {
          sourcesCount: Object.keys(sources).length,
          releasesCount: Belt_Array.concatMany(Belt_Array.mapU(Js_dict.values(sources), Js_dict.entries)).length,
          breakdown: Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(sources), (function (param) {
                      var sourceId = param[0];
                      return [
                              sourceId,
                              {
                                releases: Belt_SortArray.stableSortByU(Js_dict.entries(param[1]), (function (a, b) {
                                        return compareRelease(b[0], a[0]);
                                      })),
                                totalEventCount: sourceTotalEventCount[sourceId]
                              }
                            ];
                    })))
        };
}

var Utils = {
  getIssuePercentage: getIssuePercentage,
  getIssueDisplayPercentage: getIssueDisplayPercentage,
  getDisplayPercentageString: getDisplayPercentageString,
  fromIssue: fromIssue,
  fromIssues: fromIssues,
  getSourceReport: getSourceReport
};

function encode$2(t) {
  return Json_encode.object_({
              hd: [
                "id",
                t.id
              ],
              tl: {
                hd: [
                  "sharedId",
                  t.sharedId
                ],
                tl: {
                  hd: [
                    "issueType",
                    InspectorIssueType.encode(t.issueType)
                  ],
                  tl: {
                    hd: [
                      "issueStatus",
                      InspectorIssueStatus.encode(t.issueStatus)
                    ],
                    tl: {
                      hd: [
                        "eventName",
                        t.eventName
                      ],
                      tl: {
                        hd: [
                          "propertyName",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), t.propertyName)
                        ],
                        tl: {
                          hd: [
                            "source",
                            encode$1(t.source)
                          ],
                          tl: {
                            hd: [
                              "categories",
                              Json_encode.array((function (prim) {
                                      return prim;
                                    }), t.categories)
                            ],
                            tl: {
                              hd: [
                                "tags",
                                Json_encode.array((function (prim) {
                                        return prim;
                                      }), t.tags)
                              ],
                              tl: {
                                hd: [
                                  "firstSeen",
                                  Json_encode.date(t.firstSeen)
                                ],
                                tl: {
                                  hd: [
                                    "lastSeen",
                                    Json_encode.date(t.lastSeen)
                                  ],
                                  tl: {
                                    hd: [
                                      "eventCount",
                                      t.eventCount
                                    ],
                                    tl: {
                                      hd: [
                                        "issueCount",
                                        t.issueCount
                                      ],
                                      tl: {
                                        hd: [
                                          "newestAppVersion",
                                          t.newestAppVersion
                                        ],
                                        tl: {
                                          hd: [
                                            "oldestAppVersion",
                                            t.oldestAppVersion
                                          ],
                                          tl: {
                                            hd: [
                                              "regression",
                                              t.regression
                                            ],
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

export {
  Version ,
  Source ,
  Utils ,
  encode$2 as encode,
  
}
/* Comparator Not a pure module */
