// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Sentry from "./externals/Sentry.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as PromiseExt from "./externals/PromiseExt.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as EventNameComponent from "../../shared/models/EventNameComponent.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function getCollectionPath(firestore, schemaId, configString) {
  return firestore.collection("schemas").doc(schemaId).collection("eventNameConfigs").doc(configString).collection("eventComponents");
}

function getEventNameComponent(firestore, schemaId, eventName, configString) {
  return getCollectionPath(firestore, schemaId, configString).doc(eventName).get().then(function (snap) {
              var exists = snap.exists;
              if (!exists) {
                return ;
              }
              try {
                return Json_decode.field("components", EventNameComponent.decodeArray, snap.data());
              }
              catch (raw_exn){
                var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                if (exn.RE_EXN_ID === Json_decode.DecodeError) {
                  return ;
                }
                throw exn;
              }
            });
}

function useEventNameComponent(schemaId, configString, eventName) {
  return FirebaseFetcherHooks.useDoc(getCollectionPath(Firebase.app(undefined).firestore(), schemaId, configString), eventName === "" ? " " : eventName, true);
}

function useEventNameComponents(schemaId, configString) {
  var eventComponents = FirebaseFetcherHooks.useStreamCollectionWithDocIdsArr(getCollectionPath(Firebase.app(undefined).firestore(), schemaId, configString), (function (query) {
          return query;
        }), undefined, (function (json) {
          return Json_decode.field("components", EventNameComponent.decodeArray, json);
        }), configString, undefined);
  var match = React.useState(function () {
        return Belt_MapString.fromArray(eventComponents);
      });
  var setResult = match[1];
  React.useEffect((function () {
          Curry._1(setResult, (function (param) {
                  return Belt_MapString.fromArray(eventComponents);
                }));
          
        }), [eventComponents]);
  return match[0];
}

function setEventNameComponent(firestore, schemaId, eventName, components, configString) {
  var json = Json_encode.object_({
        hd: [
          "components",
          EventNameComponent.encodeArray(components)
        ],
        tl: /* [] */0
      });
  return $$Promise.$$catch(getCollectionPath(firestore, schemaId, configString).doc(eventName).set(json).then(function (param) {
                  return {
                          TAG: /* Ok */0,
                          _0: "Success"
                        };
                }), (function (error) {
                console.error("Failed to write name components", error);
                Sentry.captureException(new Error("Failed to write name components"), {
                      error: error,
                      schemaId: schemaId,
                      eventName: eventName,
                      configString: configString
                    });
                var message = PromiseExt.getErrorMessage(error);
                return Promise.resolve({
                            TAG: /* Error */1,
                            _0: [
                              /* InternalStoreError */2,
                              message
                            ]
                          });
              }));
}

function setEventNameComponentBatch(firestore, schemaId, eventName, components, configString, batch) {
  var json = Json_encode.object_({
        hd: [
          "components",
          EventNameComponent.encodeArray(components)
        ],
        tl: /* [] */0
      });
  var __x = getCollectionPath(firestore, schemaId, configString).doc(eventName);
  batch.set(__x, json);
  return batch;
}

export {
  getCollectionPath ,
  getEventNameComponent ,
  useEventNameComponent ,
  useEventNameComponents ,
  setEventNameComponent ,
  setEventNameComponentBatch ,
  
}
/* react Not a pure module */
