// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import Shortid from "shortid";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function AvoEngagement__RichText$Child(Props) {
  var children = Props.children;
  var isItalic = children.italic;
  var isBold = children.bold;
  var wrapItalic = function (el) {
    if (isItalic) {
      return React.createElement("em", undefined, el);
    } else {
      return el;
    }
  };
  if (children.text.trim() === "") {
    return React.createElement(Spacer.make, {
                height: 10
              });
  } else {
    var el = wrapItalic(children.text);
    if (isBold) {
      return React.createElement("b", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: /* [] */0
                      })
                }, el);
    } else {
      return el;
    }
  }
}

var Child = {
  make: AvoEngagement__RichText$Child
};

function AvoEngagement__RichText$Children(Props) {
  var children = Props.children;
  var key = React.useRef(Shortid());
  return React.createElement($$Text.make, {
              size: "Medium",
              children: Belt_Array.mapWithIndex(children.children, (function (index, child) {
                      return React.createElement(AvoEngagement__RichText$Child, {
                                  children: child,
                                  key: key.current + "-" + String(index)
                                });
                    }))
            });
}

var Children = {
  make: AvoEngagement__RichText$Children
};

function AvoEngagement__RichText(Props) {
  var content = Props.content;
  var key = React.useRef(Shortid());
  return Belt_Array.mapWithIndex(content, (function (index, richText) {
                return React.createElement(AvoEngagement__RichText$Children, {
                            children: richText,
                            key: key.current + "-" + String(index)
                          });
              }));
}

var make = AvoEngagement__RichText;

export {
  Child ,
  Children ,
  make ,
  
}
/* Css Not a pure module */
