// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(t) {
  if (t) {
    return "Property";
  } else {
    return "Event";
  }
}

function decode(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "Event" :
        return /* Event */0;
    case "Property" :
        return /* Property */1;
    default:
      return Pervasives.failwith("Unexpected item type");
  }
}

var ItemType = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                t.schemaId
              ],
              tl: {
                hd: [
                  "nameSpace",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), t.nameSpace)
                ],
                tl: {
                  hd: [
                    "itemType",
                    t.itemType ? "Property" : "Event"
                  ],
                  tl: {
                    hd: [
                      "case",
                      Case.tToJs(t.case)
                    ],
                    tl: {
                      hd: [
                        "inputName",
                        t.inputName
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function decode$1(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          nameSpace: Json_decode.field("nameSpace", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          itemType: Json_decode.field("itemType", decode, json),
          case: Json_decode.field("case", (function (json) {
                  return Belt_Option.getExn(Case.tFromJs(Json_decode.string(json)));
                }), json),
          inputName: Json_decode.field("inputName", Json_decode.string, json)
        };
}

var $$Request = {
  ItemType: ItemType,
  encode: encode$1,
  decode: decode$1
};

function encode$2(t) {
  return Json_encode.object_({
              hd: [
                "feedback",
                t.feedback
              ],
              tl: /* [] */0
            });
}

function decode$2(json) {
  return {
          feedback: Json_decode.field("feedback", Json_decode.string, json)
        };
}

var $$Response = {
  encode: encode$2,
  decode: decode$2
};

export {
  $$Request ,
  $$Response ,
  
}
/* Case Not a pure module */
