// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Styles from "../styles.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.label("row"),
      tl: {
        hd: Css.cursor("pointer"),
        tl: {
          hd: Css.hover({
                hd: Css.backgroundColor(Styles.Color.light01),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.minHeight(Css.px(56)),
              tl: {
                hd: Css.height(Css.px(56)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var checkboxStyles = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.cursor("pointer"),
        tl: {
          hd: Css.display("block"),
          tl: /* [] */0
        }
      }
    });

var propertyInfoStyles = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(0)),
      tl: /* [] */0
    });

var propertyTopStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginBottom(Css.px(2)),
          tl: /* [] */0
        }
      }
    });

var propertyTypeStyles = Curry._1(Css.style, {
      hd: Css.margin(Css.px(0)),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.medium),
          tl: {
            hd: Css.color(Styles.Color.light10),
            tl: {
              hd: Css.fontSize(Styles.FontSize.tiny),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light02),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.borderRadius(Css.px(5)),
                    tl: {
                      hd: Css.padding4(Css.px(2), Css.px(4), Css.px(2), Css.px(4)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var propertyMatchesStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.flexShrink(10000.0),
              tl: {
                hd: Css.minWidth(Css.px(1)),
                tl: {
                  hd: Css.maxWidth(Css.px(500)),
                  tl: {
                    hd: Css.marginTop(Css.px(8)),
                    tl: {
                      hd: Css.marginBottom(Css.px(8)),
                      tl: {
                        hd: Css.marginLeft(Css.px(12)),
                        tl: {
                          hd: Css.marginRight(Css.px(8)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(12)),
                            tl: {
                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var propertyMatchStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: /* [] */0
      }
    });

export {
  rootStyles ,
  checkboxStyles ,
  propertyInfoStyles ,
  propertyTopStyles ,
  propertyTypeStyles ,
  propertyMatchesStyles ,
  propertyMatchStyles ,
  
}
/* rootStyles Not a pure module */
