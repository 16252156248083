// Generated by ReScript, PLEASE EDIT WITH CARE


var defaultOrder = [
  "firstSeen",
  "desc"
];

export {
  defaultOrder ,
  
}
/* No side effect */
