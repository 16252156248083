// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../bs-case/src/case.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as StringSimilarity from "string-similarity";

function getCommonCase(names) {
  var caseCountMap = Belt_List.reduceU(names, undefined, (function (currentCaseMap, name) {
          var caseKey = Case.tToJs(Case.getCasingWithWorkspaceCase(name, "None"));
          return Belt_MapString.updateU(currentCaseMap, caseKey, (function (count) {
                        return Belt_Option.getWithDefault(count, 0) + 1 | 0;
                      }));
        }));
  return Belt_Option.getWithDefault(Belt_Option.flatMapU(Belt_List.head(Belt_List.sortU(Belt_MapString.toList(caseCountMap), (function (param, param$1) {
                            return Caml.caml_int_compare(param$1[1], param[1]);
                          }))), (function (param) {
                    return Case.tFromJs(param[0]);
                  })), "None");
}

function getCommonCase__array(names) {
  var caseCountMap = Belt_Array.reduceU(names, undefined, (function (currentCaseMap, name) {
          var caseKey = Case.tToJs(Case.getCasingWithWorkspaceCase(name, "None"));
          return Belt_MapString.updateU(currentCaseMap, caseKey, (function (count) {
                        return Belt_Option.getWithDefault(count, 0) + 1 | 0;
                      }));
        }));
  return Belt_Option.getWithDefault(Belt_Option.flatMapU(Belt_List.head(Belt_List.sortU(Belt_MapString.toList(caseCountMap), (function (param, param$1) {
                            return Caml.caml_int_compare(param$1[1], param[1]);
                          }))), (function (param) {
                    return Case.tFromJs(param[0]);
                  })), "None");
}

function isCorrectCase(commonCase, name) {
  if (Case.to_(commonCase, name.trim()) === name.trim()) {
    return true;
  } else {
    return Belt_Array.someU(Case.commonStringSeparators, (function (separator) {
                  return Belt_Array.everyU(name.split(separator), (function (substring) {
                                return substring.trim() === Case.to_(commonCase, substring.trim());
                              }));
                }));
  }
}

function getCorrectCase(commonCase, name) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getByU(Case.commonStringSeparators, (function (separator) {
                        return name.indexOf(separator) > -1;
                      })), (function (separator) {
                    return Belt_Array.joinWith(name.split(separator), separator, (function (subString) {
                                  return Case.to_(commonCase, subString);
                                }));
                  })), Case.to_(commonCase, name));
}

function areConflictingNames(name, existingName) {
  if (name === existingName || name.toLowerCase() === existingName.toLowerCase() || Case.camel(name) === Case.camel(existingName)) {
    return true;
  } else {
    return Case.snake(name) === Case.snake(existingName);
  }
}

function getConflictingNames(existingNames, name) {
  return Belt_List.getByU(existingNames, (function (existingName) {
                return areConflictingNames(name, existingName);
              }));
}

function getConflictingNames__array(existingNames, name) {
  return Belt_Array.getByU(existingNames, (function (existingName) {
                return areConflictingNames(name, existingName);
              }));
}

function replaceNewlinesWithWhitespace(string) {
  return string.replace(/(\r\n|\n|\r)/gm, " ");
}

function areSimilarlyNamed(similarityThresholdOpt, a, b) {
  var similarityThreshold = similarityThresholdOpt !== undefined ? similarityThresholdOpt : 0.8;
  return StringSimilarity.compareTwoStrings(a, b) >= similarityThreshold;
}

function isEmpty(name) {
  if (name === "" || name.toLowerCase() === "" || Case.camel(name) === "") {
    return true;
  } else {
    return Case.snake(name) === "";
  }
}

export {
  getCommonCase ,
  getCommonCase__array ,
  isCorrectCase ,
  getCorrectCase ,
  areConflictingNames ,
  getConflictingNames ,
  getConflictingNames__array ,
  replaceNewlinesWithWhitespace ,
  areSimilarlyNamed ,
  isEmpty ,
  
}
/* Case Not a pure module */
