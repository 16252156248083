// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NavigatorOs from "./navigatorOs.mjs";

function use(disabledOpt, metaOpt, ctrlKeyOpt, onPress, onKeyUp, keyName) {
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var meta = metaOpt !== undefined ? metaOpt : false;
  var ctrlKey = ctrlKeyOpt !== undefined ? ctrlKeyOpt : false;
  React.useEffect((function () {
          var eventHandler = function ($$event) {
            if ($$event.key === keyName && $$event.metaKey === meta && $$event.ctrlKey === ctrlKey) {
              return Curry._1(onPress, $$event);
            }
            
          };
          var keyUpEventHandler = function ($$event) {
            if ($$event.key === keyName && $$event.metaKey === meta && $$event.ctrlKey === ctrlKey) {
              return Belt_Option.forEach(onKeyUp, (function (onKeyUp) {
                            return Curry._1(onKeyUp, $$event);
                          }));
            }
            
          };
          if (!disabled) {
            window.addEventListener("keydown", eventHandler);
          }
          if (!disabled) {
            window.addEventListener("keyup", keyUpEventHandler);
          }
          return (function (param) {
                    window.removeEventListener("keydown", eventHandler);
                    window.removeEventListener("keyup", keyUpEventHandler);
                    
                  });
        }), [
        keyName,
        onPress,
        disabled
      ]);
  
}

function useWithOperatingSystemModKey(onPress, keyName) {
  var meta = NavigatorOs.os === "MacOS";
  var ctrlKey = NavigatorOs.os !== "MacOS";
  return use(undefined, meta, ctrlKey, onPress, undefined, keyName);
}

function KeyListener$WithOperatingSystemModKey(Props) {
  var onPress = Props.onPress;
  var keyName = Props.keyName;
  useWithOperatingSystemModKey(onPress, keyName);
  return null;
}

var WithOperatingSystemModKey = {
  make: KeyListener$WithOperatingSystemModKey
};

function KeyListener(Props) {
  var keyName = Props.keyName;
  var meta = Props.meta;
  var ctrlKey = Props.ctrlKey;
  var onPress = Props.onPress;
  use(undefined, meta, ctrlKey, onPress, undefined, keyName);
  return null;
}

var make = KeyListener;

export {
  use ,
  useWithOperatingSystemModKey ,
  WithOperatingSystemModKey ,
  make ,
  
}
/* react Not a pure module */
