// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function Spacer(Props) {
  var displayOpt = Props.display;
  var x = Props.width;
  var y = Props.height;
  var grow = Props.grow;
  var display = displayOpt !== undefined ? displayOpt : "block";
  return React.createElement("span", {
              className: Curry._1(Css.style, Belt_List.concatMany([
                        {
                          hd: Css.display(display),
                          tl: {
                            hd: Css.flexShrink(0.0),
                            tl: /* [] */0
                          }
                        },
                        Belt_Option.mapWithDefault(x, /* [] */0, (function (x) {
                                return {
                                        hd: Css.width(Css.px(x)),
                                        tl: /* [] */0
                                      };
                              })),
                        Belt_Option.mapWithDefault(y, /* [] */0, (function (y) {
                                return {
                                        hd: Css.height(Css.px(y)),
                                        tl: /* [] */0
                                      };
                              })),
                        Belt_Option.mapWithDefault(grow, /* [] */0, (function (grow) {
                                return {
                                        hd: Css.flexGrow(grow),
                                        tl: /* [] */0
                                      };
                              }))
                      ]))
            });
}

var make = Spacer;

export {
  make ,
  
}
/* Css Not a pure module */
