// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"member":"member"};

function roleToJs(param) {
  return param;
}

function roleFromJs(param) {
  return _map[param];
}

var Member = {
  roleToJs: roleToJs,
  roleFromJs: roleFromJs
};

var DomainItem = {};

export {
  Member ,
  DomainItem ,
  
}
/* No side effect */
