// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function variantEventId(variantIdentifier) {
  return variantIdentifier.baseEventId + "." + variantIdentifier.variantId;
}

function baseEventIdFromVariantEventId(variantEventId) {
  return Belt_Array.getExn(variantEventId.split("."), 0);
}

function variantIdFromVariantEventId(variantEventId) {
  return Belt_Array.getExn(variantEventId.split("."), 1);
}

function isVariantEventId(eventId) {
  return eventId.includes(".");
}

export {
  variantEventId ,
  baseEventIdFromVariantEventId ,
  variantIdFromVariantEventId ,
  isVariantEventId ,
  
}
/* No side effect */
