// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as FloatingUI from "./externals/FloatingUI.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function getDistanceFromElement(placement, arrowOptions, withArrow) {
  if (withArrow && Curry._1(FloatingUI.T.Placement.isRootPlacement, placement)) {
    if (Curry._1(FloatingUI.T.Placement.isVerticalPlacement, placement)) {
      return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(arrowOptions.height), 7.0);
    } else {
      return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(arrowOptions.width), 14.0);
    }
  } else {
    return 0.0;
  }
}

function FloatingUIHelperComponents$Arrow(Props) {
  var arrowRef = Props.arrowRef;
  var context = Props.context;
  var arrowOptions = Props.arrowOptions;
  var withArrow = Props.withArrow;
  if (!withArrow) {
    return null;
  }
  var tmp = {
    context: context,
    ref: arrowRef
  };
  var tmp$1 = Caml_option.undefined_to_opt(arrowOptions.width);
  if (tmp$1 !== undefined) {
    tmp.width = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Caml_option.undefined_to_opt(arrowOptions.height);
  if (tmp$2 !== undefined) {
    tmp.height = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = Caml_option.undefined_to_opt(arrowOptions.tipRadius);
  if (tmp$3 !== undefined) {
    tmp.tipRadius = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = Caml_option.undefined_to_opt(arrowOptions.staticOffset);
  if (tmp$4 !== undefined) {
    tmp.staticOffset = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = Caml_option.undefined_to_opt(arrowOptions.d);
  if (tmp$5 !== undefined) {
    tmp.d = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = Belt_Option.map(Caml_option.undefined_to_opt(arrowOptions.fill), Styles.Color.toString);
  if (tmp$6 !== undefined) {
    tmp.fill = Caml_option.valFromOption(tmp$6);
  }
  var tmp$7 = Belt_Option.map(Caml_option.undefined_to_opt(arrowOptions.stroke), Styles.Color.toString);
  if (tmp$7 !== undefined) {
    tmp.stroke = Caml_option.valFromOption(tmp$7);
  }
  var tmp$8 = Caml_option.undefined_to_opt(arrowOptions.strokeWidth);
  if (tmp$8 !== undefined) {
    tmp.strokeWidth = Caml_option.valFromOption(tmp$8);
  }
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.selector(" path", {
                          hd: Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(arrowOptions.fill), Styles.emptyStyle, (function (c) {
                                  return Css.important(Css_Legacy_Core.SVG.fill(c));
                                })),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  })
            }, React.createElement(FloatingUI.FloatingArrow.make, tmp));
}

var Arrow = {
  getDistanceFromElement: getDistanceFromElement,
  make: FloatingUIHelperComponents$Arrow
};

function FloatingUIHelperComponents$WithPortal(Props) {
  var withPortal = Props.withPortal;
  var children = Props.children;
  if (withPortal) {
    return React.createElement(FloatingUI.FloatingPortal.make, {
                children: children
              });
  } else {
    return children;
  }
}

var WithPortal = {
  make: FloatingUIHelperComponents$WithPortal
};

function FloatingUIHelperComponents$FloatingElement(Props) {
  var forwardRef = Props.forwardRef;
  var className = Props.className;
  var style = Props.style;
  var floatingProps = Props.floatingProps;
  var children = Props.children;
  return React.cloneElement(React.createElement("div", undefined), Object.assign({
                  ref: forwardRef,
                  className: className,
                  style: style,
                  children: children
                }, floatingProps));
}

var FloatingElement = {
  make: FloatingUIHelperComponents$FloatingElement
};

export {
  Arrow ,
  WithPortal ,
  FloatingElement ,
  
}
/* Css Not a pure module */
