// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as BeltListExtensions from "../app/src/BeltListExtensions.mjs";

function MakeMapModule(C) {
  var get = Belt_MapString.get;
  var getExn = Belt_MapString.getExn;
  var getBy = function (items, f) {
    return Belt_Array.getByU(Belt_MapString.valuesToArray(items), Curry.__1(f));
  };
  var toArray = Belt_MapString.valuesToArray;
  var fromArray = function (items) {
    var mapU = function (item) {
      return [
              Curry._1(C.getId, item),
              item
            ];
    };
    return Belt_MapString.fromArray(Belt_Array.mapU(items, mapU));
  };
  var toList = function (items) {
    return Belt_List.fromArray(Belt_MapString.valuesToArray(items));
  };
  var fromList = function (items) {
    return fromArray(Belt_List.toArray(items));
  };
  var keys = Belt_MapString.keysToArray;
  var add = function (items, item) {
    return Belt_MapString.set(items, Curry._1(C.getId, item), item);
  };
  var update = function (items, id, f) {
    var mapMaybeU = Curry.__1(f);
    return Belt_MapString.updateU(items, id, mapMaybeU);
  };
  var updateMany = function (items, ids, f) {
    var mapMaybeU = Curry.__1(f);
    return Belt_Array.reduceU(ids, items, (function (newItems, id) {
                  return Belt_MapString.updateU(newItems, id, mapMaybeU);
                }));
  };
  var mergeMany = function (a, b) {
    return Belt_MapString.mergeMany(a, Belt_MapString.toArray(b));
  };
  var remove = Belt_MapString.remove;
  var reduce = function (items, init, f) {
    var reduceU = function (acc, _key, item) {
      return Curry._2(f, acc, item);
    };
    return Belt_MapString.reduceU(items, init, reduceU);
  };
  var map = function (items, f) {
    var mapU = Curry.__1(f);
    return Belt_MapString.mapU(items, mapU);
  };
  var forEach = function (items, f) {
    var forEachU = function (_key, item) {
      return Curry._1(f, item);
    };
    return Belt_MapString.forEachU(items, forEachU);
  };
  var keep = function (items, f) {
    var keepU = function (param, item) {
      return Curry._1(f, item);
    };
    return Belt_MapString.keepU(items, keepU);
  };
  var keepMap = function (items, f) {
    var keepU = function (param, item) {
      return Belt_Option.isSome(item);
    };
    var mapU = Belt_Option.getExn;
    return Belt_MapString.mapU(Belt_MapString.keepU(map(items, f), keepU), mapU);
  };
  var has = Belt_MapString.has;
  var some = function (items, fn) {
    return Belt_MapString.someU(items, (function (param, item) {
                  return Curry._1(fn, item);
                }));
  };
  var every = function (items, fn) {
    return Belt_MapString.everyU(items, (function (param, item) {
                  return Curry._1(fn, item);
                }));
  };
  var size = Belt_MapString.size;
  var sizeToString = function (items) {
    return String(Belt_MapString.size(items));
  };
  var eq = function (a, b) {
    return Belt_MapString.eqU(a, b, C.eq);
  };
  var toArrayWithKeys = Belt_MapString.toArray;
  var encodeDict = function (items, encode) {
    return Json_encode.dict(encode, Js_dict.fromArray(Belt_MapString.toArray(items)));
  };
  return {
          get: get,
          getExn: getExn,
          getBy: getBy,
          toArray: toArray,
          fromArray: fromArray,
          toList: toList,
          fromList: fromList,
          keys: keys,
          empty: undefined,
          add: add,
          update: update,
          updateMany: updateMany,
          mergeMany: mergeMany,
          remove: remove,
          reduce: reduce,
          map: map,
          forEach: forEach,
          keep: keep,
          keepMap: keepMap,
          has: has,
          some: some,
          every: every,
          size: size,
          sizeToString: sizeToString,
          eq: eq,
          toArrayWithKeys: toArrayWithKeys,
          encodeDict: encodeDict
        };
}

function MakeOrderedMapModule(C) {
  var get = Belt_MapString.get;
  var getExn = Belt_MapString.getExn;
  var getBy = function (items, f) {
    return Belt_Array.getByU(Belt_MapString.valuesToArray(items), Curry.__1(f));
  };
  var toArray = Belt_MapString.valuesToArray;
  var fromArray = function (items) {
    var mapU = function (item) {
      return [
              Curry._1(C.getId, item),
              item
            ];
    };
    return Belt_MapString.fromArray(Belt_Array.mapU(items, mapU));
  };
  var toList = function (items) {
    return Belt_List.fromArray(Belt_MapString.valuesToArray(items));
  };
  var fromList = function (items) {
    return fromArray(Belt_List.toArray(items));
  };
  var keys = Belt_MapString.keysToArray;
  var add = function (items, item) {
    return Belt_MapString.set(items, Curry._1(C.getId, item), item);
  };
  var update = function (items, id, f) {
    var mapMaybeU = Curry.__1(f);
    return Belt_MapString.updateU(items, id, mapMaybeU);
  };
  var updateMany = function (items, ids, f) {
    var mapMaybeU = Curry.__1(f);
    return Belt_Array.reduceU(ids, items, (function (newItems, id) {
                  return Belt_MapString.updateU(newItems, id, mapMaybeU);
                }));
  };
  var mergeMany = function (a, b) {
    return Belt_MapString.mergeMany(a, Belt_MapString.toArray(b));
  };
  var remove = Belt_MapString.remove;
  var reduce = function (items, init, f) {
    var reduceU = function (acc, _key, item) {
      return Curry._2(f, acc, item);
    };
    return Belt_MapString.reduceU(items, init, reduceU);
  };
  var map = function (items, f) {
    var mapU = Curry.__1(f);
    return Belt_MapString.mapU(items, mapU);
  };
  var forEach = function (items, f) {
    var forEachU = function (_key, item) {
      return Curry._1(f, item);
    };
    return Belt_MapString.forEachU(items, forEachU);
  };
  var keep = function (items, f) {
    var keepU = function (param, item) {
      return Curry._1(f, item);
    };
    return Belt_MapString.keepU(items, keepU);
  };
  var keepMap = function (items, f) {
    var keepU = function (param, item) {
      return Belt_Option.isSome(item);
    };
    var mapU = Belt_Option.getExn;
    return Belt_MapString.mapU(Belt_MapString.keepU(map(items, f), keepU), mapU);
  };
  var has = Belt_MapString.has;
  var some = function (items, fn) {
    return Belt_MapString.someU(items, (function (param, item) {
                  return Curry._1(fn, item);
                }));
  };
  var every = function (items, fn) {
    return Belt_MapString.everyU(items, (function (param, item) {
                  return Curry._1(fn, item);
                }));
  };
  var size = Belt_MapString.size;
  var sizeToString = function (items) {
    return String(Belt_MapString.size(items));
  };
  var eq = function (a, b) {
    return Belt_MapString.eqU(a, b, C.eq);
  };
  var toArrayWithKeys = Belt_MapString.toArray;
  var encodeDict = function (items, encode) {
    return Json_encode.dict(encode, Js_dict.fromArray(Belt_MapString.toArray(items)));
  };
  var MapModule = {
    get: get,
    getExn: getExn,
    getBy: getBy,
    toArray: toArray,
    fromArray: fromArray,
    toList: toList,
    fromList: fromList,
    keys: keys,
    empty: undefined,
    add: add,
    update: update,
    updateMany: updateMany,
    mergeMany: mergeMany,
    remove: remove,
    reduce: reduce,
    map: map,
    forEach: forEach,
    keep: keep,
    keepMap: keepMap,
    has: has,
    some: some,
    every: every,
    size: size,
    sizeToString: sizeToString,
    eq: eq,
    toArrayWithKeys: toArrayWithKeys,
    encodeDict: encodeDict
  };
  var pushIfValid = function (arr, item) {
    if ((item !== undefined)) {
      arr.push(item);
      return ;
    }
    
  };
  var has$1 = function (items, id) {
    return Belt_MapString.has(items.items, id);
  };
  var get$1 = function (items, id) {
    return Belt_MapString.get(items.items, id);
  };
  var getExn$1 = function (items, id) {
    return Belt_MapString.getExn(items.items, id);
  };
  var getAtIndex = function (items, index) {
    return Belt_Option.flatMapU(Belt_Array.get(items.orderedItems, index), (function (id) {
                  return Belt_MapString.get(items.items, id);
                }));
  };
  var getBy$1 = function (items, f) {
    return getBy(items.items, f);
  };
  var toArray$1 = function (items) {
    var arr = [];
    var straightArr = Belt_MapString.toArray(items.items);
    var dict = Js_dict.fromArray(straightArr);
    for(var i = 0 ,i_finish = straightArr.length; i < i_finish; ++i){
      var key = items.orderedItems[i];
      var item = dict[key];
      pushIfValid(arr, item);
    }
    return arr;
  };
  var fromArray$1 = function (orderedItems) {
    var mapU = function (item) {
      return Curry._1(C.getId, item);
    };
    return {
            orderedItems: Belt_Array.mapU(orderedItems, mapU),
            items: fromArray(orderedItems)
          };
  };
  var toList$1 = function (items) {
    return Belt_List.fromArray(toArray$1(items));
  };
  var fromList$1 = function (orderedItems) {
    var mapU = function (item) {
      return Curry._1(C.getId, item);
    };
    return {
            orderedItems: Belt_Array.mapU(Belt_List.toArray(orderedItems), mapU),
            items: fromArray(Belt_List.toArray(orderedItems))
          };
  };
  var keys$1 = function (items) {
    return items.orderedItems;
  };
  var empty_orderedItems = [];
  var empty = {
    orderedItems: empty_orderedItems,
    items: undefined
  };
  var update$1 = function (items, id, f) {
    var newItems = update(items.items, id, f);
    var match = Belt_MapString.has(newItems, id);
    var match$1 = Belt_MapString.has(items.items, id);
    return {
            orderedItems: match ? (
                match$1 ? items.orderedItems : Belt_Array.concat(items.orderedItems, [id])
              ) : (
                match$1 ? Belt_Array.keepU(items.orderedItems, (function (itemId) {
                          return itemId !== id;
                        })) : items.orderedItems
              ),
            items: newItems
          };
  };
  var updateExisting = function (items, id, f) {
    return {
            orderedItems: items.orderedItems,
            items: update(items.items, id, (function (maybeItem) {
                    return Belt_Option.mapU(maybeItem, Curry.__1(f));
                  }))
          };
  };
  var prepend = function (items, item) {
    var id = Curry._1(C.getId, item);
    return {
            orderedItems: Belt_MapString.has(items.items, id) ? items.orderedItems : Belt_Array.concat([Curry._1(C.getId, item)], items.orderedItems),
            items: add(items.items, item)
          };
  };
  var append = function (items, item) {
    var id = Curry._1(C.getId, item);
    return {
            orderedItems: Belt_MapString.has(items.items, id) ? items.orderedItems : Belt_Array.concat(items.orderedItems, [Curry._1(C.getId, item)]),
            items: add(items.items, item)
          };
  };
  var updateMany$1 = function (items, ids, f) {
    return Belt_Array.reduceU(ids, items, (function (items, id) {
                  return update$1(items, id, f);
                }));
  };
  var updateManyExisting = function (items, ids, f) {
    return Belt_Array.reduceU(ids, items, (function (items, id) {
                  return updateExisting(items, id, f);
                }));
  };
  var mergeMany$1 = function (a, b) {
    var set = Belt_SetString.fromArray(b.orderedItems);
    var keptFromA = Belt_Array.keep(a.orderedItems, (function (item) {
            return !Belt_SetString.has(set, item);
          }));
    return {
            orderedItems: Belt_Array.concat(keptFromA, b.orderedItems),
            items: Belt_MapString.mergeMany(a.items, Belt_MapString.toArray(b.items))
          };
  };
  var remove$1 = function (items, id) {
    var keepU = function (itemId) {
      return itemId !== id;
    };
    return {
            orderedItems: Belt_Array.keepU(items.orderedItems, keepU),
            items: Belt_MapString.remove(items.items, id)
          };
  };
  var reduce$1 = function (items, init, f) {
    var reduceU = function (acc, id) {
      var item = Belt_MapString.get(items.items, id);
      if (item !== undefined) {
        return Curry._2(f, acc, Caml_option.valFromOption(item));
      } else {
        return acc;
      }
    };
    return Belt_Array.reduceU(items.orderedItems, init, reduceU);
  };
  var mapToArray = function (items, f) {
    var keepMapU = function (id) {
      return Belt_Option.mapU(Belt_MapString.get(items.items, id), Curry.__1(f));
    };
    return Belt_Array.keepMapU(items.orderedItems, keepMapU);
  };
  var mapToMap = function (items, f) {
    return map(items.items, f);
  };
  var mapToT = function (items, f) {
    return {
            orderedItems: items.orderedItems,
            items: map(items.items, f)
          };
  };
  var forEach$1 = function (items, f) {
    var forEachU = function (id) {
      return Belt_Option.forEachU(Belt_MapString.get(items.items, id), Curry.__1(f));
    };
    return Belt_Array.forEachU(items.orderedItems, forEachU);
  };
  var keep$1 = function (items, f) {
    var keptItems = keep(items.items, f);
    var keepU = function (id) {
      return Belt_MapString.has(keptItems, id);
    };
    return {
            orderedItems: Belt_Array.keepU(items.orderedItems, keepU),
            items: keptItems
          };
  };
  var keepMap$1 = function (items, f) {
    var keepMapU = function (id) {
      return Belt_Option.flatMapU(Belt_MapString.get(items.items, id), Curry.__1(f));
    };
    return Belt_Array.keepMapU(items.orderedItems, keepMapU);
  };
  var some$1 = function (items, fn) {
    return Belt_MapString.someU(items.items, (function (param, item) {
                  return Curry._1(fn, item);
                }));
  };
  var every$1 = function (items, fn) {
    return Belt_MapString.everyU(items.items, (function (param, item) {
                  return Curry._1(fn, item);
                }));
  };
  var size$1 = function (items) {
    return Belt_MapString.size(items.items);
  };
  var sizeToString$1 = function (items) {
    return String(Belt_MapString.size(items.items));
  };
  var reorderV2 = function (items, moveItemId, beforeItemId) {
    var doesPredecessorExist = function (listWithoutItem, predecessorId) {
      return Belt_List.someU(listWithoutItem, (function (itemId) {
                    return itemId === predecessorId;
                  }));
    };
    var orderedItems = Belt_List.fromArray(items.orderedItems);
    var movedItemId = Belt_List.getByU(orderedItems, (function (itemId) {
            return itemId === moveItemId;
          }));
    var listWithoutItemId = Belt_List.keepU(orderedItems, (function (itemId) {
            return itemId !== moveItemId;
          }));
    var reorderedItems = movedItemId !== undefined ? (
        beforeItemId !== undefined ? (
            doesPredecessorExist(listWithoutItemId, beforeItemId) ? Belt_List.toArray(BeltListExtensions.flatMapU(listWithoutItemId, (function (itemId) {
                          if (itemId === beforeItemId) {
                            return {
                                    hd: itemId,
                                    tl: {
                                      hd: movedItemId,
                                      tl: /* [] */0
                                    }
                                  };
                          } else {
                            return {
                                    hd: itemId,
                                    tl: /* [] */0
                                  };
                          }
                        }))) : Belt_List.toArray(orderedItems)
          ) : Belt_Array.concat([movedItemId], Belt_List.toArray(listWithoutItemId))
      ) : Belt_List.toArray(orderedItems);
    return {
            orderedItems: reorderedItems,
            items: items.items
          };
  };
  var reorderBuggy = function (items, moveItemId, beforeItemId) {
    var orderedItems = Belt_List.fromArray(items.orderedItems);
    var movedItemId = Belt_List.getByU(orderedItems, (function (itemId) {
            return itemId === moveItemId;
          }));
    var listWithoutItemId = Belt_List.keepU(orderedItems, (function (itemId) {
            return itemId !== moveItemId;
          }));
    var reorderedItems = movedItemId !== undefined ? (
        beforeItemId !== undefined ? Belt_List.toArray(BeltListExtensions.flatMapU(listWithoutItemId, (function (itemId) {
                      if (itemId === beforeItemId) {
                        return {
                                hd: itemId,
                                tl: {
                                  hd: movedItemId,
                                  tl: /* [] */0
                                }
                              };
                      } else {
                        return {
                                hd: itemId,
                                tl: /* [] */0
                              };
                      }
                    }))) : Belt_Array.concat([movedItemId], Belt_List.toArray(listWithoutItemId))
      ) : Belt_List.toArray(orderedItems);
    return {
            orderedItems: reorderedItems,
            items: items.items
          };
  };
  var eq$1 = function (a, b) {
    if (Belt_Array.eq(a.orderedItems, b.orderedItems, (function (prim0, prim1) {
              return prim0 === prim1;
            }))) {
      return Belt_MapString.eqU(a.items, b.items, C.eq);
    } else {
      return false;
    }
  };
  var toArrayWithKeys$1 = function (items) {
    var mapU = function (id) {
      var item = Belt_MapString.getExn(items.items, id);
      return [
              id,
              item
            ];
    };
    return Belt_Array.mapU(items.orderedItems, mapU);
  };
  var encodeDict$1 = function (items, encode) {
    return Json_encode.dict(encode, Js_dict.fromArray(toArrayWithKeys$1(items)));
  };
  var slice = function (items, len, offset) {
    var orderedItems = Belt_SetString.fromArray(Belt_Array.slice(items.orderedItems, offset, len));
    return keep$1(items, (function (item) {
                  return Belt_SetString.has(orderedItems, Curry._1(C.getId, item));
                }));
  };
  var take = function (items, take$1) {
    return slice(items, take$1, 0);
  };
  return {
          MapModule: MapModule,
          has: has$1,
          get: get$1,
          getExn: getExn$1,
          getAtIndex: getAtIndex,
          getBy: getBy$1,
          toArray: toArray$1,
          fromArray: fromArray$1,
          toList: toList$1,
          fromList: fromList$1,
          keys: keys$1,
          empty: empty,
          update: update$1,
          updateExisting: updateExisting,
          prepend: prepend,
          append: append,
          updateMany: updateMany$1,
          updateManyExisting: updateManyExisting,
          mergeMany: mergeMany$1,
          remove: remove$1,
          reduce: reduce$1,
          mapToArray: mapToArray,
          mapToMap: mapToMap,
          mapToT: mapToT,
          forEach: forEach$1,
          keep: keep$1,
          keepMap: keepMap$1,
          some: some$1,
          every: every$1,
          size: size$1,
          sizeToString: sizeToString$1,
          reorderV2: reorderV2,
          reorderBuggy: reorderBuggy,
          eq: eq$1,
          toArrayWithKeys: toArrayWithKeys$1,
          encodeDict: encodeDict$1,
          slice: slice,
          take: take
        };
}

export {
  MakeMapModule ,
  MakeOrderedMapModule ,
  
}
/* No side effect */
