// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Printer from "../model/src/Printer.mjs";
import * as AvoModel from "../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "./utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CodegenRules from "../codegen/src/CodegenRules.mjs";
import * as ActionsReducer from "../app/src/actionsReducer.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "../model/src/TrackingPlanModel.mjs";
import * as PropertyAbsenceUtils from "./utils/PropertyAbsenceUtils.mjs";
import * as AnalyticsUtilsBundles from "../app/src/analyticsUtilsBundles.mjs";
import * as PropertyValidationUtils from "../app/src/PropertyValidationUtils.mjs";
import * as TrackingPlanMappedModel from "../model/src/TrackingPlanMappedModel.mjs";
import * as GetRegexValidationUseCase from "../app/src/regex/GetRegexValidationUseCase.mjs";
import * as FetchImplementationStatusUtils from "./utils/FetchImplementationStatusUtils.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";

var empty = {
  new: undefined,
  removed: undefined,
  unchanged: undefined,
  changed: undefined
};

function private_diffItemsArray(fromArray, toArray, getIdentifier, compare) {
  var fromMap = Belt_MapString.fromArray(Belt_Array.mapU(fromArray, (function (item) {
              return [
                      Curry._1(getIdentifier, item),
                      item
                    ];
            })));
  var toMap = Belt_MapString.fromArray(Belt_Array.mapU(toArray, (function (item) {
              return [
                      Curry._1(getIdentifier, item),
                      item
                    ];
            })));
  var addIds = Belt_SetString.diff(Belt_SetString.fromArray(Belt_MapString.keysToArray(toMap)), Belt_SetString.fromArray(Belt_MapString.keysToArray(fromMap)));
  var removeIds = Belt_SetString.diff(Belt_SetString.fromArray(Belt_MapString.keysToArray(fromMap)), Belt_SetString.fromArray(Belt_MapString.keysToArray(toMap)));
  var commonIds = Belt_SetString.intersect(Belt_SetString.fromArray(Belt_MapString.keysToArray(fromMap)), Belt_SetString.fromArray(Belt_MapString.keysToArray(toMap)));
  var oldCommonItems = Belt_MapString.keepU(fromMap, (function (itemId, param) {
          return Belt_SetString.has(commonIds, itemId);
        }));
  var newCommonItems = Belt_MapString.keepU(toMap, (function (itemId, param) {
          return Belt_SetString.has(commonIds, itemId);
        }));
  var commons = Belt_Array.reduceU(Belt_SetString.toArray(commonIds), {
        changed: /* [] */0,
        unchanged: /* [] */0
      }, (function (currentChangeSet, commonId) {
          var oldItem = Belt_MapString.getExn(oldCommonItems, commonId);
          var newItem = Belt_MapString.getExn(newCommonItems, commonId);
          var match = Curry._2(compare, oldItem, newItem);
          if (typeof match === "object") {
            return {
                    unchanged: currentChangeSet.unchanged,
                    changed: Belt_List.add(currentChangeSet.changed, [
                          commonId,
                          [
                            match.VAL,
                            {
                              newItem: newItem,
                              oldItem: oldItem
                            }
                          ]
                        ])
                  };
          } else {
            return {
                    unchanged: Belt_List.add(currentChangeSet.unchanged, newItem),
                    changed: currentChangeSet.changed
                  };
          }
        }));
  return {
          new: Belt_MapString.keepU(toMap, (function (id, param) {
                  return Belt_SetString.has(addIds, id);
                })),
          removed: Belt_MapString.keepU(fromMap, (function (id, param) {
                  return Belt_SetString.has(removeIds, id);
                })),
          unchanged: Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(commons.unchanged), (function (item) {
                      return [
                              Curry._1(getIdentifier, item),
                              item
                            ];
                    }))),
          changed: Belt_MapString.fromArray(Belt_List.toArray(commons.changed))
        };
}

function hasSameName(fromName, toName) {
  return fromName === toName;
}

function comparePropertyTypes(fromType, toType, fromList, toList) {
  if (fromType === "int" && toType === "float" && Caml_obj.caml_equal(fromList, toList)) {
    return {
            NAME: "change",
            VAL: "tier1"
          };
  }
  if (fromType === toType && Caml_obj.caml_equal(fromList, toList)) {
    return "noChange";
  } else {
    return {
            NAME: "change",
            VAL: "tier2"
          };
  }
}

function getSimpleSourceAbsence(property, $$event, source) {
  var absence = Belt_Option.getWithDefault(property.absence, /* AlwaysSent */0);
  if (source === undefined) {
    return "never";
  }
  var simplestAbsence = PropertyAbsenceUtils.getSimplestAbsenceModeByEvent(absence, {
        hd: source,
        tl: /* [] */0
      }, $$event, property.sendAs);
  if (simplestAbsence === "Always") {
    return "always";
  } else if (simplestAbsence === "Never") {
    return "never";
  } else {
    return "sometimes";
  }
}

function getHighestChangeImpact(comparisons) {
  var comparison = {
    contents: "noChange"
  };
  Belt_Array.forEach(comparisons, (function (comparisonFunction) {
          if (Caml_obj.caml_equal(comparison.contents, {
                  NAME: "change",
                  VAL: "tier2"
                })) {
            return ;
          }
          var match = comparison.contents;
          var match$1 = Curry._1(comparisonFunction, undefined);
          var tmp;
          if (typeof match === "object") {
            if (match.NAME === "change") {
              var match$2 = match.VAL;
              if (match$2 === "tier0") {
                if (typeof match$1 === "object" && match$1.NAME === "change") {
                  var match$3 = match$1.VAL;
                  tmp = match$3 === "tier2" || match$3 === "tier1" ? match$1 : match;
                } else {
                  tmp = match;
                }
              } else {
                tmp = match$2 === "tier1" && typeof match$1 === "object" && match$1.NAME === "change" && match$1.VAL === "tier2" ? match$1 : match;
              }
            } else {
              tmp = match;
            }
          } else {
            tmp = match === "noChange" && typeof match$1 === "object" && match$1.NAME === "change" ? match$1 : match;
          }
          comparison.contents = tmp;
          
        }));
  return comparison.contents;
}

function getEventsNameMappings(model) {
  var matchesNoDestination = function (id) {
    return Belt_List.every(model.destinations, (function (destination) {
                  return destination.id !== id;
                }));
  };
  return Belt_Array.reduceU(Belt_Array.keepMapU(Belt_List.toArray(model.rules), (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (typeof match !== "object") {
                      return ;
                    }
                    if (match.NAME !== "Event") {
                      return ;
                    }
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "NameMapping") {
                      return ;
                    }
                    var match$2 = match$1.VAL;
                    var name = match$2.name;
                    var destination = match$2.destination;
                    if (destination === undefined) {
                      return ;
                    }
                    if (typeof destination !== "object") {
                      return ;
                    }
                    if (destination.NAME !== "Destination") {
                      return ;
                    }
                    if (name === undefined) {
                      return ;
                    }
                    var destinationId = destination.VAL;
                    if (matchesNoDestination(destinationId)) {
                      return ;
                    } else {
                      return [
                              match.VAL,
                              destinationId,
                              name
                            ];
                    }
                  })), undefined, (function (currentMap, rule) {
                var nameMapping = rule[2];
                var destinationId = rule[1];
                return Belt_MapString.update(currentMap, rule[0], (function (maybeNameMappings) {
                              if (maybeNameMappings !== undefined) {
                                return Caml_option.some(Belt_MapString.merge(Caml_option.valFromOption(maybeNameMappings), Belt_MapString.fromArray([[
                                                      destinationId,
                                                      nameMapping
                                                    ]]), (function (_key, _maybeFromNameMappings, maybeToNameMappings) {
                                                  return maybeToNameMappings;
                                                })));
                              } else {
                                return Caml_option.some(Belt_MapString.fromArray([[
                                                  destinationId,
                                                  nameMapping
                                                ]]));
                              }
                            }));
              }));
}

function getEventPropertiesNameMappings(model) {
  return Belt_Array.reduceU(Belt_List.toArray(model.rules), undefined, (function (currentMap, rule) {
                var match = rule.item;
                if (typeof match !== "object") {
                  return currentMap;
                }
                if (match.NAME !== "PropertyRef") {
                  return currentMap;
                }
                var match$1 = rule.definition;
                var match$2 = match.VAL;
                var itemPropertyId = match$2[1];
                if (typeof match$1 !== "object") {
                  return currentMap;
                }
                var variant = match$1.NAME;
                if (variant === "NameInLocalWorkspace" || variant === "NameMapping") {
                  return Belt_MapString.update(currentMap, match$2[0], (function (maybePropertyNameMappings) {
                                return Caml_option.some(Belt_MapString.update(Belt_Option.getWithDefault(maybePropertyNameMappings, undefined), itemPropertyId, (function (maybeRuleList) {
                                                  return Belt_List.add(Belt_Option.getWithDefault(maybeRuleList, /* [] */0), rule);
                                                })));
                              }));
                } else {
                  return currentMap;
                }
              }));
}

function getNameMappingForEventPropertyFromMap(map, eventId, propertyId) {
  return Belt_Option.getWithDefault(Belt_MapString.get(Belt_Option.getWithDefault(Belt_MapString.get(map, eventId), undefined), propertyId), /* [] */0);
}

function compareRegexRule(fromRegexValidationRule, toRegexValidationRule) {
  return $$String.compare(fromRegexValidationRule.regex, toRegexValidationRule.regex);
}

function compareMatches(fromProperty, toProperty, fromEvent, toEvent, fromModel, toModel, sourceId) {
  var toPropertyPinnedValue = Belt_Option.flatMap(Belt_List.getByU(toEvent.directPropertyRefs, (function (property) {
              if (property.TAG === /* PropertyRef */0) {
                return property._0.id === toProperty.id;
              } else {
                return false;
              }
            })), (function (property) {
          if (property.TAG !== /* PropertyRef */0) {
            return ;
          }
          var pinnedValue = property._0.pinnedValue;
          if (pinnedValue === undefined) {
            return ;
          }
          var isValid = ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, toProperty, ModelUtils.hasMigrated(toModel, "EventSpecificAllowedPropertyValues"));
          if (isValid) {
            return pinnedValue;
          }
          
        }));
  var match;
  if (Belt_Option.isSome(toPropertyPinnedValue)) {
    match = [
      undefined,
      undefined
    ];
  } else if (ModelUtils.hasMigrated(toModel, "EventSpecificAllowedPropertyValues")) {
    var fromModel$1 = ModelUtils.hasMigrated(fromModel, "EventSpecificAllowedPropertyValues") ? fromModel : ActionsReducer.reduce(fromModel, "MigrateToEventSpecificAllowedPropertyValues");
    var match$1 = ModelUtils.getPropertyByIdWithArchive(fromProperty.id, fromModel$1);
    var fromProperty$1 = match$1 !== undefined && match$1.TAG !== /* PropertyRef */0 ? match$1._0 : fromProperty;
    var fromEvent$1 = Belt_Option.getWithDefault(ModelUtils.getEventByIdWithArchive(fromEvent.id, fromModel$1), fromEvent);
    var fromPropertyMatches = Belt_SetString.fromArray(GetEventSpecificPropertyValuesUseCase.getAllowedValuesOnSource(fromProperty$1.eventSpecificAllowedPropertyValues, fromEvent$1, sourceId));
    var toPropertyMatches = Belt_SetString.fromArray(GetEventSpecificPropertyValuesUseCase.getAllowedValuesOnSource(toProperty.eventSpecificAllowedPropertyValues, toEvent, sourceId));
    match = [
      fromPropertyMatches,
      toPropertyMatches
    ];
  } else {
    var fromPropertyMatches$1 = Belt_SetString.fromArray(Belt_List.toArray(PropertyValidationUtils.getPropertyMatchesForSourceLegacy(sourceId, fromProperty)));
    var toPropertyMatches$1 = Belt_SetString.fromArray(Belt_List.toArray(PropertyValidationUtils.getPropertyMatchesForSourceLegacy(sourceId, toProperty)));
    match = [
      fromPropertyMatches$1,
      toPropertyMatches$1
    ];
  }
  var toPropertyMatches$2 = match[1];
  var fromPropertyMatches$2 = match[0];
  if (Belt_SetString.size(fromPropertyMatches$2) === 0 && Belt_SetString.size(toPropertyMatches$2) > 0 || Belt_SetString.size(fromPropertyMatches$2) > 0 && Belt_SetString.size(toPropertyMatches$2) === 0) {
    return {
            NAME: "change",
            VAL: "tier1"
          };
  } else if (Belt_SetString.eq(fromPropertyMatches$2, toPropertyMatches$2)) {
    return "noChange";
  } else if (Belt_SetString.subset(fromPropertyMatches$2, toPropertyMatches$2)) {
    return {
            NAME: "change",
            VAL: "tier0"
          };
  } else {
    return {
            NAME: "change",
            VAL: "tier1"
          };
  }
}

function comparePropertiesSentFromEvent(fromProperty, toProperty, fromEvent, toEvent, fromModel, toModel, sourceId, fromSource, toSource, fromEventPropertiesNameMappings, toEventPropertiesNameMappings) {
  return getHighestChangeImpact([
              (function (param) {
                  if (fromProperty.name !== toProperty.name) {
                    return {
                            NAME: "change",
                            VAL: "tier2"
                          };
                  } else {
                    return "noChange";
                  }
                }),
              (function (param) {
                  return comparePropertyTypes(fromProperty.type_, toProperty.type_, fromProperty.list, toProperty.list);
                }),
              (function (param) {
                  var match = getSimpleSourceAbsence(fromProperty, fromEvent, fromSource);
                  var match$1 = getSimpleSourceAbsence(toProperty, toEvent, toSource);
                  if (match === "never") {
                    if (match$1 === "never") {
                      return "noChange";
                    } else if (match$1 === "sometimes") {
                      return {
                              NAME: "change",
                              VAL: "tier1"
                            };
                    } else {
                      return {
                              NAME: "change",
                              VAL: "tier2"
                            };
                    }
                  } else if (match === "sometimes") {
                    if (match$1 === "sometimes") {
                      return "noChange";
                    } else {
                      return {
                              NAME: "change",
                              VAL: "tier2"
                            };
                    }
                  } else if (match$1 === "never") {
                    return {
                            NAME: "change",
                            VAL: "tier2"
                          };
                  } else if (match$1 === "sometimes") {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else {
                    return "noChange";
                  }
                }),
              (function (param) {
                  if (Caml_obj.caml_equal(fromEventPropertiesNameMappings, toEventPropertiesNameMappings)) {
                    return "noChange";
                  } else {
                    return {
                            NAME: "change",
                            VAL: "tier2"
                          };
                  }
                }),
              (function (param) {
                  var match = GetRegexValidationUseCase.get(fromProperty);
                  var match$1 = GetRegexValidationUseCase.get(toProperty);
                  if (match === undefined) {
                    if (match$1 !== undefined) {
                      return {
                              NAME: "change",
                              VAL: "tier1"
                            };
                    } else {
                      return "noChange";
                    }
                  }
                  if (match$1 === undefined) {
                    return "noChange";
                  }
                  var toPropertyRule = match$1.propertyRule;
                  var fromPropertyRule = match.propertyRule;
                  var match$2 = Belt_MapString.get(match.eventOverrides, fromEvent.id);
                  var match$3 = Belt_MapString.get(match$1.eventOverrides, toEvent.id);
                  if (match$2 !== undefined) {
                    if (match$3 !== undefined) {
                      if (compareRegexRule(match$2, match$3) === 0) {
                        return "noChange";
                      } else {
                        return {
                                NAME: "change",
                                VAL: "tier1"
                              };
                      }
                    } else if (toPropertyRule !== undefined && compareRegexRule(match$2, toPropertyRule) !== 0) {
                      return {
                              NAME: "change",
                              VAL: "tier1"
                            };
                    } else {
                      return "noChange";
                    }
                  } else if (match$3 !== undefined) {
                    if (fromPropertyRule !== undefined && compareRegexRule(fromPropertyRule, match$3) === 0) {
                      return "noChange";
                    } else {
                      return {
                              NAME: "change",
                              VAL: "tier1"
                            };
                    }
                  } else if (fromPropertyRule !== undefined) {
                    if (toPropertyRule !== undefined && compareRegexRule(fromPropertyRule, toPropertyRule) !== 0) {
                      return {
                              NAME: "change",
                              VAL: "tier1"
                            };
                    } else {
                      return "noChange";
                    }
                  } else if (toPropertyRule !== undefined) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else {
                    return "noChange";
                  }
                }),
              (function (param) {
                  return compareMatches(fromProperty, toProperty, fromEvent, toEvent, fromModel, toModel, sourceId);
                }),
              (function (param) {
                  var fromPropertyMinInt = PropertyValidationUtils.getMinInt(fromProperty);
                  var toPropertyMinInt = PropertyValidationUtils.getMinInt(toProperty);
                  if (fromPropertyMinInt !== undefined) {
                    if (toPropertyMinInt !== undefined) {
                      if (toPropertyMinInt === fromPropertyMinInt) {
                        return "noChange";
                      } else {
                        return {
                                NAME: "change",
                                VAL: "tier1"
                              };
                      }
                    } else {
                      return {
                              NAME: "change",
                              VAL: "tier0"
                            };
                    }
                  } else if (toPropertyMinInt !== undefined) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else {
                    return "noChange";
                  }
                }),
              (function (param) {
                  var fromPropertyMinFloat = PropertyValidationUtils.getMinFloat(fromProperty);
                  var toPropertyMinFloat = PropertyValidationUtils.getMinFloat(toProperty);
                  if (fromPropertyMinFloat !== undefined) {
                    if (toPropertyMinFloat !== undefined) {
                      if (toPropertyMinFloat === fromPropertyMinFloat) {
                        return "noChange";
                      } else {
                        return {
                                NAME: "change",
                                VAL: "tier1"
                              };
                      }
                    } else {
                      return {
                              NAME: "change",
                              VAL: "tier0"
                            };
                    }
                  } else if (toPropertyMinFloat !== undefined) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else {
                    return "noChange";
                  }
                }),
              (function (param) {
                  var fromPropertyMaxInt = PropertyValidationUtils.getMaxInt(fromProperty);
                  var toPropertyMaxInt = PropertyValidationUtils.getMaxInt(toProperty);
                  if (fromPropertyMaxInt !== undefined) {
                    if (toPropertyMaxInt !== undefined) {
                      if (toPropertyMaxInt === fromPropertyMaxInt) {
                        return "noChange";
                      } else {
                        return {
                                NAME: "change",
                                VAL: "tier1"
                              };
                      }
                    } else {
                      return {
                              NAME: "change",
                              VAL: "tier0"
                            };
                    }
                  } else if (toPropertyMaxInt !== undefined) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else {
                    return "noChange";
                  }
                }),
              (function (param) {
                  var fromPropertyMaxFloat = PropertyValidationUtils.getMaxFloat(fromProperty);
                  var toPropertyMaxFloat = PropertyValidationUtils.getMaxFloat(toProperty);
                  if (fromPropertyMaxFloat !== undefined) {
                    if (toPropertyMaxFloat !== undefined) {
                      if (toPropertyMaxFloat === fromPropertyMaxFloat) {
                        return "noChange";
                      } else {
                        return {
                                NAME: "change",
                                VAL: "tier1"
                              };
                      }
                    } else {
                      return {
                              NAME: "change",
                              VAL: "tier0"
                            };
                    }
                  } else if (toPropertyMaxFloat !== undefined) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else {
                    return "noChange";
                  }
                }),
              (function (param) {
                  var match = private_diffItemsArray(Belt_Array.map(Belt_List.toArray(CodegenRules.getNestedProperties(fromProperty, fromModel)), (function (param) {
                              return param[1];
                            })), Belt_Array.map(Belt_List.toArray(CodegenRules.getNestedProperties(toProperty, toModel)), (function (param) {
                              return param[1];
                            })), (function (param) {
                          return param.id;
                        }), (function (fromProperty, toProperty) {
                          return Curry.app(comparePropertiesSentFromEvent, [
                                      fromProperty,
                                      toProperty,
                                      fromEvent,
                                      toEvent,
                                      fromModel,
                                      toModel,
                                      sourceId,
                                      fromSource,
                                      toSource,
                                      fromEventPropertiesNameMappings,
                                      toEventPropertiesNameMappings
                                    ]);
                        }));
                  var changedEvents = match.changed;
                  if (!Belt_MapString.isEmpty(match.new) || !Belt_MapString.isEmpty(match.removed)) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else if (Belt_MapString.isEmpty(changedEvents)) {
                    return "noChange";
                  } else {
                    return getHighestChangeImpact(Belt_Array.map(Belt_MapString.valuesToArray(changedEvents), (function (param, param$1) {
                                      var description = param[0];
                                      if (description === "tier2" || description === "tier1") {
                                        return {
                                                NAME: "change",
                                                VAL: "tier1"
                                              };
                                      } else {
                                        return {
                                                NAME: "change",
                                                VAL: "tier0"
                                              };
                                      }
                                    })));
                  }
                }),
              (function (param) {
                  var fromPropertyPinnedValue = Belt_Option.flatMap(Belt_List.getByU(fromEvent.directPropertyRefs, (function (property) {
                              if (property.TAG === /* PropertyRef */0) {
                                return property._0.id === fromProperty.id;
                              } else {
                                return false;
                              }
                            })), (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return property._0.pinnedValue;
                          }
                          
                        }));
                  var toPropertyPinnedValue = Belt_Option.flatMap(Belt_List.getByU(toEvent.directPropertyRefs, (function (property) {
                              if (property.TAG === /* PropertyRef */0) {
                                return property._0.id === toProperty.id;
                              } else {
                                return false;
                              }
                            })), (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return property._0.pinnedValue;
                          }
                          
                        }));
                  if (fromPropertyPinnedValue === undefined) {
                    if (toPropertyPinnedValue !== undefined) {
                      return {
                              NAME: "change",
                              VAL: "tier1"
                            };
                    } else {
                      return "noChange";
                    }
                  }
                  if (toPropertyPinnedValue === undefined) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  }
                  if (Caml_obj.caml_equal(fromPropertyPinnedValue, toPropertyPinnedValue)) {
                    return "noChange";
                  }
                  var fromIncludedInCodegen = Belt_Option.mapWithDefault(Belt_List.getByU(fromEvent.includeSources, (function (param) {
                              return param.id === sourceId;
                            })), false, (function (param) {
                          return param.includeInCodegen;
                        }));
                  var toIncludedInCodegen = Belt_Option.mapWithDefault(Belt_List.getByU(toEvent.includeSources, (function (param) {
                              return param.id === sourceId;
                            })), false, (function (param) {
                          return param.includeInCodegen;
                        }));
                  if (!fromIncludedInCodegen && !toIncludedInCodegen) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else {
                    return {
                            NAME: "change",
                            VAL: "tier0"
                          };
                  }
                }),
              (function (param) {
                  if (fromProperty.operation !== toProperty.operation) {
                    return {
                            NAME: "change",
                            VAL: "tier0"
                          };
                  } else {
                    return "noChange";
                  }
                }),
              (function (param) {
                  if (fromProperty.optionalWhenInObject !== toProperty.optionalWhenInObject) {
                    return {
                            NAME: "change",
                            VAL: "tier1"
                          };
                  } else {
                    return "noChange";
                  }
                })
            ]);
}

function pruneArchivedSourceIds(usedSourceIds, includedSources) {
  return Belt_List.keepU(includedSources, (function (param) {
                return Belt_SetString.has(usedSourceIds, param.id);
              }));
}

function hasSameNameMappings(fromNameMappings, toNameMappings) {
  return Belt_MapString.eqU(fromNameMappings, toNameMappings, (function (a, b) {
                return Caml.caml_string_compare(a, b) === 0;
              }));
}

function compareEvents(fromEvent, toEvent, fromModelPropertiesMap, toModelPropertiesMap, fromModel, toModel, usedFromSourceIds, usedToSourceIds, fromEventsNameMappingsMap, toEventsNameMappingsMap, fromEventPropertiesNameMappingsMap, toEventPropertiesNameMappingsMap) {
  var fromEventSourceIds = Belt_SetString.fromArray(Belt_List.toArray(ModelUtils.getIncludedSourceIds(pruneArchivedSourceIds(usedFromSourceIds, fromEvent.includeSources))));
  var toEventSourceIds = Belt_SetString.fromArray(Belt_List.toArray(ModelUtils.getIncludedSourceIds(pruneArchivedSourceIds(usedToSourceIds, toEvent.includeSources))));
  var sourceIdsState = {
    new: Belt_SetString.diff(toEventSourceIds, fromEventSourceIds),
    removed: Belt_SetString.diff(fromEventSourceIds, toEventSourceIds),
    common: Belt_SetString.intersect(fromEventSourceIds, toEventSourceIds)
  };
  var changeSet = Belt_MapString.fromArray(Belt_Array.mapU(Belt_SetString.toArray(sourceIdsState.new), (function (sourceId) {
              return [
                      sourceId,
                      "tier1"
                    ];
            })));
  if (fromEvent.name !== toEvent.name) {
    var changeSet$1 = Belt_MapString.mergeMany(changeSet, Belt_Array.mapU(Belt_Array.concat(Belt_SetString.toArray(sourceIdsState.removed), Belt_SetString.toArray(sourceIdsState.common)), (function (sourceId) {
                return [
                        sourceId,
                        "tier2"
                      ];
              })));
    return {
            NAME: "change",
            VAL: changeSet$1
          };
  }
  var changeSet$2 = Belt_MapString.mergeMany(changeSet, Belt_Array.mapU(Belt_SetString.toArray(sourceIdsState.removed), (function (sourceId) {
              return [
                      sourceId,
                      "tier2"
                    ];
            })));
  var fromEventNameMappings = Belt_Option.getWithDefault(Belt_MapString.get(fromEventsNameMappingsMap, fromEvent.id), undefined);
  var toEventNameMappings = Belt_Option.getWithDefault(Belt_MapString.get(toEventsNameMappingsMap, toEvent.id), undefined);
  var changeSet$3 = hasSameNameMappings(fromEventNameMappings, toEventNameMappings) ? Belt_Array.reduceU(Belt_SetString.toArray(sourceIdsState.common), changeSet$2, (function (currentChangeSet, sourceId) {
            var fromSource = ModelUtils.getSourceById(sourceId, fromModel);
            var toSource = ModelUtils.getSourceById(sourceId, toModel);
            var fromProperties = AvoModel.getResolvedPropertiesForEventWithPropertyMap(fromModel.propertyBundles, fromModelPropertiesMap, fromEvent);
            var toProperties = AvoModel.getResolvedPropertiesForEventWithPropertyMap(toModel.propertyBundles, toModelPropertiesMap, toEvent);
            var match = private_diffItemsArray(fromProperties, toProperties, (function (p) {
                    return p.id;
                  }), (function (fromProperty, toProperty) {
                    return comparePropertiesSentFromEvent(fromProperty, toProperty, fromEvent, toEvent, fromModel, toModel, sourceId, fromSource, toSource, getNameMappingForEventPropertyFromMap(fromEventPropertiesNameMappingsMap, fromEvent.id, fromProperty.id), getNameMappingForEventPropertyFromMap(toEventPropertiesNameMappingsMap, toEvent.id, toProperty.id));
                  }));
            var changes = getHighestChangeImpact(Belt_Array.concatMany([
                      Belt_Array.map(Belt_MapString.valuesToArray(match.new), (function (property, param) {
                              var absence = getSimpleSourceAbsence(property, toEvent, toSource);
                              if (absence === "never") {
                                return "noChange";
                              } else if (absence === "sometimes") {
                                return {
                                        NAME: "change",
                                        VAL: "tier1"
                                      };
                              } else {
                                return {
                                        NAME: "change",
                                        VAL: "tier2"
                                      };
                              }
                            })),
                      Belt_Array.map(Belt_MapString.keysToArray(match.removed), (function (propertyId, param) {
                              var oldProperty = Belt_MapString.getExn(fromModelPropertiesMap, propertyId);
                              var absence = getSimpleSourceAbsence(oldProperty, fromEvent, fromSource);
                              if (absence === "never") {
                                return "noChange";
                              } else {
                                return {
                                        NAME: "change",
                                        VAL: "tier2"
                                      };
                              }
                            })),
                      Belt_Array.map(Belt_MapString.valuesToArray(match.changed), (function (param, param$1) {
                              var description = param[0];
                              if (description === "tier1") {
                                return {
                                        NAME: "change",
                                        VAL: "tier1"
                                      };
                              } else if (description === "tier2") {
                                return {
                                        NAME: "change",
                                        VAL: "tier2"
                                      };
                              } else {
                                return {
                                        NAME: "change",
                                        VAL: "tier0"
                                      };
                              }
                            })),
                      [(function (param) {
                            var fromEventIncludedInCodegen = Belt_Option.mapWithDefault(Belt_List.getByU(fromEvent.includeSources, (function (param) {
                                        return param.id === sourceId;
                                      })), false, (function (param) {
                                    return param.includeInCodegen;
                                  }));
                            var toEventIncludedInCodegen = Belt_Option.mapWithDefault(Belt_List.getByU(toEvent.includeSources, (function (param) {
                                        return param.id === sourceId;
                                      })), false, (function (param) {
                                    return param.includeInCodegen;
                                  }));
                            if (fromEventIncludedInCodegen !== toEventIncludedInCodegen) {
                              return {
                                      NAME: "change",
                                      VAL: "tier1"
                                    };
                            } else {
                              return "noChange";
                            }
                          })],
                      [(function (param) {
                            if (Belt_SetString.eq(Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray(fromEvent.types), TrackingPlanModel.eventTypeToJs)), Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray(toEvent.types), TrackingPlanModel.eventTypeToJs)))) {
                              return "noChange";
                            } else {
                              return {
                                      NAME: "change",
                                      VAL: "tier1"
                                    };
                            }
                          })],
                      [(function (param) {
                            if (Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map(fromEvent.includeDestinations, (function (sourceDestinationMap) {
                                                      var match = sourceDestinationMap.VAL;
                                                      return match[0] === sourceId ? Belt_List.map(match[1], (function (destinationId) {
                                                                      return sourceId + "-" + destinationId;
                                                                    })) : /* [] */0;
                                                    }))))), Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map(toEvent.includeDestinations, (function (sourceDestinationMap) {
                                                      var match = sourceDestinationMap.VAL;
                                                      return match[0] === sourceId ? Belt_List.map(match[1], (function (destinationId) {
                                                                      return sourceId + "-" + destinationId;
                                                                    })) : /* [] */0;
                                                    }))))))) {
                              return "noChange";
                            } else {
                              return {
                                      NAME: "change",
                                      VAL: "tier0"
                                    };
                            }
                          })],
                      [(function (param) {
                            if (Belt_SetString.eq(Belt_SetString.fromArray(fromEvent.eventGroupTypeIdsWithArchive), Belt_SetString.fromArray(toEvent.eventGroupTypeIdsWithArchive))) {
                              return "noChange";
                            } else {
                              return {
                                      NAME: "change",
                                      VAL: "tier0"
                                    };
                            }
                          })],
                      [(function (param) {
                            if (Belt_SetString.eq(Belt_SetString.fromArray(fromEvent.userGroupTypeIdsWithArchive), Belt_SetString.fromArray(toEvent.userGroupTypeIdsWithArchive))) {
                              return "noChange";
                            } else {
                              return {
                                      NAME: "change",
                                      VAL: "tier0"
                                    };
                            }
                          })],
                      [(function (param) {
                            if (Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(fromEvent.propertyWhitelist, (function (param) {
                                                  return AvoConfig.analyticsToolsLabel(param[0]) + "-" + param[1];
                                                })))), Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(toEvent.propertyWhitelist, (function (param) {
                                                  return AvoConfig.analyticsToolsLabel(param[0]) + "-" + param[1];
                                                })))))) {
                              return "noChange";
                            } else {
                              return {
                                      NAME: "change",
                                      VAL: "tier0"
                                    };
                            }
                          })]
                    ]));
            if (typeof changes === "object") {
              return Belt_MapString.set(currentChangeSet, sourceId, changes.VAL);
            } else {
              return currentChangeSet;
            }
          })) : Belt_MapString.mergeMany(changeSet$2, Belt_Array.mapU(Belt_SetString.toArray(sourceIdsState.common), (function (sourceId) {
                return [
                        sourceId,
                        "tier2"
                      ];
              })));
  if (Belt_MapString.isEmpty(changeSet$3)) {
    return "noChange";
  } else {
    return {
            NAME: "change",
            VAL: changeSet$3
          };
  }
}

function getNormalizedIdentifier(metadata, id) {
  if (metadata !== undefined) {
    return metadata.fromLocalItemId;
  } else {
    return id;
  }
}

function getNormalizedModel(model) {
  var modelPropertiesMapped = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, model.properties);
  var modelPropertyBundlesMapped = Curry._1(TrackingPlanMappedModel.PropertyBundles.fromList, model.propertyBundles);
  return {
          types: model.types,
          properties: Belt_List.mapU(model.properties, (function (property) {
                  if (property.TAG === /* PropertyRef */0) {
                    return property;
                  }
                  var p = property._0;
                  var newrecord = Caml_obj.caml_obj_dup(p);
                  return {
                          TAG: /* Property */1,
                          _0: (newrecord.id = getNormalizedIdentifier(p.globalRequirementsMetadata, p.id), newrecord)
                        };
                })),
          propertyBundles: Belt_List.mapU(model.propertyBundles, (function (pb) {
                  return {
                          id: getNormalizedIdentifier(pb.globalRequirementsMetadata, pb.id),
                          name: pb.name,
                          description: pb.description,
                          properties: pb.properties,
                          globalRequirementsMetadata: pb.globalRequirementsMetadata
                        };
                })),
          events: Belt_List.mapU(model.events, (function (e) {
                  return {
                          id: getNormalizedIdentifier(e.globalRequirementsMetadata, e.id),
                          name: e.name,
                          uniqueName: e.uniqueName,
                          description: e.description,
                          directPropertyRefs: Belt_List.keepMapU(e.directPropertyRefs, (function (p) {
                                  if (p.TAG !== /* PropertyRef */0) {
                                    return ;
                                  }
                                  var pr = p._0;
                                  var modelProperty = Curry._2(TrackingPlanMappedModel.Properties.get, modelPropertiesMapped, ModelUtils.getPropertyId(p));
                                  if (modelProperty !== undefined) {
                                    return {
                                            TAG: /* PropertyRef */0,
                                            _0: {
                                              id: getNormalizedIdentifier(modelProperty.globalRequirementsMetadata, modelProperty.id),
                                              description: pr.description,
                                              pinnedValue: pr.pinnedValue
                                            }
                                          };
                                  }
                                  
                                })),
                          propertyBundles: Belt_List.keepMapU(e.propertyBundles, (function (pb) {
                                  var match = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, modelPropertyBundlesMapped, pb.id);
                                  if (match !== undefined) {
                                    return {
                                            id: getNormalizedIdentifier(match.globalRequirementsMetadata, pb.id),
                                            description: pb.description
                                          };
                                  }
                                  
                                })),
                          variants: e.variants,
                          types: e.types,
                          tags: e.tags,
                          excludeSourcesDeprecated: e.excludeSourcesDeprecated,
                          includeSources: e.includeSources,
                          includeDestinations: e.includeDestinations,
                          hashes: e.hashes,
                          propertyWhitelist: e.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: e.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: e.userGroupTypeIdsWithArchive,
                          triggers: e.triggers,
                          globalRequirementsMetadata: e.globalRequirementsMetadata
                        };
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function hasSameCategories(fromEventsToCategories, toEventsToCategories, eventId) {
  var fromCategoryIds = Belt_Option.getWithDefault(Belt_MapString.get(fromEventsToCategories, eventId), undefined);
  var toCategoryIds = Belt_Option.getWithDefault(Belt_MapString.get(toEventsToCategories, eventId), undefined);
  return Belt_SetString.eq(fromCategoryIds, toCategoryIds);
}

function getEventsCategories(categories) {
  return Belt_Array.reduceU(Belt_List.toArray(categories), undefined, (function (eventsToCategoriesMap, category) {
                return Belt_Array.reduceU(Belt_List.toArray(category.events), eventsToCategoriesMap, (function (eventsToCategoriesMap, eventId) {
                              return Belt_MapString.updateU(eventsToCategoriesMap, eventId, (function (maybeCategoryIds) {
                                            if (maybeCategoryIds !== undefined) {
                                              return Caml_option.some(Belt_SetString.add(Caml_option.valFromOption(maybeCategoryIds), category.id));
                                            } else {
                                              return Caml_option.some(Belt_SetString.fromArray([category.id]));
                                            }
                                          }));
                            }));
              }));
}

function getDirectEventChanges(fromModelWithoutFlattenedEventVariants, toModelWithoutFlattenedEventVariants) {
  var fromModel = getNormalizedModel(fromModelWithoutFlattenedEventVariants);
  var toModel = getNormalizedModel(toModelWithoutFlattenedEventVariants);
  var fromEventsNameMappingsMap = getEventsNameMappings(fromModel);
  var toEventsNameMappingsMap = getEventsNameMappings(toModel);
  var fromEventsToCategories = getEventsCategories(fromModel.goals);
  var toEventsToCategories = getEventsCategories(toModel.goals);
  var directlyCompareEvents = function (fromEvent, toEvent) {
    var fromEventNameMappings = Belt_Option.getWithDefault(Belt_MapString.get(fromEventsNameMappingsMap, fromEvent.id), undefined);
    var toEventNameMappings = Belt_Option.getWithDefault(Belt_MapString.get(toEventsNameMappingsMap, toEvent.id), undefined);
    if (!hasSameNameMappings(fromEventNameMappings, toEventNameMappings) || !hasSameCategories(fromEventsToCategories, toEventsToCategories, fromEvent.id) || Caml_obj.caml_notequal(Printer.printEvent(fromEvent), Printer.printEvent(toEvent))) {
      return {
              NAME: "change",
              VAL: "modified"
            };
    } else {
      return "noChange";
    }
  };
  return private_diffItemsArray(Belt_List.toArray(fromModel.events), Belt_List.toArray(toModel.events), (function (e) {
                return e.id;
              }), directlyCompareEvents);
}

function getChanges(fromModelWithFlattenedEventVariants, toModelWithFlattenedEventVariants) {
  var fromModel = getNormalizedModel(fromModelWithFlattenedEventVariants);
  var toModel = getNormalizedModel(toModelWithFlattenedEventVariants);
  var fromModelPropertiesMap = Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(AvoModel.resolveProperties(fromModel, fromModel.properties)), (function (property) {
              return [
                      property.id,
                      property
                    ];
            })));
  var toModelPropertiesMap = Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(AvoModel.resolveProperties(toModel, toModel.properties)), (function (property) {
              return [
                      property.id,
                      property
                    ];
            })));
  var usedFromSourceIds = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray(fromModel.sources), (function (param) {
              return param.id;
            })));
  var usedToSourceIds = Belt_SetString.fromArray(Belt_Array.mapU(Belt_List.toArray(toModel.sources), (function (param) {
              return param.id;
            })));
  var fromEventsNameMappingsMap = getEventsNameMappings(fromModel);
  var toEventsNameMappingsMap = getEventsNameMappings(toModel);
  var fromEventPropertiesNameMappingsMap = getEventPropertiesNameMappings(fromModel);
  var toEventPropertiesNameMappingsMap = getEventPropertiesNameMappings(toModel);
  return private_diffItemsArray(Belt_List.toArray(fromModel.events), Belt_List.toArray(toModel.events), (function (e) {
                return e.id;
              }), (function (fromEvent, toEvent) {
                return compareEvents(fromEvent, toEvent, fromModelPropertiesMap, toModelPropertiesMap, fromModel, toModel, usedFromSourceIds, usedToSourceIds, fromEventsNameMappingsMap, toEventsNameMappingsMap, fromEventPropertiesNameMappingsMap, toEventPropertiesNameMappingsMap);
              }));
}

function getTierChanges(itemsDiff) {
  var changed = Belt_Array.map(Belt_MapString.toArray(itemsDiff.changed), (function (param) {
          return [
                  param[0],
                  Belt_MapString.toArray(param[1][0])
                ];
        }));
  var removed = Belt_Array.map(Belt_MapString.toArray(itemsDiff.removed), (function (param) {
          return [
                  param[0],
                  Belt_Array.map(Belt_List.toArray(param[1].includeSources), (function (param) {
                          return [
                                  param.id,
                                  "tier2"
                                ];
                        }))
                ];
        }));
  return Belt_Array.concat(changed, removed);
}

function addDiffEventToMap(invocationSupportDict, sourceId, sourcesMap, sourceIdDict, eventIncludedInCodegen, eventInvocations, $$event) {
  var supportsInvocations = Js_dict.get(invocationSupportDict, sourceId);
  var supportsInvocations$1;
  if (supportsInvocations !== undefined) {
    supportsInvocations$1 = supportsInvocations;
  } else {
    var source = Belt_MapString.get(sourcesMap, sourceId);
    if (source !== undefined) {
      var supportsInvocations$2 = AvoConfig.supportsInvocations(source);
      invocationSupportDict[sourceId] = supportsInvocations$2;
      supportsInvocations$1 = supportsInvocations$2;
    } else {
      supportsInvocations$1 = false;
    }
  }
  var eventStatus;
  if (eventIncludedInCodegen) {
    if (supportsInvocations$1) {
      if (typeof eventInvocations === "object" && eventInvocations.NAME === "Success") {
        var invocations = FetchImplementationStatusUtils.getInvocations($$event.id, sourceId, true, undefined, undefined, eventInvocations.VAL);
        if (invocations) {
          var summary = FetchImplementationStatusUtils.reduceInvocations(invocations);
          eventStatus = Belt_Option.mapWithDefaultU(summary, /* NotSeen */0, (function (summary) {
                  return /* Seen */{
                          _0: new Date(summary.lastSeen.value)
                        };
                }));
        } else {
          eventStatus = /* NotSeen */0;
        }
      } else {
        eventStatus = /* NotSeen */0;
      }
    } else {
      eventStatus = /* Unknown */2;
    }
  } else {
    eventStatus = /* NotImplementedWithAvo */1;
  }
  var diffEvent_1 = [
    $$event,
    eventStatus
  ];
  var diffEvent = {
    NAME: "Updated",
    VAL: diffEvent_1
  };
  var l = Js_dict.get(sourceIdDict, sourceId);
  if (l !== undefined) {
    sourceIdDict[sourceId] = Belt_List.add(l, diffEvent);
  } else {
    sourceIdDict[sourceId] = {
      hd: diffEvent,
      tl: /* [] */0
    };
  }
  
}

function getBreakingChangesReport(changes) {
  var tierChanges = getTierChanges(changes);
  var events = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.keepMapU(tierChanges, (function (param) {
                  if (Belt_Array.someU(param[1], (function (param) {
                            return param[1] === "tier2";
                          }))) {
                    return param[0];
                  }
                  
                }))));
  var breakingEventIdsBySource = Belt_Array.reduceU(tierChanges, undefined, (function (currentMap, param) {
          var eventId = param[0];
          return Belt_Array.reduceU(param[1], currentMap, (function (currentMap, param) {
                        if (param[1] === "tier2") {
                          return Belt_MapString.update(currentMap, param[0], (function (maybeEvents) {
                                        if (maybeEvents !== undefined) {
                                          return Caml_option.some(Belt_SetString.add(Caml_option.valFromOption(maybeEvents), eventId));
                                        } else {
                                          return Caml_option.some(Belt_SetString.fromArray([eventId]));
                                        }
                                      }));
                        } else {
                          return currentMap;
                        }
                      }));
        }));
  return {
          breakingEvents: events,
          breakingSources: Belt_MapString.keysToArray(breakingEventIdsBySource),
          breakingEventIdsBySource: breakingEventIdsBySource
        };
}

function getAnalyticsBreakingChangeInfo(changes) {
  return AnalyticsUtilsBundles.inspectorBreakingChangeInfo(changes.breakingEvents.length, changes.breakingEvents, changes.breakingSources, changes.breakingSources.length);
}

function getCodeChangesReport(toSources, eventInvocations, changes) {
  var sourcesMap = Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(toSources), (function (source) {
              return [
                      source.id,
                      source
                    ];
            })));
  var sourceIdDict = {};
  var invocationSupportDict = {};
  Belt_Array.forEachU(Belt_MapString.valuesToArray(changes.removed), (function ($$event) {
          var diffEvent_1 = [
            $$event,
            /* NotSeen */0
          ];
          var diffEvent = {
            NAME: "Removed",
            VAL: diffEvent_1
          };
          return Belt_List.forEachU($$event.includeSources, (function (param) {
                        var sourceId = param.id;
                        var l = Js_dict.get(sourceIdDict, sourceId);
                        if (l !== undefined) {
                          sourceIdDict[sourceId] = Belt_List.add(l, diffEvent);
                        } else {
                          sourceIdDict[sourceId] = {
                            hd: diffEvent,
                            tl: /* [] */0
                          };
                        }
                        
                      }));
        }));
  Belt_Array.forEachU(Belt_MapString.valuesToArray(changes.new), (function ($$event) {
          return Belt_List.forEachU($$event.includeSources, (function (param) {
                        return addDiffEventToMap(invocationSupportDict, param.id, sourcesMap, sourceIdDict, param.includeInCodegen, eventInvocations, $$event);
                      }));
        }));
  Belt_Array.forEachU(Belt_MapString.valuesToArray(changes.changed), (function (param) {
          var $$event = param[1].newItem;
          var sources = Belt_Array.keepMapU(Belt_MapString.keysToArray(param[0]), (function (sourceId) {
                  return Belt_MapString.get(sourcesMap, sourceId);
                }));
          return Belt_Array.forEachU(sources, (function (param) {
                        var sourceId = param.id;
                        var maybeIncludedSource = Belt_List.getByU($$event.includeSources, (function (param) {
                                return param.id === sourceId;
                              }));
                        if (maybeIncludedSource !== undefined) {
                          return addDiffEventToMap(invocationSupportDict, sourceId, sourcesMap, sourceIdDict, maybeIncludedSource.includeInCodegen, eventInvocations, $$event);
                        }
                        var diffEvent_1 = [
                          $$event,
                          /* NotSeen */0
                        ];
                        var diffEvent = {
                          NAME: "Removed",
                          VAL: diffEvent_1
                        };
                        var l = Js_dict.get(sourceIdDict, sourceId);
                        if (l !== undefined) {
                          sourceIdDict[sourceId] = Belt_List.add(l, diffEvent);
                        } else {
                          sourceIdDict[sourceId] = {
                            hd: diffEvent,
                            tl: /* [] */0
                          };
                        }
                        
                      }));
        }));
  return Belt_List.fromArray(Belt_Array.keepMapU(Js_dict.entries(sourceIdDict), (function (param) {
                    var l = param[1];
                    return Belt_Option.map(Belt_MapString.get(sourcesMap, param[0]), (function (source) {
                                  return [
                                          source,
                                          l
                                        ];
                                }));
                  })));
}

export {
  empty ,
  private_diffItemsArray ,
  hasSameName ,
  comparePropertyTypes ,
  compareMatches ,
  getSimpleSourceAbsence ,
  getHighestChangeImpact ,
  comparePropertiesSentFromEvent ,
  pruneArchivedSourceIds ,
  hasSameNameMappings ,
  compareEvents ,
  getEventsNameMappings ,
  getNormalizedIdentifier ,
  getNormalizedModel ,
  getDirectEventChanges ,
  getChanges ,
  getAnalyticsBreakingChangeInfo ,
  getCodeChangesReport ,
  getEventPropertiesNameMappings ,
  getNameMappingForEventPropertyFromMap ,
  getBreakingChangesReport ,
  
}
/* Printer Not a pure module */
