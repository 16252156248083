// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Version_keepMjs from "./externals/version_keep.mjs";

var current = Version_keepMjs.current;

export {
  current ,
  
}
/* current Not a pure module */
