// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Models from "../Models.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Tooltip from "../Tooltip.mjs";
import Shortid from "shortid";
import * as RoleUtils from "../RoleUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SnippetTypes from "../detailviews/SnippetTypes.mjs";
import * as Core from "@mantine/core";
import * as FirebaseUtils from "../firebaseUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as BranchImplementStore from "./BranchImplementStore.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";

function getPulseKeyframeStyle(step) {
  var color = Styles.Color.avoPinkShade2;
  var alpha0 = Styles.Color.setAlpha(color, 0.0);
  if (step === 1) {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(24), undefined, undefined, alpha0)),
            tl: /* [] */0
          };
  } else if (step === 2) {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(0), undefined, undefined, alpha0)),
            tl: /* [] */0
          };
  } else {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(0), undefined, undefined, color)),
            tl: /* [] */0
          };
  }
}

function buttonWrapper(shouldHighlight) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.whiteSpace("nowrap"),
                    tl: /* [] */0
                  }),
              tl: {
                hd: shouldHighlight ? Curry._1(Css.style, {
                        hd: Css.selector(" button", {
                              hd: Css.animation({
                                    NAME: "ms",
                                    VAL: 3000.0
                                  }, undefined, undefined, "easeInOut", "forwards", undefined, "infinite", Curry._1(Css.keyframes, {
                                        hd: [
                                          0,
                                          getPulseKeyframeStyle(0)
                                        ],
                                        tl: {
                                          hd: [
                                            70,
                                            getPulseKeyframeStyle(1)
                                          ],
                                          tl: {
                                            hd: [
                                              100,
                                              getPulseKeyframeStyle(2)
                                            ],
                                            tl: /* [] */0
                                          }
                                        }
                                      })),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }) : Curry._1(Css.style, /* [] */0),
                tl: /* [] */0
              }
            });
}

var dropdown = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.width(Css.px(300)),
          tl: {
            hd: Css.padding(Css.px(20)),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var dropdownHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(2)),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var shareUrlOuterContainer = Curry._1(Css.style, {
      hd: Css.paddingBottom(Css.px(16)),
      tl: {
        hd: Css.marginBottom(Css.px(16)),
        tl: {
          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
          tl: /* [] */0
        }
      }
    });

var shareUrlContainer = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.paddingRight(Css.px(4)),
              tl: {
                hd: Css.borderRadius(Css.px(8)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light02),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.marginLeft(Css.px(-2)),
                      tl: {
                        hd: Css.marginRight(Css.px(-2)),
                        tl: {
                          hd: Css.overflow("hidden"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var shareUrl = Curry._1(Css.style, {
      hd: Css.overflow("auto"),
      tl: {
        hd: Css.whiteSpace("nowrap"),
        tl: {
          hd: Css.fontFamily(Styles.FontFamily.polar),
          tl: {
            hd: Css.fontSize(Css.px(11)),
            tl: {
              hd: Css.fontWeight(Styles.FontWeight.medium),
              tl: {
                hd: Css.color(Styles.Color.light12),
                tl: {
                  hd: Css.lineHeight(Css.px(40)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(8)),
                    tl: {
                      hd: Css.paddingRight(Css.px(8)),
                      tl: {
                        hd: Css.margin(Css.px(0)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  getPulseKeyframeStyle: getPulseKeyframeStyle,
  buttonWrapper: buttonWrapper,
  dropdown: dropdown,
  dropdownHeader: dropdownHeader,
  shareUrlOuterContainer: shareUrlOuterContainer,
  shareUrlContainer: shareUrlContainer,
  shareUrl: shareUrl
};

function getShareUrl(shareId) {
  return Router.getOrigin(undefined) + "/s/" + shareId + Router.getSearch(undefined);
}

function BranchImplementationShareButton(Props) {
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var maybeSharingLink = FirebaseFetcherHooks.useSharingLink(schemaId, branchId);
  var match = Router.QueryParams.useStringKey("highlightSharing");
  var removeHighlightButtonQueryParam = match.removeByReplace;
  var highlightButtonQueryParam = match.value;
  var match$1 = React.useState(function () {
        return Belt_Option.mapWithDefault(highlightButtonQueryParam, false, Pervasives.bool_of_string);
      });
  var setShouldHighlightButton = match$1[1];
  var shouldHighlightButton = match$1[0];
  var match$2 = Hooks.useDisclosure(false);
  var action = match$2[1];
  var opened = match$2[0];
  var match$3 = React.useState(function () {
        return true;
      });
  var setCloseOnClickOutside = match$3[1];
  var diffViewType = BranchImplementStore.useDiffViewType(undefined);
  var addToast = Toast.useAddToast(undefined);
  var memberCanEdit = Belt_Option.mapWithDefault(WorkspaceContext.useMember(undefined), false, (function (member) {
          return RoleUtils.canEdit(Belt_Option.getExn(Models.Role.tFromJs(member.role)));
        }));
  React.useEffect((function () {
          Curry._1(removeHighlightButtonQueryParam, undefined);
          
        }), []);
  Hooks.useDidUpdate1((function (param) {
          AnalyticsRe.branchImplementationInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, opened ? "PublicShareDropdownOpened" : "PublicShareDropdownClosed", SnippetTypes.DiffViewType.toAnalyticsDiffType(diffViewType), "Workspace", "Authenticated", schemaBundle.branchId, schemaBundle.schemaId);
          
        }), [opened]);
  var tmp;
  if (maybeSharingLink !== undefined) {
    var shareId = maybeSharingLink.shareId;
    var shareUrl$1 = getShareUrl(shareId);
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: shareUrlOuterContainer
            }, React.createElement("div", {
                  className: shareUrlContainer
                }, React.createElement("p", {
                      className: shareUrl
                    }, shareUrl$1), React.createElement(Button.make, {
                      icon: "copy",
                      onClick: (function (param) {
                          CopyTextToClipboard(shareUrl$1);
                          Curry._1(addToast, {
                                message: "Public link copied",
                                toastType: /* Success */0
                              });
                          return AnalyticsRe.branchImplementationShareModalInteraction("PublicShareLinkCopied", shareId, schemaBundle.branchId, schemaBundle.schemaId);
                        }),
                      size: "small",
                      style: "outline"
                    }))), React.createElement(Button.make, {
              label: "Delete public link",
              onClick: (function (param) {
                  Curry._1(setCloseOnClickOutside, (function (param) {
                          return false;
                        }));
                  var modal = memberCanEdit ? ({
                        NAME: "AreYouSureModal",
                        VAL: [
                          "Delete Public Link",
                          "Are you sure you want to delete this public link? Doing so will prohibit users possessing the link from seeing the code changes.",
                          "Delete",
                          (function (param) {
                              FirebaseUtils.deletePublicLink(shareId);
                              Curry._1(addToast, {
                                    message: "Public link deleted"
                                  });
                              Curry.app(undefined, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [[
                                        {
                                          NAME: "DeletePublicBranchImplementation",
                                          VAL: shareId
                                        },
                                        {
                                          branchId: branchId,
                                          branchQuery: branchId
                                        }
                                      ]]
                                  ]);
                              AnalyticsRe.branchImplementationShareModalInteraction("PublicShareLinkDeleted", shareId, schemaBundle.branchId, schemaBundle.schemaId);
                              return Curry._1(setCloseOnClickOutside, (function (param) {
                                            return true;
                                          }));
                            }),
                          (function (param) {
                              return Curry._1(setCloseOnClickOutside, (function (param) {
                                            return true;
                                          }));
                            })
                        ]
                      }) : ({
                        NAME: "AlertModal",
                        VAL: [
                          "Permissions Required",
                          "You don't have permission to delete the public link. To resolve this, you can either request access from an administrator in your workspace or ask someone with the necessary permissions to delete it for you.",
                          "OK",
                          (function (param) {
                              return Curry._1(setCloseOnClickOutside, (function (param) {
                                            return true;
                                          }));
                            })
                        ]
                      });
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: modal
                            });
                }),
              style: "outline"
            }));
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
              icon: "link",
              label: "Generate public link",
              onClick: (function (param) {
                  if (memberCanEdit) {
                    var shareId = Shortid();
                    FirebaseUtils.generatePublicLink(shareId, schemaId, branchId);
                    CopyTextToClipboard(getShareUrl(shareId));
                    Curry._1(addToast, {
                          message: "Public link copied",
                          toastType: /* Success */0
                        });
                    Curry.app(undefined, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          [[
                              {
                                NAME: "CreatePublicBranchImplementation",
                                VAL: shareId
                              },
                              {
                                branchId: branchId,
                                branchQuery: branchId
                              }
                            ]]
                        ]);
                    return AnalyticsRe.branchImplementationShareModalInteraction("PublicShareLinkCreated", shareId, schemaBundle.branchId, schemaBundle.schemaId);
                  }
                  Curry._1(setCloseOnClickOutside, (function (param) {
                          return false;
                        }));
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "AlertModal",
                                VAL: [
                                  "Permissions Required",
                                  "You don't have permission to generate a public link. To resolve this, you can either request access from an administrator in your workspace or ask someone with the necessary permissions to generate it for you.",
                                  "OK",
                                  (function (param) {
                                      return Curry._1(setCloseOnClickOutside, (function (param) {
                                                    return true;
                                                  }));
                                    })
                                ]
                              }
                            });
                }),
              style: "outline"
            }), React.createElement(Spacer.make, {
              height: 8
            }), React.createElement($$Text.make, {
              size: "Tiny",
              textAlign: "center",
              color: Styles.Color.light10,
              children: "The public link can be deleted at any time"
            }));
  }
  return React.createElement(Tooltip.WithAnalytics.make, {
              delayIn: 350,
              disabled: opened,
              fromItem: {
                itemId: undefined,
                itemName: "Share Branch Implementation",
                itemType: "BranchImplementation"
              },
              maxWidth: 210,
              preventDelayOut: true,
              withTitle: false,
              title: "Share Branch Implementation",
              tooltipPadding: 16,
              withAskUsAnything: false,
              withDismiss: false,
              openOnMount: shouldHighlightButton,
              children: null
            }, React.createElement(Tooltip.Target.make, {
                  children: React.createElement(Mantine.Popover.make, {
                        closeOnClickOutside: match$3[0],
                        onChange: (function (param) {
                            return Curry._1(action.close, undefined);
                          }),
                        opened: opened,
                        position: "bottom-end",
                        children: null
                      }, React.createElement(Core.Popover.Target, {
                            children: React.createElement("div", {
                                  className: buttonWrapper(shouldHighlightButton)
                                }, React.createElement(Button.make, {
                                      icon: "share",
                                      id: "branch-implementation-share-tour",
                                      label: "Share",
                                      onClick: (function (param) {
                                          Curry._1(setShouldHighlightButton, (function (param) {
                                                  return false;
                                                }));
                                          return Curry._1(action.toggle, undefined);
                                        })
                                    }))
                          }), React.createElement(Mantine.Popover.Dropdown.make, {
                            children: React.createElement("div", {
                                  className: dropdown
                                }, React.createElement("div", {
                                      className: dropdownHeader
                                    }, React.createElement($$Text.make, {
                                          size: "Medium",
                                          weight: "Bold",
                                          color: Styles.Color.light11,
                                          children: "Share these code changes"
                                        })), React.createElement(Spacer.make, {
                                      height: 16
                                    }), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.gap(Css.px(8)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light10,
                                          withWordBreak: true,
                                          children: "Copy a link to share with existing workspace members"
                                        }), React.createElement(Button.make, {
                                          icon: "link",
                                          onClick: (function (param) {
                                              CopyTextToClipboard(Router.getOrigin(undefined) + Router.getPathName(undefined) + Router.getSearch(undefined));
                                              Curry._1(addToast, {
                                                    message: "Link copied",
                                                    toastType: /* Success */0
                                                  });
                                              return AnalyticsRe.branchImplementationInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "WorkspaceLinkCopied", SnippetTypes.DiffViewType.toAnalyticsDiffType(diffViewType), "Workspace", "Authenticated", schemaBundle.branchId, schemaBundle.schemaId);
                                            }),
                                          style: "outline"
                                        })), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginTop(Css.px(16)),
                                            tl: {
                                              hd: Css.marginBottom(Css.px(16)),
                                              tl: {
                                                hd: Css.width(Css.pct(100.0)),
                                                tl: {
                                                  hd: Css.height(Css.px(1)),
                                                  tl: {
                                                    hd: Css.backgroundColor(Styles.Color.light04),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }), React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.selector(" button", {
                                                  hd: Css.transform({
                                                        NAME: "translateY",
                                                        VAL: Css.px(3)
                                                      }),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light10,
                                          withWordBreak: true,
                                          children: null
                                        }, "Or share them with anyone by generating a public link", React.createElement(Tooltip.WithAnalytics.make, {
                                              delayIn: 350,
                                              fromItem: {
                                                itemId: undefined,
                                                itemName: "Share These Instructions",
                                                itemType: "BranchImplementation"
                                              },
                                              maxWidth: 250,
                                              position: "right",
                                              withTitle: false,
                                              size: "small",
                                              title: "Share these instructions",
                                              tooltipPadding: 16,
                                              withAskUsAnything: false,
                                              withDismiss: false,
                                              children: React.createElement(Tooltip.Content.make, {
                                                    children: null
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        children: "When you provide a public link, non-members can view these specific code changes without having full access to the workspace itself."
                                                      }), React.createElement(Spacer.make, {
                                                        height: 8
                                                      }), React.createElement($$Text.make, {
                                                        size: "Small",
                                                        children: "By revoking access through deletion of the public link, you ensure that only authorized individuals have access to these code changes."
                                                      }))
                                            }))), React.createElement(Spacer.make, {
                                      height: 16
                                    }), tmp)
                          }))
                }), React.createElement(Tooltip.Content.make, {
                  children: React.createElement($$Text.make, {
                        size: "Small",
                        children: "Copy a link to share with existing workspace members or share them with anyone by generating a public link"
                      })
                }));
}

var make = BranchImplementationShareButton;

export {
  Style ,
  getShareUrl ,
  make ,
  
}
/* dropdown Not a pure module */
