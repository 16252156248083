// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as $$Window from "./externals/window.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.mjs";

function getWidth(fontFamilyOpt, fontWeightOpt, fontSizeOpt, text) {
  var fontFamily = fontFamilyOpt !== undefined ? fontFamilyOpt : Styles.FontFamily.inter;
  var fontWeight = fontWeightOpt !== undefined ? fontWeightOpt : "normal";
  var fontSize = fontSizeOpt !== undefined ? fontSizeOpt : Css.px(14);
  var font = Css_AtomicTypes.FontWeight.toString(fontWeight) + " " + Css_AtomicTypes.Length.toString(fontSize) + " " + Css_AtomicTypes.FontFamilyName.toString(fontFamily);
  var canvas = $$Window.Canvas.make(undefined);
  var context = canvas.getContext("2d");
  context.font = font;
  return Math.ceil(context.measureText(text).width) | 0;
}

var baseStyles = Curry._1(Css.style, {
      hd: Css.lineHeight(Css.pct(140.0)),
      tl: {
        hd: Css.margin("zero"),
        tl: /* [] */0
      }
    });

function sizeStyles(size) {
  return Curry._1(Css.style, size === "Small" ? ({
                  hd: Css.fontSize(Css.px(12)),
                  tl: {
                    hd: Css.letterSpacing(Css.em(0.0)),
                    tl: /* [] */0
                  }
                }) : (
                size === "Huge" ? ({
                      hd: Css.fontSize(Css.px(24)),
                      tl: {
                        hd: Css.letterSpacing(Css.em(-0.019)),
                        tl: /* [] */0
                      }
                    }) : (
                    size === "Medium" ? ({
                          hd: Css.fontSize(Css.px(14)),
                          tl: {
                            hd: Css.letterSpacing(Css.em(-0.006)),
                            tl: /* [] */0
                          }
                        }) : (
                        size === "Tiny" ? ({
                              hd: Css.fontSize(Css.px(10)),
                              tl: {
                                hd: Css.letterSpacing(Css.em(0.0)),
                                tl: /* [] */0
                              }
                            }) : ({
                              hd: Css.fontSize(Css.px(18)),
                              tl: {
                                hd: Css.letterSpacing(Css.em(-0.014)),
                                tl: /* [] */0
                              }
                            })
                      )
                  )
              ));
}

function getFontWeight(x) {
  if (x === "Bold") {
    return Styles.FontWeight.bold;
  } else if (x === "Medium") {
    return Styles.FontWeight.medium;
  } else if (x === "Semi") {
    return Styles.FontWeight.semi;
  } else {
    return Styles.FontWeight.regular;
  }
}

function $$Text(Props) {
  var elementOpt = Props.element;
  var size = Props.size;
  var weight = Props.weight;
  var customTextAlign = Props.textAlign;
  var singleLineOpt = Props.singleLine;
  var lines = Props.lines;
  var customColor = Props.color;
  var customBackgroundColor = Props.backgroundColor;
  var maxWidth = Props.maxWidth;
  var lineHeight = Props.lineHeight;
  var title = Props.title;
  var withWordBreakOpt = Props.withWordBreak;
  var disambiguateOpt = Props.disambiguate;
  var htmlFor = Props.htmlFor;
  var children = Props.children;
  var element = elementOpt !== undefined ? elementOpt : "P";
  var singleLine = singleLineOpt !== undefined ? singleLineOpt : false;
  var withWordBreak = withWordBreakOpt !== undefined ? withWordBreakOpt : false;
  var disambiguate = disambiguateOpt !== undefined ? disambiguateOpt : false;
  var styles = function (singleLine) {
    return Curry._1(Css.merge, {
                hd: singleLine ? Curry._1(Css.style, {
                        hd: Css.textOverflow("ellipsis"),
                        tl: {
                          hd: Css.whiteSpace("nowrap"),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: /* [] */0
                          }
                        }
                      }) : (
                    Belt_Option.isSome(lines) ? Curry._1(Css.style, {
                            hd: Css.unsafe("display", "-webkit-box"),
                            tl: {
                              hd: Css.unsafe("WebkitLineClamp", "calc(" + String(Belt_Option.getExn(lines)) + ")"),
                              tl: {
                                hd: Css.unsafe("WebkitBoxOrient", "vertical"),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }) : Curry._1(Css.style, /* [] */0)
                  ),
                tl: {
                  hd: Curry._1(Css.style, disambiguate ? ({
                            hd: Css.unsafe("fontFeatureSettings", "\"ss02\" 1"),
                            tl: /* [] */0
                          }) : /* [] */0),
                  tl: {
                    hd: baseStyles,
                    tl: {
                      hd: withWordBreak ? Curry._1(Css.style, {
                              hd: Css.unsafe("wordBreak", "break-word"),
                              tl: /* [] */0
                            }) : Curry._1(Css.style, /* [] */0),
                      tl: {
                        hd: Belt_Option.mapWithDefault(lineHeight, Curry._1(Css.style, /* [] */0), (function (lineHeight) {
                                return Curry._1(Css.style, {
                                            hd: Css.lineHeight(lineHeight),
                                            tl: /* [] */0
                                          });
                              })),
                        tl: {
                          hd: Belt_Option.mapWithDefault(size, Curry._1(Css.style, /* [] */0), sizeStyles),
                          tl: {
                            hd: Belt_Option.mapWithDefault(weight, Curry._1(Css.style, /* [] */0), (function (weight) {
                                    return Curry._1(Css.style, {
                                                hd: Css.fontWeight(getFontWeight(weight)),
                                                tl: /* [] */0
                                              });
                                  })),
                            tl: {
                              hd: Belt_Option.mapWithDefault(customTextAlign, Curry._1(Css.style, /* [] */0), (function (customTextAlign) {
                                      return Curry._1(Css.style, {
                                                  hd: Css.textAlign(customTextAlign),
                                                  tl: /* [] */0
                                                });
                                    })),
                              tl: {
                                hd: Belt_Option.mapWithDefault(customColor, Curry._1(Css.style, /* [] */0), (function (c) {
                                        return Curry._1(Css.style, {
                                                    hd: Css.color(c),
                                                    tl: /* [] */0
                                                  });
                                      })),
                                tl: {
                                  hd: Belt_Option.mapWithDefault(customBackgroundColor, Curry._1(Css.style, /* [] */0), (function (c) {
                                          return Curry._1(Css.style, {
                                                      hd: Css.backgroundColor(c),
                                                      tl: /* [] */0
                                                    });
                                        })),
                                  tl: {
                                    hd: Belt_Option.mapWithDefault(maxWidth, Curry._1(Css.style, /* [] */0), (function (maxWidth) {
                                            return Curry._1(Css.style, {
                                                        hd: Css.maxWidth(maxWidth),
                                                        tl: /* [] */0
                                                      });
                                          })),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
  };
  if (element === "B") {
    var tmp = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp.title = Caml_option.valFromOption(title);
    }
    return React.createElement("b", tmp, children);
  }
  if (element === "P") {
    var tmp$1 = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp$1.title = Caml_option.valFromOption(title);
    }
    return React.createElement("p", tmp$1, children);
  }
  if (element === "Em") {
    var tmp$2 = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp$2.title = Caml_option.valFromOption(title);
    }
    return React.createElement("em", tmp$2, children);
  }
  if (element === "H1") {
    var tmp$3 = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp$3.title = Caml_option.valFromOption(title);
    }
    return React.createElement("h1", tmp$3, children);
  }
  if (element === "H2") {
    var tmp$4 = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp$4.title = Caml_option.valFromOption(title);
    }
    return React.createElement("h2", tmp$4, children);
  }
  if (element === "Li") {
    var tmp$5 = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp$5.title = Caml_option.valFromOption(title);
    }
    return React.createElement("li", tmp$5, children);
  }
  if (element === "Div") {
    var tmp$6 = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp$6.title = Caml_option.valFromOption(title);
    }
    return React.createElement("div", tmp$6, children);
  }
  if (element === "Label") {
    var tmp$7 = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp$7.title = Caml_option.valFromOption(title);
    }
    if (htmlFor !== undefined) {
      tmp$7.htmlFor = Caml_option.valFromOption(htmlFor);
    }
    return React.createElement("label", tmp$7, children);
  }
  if (element === "Span") {
    var tmp$8 = {
      className: styles(singleLine)
    };
    if (title !== undefined) {
      tmp$8.title = Caml_option.valFromOption(title);
    }
    return React.createElement("span", tmp$8, children);
  }
  var tmp$9 = {
    className: styles(singleLine)
  };
  if (title !== undefined) {
    tmp$9.title = Caml_option.valFromOption(title);
  }
  return React.createElement("strong", tmp$9, children);
}

var make = $$Text;

export {
  getWidth ,
  baseStyles ,
  sizeStyles ,
  getFontWeight ,
  make ,
  
}
/* baseStyles Not a pure module */
