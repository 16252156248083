// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AvoEngagement from "../avoEngagement/AvoEngagement.mjs";

var defaultContext_block = AvoEngagement.Block.Container.empty;

function defaultContext_transition(param) {
  
}

var defaultContext_transitions = [];

var defaultContext = {
  block: defaultContext_block,
  transition: defaultContext_transition,
  transitions: defaultContext_transitions
};

var context = React.createContext(defaultContext);

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function AvoProductTour__StepProvider(Props) {
  var block = Props.block;
  var transition = Props.transition;
  var transitions = Props.transitions;
  var children = Props.children;
  return React.createElement(provider, {
              value: {
                block: block,
                transition: transition,
                transitions: transitions
              },
              children: children
            });
}

var make = AvoProductTour__StepProvider;

export {
  defaultContext ,
  context ,
  provider ,
  use ,
  make ,
  
}
/* context Not a pure module */
