// Generated by ReScript, PLEASE EDIT WITH CARE


function makeEmptySchema(schemaId, schemaName, plan, createdAt, isSandbox, featureFlagInspectorEventsNewApiOpt, param) {
  var featureFlagInspectorEventsNewApi = featureFlagInspectorEventsNewApiOpt !== undefined ? featureFlagInspectorEventsNewApiOpt : false;
  return {
          id: schemaId,
          name: schemaName,
          createdAt: createdAt,
          billingStatus: "free",
          avoSchemaVersion: 2,
          onboardingV2: null,
          validationConfig: null,
          plan: plan,
          featureFlags: {
            InvocationMetrics: true,
            UseConstructorBasedAnalytics: true,
            RequiredPageMethodInCutomDestinationInterfaceKt: true,
            PhpMigratedToSnakeCase: true,
            UseNewSegmentReactNative: true,
            ImprovedPropertyGroupsUnfolding: true,
            ES6: true,
            ServerTrackingWithDeviceId: true,
            IntegerTypeInJsonSchema: true,
            InspectorEventsNewApi: featureFlagInspectorEventsNewApi,
            DotNet6: true,
            CSharpPlainObjectsAndNullabilityImprovements: true
          },
          isSandbox: isSandbox
        };
}

export {
  makeEmptySchema ,
  
}
/* No side effect */
