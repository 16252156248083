// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GenericModal from "../GenericModal.mjs";
import * as IconCheckmarkCircle from "../IconCheckmarkCircle.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function ImportSuccessModal$SuccessRow(Props) {
  var label = Props.label;
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(IconCheckmarkCircle.make, {
                  size: 16,
                  color: Styles.Color.green
                }), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light12,
                  children: null
                }, React.createElement($$Text.make, {
                      element: "Span",
                      weight: "Semi",
                      children: label
                    }), " imported successfully"));
}

var SuccessRow = {
  rootStyles: rootStyles,
  make: ImportSuccessModal$SuccessRow
};

function ImportSuccessModal(Props) {
  var eventCount = Props.eventCount;
  var propertyCount = Props.propertyCount;
  var sourceCount = Props.sourceCount;
  var resolvedIssuesCount = Props.resolvedIssuesCount;
  var importOrigin = Props.importOrigin;
  var onMount = Props.onMount;
  var onClose = Props.onClose;
  React.useEffect((function () {
          Belt_Option.forEach(onMount, (function (fn) {
                  return Curry._1(fn, undefined);
                }));
          
        }), []);
  var handleClose = function (param) {
    if (importOrigin === "InspectorIssueDetails" || importOrigin === "InspectorIssuesHeader") {
      return Curry._1(onClose, undefined);
    } else {
      return Router.Schema.pushSchemaRoute(undefined, undefined, "events");
    }
  };
  return React.createElement(GenericModal.make, {
              onClose: handleClose,
              children: (function (param) {
                  return React.createElement("aside", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(50)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement(Title.make, {
                                  children: "Import Complete 🎉",
                                  size: "Medium"
                                }), React.createElement(Spacer.make, {
                                  height: 20
                                }), React.createElement(React.Fragment, undefined, importOrigin === "InspectorEventsTableRow" || importOrigin === "InspectorEventsSidebar" || importOrigin === "InspectorIssueDetails" || importOrigin === "InspectorEventsHeader" || importOrigin === "InspectorIssuesHeader" || importOrigin === "OnboardingChecklist" ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                            size: "Medium",
                                            color: Styles.Color.light11,
                                            children: "Your new " + (
                                              eventCount === 1 ? "event was" : "events were"
                                            ) + " successfully added to your Tracking Plan."
                                          }), React.createElement(Spacer.make, {
                                            height: 20
                                          }), resolvedIssuesCount !== 0 ? React.createElement($$Text.make, {
                                              size: "Medium",
                                              color: Styles.Color.light11,
                                              children: "We've automatically resolved " + AvoUtils.plural(undefined, undefined, resolvedIssuesCount, "issue") + " related to " + (
                                                eventCount === 1 ? "this event" : "these events"
                                              ) + ", since " + (
                                                eventCount === 1 ? "it's" : "they are"
                                              ) + " no longer unexpected ✅"
                                            }) : null, React.createElement(Spacer.make, {
                                            height: 20
                                          }), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.justifyContent("flexEnd"),
                                                    tl: {
                                                      hd: Css.gap(Css.px(12)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement(Button.make, {
                                                label: "Close",
                                                onClick: (function (param) {
                                                    return Curry._1(onClose, undefined);
                                                  }),
                                                size: "large",
                                                style: "outline"
                                              }), React.createElement(Button.make, {
                                                label: "View Tracking Plan",
                                                onClick: (function (param) {
                                                    Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                                                    return Curry._1(onClose, undefined);
                                                  }),
                                                size: "large"
                                              }))) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                            size: "Medium",
                                            color: Styles.Color.light11,
                                            children: "Your imported tracking plan is ready to be explored. Here is a quick review of what was done:"
                                          }), React.createElement(Spacer.make, {
                                            height: 20
                                          }), eventCount !== 0 ? React.createElement(ImportSuccessModal$SuccessRow, {
                                              label: AvoUtils.plural(undefined, undefined, eventCount, "event")
                                            }) : null, propertyCount !== 0 ? (
                                          propertyCount !== 1 ? React.createElement(ImportSuccessModal$SuccessRow, {
                                                  label: propertyCount.toString() + " properties"
                                                }) : React.createElement(ImportSuccessModal$SuccessRow, {
                                                  label: "1 property"
                                                })
                                        ) : null, sourceCount !== 0 ? React.createElement(ImportSuccessModal$SuccessRow, {
                                              label: AvoUtils.plural(undefined, undefined, sourceCount, "source")
                                            }) : null, React.createElement(Spacer.make, {
                                            height: 20
                                          }), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.justifyContent("center"),
                                                    tl: {
                                                      hd: Css.gap(Css.px(12)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement(Button.make, {
                                                label: "Close",
                                                onClick: (function (param) {
                                                    return Curry._1(onClose, undefined);
                                                  }),
                                                size: "large",
                                                style: "outline"
                                              }), React.createElement(Button.make, {
                                                label: "View Tracking Plan",
                                                onClick: (function (param) {
                                                    Router.Schema.pushSchemaRoute(undefined, undefined, "events");
                                                    return Curry._1(onClose, undefined);
                                                  }),
                                                size: "large"
                                              })))));
                })
            });
}

var make = ImportSuccessModal;

export {
  SuccessRow ,
  make ,
  
}
/* rootStyles Not a pure module */
