// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as ValidationConfigV2 from "../../app/src/guardrails/ValidationConfigV2.mjs";

function make(name, value) {
  return {
          componentName: name,
          componentValue: value
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "componentName",
                t.componentName
              ],
              tl: {
                hd: [
                  "componentValue",
                  t.componentValue
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          componentName: Json_decode.field("componentName", Json_decode.string, json),
          componentValue: Json_decode.field("componentValue", Json_decode.string, json)
        };
}

function encodeArray(arr) {
  return Json_encode.array(encode, arr);
}

function decodeArray(json) {
  return Json_decode.array(decode, json);
}

function getEventNameComponentFromName(eventName, nameComponents) {
  var seperators = ValidationConfigV2.NameComponent.getSeparators(nameComponents);
  var match = Belt_Array.reduce(seperators, [
        eventName,
        []
      ], (function (param, seperator) {
          var components = param[1];
          var name = param[0];
          var sepLength = seperator.length;
          var index = name.indexOf(seperator);
          if (index === -1) {
            return [
                    name,
                    components
                  ];
          }
          var component = name.slice(0, index);
          var name$1 = name.slice(index + sepLength | 0);
          return [
                  name$1,
                  Belt_Array.concat(components, [component])
                ];
        }));
  var components = match[1];
  var lastComponent = match[0];
  var hasAdditionalSeperators = Belt_Array.some(seperators, (function (seperator) {
          return lastComponent.includes(seperator);
        }));
  var components$1 = hasAdditionalSeperators ? [] : (
      lastComponent !== "" ? Belt_Array.concat(components, [lastComponent]) : components
    );
  var buildingBlocks = ValidationConfigV2.NameComponent.getBuildingBlocks(nameComponents);
  var fullEventName = Belt_Array.reduceWithIndex(components$1, "", (function (name, component, index) {
          var separator = Belt_Array.get(seperators, index);
          if (separator !== undefined) {
            return name + component + separator;
          } else {
            return name + component;
          }
        }));
  if (buildingBlocks.length === components$1.length && fullEventName === eventName) {
    return Belt_Array.map(Belt_Array.zip(buildingBlocks, components$1), (function (param) {
                  var buildingBlock = param[0];
                  return {
                          componentName: buildingBlock.name,
                          componentValue: Case.to_(Curry._1(ValidationConfigV2.Value.getCase, buildingBlock.case.value), param[1])
                        };
                }));
  }
  
}

export {
  make ,
  encode ,
  decode ,
  encodeArray ,
  decodeArray ,
  getEventNameComponentFromName ,
  
}
/* Case Not a pure module */
