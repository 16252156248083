// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoEngagement__Types from "./AvoEngagement__Types.mjs";

var complete = AvoEngagement__Types.Block.Transition.complete;

var step1_transitions = [Curry._1(complete, undefined)];

var step1_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Introducing Fast Event Creation"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [{
              type_: "paragraph",
              children: [
                {
                  text: "Looking to quickly create multiple events? Turn the ",
                  italic: false,
                  bold: false
                },
                {
                  text: "Create more",
                  italic: false,
                  bold: true
                },
                {
                  text: " toggle on to stay in the flow of creating many events.",
                  italic: false,
                  bold: false
                }
              ]
            }]
        }
      },
      {
        id: "body-2",
        value: {
          NAME: "richText",
          VAL: [{
              type_: "paragraph",
              children: [
                {
                  text: "Pro tip: ",
                  italic: false,
                  bold: true
                },
                {
                  text: "Once you've created a few events you can use the new Bulk Editing feature to select your new events and start fleshing them out.",
                  italic: false,
                  bold: false
                }
              ]
            }]
        }
      }
    ]);

var step1 = {
  id: "step-1",
  transitions: step1_transitions,
  fields: step1_fields
};

var content_blocks = Curry._1(AvoEngagement__Types.Blocks.fromArray, [step1]);

var content = {
  id: "create-more",
  version: 1,
  rootId: "step-1",
  blocks: content_blocks
};

export {
  complete ,
  step1 ,
  content ,
  
}
/* step1 Not a pure module */
