// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as IconX from "./IconX.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@mantine/core";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var buttonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("inlineFlex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "all"),
                    tl: {
                      hd: Css.hover({
                            hd: Icon.color(Styles.Color.avoPink),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.focus({
                              hd: Icon.color(Styles.Color.avoPink),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var tooltipStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.regular),
          tl: {
            hd: Css.letterSpacing(Css.em(-0.006)),
            tl: {
              hd: Css.lineHeight(Css.pct(140.0)),
              tl: {
                hd: Css.fontSize(Styles.FontSize.regular),
                tl: {
                  hd: Css.color(Styles.Color.light01),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.light11),
                    tl: {
                      hd: Css.width("fitContent"),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.margin(Css.px(-1)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function Tooltip$Target(Props) {
  return Props.children;
}

Tooltip$Target.displayName = "tooltipTarget";

var Target = {
  make: Tooltip$Target
};

function Tooltip$Content(Props) {
  return Props.children;
}

Tooltip$Content.displayName = "tooltipContent";

var Content = {
  make: Tooltip$Content
};

function getTargetAndContent(children) {
  var match = Belt_Array.partition(React.Children.toArray(children), (function (child) {
          return child.type.displayName === "tooltipTarget";
        }));
  var target = Belt_Array.get(match[0], 0);
  var content = Belt_Array.keep(match[1], (function (child) {
          return child.type.displayName === "tooltipContent";
        }));
  if (content.length !== 0) {
    return [
            target,
            content
          ];
  }
  console.error("Tooltip component requires Content component");
  var content$1 = Belt_Array.keep(React.Children.toArray(children), (function (child) {
          return child.type.displayName !== "tooltipTarget";
        }));
  return [
          target,
          content$1
        ];
}

function Tooltip$Default(Props) {
  var arrowSizeOpt = Props.arrowSize;
  var delayInOpt = Props.delayIn;
  var disabledOpt = Props.disabled;
  var customMaxWidthOpt = Props.maxWidth;
  var onDismiss = Props.onDismiss;
  var onDisplay = Props.onDisplay;
  var offset = Props.offset;
  var onInteraction = Props.onInteraction;
  var customPositionOpt = Props.position;
  var preventDelayOutOpt = Props.preventDelayOut;
  var sizeOpt = Props.size;
  var title = Props.title;
  var tooltipPaddingOpt = Props.tooltipPadding;
  var withAskUsAnythingOpt = Props.withAskUsAnything;
  var withDismissOpt = Props.withDismiss;
  var withTitleOpt = Props.withTitle;
  var openOnMountOpt = Props.openOnMount;
  var children = Props.children;
  var arrowSize = arrowSizeOpt !== undefined ? arrowSizeOpt : 16;
  var delayIn = delayInOpt !== undefined ? delayInOpt : 350;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var customMaxWidth = customMaxWidthOpt !== undefined ? customMaxWidthOpt : 350;
  var customPosition = customPositionOpt !== undefined ? customPositionOpt : "bottom";
  var preventDelayOut = preventDelayOutOpt !== undefined ? preventDelayOutOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : "medium";
  var tooltipPadding = tooltipPaddingOpt !== undefined ? tooltipPaddingOpt : 32;
  var withAskUsAnything = withAskUsAnythingOpt !== undefined ? withAskUsAnythingOpt : true;
  var withDismiss = withDismissOpt !== undefined ? withDismissOpt : true;
  var withTitle = withTitleOpt !== undefined ? withTitleOpt : true;
  var openOnMount = openOnMountOpt !== undefined ? openOnMountOpt : false;
  var match = Hooks.useDisclosure(openOnMount);
  var action = match[1];
  var opened = match[0];
  var tooltipOpenTimeout = React.useRef(undefined);
  var tooltipCloseTimeout = React.useRef(undefined);
  var tooltipSendOpenEventTimeout = React.useRef(undefined);
  Hooks.useDidUpdate1((function (param) {
          if (opened && !disabled) {
            tooltipSendOpenEventTimeout.current = Caml_option.some(setTimeout((function (param) {
                        Belt_Option.forEach(onDisplay, (function (cb) {
                                return Curry._1(cb, undefined);
                              }));
                        tooltipSendOpenEventTimeout.current = undefined;
                        
                      }), 600));
          }
          if (!opened && !disabled) {
            Belt_Option.forEach(tooltipSendOpenEventTimeout.current, (function (prim) {
                    clearTimeout(prim);
                    
                  }));
            if (tooltipSendOpenEventTimeout.current === undefined) {
              Belt_Option.forEach(onDismiss, (function (cb) {
                      return Curry._1(cb, undefined);
                    }));
            }
            
          }
          return (function (param) {
                    Belt_Option.forEach(tooltipSendOpenEventTimeout.current, (function (prim) {
                            clearTimeout(prim);
                            
                          }));
                    Belt_Option.forEach(tooltipCloseTimeout.current, (function (prim) {
                            clearTimeout(prim);
                            
                          }));
                    return Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [opened]);
  var onMouseEnter = function (param) {
    Belt_Option.forEach(tooltipCloseTimeout.current, (function (prim) {
            clearTimeout(prim);
            
          }));
    if (!disabled) {
      tooltipOpenTimeout.current = Caml_option.some(setTimeout((function (param) {
                  return Curry._1(action.open_, undefined);
                }), delayIn));
      return ;
    }
    
  };
  var onMouseLeave = function (param) {
    Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
            clearTimeout(prim);
            
          }));
    if (preventDelayOut) {
      if (!disabled && opened) {
        return Curry._1(action.close, undefined);
      } else {
        return ;
      }
    } else if (!disabled && opened) {
      tooltipCloseTimeout.current = Caml_option.some(setTimeout((function (param) {
                  return Curry._1(action.close, undefined);
                }), 250));
      return ;
    } else {
      return ;
    }
  };
  Hooks.useDidUpdate1((function (param) {
          if (disabled) {
            Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
                    clearTimeout(prim);
                    
                  }));
            Curry._1(action.close, undefined);
          }
          
        }), [disabled]);
  var match$1 = getTargetAndContent(children);
  var target = match$1[0];
  var tmp = {
    arrowSize: arrowSize,
    arrowStyles: {
      hd: Css.backgroundColor(Styles.Color.light11),
      tl: {
        hd: Css.boxSizing("unset"),
        tl: {
          hd: Css.borderColor("transparent"),
          tl: {
            hd: Css.zIndex(-1),
            tl: /* [] */0
          }
        }
      }
    },
    dropdownStyles: {
      hd: Css.borderRadius(Css.px(6)),
      tl: {
        hd: Css.backgroundColor("transparent"),
        tl: {
          hd: Css.boxShadow(Styles.Shadow.box),
          tl: /* [] */0
        }
      }
    },
    opened: opened && !disabled,
    position: customPosition,
    withArrow: true,
    zIndex: Styles.ZIndex.aboveAll + 1 | 0,
    children: null
  };
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  return React.createElement(Mantine.Popover.make, tmp, React.createElement(Core.Popover.Target, {
                  children: target !== undefined ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.maxWidth(Css.pct(100.0)),
                                tl: /* [] */0
                              }),
                          onMouseEnter: onMouseEnter,
                          onMouseLeave: onMouseLeave
                        }, Caml_option.valFromOption(target)) : React.createElement("button", {
                          className: buttonStyles,
                          onMouseEnter: onMouseEnter,
                          onMouseLeave: onMouseLeave
                        }, React.createElement(Icon.make, {
                              type_: "information",
                              size: size,
                              color: Styles.Color.light09
                            }))
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("div", {
                        className: Curry._1(Css.merge, {
                              hd: tooltipStyles,
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.maxWidth(Css.px(customMaxWidth)),
                                      tl: {
                                        hd: Css.padding(Css.px(tooltipPadding)),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (e) {
                            var target = e.target;
                            if (target.tagName === "A" && target.href.includes("avo.app/docs/")) {
                              e.preventDefault();
                              window.open(target.href, "_blank", "noopener");
                              return Belt_Option.forEach(onInteraction, (function (cb) {
                                            return Curry._1(cb, "ClickLinkToDocs");
                                          }));
                            }
                            
                          }),
                        onMouseEnter: (function (param) {
                            if (preventDelayOut) {
                              return ;
                            } else {
                              return onMouseEnter(undefined);
                            }
                          }),
                        onMouseLeave: (function (param) {
                            if (preventDelayOut) {
                              return ;
                            } else {
                              return onMouseLeave(undefined);
                            }
                          })
                      }, withDismiss ? React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.buttonReset),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.position("absolute"),
                                            tl: {
                                              hd: Css.top(Css.px(16)),
                                              tl: {
                                                hd: Css.right(Css.px(16)),
                                                tl: {
                                                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  Belt_Option.forEach(onDismiss, (function (cb) {
                                          return Curry._1(cb, undefined);
                                        }));
                                  return Curry._1(action.close, undefined);
                                })
                            }, React.createElement(IconX.make, {})) : null, withTitle ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                  size: "Large",
                                  weight: "Semi",
                                  children: title
                                }), React.createElement(Spacer.make, {
                                  height: 8
                                })) : null, match$1[1], withAskUsAnything ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 8
                                }), React.createElement("div", undefined, React.createElement(Button.make, {
                                      label: "Ask Us Anything!",
                                      onClick: (function (param) {
                                          Belt_Option.forEach(onInteraction, (function (cb) {
                                                  return Curry._1(cb, "ClickCTAToStartAChat");
                                                }));
                                          return Intercom.showNewMessage("");
                                        })
                                    }))) : null)
                }));
}

var Default = {
  getTargetAndContent: getTargetAndContent,
  make: Tooltip$Default
};

function Tooltip$WithAnalytics(Props) {
  var delayIn = Props.delayIn;
  var disabled = Props.disabled;
  var fromItem = Props.fromItem;
  var maxWidth = Props.maxWidth;
  var position = Props.position;
  var preventDelayOut = Props.preventDelayOut;
  var withTitle = Props.withTitle;
  var sizeOpt = Props.size;
  var title = Props.title;
  var tooltipPadding = Props.tooltipPadding;
  var withAskUsAnything = Props.withAskUsAnything;
  var withDismiss = Props.withDismiss;
  var openOnMount = Props.openOnMount;
  var children = Props.children;
  var size = sizeOpt !== undefined ? sizeOpt : "medium";
  var schemaBundle = SchemaBundleContext.use(undefined);
  var itemType = fromItem.itemType;
  var itemName = fromItem.itemName;
  var itemId = fromItem.itemId;
  var onDisplay = function (param) {
    return AnalyticsRe.tooltipDisplayed(itemId, itemName, itemType, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, title, "Documentation", "OnHover", "Workspace", "Authenticated", schemaBundle.schemaId);
  };
  var onDismiss = function (param) {
    return AnalyticsRe.tooltipInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, itemId, itemName, itemType, title, "Documentation", "OnHover", "Dismiss", "Workspace", "Authenticated", schemaBundle.schemaId);
  };
  var onInteraction = function (tooltipInteractionType) {
    AnalyticsRe.tooltipInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, itemId, itemName, itemType, title, "Documentation", "OnHover", tooltipInteractionType, "Workspace", "Authenticated", schemaBundle.schemaId);
    
  };
  var tmp = {
    onDismiss: onDismiss,
    onDisplay: onDisplay,
    onInteraction: onInteraction,
    size: size,
    title: title,
    children: children
  };
  if (delayIn !== undefined) {
    tmp.delayIn = Caml_option.valFromOption(delayIn);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (maxWidth !== undefined) {
    tmp.maxWidth = Caml_option.valFromOption(maxWidth);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (preventDelayOut !== undefined) {
    tmp.preventDelayOut = Caml_option.valFromOption(preventDelayOut);
  }
  if (tooltipPadding !== undefined) {
    tmp.tooltipPadding = Caml_option.valFromOption(tooltipPadding);
  }
  if (withAskUsAnything !== undefined) {
    tmp.withAskUsAnything = Caml_option.valFromOption(withAskUsAnything);
  }
  if (withDismiss !== undefined) {
    tmp.withDismiss = Caml_option.valFromOption(withDismiss);
  }
  if (withTitle !== undefined) {
    tmp.withTitle = Caml_option.valFromOption(withTitle);
  }
  if (openOnMount !== undefined) {
    tmp.openOnMount = Caml_option.valFromOption(openOnMount);
  }
  return React.createElement(Tooltip$Default, tmp);
}

var WithAnalytics = {
  make: Tooltip$WithAnalytics
};

var make = Tooltip$Default;

export {
  buttonStyles ,
  tooltipStyles ,
  Target ,
  Content ,
  Default ,
  WithAnalytics ,
  getTargetAndContent ,
  make ,
  
}
/* buttonStyles Not a pure module */
