// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as PlanLightning from "./PlanLightning.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function EntityLightning(Props) {
  var availability = Props.availability;
  var planHint = Props.planHint;
  var tooltipDirectionOpt = Props.tooltipDirection;
  var strokeOpt = Props.stroke;
  var fillOpt = Props.fill;
  var tooltipDirection = tooltipDirectionOpt !== undefined ? tooltipDirectionOpt : "bottom";
  var stroke = strokeOpt !== undefined ? strokeOpt : Styles.Color.light08;
  var fill = fillOpt !== undefined ? fillOpt : "transparent";
  if (availability === "Available") {
    return null;
  }
  if (availability === "AvailableDuringTrial") {
    var plan = Belt_Option.mapWithDefault(planHint, "next", (function (plan) {
            return Workspace.printPlanName(plan.name);
          }));
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css_Legacy_Core.SVG.fill(fill),
                      tl: {
                        hd: Css_Legacy_Core.SVG.stroke(stroke),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement(PlanLightning.make, {
                    tooltipText: "Available during trial and on the " + plan + " plan",
                    tooltipDirection: tooltipDirection
                  }));
  }
  var plan$1 = Belt_Option.mapWithDefault(planHint, "next", (function (plan) {
          return Workspace.printPlanName(plan.name);
        }));
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(fill),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(stroke),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(PlanLightning.make, {
                  tooltipText: "Available on the " + plan$1 + " plan",
                  tooltipDirection: tooltipDirection
                }));
}

var make = EntityLightning;

export {
  make ,
  
}
/* Css Not a pure module */
