// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as $$Window from "../externals/window.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProductTour__OnElement from "./ProductTour__OnElement.mjs";

function ProductTour__OnElements(Props) {
  var forIds = Props.forIds;
  var arrowOffset = Props.arrowOffset;
  var borderRadius = Props.borderRadius;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var lockBackground = Props.lockBackground;
  var onClick = Props.onClick;
  var onOutsideClick = Props.onOutsideClick;
  var offset = Props.offset;
  var opened = Props.opened;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var onStepActive = Props.onStepActive;
  var simulateClick = Props.simulateClick;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var waitForId = Props.waitForId;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var forId = React.useMemo((function () {
          return Shortid();
        }), [positionDependencies]);
  var match = React.useMemo(function () {
        var match = Belt_Array.reduce(forIds, [
              undefined,
              undefined,
              undefined,
              undefined
            ], (function (param, id) {
                var b = param[3];
                var r = param[2];
                var t = param[1];
                var l = param[0];
                var el = $$Window.$$Document.getElementById(id);
                if (el === undefined) {
                  return [
                          l,
                          t,
                          r,
                          b
                        ];
                }
                var domRect = Caml_option.valFromOption(el).getBoundingClientRect();
                var left = domRect.left;
                var top = domRect.top;
                var right = domRect.right;
                var bottom = domRect.bottom;
                return [
                        Math.min(Belt_Option.getWithDefault(l, left), left),
                        Math.min(Belt_Option.getWithDefault(t, top), top),
                        Math.max(Belt_Option.getWithDefault(r, right), right),
                        Math.max(Belt_Option.getWithDefault(b, bottom), bottom)
                      ];
              }));
        var l = Belt_Option.getWithDefault(match[0], 0);
        var t = Belt_Option.getWithDefault(match[1], 0);
        var r = Belt_Option.getWithDefault(match[2], 0);
        var b = Belt_Option.getWithDefault(match[3], 0);
        return [
                l,
                t,
                r - l | 0,
                b - t | 0
              ];
      });
  var tmp = {
    forId: forId,
    forIds: forIds,
    opened: opened,
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (borderRadius !== undefined) {
    tmp.borderRadius = Caml_option.valFromOption(borderRadius);
  }
  if (delay !== undefined) {
    tmp.delay = Caml_option.valFromOption(delay);
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = Caml_option.valFromOption(elementClickable);
  }
  if (elementPadding !== undefined) {
    tmp.elementPadding = Caml_option.valFromOption(elementPadding);
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = Caml_option.valFromOption(lockBackground);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onOutsideClick !== undefined) {
    tmp.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (onStepActive !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
  }
  if (waitForId !== undefined) {
    tmp.waitForId = Caml_option.valFromOption(waitForId);
  }
  if (withOutline !== undefined) {
    tmp.withOutline = Caml_option.valFromOption(withOutline);
  }
  return React.createElement(React.Fragment, undefined, opened ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Caml_obj.caml_equal(elementClickable, true) || elementClickable === undefined ? Css.pointerEvents("none") : Styles.emptyStyle,
                          tl: {
                            hd: Css.position("fixed"),
                            tl: {
                              hd: Css.left(Css.px(match[0])),
                              tl: {
                                hd: Css.top(Css.px(match[1])),
                                tl: {
                                  hd: Css.width(Css.px(match[2])),
                                  tl: {
                                    hd: Css.height(Css.px(match[3])),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }),
                    id: forId
                  }) : null, React.createElement(ProductTour__OnElement.make, tmp));
}

var OnElement;

var make = ProductTour__OnElements;

export {
  OnElement ,
  make ,
  
}
/* Css Not a pure module */
