// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Printer from "../../../model/src/Printer.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ValidationConfigV2 from "../guardrails/ValidationConfigV2.mjs";
import * as TrackingPlanDiscrepanciesWorker from "./TrackingPlanDiscrepanciesWorker.mjs";

var instance = new ComlinkWorker(new URL("./TrackingPlanDiscrepanciesWorker.mjs", import.meta.url), {});

function getDiscrepancies(eventComponentsWithNames, config, model) {
  var model$1 = Printer.printModel(model);
  var configJson = ValidationConfigV2.encode(config);
  return instance.getDiscrepancies(Belt_MapString.toArray(eventComponentsWithNames), configJson, model$1);
}

export {
  getDiscrepancies ,
  
}
/* instance Not a pure module */
