// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

function parse(json) {
  return {
          propertyConflictOnEvent: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("propertyConflictOnEvent", Json_decode.bool, param);
                    }), json), true),
          sameEventName: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("sameEventName", Json_decode.bool, param);
                    }), json), true),
          samePropertyName: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("samePropertyName", Json_decode.bool, param);
                    }), json), true),
          eventsWithUnexpectedCase: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("eventsWithUnexpectedCase", Json_decode.bool, param);
                    }), json), true),
          preferredEventCase: Belt_Option.getWithDefault(Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field("preferredEventCase", Json_decode.string, param);
                        }), json), Case.tFromJs), "None"),
          forceEventCase: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("forceEventCase", Json_decode.bool, param);
                    }), json), false),
          propertiesWithUnexpectedCase: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("propertiesWithUnexpectedCase", Json_decode.bool, param);
                    }), json), true),
          forcePropertyCase: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("forcePropertyCase", Json_decode.bool, param);
                    }), json), false),
          preferredPropertyCase: Belt_Option.getWithDefault(Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field("preferredPropertyCase", Json_decode.string, param);
                        }), json), Case.tFromJs), "None"),
          propertiesWithAnyType: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("propertiesWithAnyType", Json_decode.bool, param);
                    }), json), true),
          eventsWithoutDescription: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("eventsWithoutDescription", Json_decode.bool, param);
                    }), json), true),
          propertiesWithoutDescription: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("propertiesWithoutDescription", Json_decode.bool, param);
                    }), json), true)
        };
}

export {
  parse ,
  
}
/* Case Not a pure module */
