// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function getMatchesFromPropertyLegacy(property) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_List.getByU(property.validations, (function (validation) {
                        if (typeof validation === "object") {
                          return validation.NAME === "Matches";
                        } else {
                          return false;
                        }
                      })), (function (matches) {
                    if (typeof matches === "object" && matches.NAME === "Matches") {
                      return matches.VAL;
                    }
                    
                  })), /* [] */0);
}

function getTriggerFromEvent(id, $$event) {
  return Belt_Array.getBy($$event.triggers, (function (trigger) {
                return trigger.id === id;
              }));
}

var defaultDestinationName = "Untitled Destination";

function getDestinationName(destination) {
  return Belt_Option.mapWithDefault(destination.name, defaultDestinationName, (function (name) {
                if (name === "") {
                  return defaultDestinationName;
                } else {
                  return name;
                }
              }));
}

function getPropertyId(property) {
  return property._0.id;
}

function getPropertyNameMappingsOnEvent(rules, $$event, property, mappedSources) {
  return Belt_Array.keepU(rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return false;
                }
                if (match.NAME !== "PropertyRef") {
                  return false;
                }
                if (typeof match$1 !== "object") {
                  return false;
                }
                var variant = match$1.NAME;
                var match$2 = match.VAL;
                var propertyId = match$2[1];
                var eventId = match$2[0];
                if (variant === "NameMapping") {
                  if (eventId === $$event.id) {
                    return propertyId === property.id;
                  } else {
                    return false;
                  }
                }
                if (variant !== "NameInLocalWorkspace") {
                  return false;
                }
                var workspace = match$1.VAL.workspace;
                var workspaceMissing = workspace !== undefined && typeof workspace === "object" ? !Curry._2(TrackingPlanMappedModel.Sources.has, mappedSources, workspace.VAL) : false;
                if (eventId === $$event.id && propertyId === property.id) {
                  return !workspaceMissing;
                } else {
                  return false;
                }
              }));
}

function isDestinationInterfaceEnabledForSourceDestination(source, destinationId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(source.destinations, (function (destinationRef) {
                    return destinationRef.destinationId === destinationId;
                  })), false, (function (destinationRef) {
                return destinationRef.destinationMode === /* DestinationInterface */1;
              }));
}

function getPropertyPinnedValueFromEvent($$event, propertyId) {
  return Belt_List.head(Belt_List.keepMapU($$event.directPropertyRefs, (function (property) {
                    if (property.TAG !== /* PropertyRef */0) {
                      return ;
                    }
                    var propRef = property._0;
                    if (propRef.id === propertyId) {
                      return propRef.pinnedValue;
                    }
                    
                  })));
}

function getEventActions(actions) {
  return Belt_List.keep(actions, (function (action) {
                if (!action) {
                  return false;
                }
                var match = action.hd[0];
                if (typeof match === "object") {
                  return match.NAME === "Event";
                } else {
                  return false;
                }
              }));
}

function eventsSendingPropertyFromSources__eventUsingProperty($$event, propertyId) {
  return Belt_List.someU($$event.directPropertyRefs, (function (property) {
                if (property.TAG === /* PropertyRef */0) {
                  return property._0.id === propertyId;
                } else {
                  return false;
                }
              }));
}

function eventsSendingPropertyFromSources__eventVariantUsingProperty($$event, propertyId) {
  return Belt_Array.someU($$event.variants, (function (variant) {
                return Belt_Option.mapWithDefault(Belt_MapString.get(variant.propertyOverrides, propertyId), false, (function (override) {
                              if (override) {
                                return true;
                              } else {
                                return false;
                              }
                            }));
              }));
}

function doesPropertyContainPropertyIdAsFirstLevelNested(parentProperty, nestedPropertyId) {
  return Belt_List.someU(parentProperty.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                  return Belt_List.someU(validation.VAL, (function (nestedPropertyRef) {
                                return nestedPropertyRef.id === nestedPropertyId;
                              }));
                } else {
                  return false;
                }
              }));
}

function doesPropertyContainPropertyIdAsAnyLevelNested(parentProperty, resolvepropertyById, nestedPropertyId) {
  return Belt_List.someU(parentProperty.validations, (function (validation) {
                if (typeof validation !== "object") {
                  return false;
                }
                if (validation.NAME !== "NestedProperty") {
                  return false;
                }
                var nestedPropertyRefs = validation.VAL;
                var isFirstLevelChild = Belt_List.someU(nestedPropertyRefs, (function (nestedPropertyRef) {
                        return nestedPropertyRef.id === nestedPropertyId;
                      }));
                if (isFirstLevelChild) {
                  return true;
                } else {
                  return Belt_List.some(nestedPropertyRefs, (function (nestedPropertyRef) {
                                var nestedProperty = Curry._1(resolvepropertyById, nestedPropertyRef.id);
                                if (nestedProperty !== undefined) {
                                  return doesPropertyContainPropertyIdAsAnyLevelNested(nestedProperty, resolvepropertyById, nestedPropertyId);
                                } else {
                                  return false;
                                }
                              }));
                }
              }));
}

export {
  getMatchesFromPropertyLegacy ,
  getTriggerFromEvent ,
  defaultDestinationName ,
  getDestinationName ,
  getPropertyId ,
  getPropertyNameMappingsOnEvent ,
  isDestinationInterfaceEnabledForSourceDestination ,
  getPropertyPinnedValueFromEvent ,
  getEventActions ,
  eventsSendingPropertyFromSources__eventUsingProperty ,
  eventsSendingPropertyFromSources__eventVariantUsingProperty ,
  doesPropertyContainPropertyIdAsFirstLevelNested ,
  doesPropertyContainPropertyIdAsAnyLevelNested ,
  
}
/* TrackingPlanMappedModel Not a pure module */
