// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var context = React.createContext(undefined);

var provider = context.Provider;

function use(param) {
  return Belt_Option.getExn(React.useContext(context));
}

function ViewerContext(Props) {
  var user = Props.user;
  var children = Props.children;
  return React.createElement(provider, {
              value: user,
              children: children
            });
}

var make = ViewerContext;

export {
  context ,
  provider ,
  use ,
  make ,
  
}
/* context Not a pure module */
