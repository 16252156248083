// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as React$1 from "@floating-ui/react";

function isRootPlacement(p) {
  if (p === "top" || p === "right" || p === "bottom") {
    return true;
  } else {
    return p === "left";
  }
}

function isEndPlacement(p) {
  if (p === "left-end" || p === "right-end" || p === "bottom-end") {
    return true;
  } else {
    return p === "top-end";
  }
}

function isStartPlacement(p) {
  if (p === "right-start" || p === "top-start" || p === "left-start") {
    return true;
  } else {
    return p === "bottom-start";
  }
}

function isVerticalPlacement(p) {
  if (p === "bottom-end" || p === "top-start" || p === "top" || p === "bottom" || p === "top-end") {
    return true;
  } else {
    return p === "bottom-start";
  }
}

function isHorizontalPlacement(p) {
  if (p === "left-end" || p === "right-start" || p === "right-end" || p === "left-start" || p === "right") {
    return true;
  } else {
    return p === "left";
  }
}

var Placement = {
  isRootPlacement: isRootPlacement,
  isEndPlacement: isEndPlacement,
  isStartPlacement: isStartPlacement,
  isVerticalPlacement: isVerticalPlacement,
  isHorizontalPlacement: isHorizontalPlacement
};

var Arrow = {};

var Middleware = {
  Arrow: Arrow
};

var Handlers = {};

var Coords = {};

var Interaction = {};

var $$Event = {};

var OpenChangeReason = {};

var FloatingUIRef = {};

var FloatingTree = {};

var Strategy = {};

var Context = {};

function castInitialFocus(i) {
  return i.VAL;
}

function castBoolString(b) {
  return b.VAL;
}

function castRoot(r) {
  return r.VAL;
}

var Flip = {};

var Shift = {};

var Offset = {};

var SafePolygon = {};

var AutoUpdate = {};

var T = {
  Placement: Placement,
  Middleware: Middleware,
  Handlers: Handlers,
  Coords: Coords,
  Interaction: Interaction,
  $$Event: $$Event,
  OpenChangeReason: OpenChangeReason,
  FloatingUIRef: FloatingUIRef,
  FloatingTree: FloatingTree,
  Strategy: Strategy,
  Context: Context,
  castInitialFocus: castInitialFocus,
  castBoolString: castBoolString,
  castRoot: castRoot,
  Flip: Flip,
  Shift: Shift,
  Offset: Offset,
  SafePolygon: SafePolygon,
  AutoUpdate: AutoUpdate
};

function useFloatingParentNodeId(param) {
  return Caml_option.nullable_to_opt(React$1.useFloatingParentNodeId());
}

function useFloatingTree(param) {
  return Caml_option.nullable_to_opt(React$1.useFloatingTree());
}

var Internal = {};

function FloatingUI$FloatingFocusManager(Props) {
  var context = Props.context;
  var disabled = Props.disabled;
  var order = Props.order;
  var initialFocus = Props.initialFocus;
  var returnFocus = Props.returnFocus;
  var guards = Props.guards;
  var modal = Props.modal;
  var visuallyHiddenDismiss = Props.visuallyHiddenDismiss;
  var closeOnFocusOut = Props.closeOnFocusOut;
  var children = Props.children;
  var tmp = {
    context: context,
    children: children
  };
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  if (order !== undefined) {
    tmp.order = Caml_option.valFromOption(order);
  }
  var tmp$1 = Belt_Option.map(initialFocus, castInitialFocus);
  if (tmp$1 !== undefined) {
    tmp.initialFocus = Caml_option.valFromOption(tmp$1);
  }
  if (returnFocus !== undefined) {
    tmp.returnFocus = returnFocus;
  }
  if (guards !== undefined) {
    tmp.guards = guards;
  }
  if (modal !== undefined) {
    tmp.modal = modal;
  }
  var tmp$2 = Belt_Option.map(visuallyHiddenDismiss, castBoolString);
  if (tmp$2 !== undefined) {
    tmp.visuallyHiddenDismiss = Caml_option.valFromOption(tmp$2);
  }
  if (closeOnFocusOut !== undefined) {
    tmp.closeOnFocusOut = closeOnFocusOut;
  }
  return React.createElement(React$1.FloatingFocusManager, tmp);
}

var FloatingFocusManager = {
  Internal: Internal,
  make: FloatingUI$FloatingFocusManager
};

var FloatingList = {};

var FloatingNode = {};

var Internal$1 = {};

function FloatingUI$FloatingPortal(Props) {
  var id = Props.id;
  var root = Props.root;
  var preserveTabOrder = Props.preserveTabOrder;
  var children = Props.children;
  var tmp = {
    children: children
  };
  if (id !== undefined) {
    tmp.id = id;
  }
  var tmp$1 = Belt_Option.map(root, castRoot);
  if (tmp$1 !== undefined) {
    tmp.root = Caml_option.valFromOption(tmp$1);
  }
  if (preserveTabOrder !== undefined) {
    tmp.preserveTabOrder = preserveTabOrder;
  }
  return React.createElement(React$1.FloatingPortal, tmp);
}

var FloatingPortal = {
  Internal: Internal$1,
  make: FloatingUI$FloatingPortal
};

var FloatingTree$1 = {};

var Internal$2 = {};

var make = React.forwardRef(function (Props, ref_) {
      var context = Props.context;
      var width = Props.width;
      var height = Props.height;
      var tipRadius = Props.tipRadius;
      var staticOffset = Props.staticOffset;
      var d = Props.d;
      var fill = Props.fill;
      var stroke = Props.stroke;
      var strokeWidth = Props.strokeWidth;
      var tmp = {
        ref: ref_,
        context: context
      };
      if (width !== undefined) {
        tmp.width = width;
      }
      if (height !== undefined) {
        tmp.height = height;
      }
      if (tipRadius !== undefined) {
        tmp.tipRadius = tipRadius;
      }
      var tmp$1 = staticOffset !== undefined ? (
          typeof staticOffset === "object" ? (
              staticOffset.NAME === "pct" ? String(staticOffset.VAL) + "%" : String(staticOffset.VAL) + "px"
            ) : (
              staticOffset === "start" ? "start" : "end"
            )
        ) : undefined;
      if (tmp$1 !== undefined) {
        tmp.staticOffset = tmp$1;
      }
      if (d !== undefined) {
        tmp.d = d;
      }
      if (fill !== undefined) {
        tmp.fill = fill;
      }
      if (stroke !== undefined) {
        tmp.stroke = stroke;
      }
      if (strokeWidth !== undefined) {
        tmp.strokeWidth = strokeWidth;
      }
      return React.createElement(React$1.FloatingArrow, tmp);
    });

var FloatingArrow = {
  Internal: Internal$2,
  make: make
};

export {
  T ,
  useFloatingParentNodeId ,
  useFloatingTree ,
  FloatingFocusManager ,
  FloatingList ,
  FloatingNode ,
  FloatingPortal ,
  FloatingTree$1 as FloatingTree,
  FloatingArrow ,
  
}
/* make Not a pure module */
