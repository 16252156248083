// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function get(property) {
  return Belt_Option.flatMapU(Belt_List.getByU(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return false;
                    }
                    if (validation.NAME !== "Regex") {
                      return false;
                    }
                    var value = validation.VAL;
                    if (Belt_Option.isSome(value.propertyRule)) {
                      return true;
                    } else {
                      return !Belt_MapString.isEmpty(value.eventOverrides);
                    }
                  })), (function (validation) {
                if (typeof validation === "object" && validation.NAME === "Regex") {
                  return validation.VAL;
                }
                
              }));
}

export {
  get ,
  
}
/* No side effect */
