// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "./IconErase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import UrlSafeString from "url-safe-string";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function SelectBranchPopup$OpenedBy(Props) {
  var openAction = Props.openAction;
  var user = FirebaseFetcherHooks.useUser(openAction.createdBy);
  var date = Models.toDateOption(openAction.createdAt);
  return React.createElement(React.Fragment, undefined, "Opened ", date !== undefined ? DateFns.formatDistanceToNow(false, false, Caml_option.valFromOption(date)) + " ago" : null, typeof user === "object" ? " by " + AvoConfig.getUserDisplayName(user.VAL) : (
                user === "NotFound" ? null : " by …"
              ));
}

var OpenedBy = {
  make: SelectBranchPopup$OpenedBy
};

var rootStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.width(Css.pct(100.0)),
                  tl: {
                    hd: Css.padding2(Css.px(5), Css.px(20)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "background-color"),
                      tl: {
                        hd: Css.color(Styles.Color.light10),
                        tl: {
                          hd: Css.textAlign("left"),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.backgroundColor(Styles.Color.light02),
                                  tl: {
                                    hd: Css.color(Styles.Color.light12),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: {
                              hd: Css.active({
                                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.focus({
                                      hd: Css.outlineStyle("none"),
                                      tl: {
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), true, Styles.Color.darkBlue)),
                                        tl: {
                                          hd: Css.borderRadius(Styles.Border.radius),
                                          tl: /* [] */0
                                        }
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function SelectBranchPopup$Branch(Props) {
  var name = Props.name;
  var id = Props.id;
  var onClick = Props.onClick;
  var workspace = WorkspaceContext.use(undefined);
  var openAction = FirebaseFetcherHooks.useBranchOpenAction(workspace.id, id, "All", undefined, undefined);
  var tmp;
  tmp = openAction !== undefined ? React.createElement(SelectBranchPopup$OpenedBy, {
          openAction: Caml_option.valFromOption(openAction)
        }) : (
      id === "master" ? "The default branch" : "Opened …"
    );
  return React.createElement("button", {
              className: rootStyles,
              onClick: onClick
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Medium",
                  children: name
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Medium",
                  color: Styles.Color.light08,
                  children: tmp
                }));
}

var Branch = {
  rootStyles: rootStyles,
  make: SelectBranchPopup$Branch
};

var safeBranchName = new UrlSafeString({
      trimWhitespace: false
    });

var rootStyles$1 = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(300)),
      tl: {
        hd: Css.marginTop(Css.px(4)),
        tl: {
          hd: Css.marginBottom(Css.px(16)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                              NAME: "num",
                              VAL: 0.10
                            }))),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.minHeight(Css.px(50)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
              tl: {
                hd: Css.padding4(Css.px(7), Css.px(12), Css.px(5), Css.px(20)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var filterStyles = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(0)),
      tl: {
        hd: Css.width(Css.px(100)),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.flexGrow(1.0),
            tl: {
              hd: Css.borderWidth(Css.px(0)),
              tl: {
                hd: Css.fontSize(Styles.FontSize.regular),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.medium),
                  tl: {
                    hd: Css.lineHeight(Styles.LineHeight.regular),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.paddingTop(Css.px(0)),
                        tl: {
                          hd: Css.paddingRight(Css.px(8)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(0)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(0)),
                              tl: {
                                hd: Css.placeholder({
                                      hd: Css.color(Styles.Color.light08),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.focus({
                                        hd: Css.outlineStyle("none"),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButtonStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginTop(Css.px(0)),
                tl: {
                  hd: Css.marginBottom(Css.px(0)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.marginRight(Css.px(8)),
                      tl: {
                        hd: Css.borderWidth(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.px(2)),
                          tl: {
                            hd: Css.background("transparent"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "opacity"),
                              tl: {
                                hd: Css.opacity(disabled ? 0 : 1),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.active({
                                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var listStyles = Curry._1(Css.style, {
      hd: Css.maxHeight(Css.px(300)),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.paddingTop(Css.px(12)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var noBranchesStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.fontWeight(Styles.FontWeight.medium),
            tl: {
              hd: Css.fontSize(Styles.FontSize.regular),
              tl: {
                hd: Css.color(Styles.Color.light08),
                tl: {
                  hd: Css.lineHeight(Styles.LineHeight.regular),
                  tl: {
                    hd: Css.padding2(Css.px(16), Css.px(20)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function SelectBranchPopup(Props) {
  var canSelectExistingBranchesOpt = Props.canSelectExistingBranches;
  var canSelectMain = Props.canSelectMain;
  var onClose = Props.onClose;
  var onSelect = Props.onSelect;
  var openBranches = Props.openBranches;
  var canSelectExistingBranches = canSelectExistingBranchesOpt !== undefined ? canSelectExistingBranchesOpt : true;
  var match = React.useState(function () {
        return "";
      });
  var setFilter = match[1];
  var filter = match[0];
  var branchRecords = Belt_Array.map(Belt_Array.concat(canSelectMain ? [[
                "master",
                "main"
              ]] : [], canSelectExistingBranches ? Belt_List.toArray(Belt_List.reverse(openBranches)) : []), (function (param) {
          return {
                  id: param[0],
                  name: param[1]
                };
        }));
  var filteredBranches = Fuzzaldrin.filter(branchRecords, filter === "master" ? "main" : filter, {
        key: "name",
        maxResults: 100
      });
  var filterEqualsExistingBranchName;
  switch (filter) {
    case "main" :
    case "master" :
        filterEqualsExistingBranchName = true;
        break;
    default:
      filterEqualsExistingBranchName = Belt_List.someU(openBranches, (function (param) {
              return filter === param[1];
            }));
  }
  return React.createElement("div", {
              className: rootStyles$1,
              onKeyDown: (function ($$event) {
                  if ($$event.key === "Escape") {
                    return Curry._1(onClose, undefined);
                  }
                  
                })
            }, React.createElement("div", {
                  className: headerStyles
                }, React.createElement("input", {
                      className: filterStyles,
                      autoFocus: true,
                      placeholder: "New branch name…",
                      value: filter,
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          return Curry._1(setFilter, (function (param) {
                                        return safeBranchName.generate(value);
                                      }));
                        })
                    }), React.createElement("button", {
                      className: eraseButtonStyles(filter === ""),
                      disabled: filter === "",
                      onClick: (function (param) {
                          return Curry._1(setFilter, (function (param) {
                                        return "";
                                      }));
                        })
                    }, React.createElement(IconErase.make, {
                          color: Styles.Color.light07,
                          hoverColor: Styles.Color.red
                        })), React.createElement(Button.make, {
                      disabled: filter === "" || filterEqualsExistingBranchName,
                      label: "Create",
                      onClick: (function (param) {
                          Curry._1(onSelect, {
                                NAME: "Create",
                                VAL: filter
                              });
                          return Curry._1(onClose, undefined);
                        })
                    })), !canSelectExistingBranches && Belt_List.someU(openBranches, (function (param) {
                    return filter === param[1];
                  })) ? React.createElement("div", {
                    className: noBranchesStyles
                  }, "A branch with this name already exists") : (
                filteredBranches.length !== 0 ? React.createElement("div", {
                        className: listStyles
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.marginLeft(Css.px(20)),
                                  tl: {
                                    hd: Css.marginBottom(Css.px(4)),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light08,
                                children: canSelectExistingBranches ? "Or select an existing branch:" : "Existing branches not supported"
                              })), Belt_Array.mapU(filteredBranches, (function (param) {
                              var name = param.name;
                              var id = param.id;
                              return React.createElement(SelectBranchPopup$Branch, {
                                          name: name,
                                          id: id,
                                          onClick: (function (param) {
                                              var tmp = id === "master" ? "Master" : ({
                                                    NAME: "Branch",
                                                    VAL: {
                                                      id: id,
                                                      name: name
                                                    }
                                                  });
                                              Curry._1(onSelect, tmp);
                                              return Curry._1(onClose, undefined);
                                            }),
                                          key: id
                                        });
                            }))) : (
                    canSelectExistingBranches ? React.createElement("div", {
                            className: noBranchesStyles
                          }, "Found no matching existing branches") : React.createElement("div", {
                            className: noBranchesStyles
                          }, "'" + filter + "' does not already exist")
                  )
              ));
}

var make = SelectBranchPopup;

export {
  OpenedBy ,
  Branch ,
  safeBranchName ,
  rootStyles$1 as rootStyles,
  headerStyles ,
  filterStyles ,
  eraseButtonStyles ,
  listStyles ,
  noBranchesStyles ,
  make ,
  
}
/* rootStyles Not a pure module */
