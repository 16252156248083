// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Quill from "./externals/quill.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as ArrayExt from "./ArrayExt.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconBranch from "./IconBranch.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as DomainStore from "./DomainStore.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as IntegrationIcon from "./integration/IntegrationIcon.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as ActivityItemLoading from "./ActivityItemLoading.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as InspectorIssueStatus from "../../shared/models/InspectorIssueStatus.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";
import * as BranchImplementationShareButton from "./branchImplementation/BranchImplementationShareButton.mjs";

function ActivityItem$DomainActivityItem(Props) {
  var children = Props.children;
  var domainId = Props.domainId;
  return Curry._1(children, DomainStore.useDomainWithArchive(domainId));
}

var DomainActivityItem = {
  make: ActivityItem$DomainActivityItem
};

function rootStyles(withHover) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.paddingTop(Css.px(16)),
                    tl: {
                      hd: Css.paddingRight(Css.px(16)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(16)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(16)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.hover(withHover ? ({
                                        hd: Css.backgroundColor(Styles.Color.light01),
                                        tl: /* [] */0
                                      }) : /* [] */0),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "background-color"),
                                tl: {
                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var actionContainerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: /* [] */0
    });

var iconStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.width(Css.px(24)),
              tl: {
                hd: Css.height(Css.px(24)),
                tl: {
                  hd: Css.marginTop(Css.px(3)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var picturePlaceholderStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(24)),
      tl: {
        hd: Css.height(Css.px(24)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.light02),
          tl: {
            hd: Css.borderRadius(Css.px(17)),
            tl: /* [] */0
          }
        }
      }
    });

function ActivityItem$ActivityItemAction(Props) {
  var action = Props.action;
  var users = Props.users;
  var message = Props.message;
  var icon = Props.icon;
  var path = Props.path;
  var menuOptions = Props.menuOptions;
  var openBranches = Props.openBranches;
  var onClickOpt = Props.onClick;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        
      });
  var workspace = WorkspaceContext.use(undefined);
  var branchId = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(action.branchId), "master");
  var maybeOpenBranch = Belt_List.getBy(openBranches, (function (param) {
          return param[0] === branchId;
        }));
  var maybeOpenBranchName = Belt_Option.map(maybeOpenBranch, (function (param) {
          return param[1];
        }));
  var tmp;
  tmp = maybeOpenBranchName !== undefined || branchId === "master" ? "Skip" : "Fetch";
  var branchState = FirebaseFetcherHooks.useBranchState(tmp, workspace.id, branchId);
  var branchName = branchId === "master" ? "main" : (
      maybeOpenBranchName !== undefined ? maybeOpenBranchName : (
          branchState !== undefined ? branchState.branchName : undefined
        )
    );
  var content = function (withHover) {
    var match = Belt_Array.get(users, 0);
    return React.createElement("div", {
                className: rootStyles(withHover)
              }, React.createElement("div", {
                    className: actionContainerStyles
                  }, React.createElement("div", {
                        className: iconStyles
                      }, icon !== undefined ? Caml_option.valFromOption(icon) : (
                          match !== undefined ? React.createElement(ProfilePhoto.make, {
                                  user: match,
                                  size: 24
                                }) : React.createElement("div", {
                                  className: picturePlaceholderStyles
                                })
                        )), React.createElement(Spacer.make, {
                        width: 12
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.unsafe("wordBreak", "break-word"),
                              tl: /* [] */0
                            })
                      }, React.createElement($$Text.make, {
                            element: "Div",
                            size: "Small",
                            color: Styles.Color.light12,
                            children: null
                          }, Belt_Array.mapWithIndex(users, (function (index, user) {
                                  var match = users.length - index | 0;
                                  return React.createElement(React.Fragment, {
                                              children: null,
                                              key: user.id
                                            }, React.createElement("b", undefined, AvoConfig.getUserDisplayName(user)), match !== 1 ? (
                                                match !== 2 ? ", " : " and "
                                              ) : null);
                                })), " ", message), React.createElement(Spacer.make, {
                            height: 2
                          }), React.createElement($$Text.make, {
                            size: "Tiny",
                            color: Styles.Color.light08,
                            children: null
                          }, React.createElement(Timestamp.make, {
                                date: action.createdAt
                              }), branchName !== undefined ? React.createElement(React.Fragment, undefined, " on ", React.createElement(IconBranch.make, {
                                      size: 8,
                                      color: Styles.Color.light08
                                    }), " " + branchName) : null))), Belt_Option.mapWithDefault(menuOptions, null, (function (options) {
                      if (options.length !== 0) {
                        return React.createElement(ContextMenu.make, {
                                    options: options
                                  });
                      } else {
                        return null;
                      }
                    })));
  };
  if (path !== undefined) {
    return React.createElement(Link.make, {
                path: path,
                onClick: onClick,
                children: content(true)
              });
  } else {
    return content(false);
  }
}

var ActivityItemAction = {
  rootStyles: rootStyles,
  actionContainerStyles: actionContainerStyles,
  iconStyles: iconStyles,
  picturePlaceholderStyles: picturePlaceholderStyles,
  make: ActivityItem$ActivityItemAction
};

function ActivityItem$ActivityItemComment(Props) {
  var threadId = Props.threadId;
  var commentId = Props.commentId;
  var link = Props.link;
  var itemTypeName = Props.itemTypeName;
  var itemName = Props.itemName;
  var action = Props.action;
  var users = Props.users;
  var events = Props.events;
  var openBranches = Props.openBranches;
  var workspace = WorkspaceContext.use(undefined);
  var comment = FirebaseFetcherHooks.useComment(workspace.id, threadId, commentId);
  if (typeof comment !== "object") {
    return null;
  }
  var tmp;
  if (itemName !== undefined) {
    tmp = React.createElement(React.Fragment, undefined, "commented on the " + itemTypeName, React.createElement($$Text.make, {
              element: "Em",
              children: itemName
            }), " ");
  } else {
    var __x = itemTypeName.charAt(0).toLocaleLowerCase();
    var itemTypeStartsWithAVovel = ArrayExt.has([
          "a",
          "e",
          "i",
          "o",
          "u"
        ], __x);
    tmp = "commented on a" + (
      itemTypeStartsWithAVovel ? "n" : ""
    ) + " " + itemTypeName + ":";
  }
  var tmp$1 = {
    action: action,
    users: users,
    message: React.createElement(React.Fragment, undefined, tmp, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(-8)),
                    tl: {
                      hd: Css.marginBottom(Css.px(-10)),
                      tl: {
                        hd: Css.overflowWrap("breakWord"),
                        tl: {
                          hd: Css.selector(" img", {
                                hd: Css.maxWidth(Css.pct(75)),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              dangerouslySetInnerHTML: {
                __html: Quill.toHTML(JSON.parse(comment.VAL.content), events, undefined)
              }
            })),
    openBranches: openBranches
  };
  if (link !== undefined) {
    tmp$1.path = Caml_option.valFromOption(link);
  }
  return React.createElement(ActivityItem$ActivityItemAction, tmp$1);
}

var ActivityItemComment = {
  make: ActivityItem$ActivityItemComment
};

function ActivityItem$InspectorItemComment(Props) {
  var threadId = Props.threadId;
  var commentId = Props.commentId;
  var link = Props.link;
  var action = Props.action;
  var users = Props.users;
  var events = Props.events;
  var openBranches = Props.openBranches;
  var model = Props.model;
  var eventId = Props.eventId;
  var workspace = WorkspaceContext.use(undefined);
  var comment = FirebaseFetcherHooks.useComment(workspace.id, threadId, commentId);
  var eventName = Belt_Option.map(Belt_Option.flatMap(eventId, (function (id) {
              return ModelUtils.getEventById(id, model);
            })), (function ($$event) {
          return $$event.name;
        }));
  var eventElement = eventName !== undefined ? React.createElement(React.Fragment, undefined, " for event ", React.createElement($$Text.make, {
              element: "Em",
              children: eventName
            })) : null;
  if (typeof comment !== "object") {
    return null;
  }
  var tmp = {
    action: action,
    users: users,
    message: React.createElement(React.Fragment, undefined, "commented on an ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: "Inspector issue"
            }), eventElement, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(-8)),
                    tl: {
                      hd: Css.marginBottom(Css.px(-10)),
                      tl: {
                        hd: Css.overflowWrap("breakWord"),
                        tl: {
                          hd: Css.selector(" img", {
                                hd: Css.maxWidth(Css.pct(75)),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              dangerouslySetInnerHTML: {
                __html: Quill.toHTML(JSON.parse(comment.VAL.content), events, undefined)
              }
            })),
    openBranches: openBranches
  };
  if (link !== undefined) {
    tmp.path = Caml_option.valFromOption(link);
  }
  return React.createElement(ActivityItem$ActivityItemAction, tmp);
}

var InspectorItemComment = {
  make: ActivityItem$InspectorItemComment
};

function getActionContext(item) {
  var variant = item.NAME;
  if (variant === "Destination") {
    var destinationId = item.VAL;
    return {
            destinationId: destinationId,
            destinationQuery: destinationId
          };
  }
  if (variant === "Integration") {
    var integrationId = item.VAL;
    return {
            integrationId: integrationId,
            integrationQuery: integrationId
          };
  }
  if (variant === "GroupType") {
    var groupTypeId = item.VAL;
    return {
            groupTypeId: groupTypeId,
            groupTypeQuery: groupTypeId
          };
  }
  if (variant === "Property") {
    var propertyId = item.VAL;
    return {
            propertyId: propertyId,
            propertyQuery: propertyId
          };
  }
  if (variant === "Event") {
    var eventId = item.VAL;
    return {
            eventId: eventId,
            eventQuery: eventId
          };
  }
  if (variant === "PropertyGroup") {
    var groupId = item.VAL;
    return {
            propertyGroupId: groupId,
            propertyGroupQuery: groupId
          };
  }
  if (variant === "Goal") {
    var goalId = item.VAL;
    return {
            goalId: goalId,
            goalQuery: goalId
          };
  }
  if (variant === "EventVariant") {
    var variantIdentifier = item.VAL;
    return {
            eventId: variantIdentifier.baseEventId,
            eventQuery: variantIdentifier.baseEventId,
            eventVariantId: variantIdentifier.variantId,
            eventVariantQuery: variantIdentifier.variantId
          };
  }
  if (variant === "Metric") {
    var metricId = item.VAL;
    return {
            metricId: metricId,
            metricQuery: metricId
          };
  }
  var sourceId = item.VAL;
  return {
          sourceId: sourceId,
          sourceQuery: sourceId
        };
}

function unarchiveItemOptionBase(currentModel, sendActions, addToast, schemaBundle, globalSend, item, itemName) {
  var variant = item.NAME;
  var itemLabel = variant === "Destination" ? "Destination" : (
      variant === "Integration" ? "Integration" : (
          variant === "GroupType" ? "Group Type" : (
              variant === "Property" ? "Property" : (
                  variant === "Event" ? "Event" : (
                      variant === "PropertyGroup" ? "Property Bundle" : (
                          variant === "Goal" ? "Category" : (
                              variant === "EventVariant" ? "Event Variant" : (
                                  variant === "Metric" ? "Metric" : "Source"
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  var actionContext = getActionContext(item);
  var variant$1 = item.NAME;
  var drawerItem = variant$1 === "Destination" ? ({
        NAME: "destination",
        VAL: item.VAL
      }) : (
      variant$1 === "Integration" ? ({
            NAME: "integration",
            VAL: [
              item.VAL,
              undefined
            ]
          }) : (
          variant$1 === "GroupType" ? undefined : (
              variant$1 === "Property" ? ({
                    NAME: "property",
                    VAL: [
                      item.VAL,
                      undefined
                    ]
                  }) : (
                  variant$1 === "Event" ? ({
                        NAME: "event",
                        VAL: [
                          item.VAL,
                          undefined,
                          undefined,
                          false
                        ]
                      }) : (
                      variant$1 === "PropertyGroup" ? ({
                            NAME: "propertyGroup",
                            VAL: [
                              item.VAL,
                              undefined
                            ]
                          }) : (
                          variant$1 === "Goal" ? ({
                                NAME: "category",
                                VAL: [
                                  item.VAL,
                                  undefined
                                ]
                              }) : (
                              variant$1 === "EventVariant" ? ({
                                    NAME: "eventVariant",
                                    VAL: [
                                      item.VAL,
                                      undefined
                                    ]
                                  }) : (
                                  variant$1 === "Metric" ? ({
                                        NAME: "metric",
                                        VAL: [
                                          item.VAL,
                                          undefined,
                                          false
                                        ]
                                      }) : ({
                                        NAME: "source",
                                        VAL: [
                                          item.VAL,
                                          "overview"
                                        ]
                                      })
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  var variant$2 = item.NAME;
  var match = variant$2 === "Destination" ? [
      "Destination",
      item.VAL
    ] : (
      variant$2 === "Integration" ? [
          "Integration",
          item.VAL
        ] : (
          variant$2 === "GroupType" ? [
              "GroupType",
              ""
            ] : (
              variant$2 === "Property" ? [
                  "Property",
                  item.VAL
                ] : (
                  variant$2 === "Event" ? [
                      "Event",
                      item.VAL
                    ] : (
                      variant$2 === "PropertyGroup" ? [
                          "PropertyGroup",
                          item.VAL
                        ] : (
                          variant$2 === "Goal" ? [
                              "Goal",
                              item.VAL
                            ] : (
                              variant$2 === "EventVariant" ? [
                                  "EventVariant",
                                  item.VAL.variantId
                                ] : (
                                  variant$2 === "Metric" ? [
                                      "Metric",
                                      item.VAL
                                    ] : [
                                      "Source",
                                      item.VAL
                                    ]
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  var analyticsItemId = match[1];
  var analyticsItemType = match[0];
  var variant$3 = item.NAME;
  var unarchiveAvailability;
  if (variant$3 === "Destination") {
    var destinationId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.destinations, (function (destination) {
            return destination.id === destinationId;
          }));
  } else if (variant$3 === "Integration") {
    unarchiveAvailability = false;
  } else if (variant$3 === "GroupType") {
    var groupTypeId = item.VAL;
    unarchiveAvailability = Belt_Array.someU(currentModel.archive.groupTypes, (function (groupType) {
            return groupType.id === groupTypeId;
          }));
  } else if (variant$3 === "Property") {
    var propertyId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.properties, (function (property) {
            if (property.TAG === /* PropertyRef */0) {
              return false;
            } else {
              return property._0.id === propertyId;
            }
          }));
  } else if (variant$3 === "Event") {
    var eventId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.events, (function ($$event) {
            return $$event.id === eventId;
          }));
  } else if (variant$3 === "PropertyGroup") {
    var groupId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.propertyBundles, (function (group) {
            return group.id === groupId;
          }));
  } else if (variant$3 === "Goal") {
    var goalId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.goals, (function (goal) {
            return goal.id === goalId;
          }));
  } else if (variant$3 === "EventVariant") {
    var variantIdentifier = item.VAL;
    unarchiveAvailability = Belt_Array.someU(currentModel.archive.eventVariants, (function (param) {
            return param.id === variantIdentifier.variantId;
          }));
  } else if (variant$3 === "Metric") {
    var metricId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.metrics, (function (metric) {
            return metric.id === metricId;
          }));
  } else {
    var sourceId = item.VAL;
    unarchiveAvailability = Belt_List.someU(currentModel.archive.sources, (function (source) {
            return source.id === sourceId;
          }));
  }
  var variant$4 = item.NAME;
  var unarchiveConflict = variant$4 === "GroupType" ? Belt_Option.map(NameUtils.getConflictingNames(Belt_List.fromArray(ModelUtils.getGroupTypeNames(currentModel)), itemName), (function (conflictingName) {
            return "Group Type with the name \"" + conflictingName + "\" already exists.";
          })) : (
      variant$4 === "Property" ? Belt_Option.map(NameUtils.getConflictingNames(ModelUtils.getPropertyNamespace(currentModel), itemName), (function (conflictingName) {
                return "Property with the name \"" + conflictingName + "\" already exists.";
              })) : (
          variant$4 === "Event" ? Belt_Option.map(NameUtils.getConflictingNames(GetEventNamespaceUseCase.getEventNamespace(currentModel.events), itemName), (function (conflictingName) {
                    return "Event or a variant with the name \"" + conflictingName + "\" already exists.";
                  })) : (
              variant$4 === "PropertyGroup" ? Belt_Option.map(NameUtils.getConflictingNames(ModelUtils.getPropertyGroupNamespace(currentModel), itemName), (function (conflictingName) {
                        return "Property bundle with the name \"" + conflictingName + "\" already exists.";
                      })) : (
                  variant$4 === "Goal" ? Belt_Option.map(NameUtils.getConflictingNames(ModelUtils.getGoalNamespace(currentModel), itemName), (function (conflictingName) {
                            return "Category with the name \"" + conflictingName + "\" already exists.";
                          })) : (
                      variant$4 === "EventVariant" ? Belt_Option.map(Belt_Option.flatMap(ModelUtils.getEventById(item.VAL.baseEventId, currentModel), (function ($$event) {
                                    return NameUtils.getConflictingNames(Belt_List.fromArray(Belt_Array.mapU($$event.variants, (function (param) {
                                                          return param.nameSuffix;
                                                        }))), itemName);
                                  })), (function (conflictingName) {
                                return "Event Variant with the name \"" + conflictingName + "\" already exists.";
                              })) : undefined
                    )
                )
            )
        )
    );
  if (unarchiveAvailability) {
    return {
            NAME: "Option",
            VAL: {
              label: "Restore " + itemLabel,
              onClick: (function (param) {
                  if (unarchiveConflict !== undefined) {
                    return Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "AlertModal",
                                  VAL: [
                                    "Could not restore item",
                                    "The item could not be unarchived because of the following error: " + unarchiveConflict,
                                    "Ok",
                                    (function (param) {
                                        
                                      })
                                  ]
                                }
                              });
                  } else {
                    return Curry.app(sendActions, [
                                undefined,
                                false,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (branch) {
                                    Curry._1(addToast, {
                                          message: itemLabel + " Restored",
                                          toastType: /* Success */0
                                        });
                                    var arg = Router.Schema.pushDrawerItem;
                                    Belt_Option.forEach(drawerItem, (function (eta) {
                                            return Curry._3(arg, undefined, undefined, eta);
                                          }));
                                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                    return AnalyticsRe.itemUnarchived(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, analyticsItemId, itemName, analyticsItemType, "ActivityLog", schemaBundle$1.schemaId, NamedBranch.getId(branch));
                                  }),
                                (function (param) {
                                    return Curry._1(addToast, {
                                                message: "Item could not be restored because of an unexpected error",
                                                toastType: /* Error */1
                                              });
                                  }),
                                [[
                                    {
                                      NAME: "Unarchive",
                                      VAL: item
                                    },
                                    actionContext
                                  ]]
                              ]);
                  }
                })
            }
          };
  }
  
}

function ActivityItem(Props) {
  var modelBeforeAction = Props.modelBeforeAction;
  var currentModel = Props.currentModel;
  var action = Props.action;
  var userIds = Props.userIds;
  var openBranches = Props.openBranches;
  var globalSend = Props.globalSend;
  var baseRoute = RouterStore.Schema.useBaseRoute(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var users = FirebaseFetcherHooks.useUsers(userIds);
  var addToast = Toast.useAddToast(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var unarchiveItemOption = function (param, param$1) {
    return unarchiveItemOptionBase(currentModel, sendActions, addToast, schemaBundle, globalSend, param, param$1);
  };
  var itemName = "event";
  if (typeof users !== "object") {
    return React.createElement(ActivityItemLoading.make, {});
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var context = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(action.context), {});
  var linkToPropertyFromContext = Belt_Option.map(Caml_option.undefined_to_opt(context.propertyId), (function (propertyId) {
          return Router.Link.addDrawerItem(undefined, {
                      NAME: "property",
                      VAL: [
                        propertyId,
                        undefined
                      ]
                    });
        }));
  var match = action.contents;
  var eventId;
  var uniqueName;
  if (typeof match !== "object") {
    if (match === "ResetTrackingPlan") {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: "reset the Tracking Plan",
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  var variant = match.NAME;
  if (variant === "UpdateDestinationDisabledByDefault") {
    var match$1 = match.VAL;
    var destinationId = match$1[0];
    var destination = ModelUtils.getDestinationByIdWithArchive(destinationId, modelBeforeAction);
    if (destination !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: match$1[1] ? React.createElement(React.Fragment, undefined, "set the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination)), " to be disabled by default") : React.createElement(React.Fragment, undefined, "set the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination)), " to be enabled by default"),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "destination",
                        VAL: destinationId
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddProperty") {
    var match$2 = match.VAL;
    var string = match$2[2];
    var propertyId = match$2[1];
    var $$event = ModelUtils.getEventByIdWithArchive(match$2[0], modelBeforeAction);
    if ($$event !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "created the property ", React.createElement("b", undefined, string), " and added it to ", React.createElement("b", undefined, $$event.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "created the property ", React.createElement("b", undefined, string)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    }
  }
  if (variant === "MergeBranch") {
    var match$3 = match.VAL;
    var branchId = match$3[0];
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "merged the branch ", React.createElement("b", undefined, match$3[1]), " into main"),
                path: Router.Link.getSchemaRouteLink({
                      NAME: "branch",
                      VAL: branchId
                    }, {
                      NAME: "diff",
                      VAL: "index"
                    }),
                openBranches: openBranches,
                onClick: (function (param) {
                    return AnalyticsRe.branchLinkClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, branchId, "BranchesOverviewActivityItem");
                  })
              });
  }
  if (variant === "AddEventType") {
    var match$4 = match.VAL;
    var eventId$1 = match$4[0];
    var $$event$1 = ModelUtils.getEventByIdWithArchive(eventId$1, modelBeforeAction);
    if ($$event$1 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added the event type ", React.createElement("b", undefined, TrackingPlanModel.eventTypeToJs(match$4[1])), " to ", React.createElement("b", undefined, $$event$1.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          eventId$1,
                          undefined,
                          undefined,
                          false
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateTriggerContent") {
    var match$5 = match.VAL;
    var eventId$2 = match$5[0];
    var match$6 = ModelUtils.getEventByIdWithArchive(eventId$2, modelBeforeAction);
    if (match$6 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated the content for a trigger in ", React.createElement("b", undefined, match$6.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "event",
                        VAL: [
                          eventId$2,
                          undefined,
                          match$5[1],
                          false
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "ConvertPropertyBundleToGlobalPropertyBundle") {
    var match$7 = ModelUtils.getPropertyGroupById(match.VAL[1], currentModel);
    if (match$7 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "merged the property bundle ", React.createElement("b", undefined, match$7.name), " with global requirements"),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "RemoveEventVariantTrigger") {
    var match$8 = match.VAL;
    var variantIdentifier = match$8[0];
    var match$9 = ModelUtils.getEventByIdWithArchive(variantIdentifier.baseEventId, modelBeforeAction);
    var match$10 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier);
    if (match$9 !== undefined && match$10 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "removed a trigger in the variant ", React.createElement("b", undefined, match$10.nameSuffix), " on ", React.createElement("b", undefined, match$9.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier,
                          match$8[1]
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "DeprecatedRemovePropertyValidationMatch") {
    var match$11 = match.VAL;
    var match$12 = ModelUtils.getPropertyByIdWithArchive(match$11[0], modelBeforeAction);
    if (match$12 === undefined) {
      return null;
    }
    if (match$12.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "removed the allowed value ", React.createElement("b", undefined, match$11[1]), " from the property ", React.createElement("b", undefined, match$12._0.name)),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp);
  }
  if (variant === "UpdateSourceDestinationMode") {
    var match$13 = match.VAL;
    var sourceId = match$13[0];
    var match$14 = ModelUtils.getSourceByIdWithArchive(sourceId, modelBeforeAction);
    var match$15 = ModelUtils.getDestinationByIdWithArchive(match$13[1], modelBeforeAction);
    if (match$14 === undefined) {
      return null;
    }
    if (match$15 === undefined) {
      return null;
    }
    var tmp$1;
    switch (match$13[2]) {
      case /* AvoManaged */0 :
          tmp$1 = "Avo managed";
          break;
      case /* DestinationInterface */1 :
          tmp$1 = "Destination Interface";
          break;
      case /* Legacy */2 :
          tmp$1 = "Legacy";
          break;
      
    }
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "changed the mode of the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$15)), " on the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$14)), " to ", React.createElement("b", undefined, tmp$1)),
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "source",
                      VAL: [
                        sourceId,
                        "codegenSetup"
                      ]
                    }),
                openBranches: openBranches
              });
  }
  if (variant === "TogglePropertyValidationMatchSource") {
    var match$16 = match.VAL;
    var propertyId$1 = match$16[0];
    var match$17 = ModelUtils.getPropertyById(propertyId$1, modelBeforeAction);
    var match$18 = ModelUtils.getSourceById(match$16[2], modelBeforeAction);
    if (match$17 !== undefined && !(match$17.TAG === /* PropertyRef */0 || match$18 === undefined)) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, match$16[3] ? "enabled " : "disabled ", React.createElement("b", undefined, match$16[1]), " to be sent as the value of the property ", React.createElement("b", undefined, match$17._0.name), " from ", React.createElement("b", undefined, AvoConfig.getSourceName(match$18))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "property",
                        VAL: [
                          propertyId$1,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "RemoveEventSpecificPropertyValueForAllEvents") {
    var match$19 = match.VAL;
    var literal = match$19[1];
    var match$20 = ModelUtils.getPropertyByIdWithArchive(match$19[0], modelBeforeAction);
    if (match$20 !== undefined && !(match$20.TAG === /* PropertyRef */0 || !(typeof literal === "object" && literal.NAME === "StringLit"))) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "removed the allowed value ", React.createElement("b", undefined, literal.VAL), " from the property ", React.createElement("b", undefined, match$20._0.name)),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdatePropertyUniqueName") {
    var match$21 = match.VAL;
    var match$22 = ModelUtils.getPropertyByIdWithArchive(match$21[0], modelBeforeAction);
    if (match$22 === undefined) {
      return null;
    }
    if (match$22.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$2 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "set the unique name of the property ", React.createElement("b", undefined, match$22._0.name), " to ", React.createElement("b", undefined, match$21[1])),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$2.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$2);
  }
  if (variant === "UpdateDestinationIncludeUserPropsWithEventProps") {
    var match$23 = match.VAL;
    var destinationId$1 = match$23[0];
    var destination$1 = ModelUtils.getDestinationByIdWithArchive(destinationId$1, modelBeforeAction);
    if (destination$1 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: match$23[1] ? React.createElement(React.Fragment, undefined, "started including user props with event props on the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$1))) : React.createElement(React.Fragment, undefined, "stopped including user props with event props on the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$1))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "destination",
                        VAL: destinationId$1
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddEvent") {
    var match$24 = match.VAL;
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "created an " + itemName + ": ", React.createElement("b", undefined, match$24[1])),
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "event",
                      VAL: [
                        match$24[0],
                        undefined,
                        undefined,
                        false
                      ]
                    }),
                openBranches: openBranches
              });
  }
  if (variant === "RemovePropertyPermanently") {
    var match$25 = ModelUtils.getPropertyByIdWithArchive(match.VAL, modelBeforeAction);
    if (match$25 === undefined) {
      return null;
    }
    if (match$25.TAG === /* PropertyRef */0) {
      return null;
    }
    var tmp$3 = {
      action: action,
      users: users$1,
      message: React.createElement(React.Fragment, undefined, "permanently removed the property ", React.createElement("b", undefined, match$25._0.name)),
      openBranches: openBranches
    };
    if (linkToPropertyFromContext !== undefined) {
      tmp$3.path = Caml_option.valFromOption(linkToPropertyFromContext);
    }
    return React.createElement(ActivityItem$ActivityItemAction, tmp$3);
  }
  if (variant === "RemoveDomainMembers" || variant === "RemoveMember" || variant === "UpdateIntegrationDestinationIds" || variant === "CollaboratorRemoved" || variant === "BranchInit" || variant === "ArchiveSavedView" || variant === "CodeGenerated" || variant === "UpdateGlobalRequirements" || variant === "ResendInvite" || variant === "CreateInspectorIssue" || variant === "SubscriptionStarted" || variant === "AddDomainMembers" || variant === "PushGlobalRequirementsToTrackingPlan" || variant === "SubscriptionCancelled" || variant === "UpdateDomainMemberRole" || variant === "SubscriptionUpdated" || variant === "ReviewerAdded" || variant === "UpdateMemberRole" || variant === "UpdateSavedView" || variant === "RemoveDomainItems" || variant === "MigrateExcludeEventsToIncludeEvents" || variant === "CollaboratorAdded" || variant === "AddMigrationDangerous" || variant === "RemoveInvite" || variant === "AddDomainItems" || variant === "InviteMember" || variant === "ReviewerRemoved" || variant === "MigrateSourcesAndDestinationsToModel" || variant === "CreateDemoBranch" || variant === "ImportDeprecated" || variant === "PaymentCompleted" || variant === "CreateSavedView") {
    return null;
  }
  if (variant === "RemovePropertyFromWhitelist") {
    var match$26 = match.VAL;
    var match$27 = ModelUtils.getPropertyByIdWithArchive(match$26[1], modelBeforeAction);
    var match$28 = ModelUtils.getEventByIdWithArchive(match$26[0], modelBeforeAction);
    if (match$27 !== undefined && !(match$27.TAG === /* PropertyRef */0 || match$28 === undefined)) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "stopped sending the property ", React.createElement("b", undefined, match$27._0.name), " with the " + itemName + " ", React.createElement("b", undefined, match$28.name), " to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$26[2]))),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "SetBranchStatus") {
    var match$29 = match.VAL;
    var branchStatus = match$29[2];
    var branchName = match$29[1];
    var branchId$1 = match$29[0];
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: branchStatus === "ChangesRequested" ? React.createElement(React.Fragment, undefined, "requested changes to the branch ", React.createElement("b", undefined, branchName)) : (
                    branchStatus === "Approved" ? React.createElement(React.Fragment, undefined, "approved the branch ", React.createElement("b", undefined, branchName)) : (
                        branchStatus === "Draft" ? React.createElement(React.Fragment, undefined, "marked the branch ", React.createElement("b", undefined, branchName), " as a draft") : React.createElement(React.Fragment, undefined, "marked the branch ", React.createElement("b", undefined, branchName), " as ready for review")
                      )
                  ),
                path: Router.Link.getSchemaRouteLink({
                      NAME: "branch",
                      VAL: branchId$1
                    }, {
                      NAME: "diff",
                      VAL: "index"
                    }),
                openBranches: openBranches,
                onClick: (function (param) {
                    return AnalyticsRe.branchLinkClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, branchId$1, "BranchesOverviewActivityItem");
                  })
              });
  }
  if (variant === "UpdateLibraryDestination") {
    var match$30 = match.VAL;
    var sourceId$1 = match$30[0];
    var source = ModelUtils.getSourceByIdWithArchive(sourceId$1, modelBeforeAction);
    if (source !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated the library destination of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source)), " to ", React.createElement("b", undefined, match$30[1])),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "source",
                        VAL: [
                          sourceId$1,
                          "overview"
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddPropertyToEventVariant") {
    var match$31 = match.VAL;
    var variantIdentifier$1 = match$31[0];
    var match$32 = ModelUtils.getEventByIdWithArchive(variantIdentifier$1.baseEventId, modelBeforeAction);
    var match$33 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$1);
    var match$34 = ModelUtils.getPropertyByIdWithArchive(match$31[1], modelBeforeAction);
    if (match$32 !== undefined && match$33 !== undefined && match$34 !== undefined && match$34.TAG !== /* PropertyRef */0) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added the property ", React.createElement("b", undefined, match$34._0.name), " to the event variant " + match$32.name + " - " + match$33.nameSuffix),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier$1,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "UpdateEventVariantTriggerSources") {
    var match$35 = match.VAL;
    var variantIdentifier$2 = match$35[0];
    var match$36 = ModelUtils.getEventByIdWithArchive(variantIdentifier$2.baseEventId, modelBeforeAction);
    var match$37 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$2);
    if (match$36 !== undefined && match$37 !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "updated sources for a trigger in the variant ", React.createElement("b", undefined, match$37.nameSuffix), " on ", React.createElement("b", undefined, match$36.name)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier$2,
                          match$35[1]
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "ClearPropertyPinnedValueFromEventVariant") {
    var match$38 = match.VAL;
    var variantIdentifier$3 = match$38[0];
    var match$39 = ModelUtils.getEventByIdWithArchive(variantIdentifier$3.baseEventId, modelBeforeAction);
    var match$40 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$3);
    var match$41 = ModelUtils.getPropertyByIdWithArchive(match$38[1], modelBeforeAction);
    if (match$39 !== undefined && match$40 !== undefined && match$41 !== undefined && match$41.TAG !== /* PropertyRef */0) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, " removed the pinned value for the ", React.createElement("b", undefined, match$41._0.name), " property on the event variant ", React.createElement("b", undefined, match$39.name + " - " + match$40.nameSuffix)),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier$3,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "AddGoal") {
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: "created a category",
                path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                openBranches: openBranches
              });
  }
  if (variant === "UpdateIntegrationAutoPublish") {
    var match$42 = match.VAL;
    var integration = ModelUtils.getIntegrationById(match$42[0], modelBeforeAction);
    if (integration !== undefined) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, (
                        match$42[1] ? "enabled" : "disabled"
                      ) + " auto publishing for the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration))),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "integration",
                        VAL: [
                          integration.id,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    } else {
      return null;
    }
  }
  if (variant === "SetSourceOverrideOnVariant") {
    var match$43 = match.VAL;
    var variantIdentifier$4 = match$43[0];
    var match$44 = ModelUtils.getEventByIdWithArchive(variantIdentifier$4.baseEventId, modelBeforeAction);
    var match$45 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$4);
    var match$46 = ModelUtils.getSourceByIdWithArchive(match$43[1], modelBeforeAction);
    var exit = 0;
    if (match$44 !== undefined && match$45 !== undefined) {
      if (match$46 !== undefined) {
        var nameSuffix = match$45.nameSuffix;
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: match$43[2] ? React.createElement(React.Fragment, undefined, "added source ", React.createElement("b", undefined, Belt_Option.getWithDefault(match$46.name, match$46.id)), " to the event variant ", React.createElement("b", undefined, match$44.name + " - " + nameSuffix)) : React.createElement(React.Fragment, undefined, "removed source ", React.createElement("b", undefined, Belt_Option.getWithDefault(match$46.name, match$46.id)), " from the event variant ", React.createElement("b", undefined, match$44.name + " - " + nameSuffix)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "eventVariant",
                          VAL: [
                            variantIdentifier$4,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      }
      exit = 2;
    } else {
      exit = 2;
    }
    if (exit === 2) {
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "set a source override on an event variant"),
                  path: Router.Link.addDrawerItem(undefined, {
                        NAME: "eventVariant",
                        VAL: [
                          variantIdentifier$4,
                          undefined
                        ]
                      }),
                  openBranches: openBranches
                });
    }
    
  } else {
    if (variant === "DeprecatedAddPropertyValidationMatch") {
      var match$47 = match.VAL;
      var match$48 = ModelUtils.getPropertyByIdWithArchive(match$47[0], modelBeforeAction);
      if (match$48 === undefined) {
        return null;
      }
      if (match$48.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$4 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "added the allowed value ", React.createElement("b", undefined, match$47[1]), " to the property ", React.createElement("b", undefined, match$48._0.name)),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$4.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$4);
    }
    if (variant === "UpdateGoalDescription") {
      var goal = ModelUtils.getGoalByIdWithArchive(match.VAL[0], modelBeforeAction);
      if (goal !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the description of the category ", React.createElement("b", undefined, goal.name)),
                    path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "DeprecatedRemovePropertyValidationMatches") {
      var match$49 = ModelUtils.getPropertyByIdWithArchive(match.VAL, modelBeforeAction);
      if (match$49 === undefined) {
        return null;
      }
      if (match$49.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$5 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "removed matches validation from the property ", React.createElement("b", undefined, match$49._0.name)),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$5.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$5);
    }
    if (variant === "UpdatePropertyList") {
      var match$50 = match.VAL;
      var match$51 = ModelUtils.getPropertyByIdWithArchive(match$50[0], modelBeforeAction);
      if (match$51 === undefined) {
        return null;
      }
      if (match$51.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$6 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "changed the property ", React.createElement("b", undefined, match$51._0.name), match$50[1] ? " to be a list" : " to be a single item"),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$6.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$6);
    }
    if (variant === "RemoveMetricWhereV2" || variant === "RemoveMetricWhere" || variant === "SetMetricWhere" || variant === "SetMetricGroupByV2" || variant === "RemoveMetricGroupByV2" || variant === "SetMetricGroupBy" || variant === "RemoveMetricGroupBy" || variant === "SetMetricWhereV2") {
      var metricId = match.VAL[0];
      var metric = ModelUtils.getMetricByIdWithArchive(metricId, modelBeforeAction);
      if (metric !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "updated the metric ", React.createElement("b", undefined, metric.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "metric",
                          VAL: [
                            metricId,
                            undefined,
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "ConvertEventToGlobalEventV2" || variant === "ConvertEventToGlobalEvent") {
      var match$52 = ModelUtils.getEventById(match.VAL[1], currentModel);
      if (match$52 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "merged the event ", React.createElement("b", undefined, match$52.name), " with global requirements"),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertyName") {
      var match$53 = match.VAL;
      var match$54 = ModelUtils.getPropertyByIdWithArchive(match$53[0], modelBeforeAction);
      if (match$54 === undefined) {
        return null;
      }
      if (match$54.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$7 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "renamed the property ", React.createElement("b", undefined, match$54._0.name), " to ", React.createElement("b", undefined, match$53[1])),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$7.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$7);
    }
    if (variant === "UpdatePropertyDescription") {
      var match$55 = match.VAL;
      var match$56 = ModelUtils.getPropertyByIdWithArchive(match$55[0], modelBeforeAction);
      if (match$56 === undefined) {
        return null;
      }
      if (match$56.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$8 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "updated description of the property ", React.createElement("b", undefined, match$56._0.name), " to " + match$55[1]),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$8.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$8);
    }
    if (variant === "AddTag") {
      var match$57 = match.VAL;
      var eventId$3 = match$57[0];
      var $$event$2 = ModelUtils.getEventByIdWithArchive(eventId$3, modelBeforeAction);
      if ($$event$2 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added the tag ", React.createElement("b", undefined, match$57[1]), " to ", React.createElement("b", undefined, $$event$2.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "event",
                          VAL: [
                            eventId$3,
                            undefined,
                            undefined,
                            false
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "ReorderEventsInGoal" || variant === "ReorderEventsInGoalV2") {
      var goalId = match.VAL[0];
      var goal$1 = ModelUtils.getGoalByIdWithArchive(goalId, modelBeforeAction);
      if (goal$1 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "reordered the events in the category ", React.createElement("b", undefined, goal$1.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "category",
                          VAL: [
                            goalId,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "ClearAllEventSpecificPropertyValuesFromProperty") {
      var match$58 = ModelUtils.getPropertyByIdWithArchive(match.VAL, modelBeforeAction);
      if (match$58 !== undefined && match$58.TAG !== /* PropertyRef */0) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "cleared all allowed values on the property ", React.createElement("b", undefined, match$58._0.name)),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "ReorderMetricsInGoalV2" || variant === "ReorderMetricsInGoal") {
      var goalId$1 = match.VAL[0];
      var goal$2 = ModelUtils.getGoalByIdWithArchive(goalId$1, modelBeforeAction);
      if (goal$2 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "reordered the metrics in the category ", React.createElement("b", undefined, goal$2.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "category",
                          VAL: [
                            goalId$1,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdatePropertySendAs") {
      var match$59 = match.VAL;
      var sendAs = match$59[1];
      var match$60 = ModelUtils.getPropertyByIdWithArchive(match$59[0], modelBeforeAction);
      if (match$60 === undefined) {
        return null;
      }
      if (match$60.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$9;
      if (typeof sendAs === "number") {
        switch (sendAs) {
          case /* SystemProperty */0 :
              tmp$9 = "system property";
              break;
          case /* EventProperty */1 :
              tmp$9 = "event property";
              break;
          case /* UserProperty */2 :
              tmp$9 = "user property";
              break;
          
        }
      } else {
        var groupTypeName = ModelUtils.getGroupTypeName(sendAs._0, modelBeforeAction);
        tmp$9 = groupTypeName !== undefined ? groupTypeName + " group property" : "group property";
      }
      var tmp$10 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "changed the property ", React.createElement("b", undefined, match$60._0.name), " to " + tmp$9),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$10.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$10);
    }
    if (variant === "RemoveEventVariantTriggerSource") {
      var match$61 = match.VAL;
      var variantIdentifier$5 = match$61[0];
      var match$62 = ModelUtils.getEventByIdWithArchive(variantIdentifier$5.baseEventId, modelBeforeAction);
      var match$63 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$5);
      var match$64 = ModelUtils.getSourceById(match$61[2], modelBeforeAction);
      if (match$62 !== undefined && match$63 !== undefined && match$64 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed ", React.createElement("b", undefined, AvoConfig.getSourceName(match$64)), " as a source from a trigger in the variant ", React.createElement("b", undefined, match$63.nameSuffix), " on ", React.createElement("b", undefined, match$62.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "eventVariant",
                          VAL: [
                            variantIdentifier$5,
                            match$61[1]
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "AddRule") {
      var match$65 = match.VAL;
      var match$66 = match$65[1];
      if (typeof match$66 !== "object") {
        return null;
      }
      if (match$66.NAME !== "Property") {
        return null;
      }
      var match$67 = match$65[2];
      if (typeof match$67 !== "object") {
        return null;
      }
      var variant$1 = match$67.NAME;
      var propertyId$2 = match$66.VAL;
      if (variant$1 === "NameMapping") {
        var match$68 = ModelUtils.getPropertyByIdWithArchive(propertyId$2, modelBeforeAction);
        if (match$68 !== undefined && match$68.TAG !== /* PropertyRef */0) {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "added a name mapping to the property ", React.createElement("b", undefined, match$68._0.name)),
                      openBranches: openBranches
                    });
        } else {
          return null;
        }
      }
      if (variant$1 !== "NameInLocalWorkspace") {
        return null;
      }
      var match$69 = ModelUtils.getPropertyByIdWithArchive(propertyId$2, modelBeforeAction);
      if (match$69 !== undefined && match$69.TAG !== /* PropertyRef */0) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added a name mapping to the property ", React.createElement("b", undefined, match$69._0.name)),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "UpdateSourceName") {
      var match$70 = match.VAL;
      var sourceId$2 = match$70[0];
      var source$1 = ModelUtils.getSourceByIdWithArchive(sourceId$2, modelBeforeAction);
      if (source$1 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "renamed the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$1)), " to ", React.createElement("b", undefined, match$70[1])),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId$2,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "BranchComment") {
      var match$71 = match.VAL;
      var commentId = match$71[1];
      var branchId$2 = match$71[0];
      var tmp$11 = {
        threadId: branchId$2,
        commentId: commentId,
        link: Router.Link.getSchemaRouteLink({
              NAME: "branch",
              VAL: branchId$2
            }, {
              NAME: "diff",
              VAL: {
                NAME: "comment",
                VAL: commentId
              }
            }),
        itemTypeName: "branch",
        action: action,
        users: users$1,
        events: modelBeforeAction.events,
        openBranches: openBranches
      };
      var tmp$12 = Belt_List.head(Belt_List.keepMap(openBranches, (function (param) {
                  if (param[0] === branchId$2) {
                    return param[1];
                  }
                  
                })));
      if (tmp$12 !== undefined) {
        tmp$11.itemName = Caml_option.valFromOption(tmp$12);
      }
      return React.createElement(ActivityItem$ActivityItemComment, tmp$11);
    }
    if (variant === "UpdatePropertyType") {
      var match$72 = match.VAL;
      var match$73 = ModelUtils.getPropertyByIdWithArchive(match$72[0], modelBeforeAction);
      if (match$73 === undefined) {
        return null;
      }
      if (match$73.TAG === /* PropertyRef */0) {
        return null;
      }
      var tmp$13 = {
        action: action,
        users: users$1,
        message: React.createElement(React.Fragment, undefined, "updated the type of the property ", React.createElement("b", undefined, match$73._0.name), " to ", match$72[1]),
        openBranches: openBranches
      };
      if (linkToPropertyFromContext !== undefined) {
        tmp$13.path = Caml_option.valFromOption(linkToPropertyFromContext);
      }
      return React.createElement(ActivityItem$ActivityItemAction, tmp$13);
    }
    if (variant === "IncludeDestinationInSourceV2" || variant === "LegacyIncludeDestinationInSource") {
      var match$74 = match.VAL;
      var sourceId$3 = match$74[0];
      var match$75 = ModelUtils.getSourceByIdWithArchive(sourceId$3, modelBeforeAction);
      var match$76 = ModelUtils.getDestinationByIdWithArchive(match$74[1], modelBeforeAction);
      if (match$75 !== undefined && match$76 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$76)), " to the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$75))),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "source",
                          VAL: [
                            sourceId$3,
                            "overview"
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "AddPropertyRef") {
      var match$77 = match.VAL;
      var propertyId$3 = match$77[1];
      var match$78 = ModelUtils.getEventByIdWithArchive(match$77[0], modelBeforeAction);
      var match$79 = ModelUtils.getPropertyByIdWithArchive(propertyId$3, modelBeforeAction);
      if (match$78 !== undefined && match$79 !== undefined && match$79.TAG !== /* PropertyRef */0) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added the property ", React.createElement("b", undefined, match$79._0.name), " to ", React.createElement("b", undefined, match$78.name)),
                    path: Router.Link.addDrawerItem(undefined, {
                          NAME: "property",
                          VAL: [
                            propertyId$3,
                            undefined
                          ]
                        }),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "OpenBranch") {
      var match$80 = match.VAL;
      var branchId$3 = match$80[0];
      return React.createElement(ActivityItem$ActivityItemAction, {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "opened the branch ", React.createElement("b", undefined, match$80[1])),
                  path: Curry._4(Router.Link.get, baseRoute, {
                        NAME: "branch",
                        VAL: branchId$3
                      }, schemaRoute, /* [] */0),
                  openBranches: openBranches,
                  onClick: (function (param) {
                      return AnalyticsRe.branchLinkClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, branchId$3, "BranchesOverviewActivityItem");
                    })
                });
    }
    if (variant === "ConvertPropertyToGlobalPropertyV2" || variant === "ConvertPropertyToGlobalProperty") {
      var match$81 = ModelUtils.resolvePropertyById(match.VAL[1], currentModel);
      if (match$81 !== undefined) {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "merged the property ", React.createElement("b", undefined, match$81.name), " with global requirements"),
                    openBranches: openBranches
                  });
      } else {
        return null;
      }
    }
    if (variant === "RemoveEvent") {
      var $$event$3 = ModelUtils.getEventByIdWithArchive(match.VAL, modelBeforeAction);
      if ($$event$3 !== undefined) {
        if ($$event$3.name === "") {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: "removed an untitled event",
                      openBranches: openBranches
                    });
        } else {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "removed the " + itemName + " ", React.createElement("b", undefined, $$event$3.name)),
                      openBranches: openBranches
                    });
        }
      } else {
        return null;
      }
    }
    if (variant === "EventComment") {
      var match$82 = match.VAL;
      var commentId$1 = match$82[1];
      var eventId$4 = match$82[0];
      var tmp$14 = {
        threadId: eventId$4,
        commentId: commentId$1,
        link: Router.Link.addDrawerItem(undefined, {
              NAME: "event",
              VAL: [
                eventId$4,
                commentId$1,
                undefined,
                false
              ]
            }),
        itemTypeName: "event",
        action: action,
        users: users$1,
        events: modelBeforeAction.events,
        openBranches: openBranches
      };
      var tmp$15 = Belt_Option.map(ModelUtils.getEventByIdWithArchive(eventId$4, modelBeforeAction), (function ($$event) {
              return $$event.name;
            }));
      if (tmp$15 !== undefined) {
        tmp$14.itemName = Caml_option.valFromOption(tmp$15);
      }
      return React.createElement(ActivityItem$ActivityItemComment, tmp$14);
    }
    if (variant === "UpdateInspectorIssueStatus") {
      var issueContext = match.VAL;
      var exit$1 = 0;
      if (typeof issueContext.issueStatus.status === "number" && !issueContext.regression) {
        if (Caml_obj.caml_equal(userIds, ["system"])) {
          return null;
        }
        exit$1 = 2;
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        var tmp$16 = {
          action: action,
          users: users$1,
          message: React.createElement(React.Fragment, undefined, issueContext.regression ? React.createElement(React.Fragment, undefined, React.createElement("b", undefined, "Inspector "), "detected an ", React.createElement("b", undefined, "issue regression ")) : React.createElement(React.Fragment, undefined, "updated the status to ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: InspectorIssueStatus.Status.toStringWithDetails(issueContext.issueStatus.status)
                        })), " on an Inspector issue for event ", React.createElement($$Text.make, {
                    element: "Em",
                    weight: "Regular",
                    children: issueContext.eventName
                  })),
          path: Router.Link.addDrawerItem(undefined, {
                NAME: "inspectorIssue",
                VAL: [
                  issueContext.issueId,
                  undefined
                ]
              }),
          openBranches: openBranches
        };
        var tmp$17 = issueContext.regression ? Caml_option.some(React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.backgroundColor(Styles.Color.avoPink),
                          tl: {
                            hd: Css.borderRadius(Css.px(4)),
                            tl: {
                              hd: Css.width(Css.px(24)),
                              tl: {
                                hd: Css.height(Css.px(24)),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(Icon.make, {
                        type_: "inspector",
                        color: Styles.Color.white
                      }))) : undefined;
        if (tmp$17 !== undefined) {
          tmp$16.icon = Caml_option.valFromOption(tmp$17);
        }
        return React.createElement(ActivityItem$ActivityItemAction, tmp$16);
      }
      
    } else {
      if (variant === "CreateEventVariantTrigger") {
        var match$83 = match.VAL;
        var variantIdentifier$6 = match$83[0];
        var match$84 = ModelUtils.getEventByIdWithArchive(variantIdentifier$6.baseEventId, modelBeforeAction);
        var match$85 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$6);
        if (match$84 !== undefined && match$85 !== undefined) {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "created a trigger in the variant ", React.createElement("b", undefined, match$85.nameSuffix), " on ", React.createElement("b", undefined, match$84.name)),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "eventVariant",
                            VAL: [
                              variantIdentifier$6,
                              match$83[1]
                            ]
                          }),
                      openBranches: openBranches
                    });
        } else {
          return null;
        }
      }
      if (variant === "DeleteLocalWorkspace") {
        var localWorkspaceId = match.VAL;
        var source$2 = ModelUtils.getSourceByIdWithArchive(localWorkspaceId, modelBeforeAction);
        if (source$2 !== undefined) {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "deleted the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$2))),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "source",
                            VAL: [
                              localWorkspaceId,
                              "overview"
                            ]
                          }),
                      openBranches: openBranches
                    });
        } else {
          return null;
        }
      }
      if (variant === "PropertyGroupComment") {
        var match$86 = match.VAL;
        var commentId$2 = match$86[1];
        var groupId = match$86[0];
        var tmp$18 = {
          threadId: groupId,
          commentId: commentId$2,
          link: Router.Link.addDrawerItem(undefined, {
                NAME: "propertyGroup",
                VAL: [
                  groupId,
                  commentId$2
                ]
              }),
          itemTypeName: "property bundle",
          action: action,
          users: users$1,
          events: modelBeforeAction.events,
          openBranches: openBranches
        };
        var tmp$19 = Belt_Option.map(ModelUtils.getPropertyGroupByIdWithArchive(groupId, modelBeforeAction), (function (group) {
                return group.name;
              }));
        if (tmp$19 !== undefined) {
          tmp$18.itemName = Caml_option.valFromOption(tmp$19);
        }
        return React.createElement(ActivityItem$ActivityItemComment, tmp$18);
      }
      if (variant === "ExcludeEventFromSource" || variant === "ExcludeEventFromSourceV2") {
        var match$87 = match.VAL;
        var eventId$5 = match$87[0];
        var match$88 = ModelUtils.getEventByIdWithArchive(eventId$5, modelBeforeAction);
        var match$89 = ModelUtils.getSourceByIdWithArchive(match$87[1], modelBeforeAction);
        if (match$88 !== undefined) {
          if (match$89 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "removed the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$89)), " from the " + itemName + " ", React.createElement("b", undefined, match$88.name)),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "event",
                              VAL: [
                                eventId$5,
                                undefined,
                                undefined,
                                false
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "updated the sources ", React.createElement("b", undefined, match$88.name), " is sent from"),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "event",
                              VAL: [
                                eventId$5,
                                undefined,
                                undefined,
                                false
                              ]
                            }),
                        openBranches: openBranches
                      });
          }
        } else {
          return null;
        }
      }
      if (variant === "CreateGroupType") {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "created group type ", React.createElement("b", undefined, match.VAL[1])),
                    openBranches: openBranches
                  });
      }
      if (variant === "LegacyToggleDestination") {
        var match$90 = match.VAL;
        var sourceId$4 = match$90[0];
        var match$91 = ModelUtils.getSourceByIdWithArchive(sourceId$4, modelBeforeAction);
        var match$92 = ModelUtils.getDestinationByIdWithArchive(match$90[1], modelBeforeAction);
        if (match$91 !== undefined && match$92 !== undefined) {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: match$90[2] ? React.createElement(React.Fragment, undefined, "added the the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$92)), " to the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$91))) : React.createElement(React.Fragment, undefined, "removed the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$92)), " from the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$91))),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "source",
                            VAL: [
                              sourceId$4,
                              "overview"
                            ]
                          }),
                      openBranches: openBranches
                    });
        } else {
          return null;
        }
      }
      if (variant === "DeletePublicBranchImplementation") {
        return React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: "deleted a public branch implementation instructions link ",
                    openBranches: openBranches
                  });
      }
      if (variant === "ClearSourceOverrideOnVariant") {
        var match$93 = match.VAL;
        var variantIdentifier$7 = match$93[0];
        var match$94 = ModelUtils.getEventByIdWithArchive(variantIdentifier$7.baseEventId, modelBeforeAction);
        var match$95 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$7);
        var match$96 = ModelUtils.getSourceByIdWithArchive(match$93[1], modelBeforeAction);
        var exit$2 = 0;
        if (match$94 !== undefined && match$95 !== undefined) {
          if (match$96 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "cleared the source ", React.createElement("b", undefined, Belt_Option.getWithDefault(match$96.name, match$96.id)), " override on the event variant ", React.createElement("b", undefined, match$94.name + " - " + match$95.nameSuffix)),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "eventVariant",
                              VAL: [
                                variantIdentifier$7,
                                undefined
                              ]
                            }),
                        openBranches: openBranches
                      });
          }
          exit$2 = 2;
        } else {
          exit$2 = 2;
        }
        if (exit$2 === 2) {
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "cleared a source override on an event variant"),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "eventVariant",
                            VAL: [
                              variantIdentifier$7,
                              undefined
                            ]
                          }),
                      openBranches: openBranches
                    });
        }
        
      } else {
        if (variant === "DeleteSource") {
          var sourceId$5 = match.VAL;
          var source$3 = ModelUtils.getSourceByIdWithArchive(sourceId$5, modelBeforeAction);
          if (source$3 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "deleted the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$3))),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "source",
                              VAL: [
                                sourceId$5,
                                "overview"
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "ReorderEventsInMetric" || variant === "ReorderEventsInMetricV2") {
          var match$97 = match.VAL;
          var metricId$1 = match$97[0];
          var match$98 = ModelUtils.getMetricByIdWithArchive(metricId$1, modelBeforeAction);
          var match$99 = ModelUtils.getEventByIdWithArchive(match$97[1], modelBeforeAction);
          if (match$98 !== undefined && match$99 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "moved the " + itemName + " ", React.createElement("b", undefined, match$99.name), " in the metric ", React.createElement("b", undefined, match$98.name)),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "metric",
                              VAL: [
                                metricId$1,
                                undefined,
                                false
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "UpdatePropertyRegexOverrideOnEventVariant") {
          var match$100 = match.VAL;
          var maybeRegexValidationRule = match$100[2];
          var variantIdentifier$8 = match$100[0];
          var match$101 = ModelUtils.getEventByIdWithArchive(variantIdentifier$8.baseEventId, modelBeforeAction);
          var match$102 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$8);
          var match$103 = ModelUtils.getPropertyByIdWithArchive(match$100[1], modelBeforeAction);
          if (match$101 === undefined) {
            return null;
          }
          if (match$102 === undefined) {
            return null;
          }
          if (match$103 === undefined) {
            return null;
          }
          var nameSuffix$1 = match$102.nameSuffix;
          if (match$103.TAG === /* PropertyRef */0) {
            return null;
          }
          var property = match$103._0;
          return React.createElement(ActivityItem$ActivityItemAction, {
                      action: action,
                      users: users$1,
                      message: maybeRegexValidationRule !== undefined ? React.createElement(React.Fragment, undefined, "set the regex constraint for the ", React.createElement("b", undefined, property.name), " property on the event variant ", React.createElement("b", undefined, match$101.name + " - " + nameSuffix$1), " to ", React.createElement("b", undefined, maybeRegexValidationRule.regex)) : React.createElement(React.Fragment, undefined, "removed the event-specific regex constraint override from the ", React.createElement("b", undefined, property.name), " property on the event variant ", React.createElement("b", undefined, match$101.name + " - " + nameSuffix$1)),
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "eventVariant",
                            VAL: [
                              variantIdentifier$8,
                              undefined
                            ]
                          }),
                      openBranches: openBranches
                    });
        }
        if (variant === "UpdateProgrammingLanguage") {
          var match$104 = match.VAL;
          var sourceId$6 = match$104[0];
          var source$4 = ModelUtils.getSourceByIdWithArchive(sourceId$6, modelBeforeAction);
          if (source$4 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "set the programming language of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$4)), " to ", React.createElement("b", undefined, AvoConfig.languageLabel(match$104[1]))),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "source",
                              VAL: [
                                sourceId$6,
                                "overview"
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "AddMetric") {
          var metric$1 = ModelUtils.getMetricByIdWithArchive(match.VAL, modelBeforeAction);
          if (metric$1 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "created a metric ", React.createElement("b", undefined, metric$1.name)),
                        path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "UpdateTriggerSources") {
          var match$105 = match.VAL;
          var eventId$6 = match$105[0];
          var match$106 = ModelUtils.getEventByIdWithArchive(eventId$6, modelBeforeAction);
          if (match$106 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "updated sources for a trigger in ", React.createElement("b", undefined, match$106.name)),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "event",
                              VAL: [
                                eventId$6,
                                undefined,
                                match$105[1],
                                false
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "MetricComment") {
          var match$107 = match.VAL;
          var commentId$3 = match$107[1];
          var metricId$2 = match$107[0];
          var tmp$20 = {
            threadId: metricId$2,
            commentId: commentId$3,
            link: Router.Link.addDrawerItem(undefined, {
                  NAME: "metric",
                  VAL: [
                    metricId$2,
                    commentId$3,
                    false
                  ]
                }),
            itemTypeName: "metric",
            action: action,
            users: users$1,
            events: modelBeforeAction.events,
            openBranches: openBranches
          };
          var tmp$21 = Belt_Option.map(ModelUtils.getMetricByIdWithArchive(metricId$2, modelBeforeAction), (function (metric) {
                  return metric.name;
                }));
          if (tmp$21 !== undefined) {
            tmp$20.itemName = Caml_option.valFromOption(tmp$21);
          }
          return React.createElement(ActivityItem$ActivityItemComment, tmp$20);
        }
        if (variant === "UpdateSourcePlatform" || variant === "UpdateSourcePlatformV2") {
          var match$108 = match.VAL;
          var sourceId$7 = match$108[0];
          var source$5 = ModelUtils.getSourceByIdWithArchive(sourceId$7, modelBeforeAction);
          if (source$5 !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "set the development platform of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$5)), " to ", React.createElement("b", undefined, AvoConfig.devPlatformLabel(match$108[1]))),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "source",
                              VAL: [
                                sourceId$7,
                                "overview"
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "UpdateSourcePlatformV3") {
          var match$109 = match.VAL;
          var maybeDevPlatform = match$109[1];
          var sourceId$8 = match$109[0];
          var match$110 = ModelUtils.getSourceByIdWithArchive(sourceId$8, modelBeforeAction);
          if (match$110 !== undefined && maybeDevPlatform !== undefined) {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "set the development platform of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$110)), " to ", React.createElement("b", undefined, AvoConfig.devPlatformLabel(maybeDevPlatform))),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "source",
                              VAL: [
                                sourceId$8,
                                "overview"
                              ]
                            }),
                        openBranches: openBranches
                      });
          } else {
            return null;
          }
        }
        if (variant === "UpdateEventUniqueNameV2") {
          var match$111 = match.VAL;
          var uniqueName$1 = match$111[1];
          var eventId$7 = match$111[0];
          if (uniqueName$1 === "") {
            var $$event$4 = ModelUtils.getEventByIdWithArchive(eventId$7, modelBeforeAction);
            if ($$event$4 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the unique name of the " + itemName + " ", React.createElement("b", undefined, $$event$4.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$7,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          eventId = eventId$7;
          uniqueName = uniqueName$1;
        } else {
          if (variant === "CreateEventTrigger") {
            var match$112 = match.VAL;
            var eventId$8 = match$112[0];
            var match$113 = ModelUtils.getEventByIdWithArchive(eventId$8, modelBeforeAction);
            if (match$113 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "created a trigger in ", React.createElement("b", undefined, match$113.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$8,
                                  undefined,
                                  match$112[1],
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdatePropertyRegexValidation") {
            var match$114 = match.VAL;
            var maybeRule = match$114[2];
            var maybeEventId = match$114[1];
            var match$115 = ModelUtils.getPropertyByIdWithArchive(match$114[0], modelBeforeAction);
            if (match$115 === undefined) {
              return null;
            }
            if (match$115.TAG === /* PropertyRef */0) {
              return null;
            }
            var property$1 = match$115._0;
            if (maybeEventId !== undefined) {
              if (maybeRule !== undefined) {
                var $$event$5 = ModelUtils.getEventByIdWithArchive(maybeEventId, modelBeforeAction);
                if ($$event$5 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "set the regex validation for the property ", React.createElement("b", undefined, property$1.name), " on the event ", React.createElement("b", undefined, $$event$5.name), " to ", React.createElement("b", undefined, maybeRule.regex)),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "event",
                                    VAL: [
                                      maybeEventId,
                                      undefined,
                                      undefined,
                                      false
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              var $$event$6 = ModelUtils.getEventByIdWithArchive(maybeEventId, modelBeforeAction);
              if ($$event$6 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "removed the regex validation for the property ", React.createElement("b", undefined, property$1.name), " on the event ", React.createElement("b", undefined, $$event$6.name)),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    maybeEventId,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            if (maybeRule !== undefined) {
              var tmp$22 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "set the default regex validation for the property ", React.createElement("b", undefined, property$1.name), " to ", React.createElement("b", undefined, maybeRule.regex)),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$22.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$22);
            }
            var tmp$23 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "removed the default regex validation for the property ", React.createElement("b", undefined, property$1.name)),
              openBranches: openBranches
            };
            if (linkToPropertyFromContext !== undefined) {
              tmp$23.path = Caml_option.valFromOption(linkToPropertyFromContext);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$23);
          }
          if (variant === "UpdateGroupTypeName") {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "updated group type ", React.createElement("b", undefined, match.VAL[1])),
                        openBranches: openBranches
                      });
          }
          if (variant === "RemoveDestinationApiKey") {
            var match$116 = match.VAL;
            var destinationId$2 = match$116[0];
            var destination$2 = ModelUtils.getDestinationByIdWithArchive(destinationId$2, modelBeforeAction);
            if (destination$2 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the " + match$116[1] + " api key from the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$2))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "destination",
                                VAL: destinationId$2
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "DeleteServiceAccount") {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "deleted the service account ", React.createElement("b", undefined, match.VAL)),
                        openBranches: openBranches,
                        onClick: (function (param) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "Settings",
                                          VAL: /* ServiceAccounts */3
                                        }
                                      });
                          })
                      });
          }
          if (variant === "RemoveEventSpecificPropertyValue") {
            var match$117 = match.VAL;
            var sources = match$117[3];
            var literal$1 = match$117[2];
            var match$118 = ModelUtils.getPropertyByIdWithArchive(match$117[1], modelBeforeAction);
            var match$119 = ModelUtils.getEventByIdWithArchive(match$117[0], modelBeforeAction);
            if (typeof literal$1 !== "object") {
              return null;
            }
            if (literal$1.NAME !== "StringLit") {
              return null;
            }
            if (match$118 === undefined) {
              return null;
            }
            if (match$118.TAG === /* PropertyRef */0) {
              return null;
            }
            if (match$119 === undefined) {
              return null;
            }
            var sourcesAreAllEventSources = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(match$119.includeSources, (function (param) {
                                return param.id;
                              })))), sources);
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "removed the allowed value ", React.createElement("b", undefined, literal$1.VAL), " from the property ", React.createElement("b", undefined, match$118._0.name), " on the event ", React.createElement("b", undefined, match$119.name), sourcesAreAllEventSources ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources), (function (sourceId) {
                                                    return ModelUtils.getSourceByIdWithArchive(sourceId, modelBeforeAction);
                                                  })), AvoConfig.getSourceName).join(", ")))),
                        openBranches: openBranches
                      });
          }
          if (variant === "Archive") {
            var item = match.VAL;
            var variant$2 = item.NAME;
            if (variant$2 === "Destination") {
              var destination$3 = ModelUtils.getDestinationByIdWithArchive(item.VAL, modelBeforeAction);
              if (destination$3 === undefined) {
                return null;
              }
              var tmp$24 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "archived the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$3))),
                openBranches: openBranches
              };
              var tmp$25 = Belt_Option.map(unarchiveItemOption(item, ModelUtils.getDestinationName(destination$3)), (function (option) {
                      return [option];
                    }));
              if (tmp$25 !== undefined) {
                tmp$24.menuOptions = Caml_option.valFromOption(tmp$25);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$24);
            }
            if (variant$2 === "Integration") {
              return null;
            }
            if (variant$2 === "GroupType") {
              var groupTypeName$1 = ModelUtils.getGroupTypeNameWithArchive(item.VAL, modelBeforeAction);
              if (groupTypeName$1 === undefined) {
                return null;
              }
              var tmp$26 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "archived the group type ", React.createElement("b", undefined, groupTypeName$1)),
                openBranches: openBranches
              };
              var tmp$27 = Belt_Option.map(unarchiveItemOption(item, groupTypeName$1), (function (option) {
                      return [option];
                    }));
              if (tmp$27 !== undefined) {
                tmp$26.menuOptions = Caml_option.valFromOption(tmp$27);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$26);
            }
            if (variant$2 === "Property") {
              var match$120 = ModelUtils.getPropertyByIdWithArchive(item.VAL, modelBeforeAction);
              if (match$120 === undefined) {
                return null;
              }
              if (match$120.TAG === /* PropertyRef */0) {
                return null;
              }
              var property$2 = match$120._0;
              var tmp$28 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "archived the property ", React.createElement("b", undefined, property$2.name)),
                openBranches: openBranches
              };
              var tmp$29 = Belt_Option.map(unarchiveItemOption(item, property$2.name), (function (option) {
                      return [option];
                    }));
              if (tmp$29 !== undefined) {
                tmp$28.menuOptions = Caml_option.valFromOption(tmp$29);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$28);
            }
            if (variant$2 === "Event") {
              var $$event$7 = ModelUtils.getEventByIdWithArchive(item.VAL, modelBeforeAction);
              if ($$event$7 === undefined) {
                return null;
              }
              var tmp$30 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "archived the " + itemName + " ", React.createElement("b", undefined, $$event$7.name)),
                openBranches: openBranches
              };
              var tmp$31 = Belt_Option.map(unarchiveItemOption(item, itemName), (function (option) {
                      return [option];
                    }));
              if (tmp$31 !== undefined) {
                tmp$30.menuOptions = Caml_option.valFromOption(tmp$31);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$30);
            }
            if (variant$2 === "PropertyGroup") {
              var group = ModelUtils.getPropertyGroupByIdWithArchive(item.VAL, modelBeforeAction);
              if (group === undefined) {
                return null;
              }
              var tmp$32 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "archived the property bundle ", React.createElement("b", undefined, group.name)),
                openBranches: openBranches
              };
              var tmp$33 = Belt_Option.map(unarchiveItemOption(item, group.name), (function (option) {
                      return [option];
                    }));
              if (tmp$33 !== undefined) {
                tmp$32.menuOptions = Caml_option.valFromOption(tmp$33);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$32);
            }
            if (variant$2 === "Goal") {
              var goal$3 = ModelUtils.getGoalByIdWithArchive(item.VAL, modelBeforeAction);
              if (goal$3 === undefined) {
                return null;
              }
              var tmp$34 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "archived the category ", React.createElement("b", undefined, goal$3.name)),
                openBranches: openBranches
              };
              var tmp$35 = Belt_Option.map(unarchiveItemOption(item, goal$3.name), (function (option) {
                      return [option];
                    }));
              if (tmp$35 !== undefined) {
                tmp$34.menuOptions = Caml_option.valFromOption(tmp$35);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$34);
            }
            if (variant$2 === "EventVariant") {
              var variantIdentifier$9 = item.VAL;
              var variant$3 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$9);
              if (variant$3 === undefined) {
                return null;
              }
              var $$event$8 = ModelUtils.getEventByIdWithArchive(variantIdentifier$9.baseEventId, modelBeforeAction);
              var tmp$36 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "archived the event variant ", React.createElement("b", undefined, variant$3.nameSuffix), $$event$8 !== undefined ? React.createElement(React.Fragment, undefined, " on the event ", React.createElement("b", undefined, $$event$8.name)) : null),
                openBranches: openBranches
              };
              var tmp$37 = Belt_Option.map(unarchiveItemOption(item, itemName), (function (option) {
                      return [option];
                    }));
              if (tmp$37 !== undefined) {
                tmp$36.menuOptions = Caml_option.valFromOption(tmp$37);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$36);
            }
            if (variant$2 === "Metric") {
              var metric$2 = ModelUtils.getMetricByIdWithArchive(item.VAL, modelBeforeAction);
              if (metric$2 === undefined) {
                return null;
              }
              var tmp$38 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "archived the metric ", React.createElement("b", undefined, metric$2.name)),
                openBranches: openBranches
              };
              var tmp$39 = Belt_Option.map(unarchiveItemOption(item, metric$2.name), (function (option) {
                      return [option];
                    }));
              if (tmp$39 !== undefined) {
                tmp$38.menuOptions = Caml_option.valFromOption(tmp$39);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$38);
            }
            var source$6 = ModelUtils.getSourceByIdWithArchive(item.VAL, modelBeforeAction);
            if (source$6 === undefined) {
              return null;
            }
            var tmp$40 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "archived the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$6))),
              openBranches: openBranches
            };
            var tmp$41 = Belt_Option.map(unarchiveItemOption(item, AvoConfig.getSourceName(source$6)), (function (option) {
                    return [option];
                  }));
            if (tmp$41 !== undefined) {
              tmp$40.menuOptions = Caml_option.valFromOption(tmp$41);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$40);
          }
          if (variant === "RemovePropertyRefV3" || variant === "RemovePropertyRefV2Deprecated") {
            var match$121 = match.VAL;
            var eventId$9 = match$121[0];
            var match$122 = ModelUtils.getEventByIdWithArchive(eventId$9, modelBeforeAction);
            var match$123 = ModelUtils.getPropertyByIdWithArchive(match$121[1], modelBeforeAction);
            if (match$122 !== undefined && match$123 !== undefined && match$123.TAG !== /* PropertyRef */0) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the property ", React.createElement("b", undefined, match$123._0.name), " from ", React.createElement("b", undefined, match$122.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$9,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateIntegrationConfig") {
            var integration$1 = ModelUtils.getIntegrationById(match.VAL[0], modelBeforeAction);
            if (integration$1 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the configuration of the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$1))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integration$1.id,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "ClearEventVariantPropertyOverride") {
            var match$124 = match.VAL;
            var variantIdentifier$10 = match$124[0];
            var match$125 = ModelUtils.getEventByIdWithArchive(variantIdentifier$10.baseEventId, modelBeforeAction);
            var match$126 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$10);
            var match$127 = ModelUtils.getPropertyByIdWithArchive(match$124[1], modelBeforeAction);
            if (match$125 !== undefined && match$126 !== undefined && match$127 !== undefined && match$127.TAG !== /* PropertyRef */0) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "reset the ", React.createElement("b", undefined, match$127._0.name), " property override on the event variant " + match$125.name + " - " + match$126.nameSuffix),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "eventVariant",
                                VAL: [
                                  variantIdentifier$10,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdatePropertyOperation") {
            var match$128 = match.VAL;
            var match$129 = ModelUtils.getPropertyByIdWithArchive(match$128[0], modelBeforeAction);
            if (match$129 === undefined) {
              return null;
            }
            if (match$129.TAG === /* PropertyRef */0) {
              return null;
            }
            var tmp$42 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "made the property ", React.createElement("b", undefined, match$129._0.name), " ", TrackingPlanModel.operationToJs(match$128[1]).toLowerCase()),
              openBranches: openBranches
            };
            if (linkToPropertyFromContext !== undefined) {
              tmp$42.path = Caml_option.valFromOption(linkToPropertyFromContext);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$42);
          }
          if (variant === "TogglePropertyExcludedSource") {
            var match$130 = match.VAL;
            var propertyId$4 = match$130[0];
            var match$131 = ModelUtils.getPropertyById(propertyId$4, modelBeforeAction);
            var match$132 = ModelUtils.getSourceById(match$130[1], modelBeforeAction);
            if (match$131 !== undefined && !(match$131.TAG === /* PropertyRef */0 || match$132 === undefined)) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, (
                                match$130[2] ? "started" : "stopped"
                              ) + " sending the property ", React.createElement("b", undefined, match$131._0.name), " to ", React.createElement("b", undefined, AvoConfig.getSourceName(match$132))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "property",
                                VAL: [
                                  propertyId$4,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "RemoveTag") {
            var match$133 = match.VAL;
            var eventId$10 = match$133[0];
            var $$event$9 = ModelUtils.getEventByIdWithArchive(eventId$10, modelBeforeAction);
            if ($$event$9 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the tag ", React.createElement("b", undefined, match$133[1]), " from ", React.createElement("b", undefined, $$event$9.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$10,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddUserToGroup") {
            var match$134 = match.VAL;
            var match$135 = ModelUtils.getGroupTypeName(match$134[0], modelBeforeAction);
            var match$136 = ModelUtils.getEventByIdWithArchive(match$134[1], modelBeforeAction);
            if (match$135 !== undefined && match$136 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "associated the group type ", React.createElement("b", undefined, match$135), " with the user in the event ", React.createElement("b", undefined, match$136.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "SetEventSpecificPropertyValueSources") {
            var match$137 = match.VAL;
            var removedSources = match$137[4];
            var addedSources = match$137[3];
            var literal$2 = match$137[2];
            var match$138 = ModelUtils.getPropertyByIdWithArchive(match$137[1], modelBeforeAction);
            var match$139 = ModelUtils.getEventByIdWithArchive(match$137[0], modelBeforeAction);
            if (typeof literal$2 !== "object") {
              return null;
            }
            if (literal$2.NAME !== "StringLit") {
              return null;
            }
            if (match$138 === undefined) {
              return null;
            }
            var match$140 = literal$2.VAL;
            if (match$138.TAG === /* PropertyRef */0) {
              return null;
            }
            if (match$139 === undefined) {
              return null;
            }
            var property$3 = match$138._0;
            var tmp$43;
            if (removedSources === undefined) {
              tmp$43 = null;
            } else {
              var sources$1 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(removedSources), (function (sourceId) {
                            return ModelUtils.getSourceByIdWithArchive(sourceId, modelBeforeAction);
                          })), AvoConfig.getSourceName).join(", ");
              tmp$43 = React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed the allowed value ", React.createElement("b", undefined, match$140), " from the property ", React.createElement("b", undefined, property$3.name), " on the event ", React.createElement("b", undefined, match$139.name), " for ", React.createElement("b", undefined, sources$1)),
                    openBranches: openBranches
                  });
            }
            var tmp$44;
            if (addedSources === undefined) {
              tmp$44 = null;
            } else {
              var sources$2 = Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(addedSources), (function (sourceId) {
                            return ModelUtils.getSourceByIdWithArchive(sourceId, modelBeforeAction);
                          })), AvoConfig.getSourceName).join(", ");
              tmp$44 = React.createElement(ActivityItem$ActivityItemAction, {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added the allowed value ", React.createElement("b", undefined, match$140), " to the property ", React.createElement("b", undefined, property$3.name), " on the event ", React.createElement("b", undefined, match$139.name), " for ", React.createElement("b", undefined, sources$2)),
                    openBranches: openBranches
                  });
            }
            return React.createElement(React.Fragment, undefined, tmp$43, tmp$44);
          }
          if (variant === "UpdateEventVariantDescription") {
            var variantIdentifier$11 = match.VAL[0];
            var match$141 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$11);
            if (match$141 === undefined) {
              return null;
            }
            var match$142 = ModelUtils.getEventByIdWithArchive(variantIdentifier$11.baseEventId, modelBeforeAction);
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "updated the description for the variant", React.createElement("b", undefined, match$141.nameSuffix), match$142 !== undefined ? React.createElement(React.Fragment, undefined, " on ", React.createElement("b", undefined, match$142.name)) : null),
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "eventVariant",
                              VAL: [
                                variantIdentifier$11,
                                undefined
                              ]
                            }),
                        openBranches: openBranches
                      });
          }
          if (variant === "DeprecatedUpdatePropertyValidationMinOrMax") {
            var match$143 = match.VAL;
            var match$144 = ModelUtils.getPropertyByIdWithArchive(match$143[0], modelBeforeAction);
            if (match$144 === undefined) {
              return null;
            }
            if (match$144.TAG === /* PropertyRef */0) {
              return null;
            }
            var label = TrackingPlanModel.constraint_ToJs(match$143[2]).toLowerCase();
            var tmp$45 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "updated the " + label + " constraint of the property ", React.createElement("b", undefined, match$144._0.name), " to be " + match$143[1]),
              openBranches: openBranches
            };
            if (linkToPropertyFromContext !== undefined) {
              tmp$45.path = Caml_option.valFromOption(linkToPropertyFromContext);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$45);
          }
          if (variant === "InspectorIssueComment") {
            var match$145 = match.VAL;
            var commentId$4 = match$145[1];
            var inspectorIssueId = match$145[0];
            var eventId$11 = context.eventId;
            return React.createElement(ActivityItem$InspectorItemComment, {
                        threadId: inspectorIssueId,
                        commentId: commentId$4,
                        link: Router.Link.addDrawerItem(undefined, {
                              NAME: "inspectorIssue",
                              VAL: [
                                inspectorIssueId,
                                commentId$4
                              ]
                            }),
                        action: action,
                        users: users$1,
                        events: modelBeforeAction.events,
                        openBranches: openBranches,
                        model: currentModel,
                        eventId: eventId$11 === undefined ? undefined : Caml_option.some(eventId$11)
                      });
          }
          if (variant === "RemoveIntegration") {
            var integration$2 = ModelUtils.getIntegrationById(match.VAL, modelBeforeAction);
            if (integration$2 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "deleted the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$2))),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "SetPropertyAbsenceOnVariant") {
            var match$146 = match.VAL;
            var absenceOverride = match$146[2];
            var variantIdentifier$12 = match$146[0];
            var match$147 = ModelUtils.getEventByIdWithArchive(variantIdentifier$12.baseEventId, modelBeforeAction);
            var match$148 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$12);
            var match$149 = ModelUtils.getPropertyByIdWithArchive(match$146[1], modelBeforeAction);
            if (match$147 === undefined) {
              return null;
            }
            if (match$148 === undefined) {
              return null;
            }
            if (match$149 === undefined) {
              return null;
            }
            var nameSuffix$2 = match$148.nameSuffix;
            if (match$149.TAG === /* PropertyRef */0) {
              return null;
            }
            var property$4 = match$149._0;
            var tmp$46;
            if (absenceOverride) {
              var tmp$47;
              switch (absenceOverride._0) {
                case /* AlwaysSent */0 :
                    tmp$47 = "always sent";
                    break;
                case /* SometimesSent */1 :
                    tmp$47 = "sometimes sent";
                    break;
                case /* NeverSent */2 :
                    tmp$47 = "never sent";
                    break;
                
              }
              tmp$46 = React.createElement(React.Fragment, undefined, "set presence of the ", React.createElement("b", undefined, property$4.name), " property on the event variant ", React.createElement("b", undefined, match$147.name + " - " + nameSuffix$2), " to be " + tmp$47);
            } else {
              tmp$46 = React.createElement(React.Fragment, undefined, "removed presence override of the ", React.createElement("b", undefined, property$4.name), " property on the event variant ", React.createElement("b", undefined, match$147.name + " - " + nameSuffix$2));
            }
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: tmp$46,
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "eventVariant",
                              VAL: [
                                variantIdentifier$12,
                                undefined
                              ]
                            }),
                        openBranches: openBranches
                      });
          }
          if (variant === "IntegrationComment") {
            var match$150 = match.VAL;
            var commentId$5 = match$150[1];
            var integrationId = match$150[0];
            var tmp$48 = {
              threadId: integrationId,
              commentId: commentId$5,
              link: Router.Link.addDrawerItem(undefined, {
                    NAME: "integration",
                    VAL: [
                      integrationId,
                      commentId$5
                    ]
                  }),
              itemTypeName: "integration",
              action: action,
              users: users$1,
              events: modelBeforeAction.events,
              openBranches: openBranches
            };
            var tmp$49 = Belt_Option.map(ModelUtils.getIntegrationById(integrationId, modelBeforeAction), AvoConfig.getIntegrationName);
            if (tmp$49 !== undefined) {
              tmp$48.itemName = Caml_option.valFromOption(tmp$49);
            }
            return React.createElement(ActivityItem$ActivityItemComment, tmp$48);
          }
          if (variant === "UpdateDestinationApiKey") {
            var match$151 = match.VAL;
            var destinationId$3 = match$151[0];
            var destination$4 = ModelUtils.getDestinationByIdWithArchive(destinationId$3, modelBeforeAction);
            if (destination$4 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the " + match$151[1] + " api key of the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$4))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "destination",
                                VAL: destinationId$3
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateDestinationName") {
            var match$152 = match.VAL;
            var destinationId$4 = match$152[0];
            var destination$5 = ModelUtils.getDestinationByIdWithArchive(destinationId$4, modelBeforeAction);
            if (destination$5 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "renamed the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$5)), " to ", React.createElement("b", undefined, match$152[1])),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "destination",
                                VAL: destinationId$4
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "ToggleIncludeEventInCodegenForSource") {
            var match$153 = match.VAL;
            var includeInCodegen = match$153[2];
            var eventId$12 = match$153[0];
            var match$154 = ModelUtils.getEventByIdWithArchive(eventId$12, modelBeforeAction);
            var match$155 = ModelUtils.getSourceByIdWithArchive(match$153[1], modelBeforeAction);
            if (match$154 !== undefined) {
              if (match$155 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, (
                                  includeInCodegen ? "included" : "excluded"
                                ) + " the event ", React.createElement("b", undefined, match$154.name), " " + (
                                  includeInCodegen ? "in" : "from"
                                ) + " Codegen for the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$155))),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    eventId$12,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, (
                                  includeInCodegen ? "included" : "excluded"
                                ) + " the event ", React.createElement("b", undefined, match$154.name), " " + (
                                  includeInCodegen ? "in" : "from"
                                ) + " Codegen for a source"),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    eventId$12,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              }
            } else {
              return null;
            }
          }
          if (variant === "UpdateLocalWorkspaceName") {
            var match$156 = match.VAL;
            var localWorkspaceId$1 = match$156[0];
            var source$7 = ModelUtils.getSourceByIdWithArchive(localWorkspaceId$1, modelBeforeAction);
            if (source$7 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "renamed the workspace ", React.createElement("b", undefined, AvoConfig.getSourceName(source$7)), " to ", React.createElement("b", undefined, match$156[1])),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  localWorkspaceId$1,
                                  "overview"
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdatePropertyOptional") {
            var match$157 = match.VAL;
            var match$158 = ModelUtils.getPropertyByIdWithArchive(match$157[0], modelBeforeAction);
            if (match$158 === undefined) {
              return null;
            }
            if (match$158.TAG === /* PropertyRef */0) {
              return null;
            }
            var tmp$50 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "made the property ", React.createElement("b", undefined, match$158._0.name), match$157[1] ? " optional" : " required"),
              openBranches: openBranches
            };
            if (linkToPropertyFromContext !== undefined) {
              tmp$50.path = Caml_option.valFromOption(linkToPropertyFromContext);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$50);
          }
          if (variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed") {
            var match$159 = match.VAL;
            var literal$3 = match$159[2];
            var variantIdentifier$13 = match$159[0];
            var match$160 = ModelUtils.getEventByIdWithArchive(variantIdentifier$13.baseEventId, modelBeforeAction);
            var match$161 = ModelUtils.getPropertyByIdWithArchive(match$159[1], modelBeforeAction);
            var match$162 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$13);
            if (match$160 !== undefined && match$161 !== undefined && !(match$161.TAG === /* PropertyRef */0 || !(match$162 !== undefined && typeof literal$3 === "object" && literal$3.NAME === "StringLit"))) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the allowed value \"" + literal$3.VAL + "\" from the property ", React.createElement("b", undefined, match$161._0.name), " on ", React.createElement("b", undefined, match$160.name + " - " + match$162.nameSuffix)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "eventVariant",
                                VAL: [
                                  variantIdentifier$13,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "IncludeEventInSource" || variant === "IncludeEventInSourceV2") {
            var match$163 = match.VAL;
            var eventId$13 = match$163[0];
            var match$164 = ModelUtils.getEventByIdWithArchive(eventId$13, modelBeforeAction);
            var match$165 = ModelUtils.getSourceByIdWithArchive(match$163[1], modelBeforeAction);
            if (match$164 !== undefined) {
              if (match$165 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "added the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$165)), " to the " + itemName + " ", React.createElement("b", undefined, match$164.name)),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    eventId$13,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "updated the sources ", React.createElement("b", undefined, match$164.name), " is sent from"),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    eventId$13,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              }
            } else {
              return null;
            }
          }
          if (variant === "AddSystemProperty") {
            var tmp$51 = {
              action: action,
              users: users$1,
              message: React.createElement(React.Fragment, undefined, "added the system property ", React.createElement("b", undefined, match.VAL[1])),
              openBranches: openBranches
            };
            if (linkToPropertyFromContext !== undefined) {
              tmp$51.path = Caml_option.valFromOption(linkToPropertyFromContext);
            }
            return React.createElement(ActivityItem$ActivityItemAction, tmp$51);
          }
          if (variant === "UpdateRule") {
            var item$1 = match.VAL[1];
            if (typeof item$1 !== "object") {
              return null;
            }
            if (item$1.NAME !== "Property") {
              return null;
            }
            var match$166 = ModelUtils.getPropertyByIdWithArchive(item$1.VAL, modelBeforeAction);
            if (match$166 !== undefined && match$166.TAG !== /* PropertyRef */0) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed a name mapping from the property ", React.createElement("b", undefined, match$166._0.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "GoalComment") {
            var match$167 = match.VAL;
            var commentId$6 = match$167[1];
            var goalId$2 = match$167[0];
            var tmp$52 = {
              threadId: goalId$2,
              commentId: commentId$6,
              link: Router.Link.addDrawerItem(undefined, {
                    NAME: "category",
                    VAL: [
                      goalId$2,
                      commentId$6
                    ]
                  }),
              itemTypeName: "category",
              action: action,
              users: users$1,
              events: modelBeforeAction.events,
              openBranches: openBranches
            };
            var tmp$53 = Belt_Option.map(ModelUtils.getGoalByIdWithArchive(goalId$2, modelBeforeAction), (function (goal) {
                    return goal.name;
                  }));
            if (tmp$53 !== undefined) {
              tmp$52.itemName = Caml_option.valFromOption(tmp$53);
            }
            return React.createElement(ActivityItem$ActivityItemComment, tmp$52);
          }
          if (variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources") {
            var match$168 = match.VAL;
            var literal$4 = match$168[2];
            var variantIdentifier$14 = match$168[0];
            var match$169 = ModelUtils.getEventByIdWithArchive(variantIdentifier$14.baseEventId, modelBeforeAction);
            var match$170 = ModelUtils.getPropertyByIdWithArchive(match$168[1], modelBeforeAction);
            var match$171 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$14);
            if (match$169 !== undefined && match$170 !== undefined && !(match$170.TAG === /* PropertyRef */0 || !(match$171 !== undefined && typeof literal$4 === "object" && literal$4.NAME === "StringLit"))) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added the allowed value \"" + literal$4.VAL + "\" to the property ", React.createElement("b", undefined, match$170._0.name), " on ", React.createElement("b", undefined, match$169.name + " - " + match$171.nameSuffix)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "eventVariant",
                                VAL: [
                                  variantIdentifier$14,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddEventSpecificPropertyValue") {
            var match$172 = match.VAL;
            var sources$3 = match$172[3];
            var literal$5 = match$172[2];
            var eventId$14 = match$172[0];
            var match$173 = ModelUtils.getPropertyByIdWithArchive(eventId$14, modelBeforeAction);
            var match$174 = ModelUtils.getEventByIdWithArchive(eventId$14, modelBeforeAction);
            if (typeof literal$5 !== "object") {
              return null;
            }
            if (literal$5.NAME !== "StringLit") {
              return null;
            }
            if (match$173 === undefined) {
              return null;
            }
            if (match$173.TAG === /* PropertyRef */0) {
              return null;
            }
            if (match$174 === undefined) {
              return null;
            }
            var sourcesAreAllEventSources$1 = Belt_SetString.eq(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(match$174.includeSources, (function (param) {
                                return param.id;
                              })))), sources$3);
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: React.createElement(React.Fragment, undefined, "added the allowed value ", React.createElement("b", undefined, literal$5.VAL), " on the property", React.createElement("b", undefined, match$173._0.name), " to the event ", React.createElement("b", undefined, match$174.name), sourcesAreAllEventSources$1 ? null : React.createElement(React.Fragment, undefined, " for ", React.createElement("b", undefined, Belt_Array.map(Belt_Array.keepMap(Belt_SetString.toArray(sources$3), (function (sourceId) {
                                                    return ModelUtils.getSourceByIdWithArchive(sourceId, modelBeforeAction);
                                                  })), AvoConfig.getSourceName).join(", ")))),
                        openBranches: openBranches
                      });
          }
          if (variant === "RemoveSourceSegmentIntegration") {
            var match$175 = match.VAL;
            var sourceId$9 = match$175[0];
            var source$8 = ModelUtils.getSourceByIdWithArchive(sourceId$9, modelBeforeAction);
            if (source$8 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed ", React.createElement("b", undefined, match$175[1]), " from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$8)), " source"),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  sourceId$9,
                                  "overview"
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateEventVariantTriggerDescription") {
            var match$176 = match.VAL;
            var variantIdentifier$15 = match$176[0];
            var match$177 = ModelUtils.getEventByIdWithArchive(variantIdentifier$15.baseEventId, modelBeforeAction);
            var match$178 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$15);
            if (match$177 !== undefined && match$178 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the description for a trigger in the variant ", React.createElement("b", undefined, match$178.nameSuffix), " on ", React.createElement("b", undefined, match$177.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "eventVariant",
                                VAL: [
                                  variantIdentifier$15,
                                  match$176[1]
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateEventDescription") {
            var match$179 = match.VAL;
            var eventId$15 = match$179[0];
            var $$event$10 = ModelUtils.getEventByIdWithArchive(eventId$15, modelBeforeAction);
            if ($$event$10 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the description of the " + itemName + " ", React.createElement("b", undefined, $$event$10.name), " to " + match$179[1]),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$15,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "DuplicateMetric") {
            var match$180 = match.VAL;
            var match$181 = ModelUtils.getMetricById(match$180[0], modelBeforeAction);
            if (match$181 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "duplicated a metric: ", React.createElement("b", undefined, match$181.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "metric",
                                VAL: [
                                  match$180[1],
                                  undefined,
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddEventToMetric") {
            var match$182 = match.VAL;
            var match$183 = ModelUtils.getMetricByIdWithArchive(match$182[0], modelBeforeAction);
            var match$184 = ModelUtils.getEventByIdWithArchive(match$182[2], modelBeforeAction);
            if (match$183 !== undefined && match$184 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added the " + itemName + " ", React.createElement("b", undefined, match$184.name), " to the metric ", React.createElement("b", undefined, match$183.name)),
                          path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddEventVariantTriggerSource") {
            var match$185 = match.VAL;
            var variantIdentifier$16 = match$185[0];
            var match$186 = ModelUtils.getEventByIdWithArchive(variantIdentifier$16.baseEventId, modelBeforeAction);
            var match$187 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$16);
            var match$188 = ModelUtils.getSourceById(match$185[2], modelBeforeAction);
            if (match$186 !== undefined && match$187 !== undefined && match$188 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added ", React.createElement("b", undefined, AvoConfig.getSourceName(match$188)), " as a source for a trigger in the variant ", React.createElement("b", undefined, match$187.nameSuffix), " on ", React.createElement("b", undefined, match$186.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "eventVariant",
                                VAL: [
                                  variantIdentifier$16,
                                  match$185[1]
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "CreateDestination") {
            return React.createElement(ActivityItem$ActivityItemAction, {
                        action: action,
                        users: users$1,
                        message: "created a destination",
                        path: Router.Link.addDrawerItem(undefined, {
                              NAME: "destination",
                              VAL: match.VAL
                            }),
                        openBranches: openBranches
                      });
          }
          if (variant === "DeleteComment" || variant === "RemoveNestedPropertyPinnedValue" || variant === "UpdateNestedPropertyPinnedValue") {
            return null;
          }
          if (variant === "AddMetricToGoal") {
            var match$189 = match.VAL;
            var goal$4 = ModelUtils.getGoalByIdWithArchive(match$189[0], modelBeforeAction);
            if (goal$4 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added a metric to the category ", React.createElement("b", undefined, goal$4.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "metric",
                                VAL: [
                                  match$189[1],
                                  undefined,
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddEventSpecificPropertyValueForAllEvents") {
            var match$190 = match.VAL;
            var literal$6 = match$190[1];
            var match$191 = ModelUtils.getPropertyByIdWithArchive(match$190[0], modelBeforeAction);
            if (match$191 !== undefined && !(match$191.TAG === /* PropertyRef */0 || !(typeof literal$6 === "object" && literal$6.NAME === "StringLit"))) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added the allowed value ", React.createElement("b", undefined, literal$6.VAL), " to the property ", React.createElement("b", undefined, match$191._0.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "ExcludeDestinationFromEventSource") {
            var match$192 = match.VAL;
            var sourceId$10 = match$192[1];
            var match$193 = ModelUtils.getEventByIdWithArchive(match$192[0], modelBeforeAction);
            var match$194 = ModelUtils.getSourceByIdWithArchive(sourceId$10, modelBeforeAction);
            var match$195 = ModelUtils.getDestinationByIdWithArchive(match$192[2], modelBeforeAction);
            if (match$193 !== undefined && match$194 !== undefined && match$195 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "stopped sending the event ", React.createElement("b", undefined, match$193.name), " to ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$195)), " from ", React.createElement("b", undefined, AvoConfig.getSourceName(match$194))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  sourceId$10,
                                  "overview"
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "DeleteDestination") {
            var destination$6 = ModelUtils.getDestinationByIdWithArchive(match.VAL, modelBeforeAction);
            if (destination$6 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "deleted the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$6))),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "GenerateWebhookSecret") {
            var integration$3 = ModelUtils.getIntegrationById(match.VAL, modelBeforeAction);
            if (integration$3 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "successfully generated new webhook secret for integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$3))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integration$3.id,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdatePropertyGroupDescription") {
            var group$1 = ModelUtils.getPropertyGroupByIdWithArchive(match.VAL[0], modelBeforeAction);
            if (group$1 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the description of the property bundle ", React.createElement("b", undefined, group$1.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateTriggerDescription") {
            var match$196 = match.VAL;
            var eventId$16 = match$196[0];
            var match$197 = ModelUtils.getEventByIdWithArchive(eventId$16, modelBeforeAction);
            if (match$197 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the description for a trigger in ", React.createElement("b", undefined, match$197.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$16,
                                  undefined,
                                  match$196[1],
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddPropertyToWhitelist") {
            var match$198 = match.VAL;
            var match$199 = ModelUtils.getPropertyByIdWithArchive(match$198[1], modelBeforeAction);
            var match$200 = ModelUtils.getEventByIdWithArchive(match$198[0], modelBeforeAction);
            if (match$199 !== undefined && !(match$199.TAG === /* PropertyRef */0 || match$200 === undefined)) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "started sending the property ", React.createElement("b", undefined, match$199._0.name), " with the " + itemName + " ", React.createElement("b", undefined, match$200.name), " to ", React.createElement("b", undefined, AvoConfigTypes.analyticsToolToJs(match$198[2]))),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources") {
            var match$201 = ModelUtils.getPropertyByIdWithArchive(match.VAL, modelBeforeAction);
            if (match$201 !== undefined && match$201.TAG !== /* PropertyRef */0) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "allowed all values on all events and sources on the property ", React.createElement("b", undefined, match$201._0.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "CreateIntegration") {
            var integration$4 = ModelUtils.getIntegrationById(match.VAL, modelBeforeAction);
            if (integration$4 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "created the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$4))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integration$4.id,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdateDestinationAnalyticsTool") {
            var match$202 = match.VAL;
            var destinationId$5 = match$202[0];
            var destination$7 = ModelUtils.getDestinationByIdWithArchive(destinationId$5, modelBeforeAction);
            if (destination$7 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "updated the analytics tool of ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$7)), " to ", React.createElement("b", undefined, AvoConfig.analyticsToolsLabel(match$202[1]))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "destination",
                                VAL: destinationId$5
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "RemovePropertyFromGroup") {
            var match$203 = match.VAL;
            var match$204 = ModelUtils.getPropertyGroupByIdWithArchive(match$203[0], modelBeforeAction);
            var match$205 = ModelUtils.getPropertyByIdWithArchive(match$203[1], modelBeforeAction);
            if (match$204 !== undefined && match$205 !== undefined && match$205.TAG !== /* PropertyRef */0) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed the property ", React.createElement("b", undefined, match$205._0.name), " from the property bundle ", React.createElement("b", undefined, match$204.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddEventToGoal") {
            var match$206 = match.VAL;
            var goalId$3 = match$206[0];
            var match$207 = ModelUtils.getGoalByIdWithArchive(goalId$3, modelBeforeAction);
            var match$208 = ModelUtils.getEventByIdWithArchive(match$206[1], modelBeforeAction);
            if (match$207 !== undefined && match$208 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added the " + itemName + " ", React.createElement("b", undefined, match$208.name), " to the category ", React.createElement("b", undefined, match$207.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "category",
                                VAL: [
                                  goalId$3,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddTriggerSource") {
            var match$209 = match.VAL;
            var eventId$17 = match$209[0];
            var match$210 = ModelUtils.getEventByIdWithArchive(eventId$17, modelBeforeAction);
            var match$211 = ModelUtils.getSourceById(match$209[2], modelBeforeAction);
            if (match$210 !== undefined && match$211 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added ", React.createElement("b", undefined, AvoConfig.getSourceName(match$211)), " as a source for a trigger in ", React.createElement("b", undefined, match$210.name)),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$17,
                                  undefined,
                                  match$209[1],
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "IncludeDestinationInEventSource") {
            var match$212 = match.VAL;
            var sourceId$11 = match$212[1];
            var match$213 = ModelUtils.getEventByIdWithArchive(match$212[0], modelBeforeAction);
            var match$214 = ModelUtils.getSourceByIdWithArchive(sourceId$11, modelBeforeAction);
            var match$215 = ModelUtils.getDestinationByIdWithArchive(match$212[2], modelBeforeAction);
            if (match$213 !== undefined && match$214 !== undefined && match$215 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "started sending the event ", React.createElement("b", undefined, match$213.name), " to ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$215)), " from ", React.createElement("b", undefined, AvoConfig.getSourceName(match$214))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "source",
                                VAL: [
                                  sourceId$11,
                                  "overview"
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "AddGroupTypeToLogEvent") {
            var match$216 = match.VAL;
            var match$217 = ModelUtils.getGroupTypeName(match$216[0], modelBeforeAction);
            var match$218 = ModelUtils.getEventByIdWithArchive(match$216[1], modelBeforeAction);
            if (match$217 !== undefined && match$218 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "added the group type ", React.createElement("b", undefined, match$217), " to the event ", React.createElement("b", undefined, match$218.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "UpdatePropertyPinnedValue") {
            var match$219 = match.VAL;
            var eventId$18 = match$219[0];
            var match$220 = ModelUtils.getEventById(eventId$18, modelBeforeAction);
            var match$221 = ModelUtils.getPropertyById(match$219[1], modelBeforeAction);
            if (match$220 !== undefined && match$221 !== undefined && match$221.TAG !== /* PropertyRef */0) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "pinned the value of ", React.createElement("b", undefined, match$221._0.name), " on ", React.createElement("b", undefined, match$220.name), " to ", React.createElement("b", undefined, PropertyUtils.printPinnedValue(match$219[2]))),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  eventId$18,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "PublishIntegration") {
            var match$222 = match.VAL;
            var integration$5 = ModelUtils.getIntegrationById(match$222[0], modelBeforeAction);
            if (integration$5 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: match$222[1] ? React.createElement(React.Fragment, undefined, "successfully published the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$5))) : React.createElement(React.Fragment, undefined, "published the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$5)), " with errors"),
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "integration",
                                VAL: [
                                  integration$5.id,
                                  undefined
                                ]
                              }),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "RemoveUserFromGroup") {
            var match$223 = match.VAL;
            var match$224 = ModelUtils.getGroupTypeName(match$223[0], modelBeforeAction);
            var match$225 = ModelUtils.getEventByIdWithArchive(match$223[1], modelBeforeAction);
            if (match$224 !== undefined && match$225 !== undefined) {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "removed association of the group type ", React.createElement("b", undefined, match$224), " with the user in the event ", React.createElement("b", undefined, match$225.name)),
                          openBranches: openBranches
                        });
            } else {
              return null;
            }
          }
          if (variant === "RemovePropertyValidation") {
            var match$226 = match.VAL;
            var validation = match$226[1];
            var propertyId$5 = match$226[0];
            var variant$4 = validation.NAME;
            if (variant$4 === "EndsWith" || variant$4 === "Shape" || variant$4 === "NestedProperty" || variant$4 === "Contains" || variant$4 === "StartsWith") {
              return null;
            }
            if (variant$4 === "Regex") {
              var match$227 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
              if (match$227 === undefined) {
                return null;
              }
              if (match$227.TAG === /* PropertyRef */0) {
                return null;
              }
              var tmp$54 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "removed the regex validation from the property ", React.createElement("b", undefined, match$227._0.name)),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$54.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$54);
            }
            if (variant$4 === "Max") {
              var match$228 = validation.VAL;
              if (typeof match$228 !== "object") {
                return null;
              }
              var variant$5 = match$228.NAME;
              if (variant$5 === "IntLit") {
                var match$229 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
                if (match$229 === undefined) {
                  return null;
                }
                if (match$229.TAG === /* PropertyRef */0) {
                  return null;
                }
                var tmp$55 = {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "removed the max ", React.createElement("b", undefined, String(match$228.VAL)), " constraint from the property ", React.createElement("b", undefined, match$229._0.name)),
                  openBranches: openBranches
                };
                if (linkToPropertyFromContext !== undefined) {
                  tmp$55.path = Caml_option.valFromOption(linkToPropertyFromContext);
                }
                return React.createElement(ActivityItem$ActivityItemAction, tmp$55);
              }
              if (variant$5 !== "FloatLit") {
                return null;
              }
              var match$230 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
              if (match$230 === undefined) {
                return null;
              }
              if (match$230.TAG === /* PropertyRef */0) {
                return null;
              }
              var tmp$56 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "removed the max ", React.createElement("b", undefined, match$228.VAL.toString()), " constraint from the property ", React.createElement("b", undefined, match$230._0.name)),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$56.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$56);
            }
            if (variant$4 === "Min") {
              var match$231 = validation.VAL;
              if (typeof match$231 !== "object") {
                return null;
              }
              var variant$6 = match$231.NAME;
              if (variant$6 === "IntLit") {
                var match$232 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
                if (match$232 === undefined) {
                  return null;
                }
                if (match$232.TAG === /* PropertyRef */0) {
                  return null;
                }
                var tmp$57 = {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "removed the min ", React.createElement("b", undefined, String(match$231.VAL)), " constraint from the property ", React.createElement("b", undefined, match$232._0.name)),
                  openBranches: openBranches
                };
                if (linkToPropertyFromContext !== undefined) {
                  tmp$57.path = Caml_option.valFromOption(linkToPropertyFromContext);
                }
                return React.createElement(ActivityItem$ActivityItemAction, tmp$57);
              }
              if (variant$6 !== "FloatLit") {
                return null;
              }
              var match$233 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
              if (match$233 === undefined) {
                return null;
              }
              if (match$233.TAG === /* PropertyRef */0) {
                return null;
              }
              var tmp$58 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "removed the min ", React.createElement("b", undefined, match$231.VAL.toString()), " constraint from the property ", React.createElement("b", undefined, match$233._0.name)),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$58.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$58);
            }
            var match$234 = validation.VAL;
            var exit$3 = 0;
            if (match$234) {
              var match$235 = match$234.hd[0].VAL;
              if (typeof match$235 === "object") {
                if (match$235.NAME === "StringLit" && !match$234.tl) {
                  var match$236 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
                  if (match$236 === undefined) {
                    return null;
                  }
                  if (match$236.TAG === /* PropertyRef */0) {
                    return null;
                  }
                  var tmp$59 = {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed the allowed value ", React.createElement("b", undefined, match$235.VAL), " from the property ", React.createElement("b", undefined, match$236._0.name)),
                    openBranches: openBranches
                  };
                  if (linkToPropertyFromContext !== undefined) {
                    tmp$59.path = Caml_option.valFromOption(linkToPropertyFromContext);
                  }
                  return React.createElement(ActivityItem$ActivityItemAction, tmp$59);
                }
                exit$3 = 2;
              } else {
                exit$3 = 2;
              }
            } else {
              var match$237 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
              if (match$237 === undefined) {
                return null;
              }
              if (match$237.TAG === /* PropertyRef */0) {
                return null;
              }
              var tmp$60 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "removed all allowed values from the property ", React.createElement("b", undefined, match$237._0.name)),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$60.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$60);
            }
            if (exit$3 === 2) {
              var match$238 = ModelUtils.getPropertyByIdWithArchive(propertyId$5, modelBeforeAction);
              if (match$238 === undefined) {
                return null;
              }
              if (match$238.TAG === /* PropertyRef */0) {
                return null;
              }
              var tmp$61 = {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "removed multiple allowed values from the property ", React.createElement("b", undefined, match$238._0.name)),
                openBranches: openBranches
              };
              if (linkToPropertyFromContext !== undefined) {
                tmp$61.path = Caml_option.valFromOption(linkToPropertyFromContext);
              }
              return React.createElement(ActivityItem$ActivityItemAction, tmp$61);
            }
            
          } else {
            if (variant === "UpdateGoalName") {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "renamed a category to ", React.createElement("b", undefined, match.VAL[1])),
                          path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                          openBranches: openBranches
                        });
            }
            if (variant === "CreateDomain") {
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "created a new domain called ", React.createElement("b", undefined, match.VAL[1])),
                          openBranches: openBranches
                        });
            }
            if (variant === "UpdateDomainName") {
              var match$239 = match.VAL;
              return React.createElement(ActivityItem$ActivityItemAction, {
                          action: action,
                          users: users$1,
                          message: React.createElement(React.Fragment, undefined, "renamed the domain ", React.createElement("b", undefined, match$239[1]), " to ", React.createElement("b", undefined, match$239[2])),
                          openBranches: openBranches
                        });
            }
            if (variant === "DuplicateEvent") {
              var eventId$19 = match.VAL[0][1];
              var match$240 = ModelUtils.getEventByIdWithArchive(eventId$19, modelBeforeAction);
              if (match$240 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "duplicated an " + itemName + ": ", React.createElement("b", undefined, match$240.name)),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "event",
                                  VAL: [
                                    eventId$19,
                                    undefined,
                                    undefined,
                                    false
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            if (variant === "CreateEventVariant") {
              var match$241 = match.VAL;
              var variantIdentifier$17 = match$241[0];
              var match$242 = ModelUtils.getEventByIdWithArchive(variantIdentifier$17.baseEventId, modelBeforeAction);
              if (match$242 !== undefined) {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "created a variant on ", React.createElement("b", undefined, match$242.name), " called ", React.createElement("b", undefined, match$241[1])),
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "eventVariant",
                                  VAL: [
                                    variantIdentifier$17,
                                    undefined
                                  ]
                                }),
                            openBranches: openBranches
                          });
              } else {
                return null;
              }
            }
            if (variant === "AddPropertyValidation") {
              var match$243 = match.VAL;
              var validation$1 = match$243[1];
              var propertyId$6 = match$243[0];
              var variant$7 = validation$1.NAME;
              if (variant$7 === "EndsWith" || variant$7 === "Shape" || variant$7 === "NestedProperty" || variant$7 === "Contains" || variant$7 === "StartsWith") {
                return null;
              }
              if (variant$7 === "Regex") {
                var regex = validation$1.VAL;
                var componentsForEventSpecificRegex = function (property) {
                  return Belt_Array.mapU(Belt_MapString.toArray(regex.eventOverrides), (function (param) {
                                var eventId = param[0];
                                var $$event = ModelUtils.getEventByIdWithArchive(eventId, modelBeforeAction);
                                if ($$event !== undefined) {
                                  return React.createElement(ActivityItem$ActivityItemAction, {
                                              action: action,
                                              users: users$1,
                                              message: React.createElement(React.Fragment, undefined, "added regex validation ", React.createElement("b", undefined, param[1].regex), " to the property ", React.createElement("b", undefined, property.name), " on the event ", React.createElement("b", undefined, $$event.name)),
                                              path: Router.Link.addDrawerItem(undefined, {
                                                    NAME: "event",
                                                    VAL: [
                                                      eventId,
                                                      undefined,
                                                      undefined,
                                                      false
                                                    ]
                                                  }),
                                              openBranches: openBranches
                                            });
                                } else {
                                  return null;
                                }
                              }));
                };
                var match$244 = ModelUtils.getPropertyByIdWithArchive(propertyId$6, modelBeforeAction);
                var match$245 = regex.propertyRule;
                if (match$244 === undefined) {
                  return null;
                }
                if (match$244.TAG === /* PropertyRef */0) {
                  return null;
                }
                var property$5 = match$244._0;
                if (match$245 === undefined) {
                  return componentsForEventSpecificRegex(property$5);
                }
                var tmp$62 = {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added regex validation ", React.createElement("b", undefined, match$245.regex), " to the property ", React.createElement("b", undefined, property$5.name)),
                  openBranches: openBranches
                };
                if (linkToPropertyFromContext !== undefined) {
                  tmp$62.path = Caml_option.valFromOption(linkToPropertyFromContext);
                }
                return [
                        React.createElement(ActivityItem$ActivityItemAction, tmp$62),
                        componentsForEventSpecificRegex(property$5)
                      ];
              }
              if (variant$7 === "Max") {
                var match$246 = validation$1.VAL;
                if (typeof match$246 !== "object") {
                  return null;
                }
                var variant$8 = match$246.NAME;
                if (variant$8 === "IntLit") {
                  var match$247 = ModelUtils.getPropertyByIdWithArchive(propertyId$6, modelBeforeAction);
                  if (match$247 === undefined) {
                    return null;
                  }
                  if (match$247.TAG === /* PropertyRef */0) {
                    return null;
                  }
                  var tmp$63 = {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added a max ", React.createElement("b", undefined, String(match$246.VAL)), " constraint to the property ", React.createElement("b", undefined, match$247._0.name)),
                    openBranches: openBranches
                  };
                  if (linkToPropertyFromContext !== undefined) {
                    tmp$63.path = Caml_option.valFromOption(linkToPropertyFromContext);
                  }
                  return React.createElement(ActivityItem$ActivityItemAction, tmp$63);
                }
                if (variant$8 !== "FloatLit") {
                  return null;
                }
                var match$248 = ModelUtils.getPropertyByIdWithArchive(propertyId$6, modelBeforeAction);
                if (match$248 === undefined) {
                  return null;
                }
                if (match$248.TAG === /* PropertyRef */0) {
                  return null;
                }
                var tmp$64 = {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added a max ", React.createElement("b", undefined, match$246.VAL.toString()), " constraint to the property ", React.createElement("b", undefined, match$248._0.name)),
                  openBranches: openBranches
                };
                if (linkToPropertyFromContext !== undefined) {
                  tmp$64.path = Caml_option.valFromOption(linkToPropertyFromContext);
                }
                return React.createElement(ActivityItem$ActivityItemAction, tmp$64);
              }
              if (variant$7 === "Min") {
                var match$249 = validation$1.VAL;
                if (typeof match$249 !== "object") {
                  return null;
                }
                var variant$9 = match$249.NAME;
                if (variant$9 === "IntLit") {
                  var match$250 = ModelUtils.getPropertyByIdWithArchive(propertyId$6, modelBeforeAction);
                  if (match$250 === undefined) {
                    return null;
                  }
                  if (match$250.TAG === /* PropertyRef */0) {
                    return null;
                  }
                  var tmp$65 = {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added a min ", React.createElement("b", undefined, String(match$249.VAL)), " constraint to the property ", React.createElement("b", undefined, match$250._0.name)),
                    openBranches: openBranches
                  };
                  if (linkToPropertyFromContext !== undefined) {
                    tmp$65.path = Caml_option.valFromOption(linkToPropertyFromContext);
                  }
                  return React.createElement(ActivityItem$ActivityItemAction, tmp$65);
                }
                if (variant$9 !== "FloatLit") {
                  return null;
                }
                var match$251 = ModelUtils.getPropertyByIdWithArchive(propertyId$6, modelBeforeAction);
                if (match$251 === undefined) {
                  return null;
                }
                if (match$251.TAG === /* PropertyRef */0) {
                  return null;
                }
                var tmp$66 = {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added a min ", React.createElement("b", undefined, match$249.VAL.toString()), " constraint to the property ", React.createElement("b", undefined, match$251._0.name)),
                  openBranches: openBranches
                };
                if (linkToPropertyFromContext !== undefined) {
                  tmp$66.path = Caml_option.valFromOption(linkToPropertyFromContext);
                }
                return React.createElement(ActivityItem$ActivityItemAction, tmp$66);
              }
              var match$252 = validation$1.VAL;
              var exit$4 = 0;
              if (!match$252) {
                return null;
              }
              var match$253 = match$252.hd[0].VAL;
              if (typeof match$253 === "object") {
                if (match$253.NAME === "StringLit" && !match$252.tl) {
                  var match$254 = ModelUtils.getPropertyByIdWithArchive(propertyId$6, modelBeforeAction);
                  if (match$254 === undefined) {
                    return null;
                  }
                  if (match$254.TAG === /* PropertyRef */0) {
                    return null;
                  }
                  var tmp$67 = {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "added the allowed value ", React.createElement("b", undefined, match$253.VAL), " to the property ", React.createElement("b", undefined, match$254._0.name)),
                    openBranches: openBranches
                  };
                  if (linkToPropertyFromContext !== undefined) {
                    tmp$67.path = Caml_option.valFromOption(linkToPropertyFromContext);
                  }
                  return React.createElement(ActivityItem$ActivityItemAction, tmp$67);
                }
                exit$4 = 2;
              } else {
                exit$4 = 2;
              }
              if (exit$4 === 2) {
                var match$255 = ModelUtils.getPropertyByIdWithArchive(propertyId$6, modelBeforeAction);
                if (match$255 === undefined) {
                  return null;
                }
                if (match$255.TAG === /* PropertyRef */0) {
                  return null;
                }
                var tmp$68 = {
                  action: action,
                  users: users$1,
                  message: React.createElement(React.Fragment, undefined, "added multiple allowed values to the property ", React.createElement("b", undefined, match$255._0.name)),
                  openBranches: openBranches
                };
                if (linkToPropertyFromContext !== undefined) {
                  tmp$68.path = Caml_option.valFromOption(linkToPropertyFromContext);
                }
                return React.createElement(ActivityItem$ActivityItemAction, tmp$68);
              }
              
            } else {
              if (variant === "PullMaster") {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: "pulled main into branch",
                            openBranches: openBranches
                          });
              }
              if (variant === "AddEventSpecificPropertyValueForSomeEvents") {
                var match$256 = match.VAL;
                var literal$7 = match$256[1];
                var match$257 = ModelUtils.getPropertyByIdWithArchive(match$256[0], currentModel);
                if (match$257 !== undefined && !(match$257.TAG === /* PropertyRef */0 || !(typeof literal$7 === "object" && literal$7.NAME === "StringLit"))) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "added the allowed value ", React.createElement("b", undefined, literal$7.VAL), " to the property ", React.createElement("b", undefined, match$257._0.name), " on some events"),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "UpdateMetricName") {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "renamed a metric to ", React.createElement("b", undefined, match.VAL[1])),
                            path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                            openBranches: openBranches
                          });
              }
              if (variant === "CreateSource") {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: "created a source",
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "source",
                                  VAL: [
                                    match.VAL,
                                    "overview"
                                  ]
                                }),
                            openBranches: openBranches
                          });
              }
              if (variant === "AddSourceSegmentIntegration" || variant === "UpdateSourceSegmentIntegration") {
                var match$258 = match.VAL;
                var sourceId$12 = match$258[0];
                var source$9 = ModelUtils.getSourceByIdWithArchive(sourceId$12, modelBeforeAction);
                if (source$9 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, match$258[2] ? "enabled " : "disabled ", React.createElement("b", undefined, match$258[1]), " in the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$9)), " source"),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "source",
                                    VAL: [
                                      sourceId$12,
                                      "overview"
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "ArchiveDomain") {
                return React.createElement(ActivityItem$DomainActivityItem, {
                            domainId: match.VAL,
                            children: (function (domainProgress) {
                                return React.createElement(ActivityItem$ActivityItemAction, {
                                            action: action,
                                            users: users$1,
                                            message: typeof domainProgress === "object" ? React.createElement(React.Fragment, undefined, "archived the domain ", React.createElement("b", undefined, domainProgress.VAL.name)) : "archived a domain",
                                            openBranches: openBranches
                                          });
                              })
                          });
              }
              if (variant === "RemoveEventType") {
                var match$259 = match.VAL;
                var eventId$20 = match$259[0];
                var $$event$11 = ModelUtils.getEventByIdWithArchive(eventId$20, modelBeforeAction);
                if ($$event$11 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "removed the event type ", React.createElement("b", undefined, TrackingPlanModel.eventTypeToJs(match$259[1])), " to ", React.createElement("b", undefined, $$event$11.name)),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "event",
                                    VAL: [
                                      eventId$20,
                                      undefined,
                                      undefined,
                                      false
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "RemoveEventFromGoal") {
                var match$260 = match.VAL;
                var goalId$4 = match$260[0];
                var match$261 = ModelUtils.getGoalByIdWithArchive(goalId$4, modelBeforeAction);
                var match$262 = ModelUtils.getEventByIdWithArchive(match$260[1], modelBeforeAction);
                if (match$261 !== undefined && match$262 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "removed the " + itemName + " ", React.createElement("b", undefined, match$262.name), " from the category ", React.createElement("b", undefined, match$261.name)),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "category",
                                    VAL: [
                                      goalId$4,
                                      undefined
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "ExcludeDestinationFromSource") {
                var match$263 = match.VAL;
                var sourceId$13 = match$263[0];
                var match$264 = ModelUtils.getSourceByIdWithArchive(sourceId$13, modelBeforeAction);
                var match$265 = ModelUtils.getDestinationByIdWithArchive(match$263[1], modelBeforeAction);
                if (match$264 !== undefined && match$265 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "removed the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(match$265)), " from the source ", React.createElement("b", undefined, AvoConfig.getSourceName(match$264))),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "source",
                                    VAL: [
                                      sourceId$13,
                                      "overview"
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "ReorderGoals" || variant === "ReorderGoalsV2") {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: "reordered the categories",
                            path: Router.Link.getSchemaRouteLink(undefined, "dashboard"),
                            openBranches: openBranches
                          });
              }
              if (variant === "UpdateMetricType") {
                var match$266 = match.VAL;
                var metricId$3 = match$266[0];
                var metric$3 = ModelUtils.getMetricByIdWithArchive(metricId$3, modelBeforeAction);
                if (metric$3 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "changed the type of the metric ", React.createElement("b", undefined, metric$3.name), " to ", React.createElement("b", undefined, AvoUtils.getMetricTypeLabel(match$266[1]))),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "metric",
                                    VAL: [
                                      metricId$3,
                                      undefined,
                                      false
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "ClearPropertyExcludedSources") {
                var propertyId$7 = match.VAL;
                var match$267 = ModelUtils.getPropertyById(propertyId$7, modelBeforeAction);
                if (match$267 !== undefined && match$267.TAG !== /* PropertyRef */0) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "started sending the property ", React.createElement("b", undefined, match$267._0.name), " to ", React.createElement("b", undefined, "all sources")),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "property",
                                    VAL: [
                                      propertyId$7,
                                      undefined
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "UpdateIntegrationName") {
                var match$268 = match.VAL;
                var integration$6 = ModelUtils.getIntegrationById(match$268[0], modelBeforeAction);
                if (integration$6 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "updated the name of a integration to ", React.createElement("b", undefined, match$268[1])),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "integration",
                                    VAL: [
                                      integration$6.id,
                                      undefined
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "CreatePropertyGroup") {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: React.createElement(React.Fragment, undefined, "created the property bundle ", React.createElement("b", undefined, match.VAL[1])),
                            openBranches: openBranches
                          });
              }
              if (variant === "UpdatePropertyGroupName") {
                var match$269 = match.VAL;
                var group$2 = ModelUtils.getPropertyGroupByIdWithArchive(match$269[0], modelBeforeAction);
                if (group$2 !== undefined) {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "renamed the property bundle ", React.createElement("b", undefined, group$2.name), " to ", React.createElement("b", undefined, match$269[1])),
                              openBranches: openBranches
                            });
                } else {
                  return null;
                }
              }
              if (variant === "AddSourceConfig") {
                return React.createElement(ActivityItem$ActivityItemAction, {
                            action: action,
                            users: users$1,
                            message: "added config to source",
                            path: Router.Link.addDrawerItem(undefined, {
                                  NAME: "source",
                                  VAL: [
                                    match.VAL[0],
                                    "overview"
                                  ]
                                }),
                            openBranches: openBranches
                          });
              }
              if (variant === "UpdateEventUniqueName") {
                var match$270 = match.VAL;
                eventId = match$270[0];
                uniqueName = match$270[1];
              } else {
                if (variant === "CreatePublicBranchImplementation") {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: "created a public branch implementation instructions link ",
                              path: BranchImplementationShareButton.getShareUrl(match.VAL),
                              openBranches: openBranches
                            });
                }
                if (variant === "DeprecatedRemovePropertyValidationMinOrMax") {
                  var match$271 = ModelUtils.getPropertyByIdWithArchive(match.VAL[0], modelBeforeAction);
                  if (match$271 === undefined) {
                    return null;
                  }
                  if (match$271.TAG === /* PropertyRef */0) {
                    return null;
                  }
                  var tmp$69 = {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "removed min or max from the property ", React.createElement("b", undefined, match$271._0.name)),
                    openBranches: openBranches
                  };
                  if (linkToPropertyFromContext !== undefined) {
                    tmp$69.path = Caml_option.valFromOption(linkToPropertyFromContext);
                  }
                  return React.createElement(ActivityItem$ActivityItemAction, tmp$69);
                }
                if (variant === "RemovePropertyFromEventVariant") {
                  var match$272 = match.VAL;
                  var variantIdentifier$18 = match$272[0];
                  var match$273 = ModelUtils.getEventByIdWithArchive(variantIdentifier$18.baseEventId, modelBeforeAction);
                  var match$274 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$18);
                  var match$275 = ModelUtils.getPropertyByIdWithArchive(match$272[1], modelBeforeAction);
                  if (match$273 !== undefined && match$274 !== undefined && match$275 !== undefined && match$275.TAG !== /* PropertyRef */0) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "removed the property ", React.createElement("b", undefined, match$275._0.name), " from the event variant " + match$273.name + " - " + match$274.nameSuffix),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "eventVariant",
                                      VAL: [
                                        variantIdentifier$18,
                                        undefined
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "UpdateIntegrationType") {
                  var match$276 = match.VAL;
                  var integration$7 = ModelUtils.getIntegrationById(match$276[0], modelBeforeAction);
                  if (integration$7 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "updated the type of the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$7)), " to ", React.createElement("b", undefined, TrackingPlanModel.integrationTypeToJs(match$276[1]))),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "integration",
                                      VAL: [
                                        integration$7.id,
                                        undefined
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "PropertyComment") {
                  var match$277 = match.VAL;
                  var commentId$7 = match$277[1];
                  var propertyId$8 = match$277[0];
                  var tmp$70 = {
                    threadId: propertyId$8,
                    commentId: commentId$7,
                    link: Router.Link.addDrawerItem(undefined, {
                          NAME: "property",
                          VAL: [
                            propertyId$8,
                            commentId$7
                          ]
                        }),
                    itemTypeName: "property",
                    action: action,
                    users: users$1,
                    events: modelBeforeAction.events,
                    openBranches: openBranches
                  };
                  var tmp$71 = Belt_Option.flatMap(ModelUtils.getPropertyByIdWithArchive(propertyId$8, modelBeforeAction), (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return ;
                          } else {
                            return property._0.name;
                          }
                        }));
                  if (tmp$71 !== undefined) {
                    tmp$70.itemName = Caml_option.valFromOption(tmp$71);
                  }
                  return React.createElement(ActivityItem$ActivityItemComment, tmp$70);
                }
                if (variant === "UpdateEventName") {
                  var match$278 = match.VAL;
                  var eventName = match$278[1];
                  var eventId$21 = match$278[0];
                  var $$event$12 = ModelUtils.getEventByIdWithArchive(eventId$21, modelBeforeAction);
                  if ($$event$12 !== undefined) {
                    if ($$event$12.name === "") {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "renamed an untitled event to ", React.createElement("b", undefined, eventName)),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "event",
                                        VAL: [
                                          eventId$21,
                                          undefined,
                                          undefined,
                                          false
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    } else {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "renamed the " + itemName + " ", React.createElement("b", undefined, $$event$12.name), " to ", React.createElement("b", undefined, eventName)),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "event",
                                        VAL: [
                                          eventId$21,
                                          undefined,
                                          undefined,
                                          false
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    }
                  } else {
                    return null;
                  }
                }
                if (variant === "UpdateIntegrationFilters") {
                  var integration$8 = ModelUtils.getIntegrationById(match.VAL[0], modelBeforeAction);
                  if (integration$8 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "updated the destinations included in the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$8))),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "integration",
                                      VAL: [
                                        integration$8.id,
                                        undefined
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "CreateProperty") {
                  var match$279 = match.VAL;
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "created the property ", React.createElement("b", undefined, match$279[1])),
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "property",
                                    VAL: [
                                      match$279[0],
                                      undefined
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                }
                if (variant === "UpdatePropertyOptionalWhenInObject") {
                  var match$280 = match.VAL;
                  var match$281 = ModelUtils.getPropertyByIdWithArchive(match$280[0], modelBeforeAction);
                  if (match$281 === undefined) {
                    return null;
                  }
                  if (match$281.TAG === /* PropertyRef */0) {
                    return null;
                  }
                  var tmp$72 = {
                    action: action,
                    users: users$1,
                    message: React.createElement(React.Fragment, undefined, "made the property ", React.createElement("b", undefined, match$281._0.name), match$280[1] ? " optional" : " required", " when in object"),
                    openBranches: openBranches
                  };
                  if (linkToPropertyFromContext !== undefined) {
                    tmp$72.path = Caml_option.valFromOption(linkToPropertyFromContext);
                  }
                  return React.createElement(ActivityItem$ActivityItemAction, tmp$72);
                }
                if (variant === "RemoveGroupTypeFromLogEvent") {
                  var match$282 = match.VAL;
                  var match$283 = ModelUtils.getGroupTypeName(match$282[0], modelBeforeAction);
                  var match$284 = ModelUtils.getEventByIdWithArchive(match$282[1], modelBeforeAction);
                  if (match$283 !== undefined && match$284 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "removed the group type ", React.createElement("b", undefined, match$283), " from the event ", React.createElement("b", undefined, match$284.name)),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "ClearPropertyRegexOverrideFromEventVariant") {
                  var match$285 = match.VAL;
                  var variantIdentifier$19 = match$285[0];
                  var match$286 = ModelUtils.getEventByIdWithArchive(variantIdentifier$19.baseEventId, modelBeforeAction);
                  var match$287 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$19);
                  var match$288 = ModelUtils.getPropertyByIdWithArchive(match$285[1], modelBeforeAction);
                  if (match$286 !== undefined && match$287 !== undefined && match$288 !== undefined && match$288.TAG !== /* PropertyRef */0) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "cleared the regex constraint override from the ", React.createElement("b", undefined, match$288._0.name), " property on the event variant ", React.createElement("b", undefined, match$286.name + " - " + match$287.nameSuffix)),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "eventVariant",
                                      VAL: [
                                        variantIdentifier$19,
                                        undefined
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "RemovePropertyGroupFromEvent") {
                  var match$289 = match.VAL;
                  var match$290 = ModelUtils.getPropertyGroupByIdWithArchive(match$289[1], modelBeforeAction);
                  var match$291 = ModelUtils.getEventByIdWithArchive(match$289[0], modelBeforeAction);
                  if (match$290 !== undefined && match$291 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "removed the property bundle ", React.createElement("b", undefined, match$290.name), " from the " + itemName + " ", React.createElement("b", undefined, match$291.name)),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "UpdateMetricDescription") {
                  var metric$4 = ModelUtils.getMetricByIdWithArchive(match.VAL[0], modelBeforeAction);
                  if (metric$4 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "updated the description of the metric ", React.createElement("b", undefined, metric$4.name)),
                                path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "UpdateDomainDescription") {
                  return React.createElement(ActivityItem$DomainActivityItem, {
                              domainId: match.VAL[0],
                              children: (function (domainProgress) {
                                  return React.createElement(ActivityItem$ActivityItemAction, {
                                              action: action,
                                              users: users$1,
                                              message: typeof domainProgress === "object" ? React.createElement(React.Fragment, undefined, "updated the description of the domain ", React.createElement("b", undefined, domainProgress.VAL.name)) : "updated the description of a domain",
                                              openBranches: openBranches
                                            });
                                })
                            });
                }
                if (variant === "CreateLocalWorkspace") {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: "new workspace added",
                              path: Router.Link.addDrawerItem(undefined, {
                                    NAME: "source",
                                    VAL: [
                                      match.VAL,
                                      "overview"
                                    ]
                                  }),
                              openBranches: openBranches
                            });
                }
                if (variant === "RemoveMetricFromGoal") {
                  var match$292 = match.VAL;
                  var match$293 = ModelUtils.getGoalByIdWithArchive(match$292[0], modelBeforeAction);
                  var match$294 = ModelUtils.getMetricByIdWithArchive(match$292[1], modelBeforeAction);
                  if (match$293 !== undefined && match$294 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "removed the metric ", React.createElement("b", undefined, match$294.name), " from the category ", React.createElement("b", undefined, match$293.name)),
                                path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "UpdateEventVariantTriggerContent") {
                  var match$295 = match.VAL;
                  var variantIdentifier$20 = match$295[0];
                  var match$296 = ModelUtils.getEventByIdWithArchive(variantIdentifier$20.baseEventId, modelBeforeAction);
                  var match$297 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$20);
                  if (match$296 !== undefined && match$297 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "updated the content for a trigger in the variant ", React.createElement("b", undefined, match$297.nameSuffix), " on ", React.createElement("b", undefined, match$296.name)),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "eventVariant",
                                      VAL: [
                                        variantIdentifier$20,
                                        match$295[1]
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "ConfigureInspectorValidation") {
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: "configured the Inspector validation",
                              openBranches: openBranches
                            });
                }
                if (variant === "AddPropertyToGroup") {
                  var match$298 = match.VAL;
                  var match$299 = ModelUtils.getPropertyGroupByIdWithArchive(match$298[0], modelBeforeAction);
                  var match$300 = ModelUtils.getPropertyByIdWithArchive(match$298[1], modelBeforeAction);
                  if (match$299 !== undefined && match$300 !== undefined && match$300.TAG !== /* PropertyRef */0) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "added the property ", React.createElement("b", undefined, match$300._0.name), " to the property bundle ", React.createElement("b", undefined, match$299.name)),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "UpdateDestinationDevApiKey") {
                  var destinationId$6 = match.VAL[0];
                  var destination$8 = ModelUtils.getDestinationByIdWithArchive(destinationId$6, modelBeforeAction);
                  if (destination$8 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "updated the dev api key of the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$8))),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "destination",
                                      VAL: destinationId$6
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "UpdateLibraryName") {
                  var match$301 = match.VAL;
                  var sourceId$14 = match$301[0];
                  var source$10 = ModelUtils.getSourceByIdWithArchive(sourceId$14, modelBeforeAction);
                  if (source$10 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "updated the library name of the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$10)), " to ", React.createElement("b", undefined, match$301[1])),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "source",
                                      VAL: [
                                        sourceId$14,
                                        "overview"
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "CloseBranch") {
                  var match$302 = match.VAL;
                  var branchId$4 = match$302[0];
                  return React.createElement(ActivityItem$ActivityItemAction, {
                              action: action,
                              users: users$1,
                              message: React.createElement(React.Fragment, undefined, "deleted the branch ", React.createElement("b", undefined, match$302[1])),
                              path: Router.Link.getSchemaRouteLink({
                                    NAME: "branch",
                                    VAL: branchId$4
                                  }, {
                                    NAME: "diff",
                                    VAL: "index"
                                  }),
                              openBranches: openBranches,
                              onClick: (function (param) {
                                  return AnalyticsRe.branchLinkClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, branchId$4, "BranchesOverviewActivityItem");
                                })
                            });
                }
                if (variant === "RemoveEventFromMetric") {
                  var metric$5 = ModelUtils.getMetricByIdWithArchive(match.VAL[0], modelBeforeAction);
                  if (metric$5 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "removed the event from the metric ", React.createElement("b", undefined, metric$5.name)),
                                path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "RemovePropertyPinnedValue") {
                  var match$303 = match.VAL;
                  var eventId$22 = match$303[0];
                  var match$304 = ModelUtils.getEventById(eventId$22, modelBeforeAction);
                  var match$305 = ModelUtils.getPropertyById(match$303[1], modelBeforeAction);
                  if (match$304 !== undefined && match$305 !== undefined && match$305.TAG !== /* PropertyRef */0) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "removed pinned value from ", React.createElement("b", undefined, match$305._0.name), " on ", React.createElement("b", undefined, match$304.name)),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "event",
                                      VAL: [
                                        eventId$22,
                                        undefined,
                                        undefined,
                                        false
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "UpdateDestinationProdApiKey") {
                  var destinationId$7 = match.VAL[0];
                  var destination$9 = ModelUtils.getDestinationByIdWithArchive(destinationId$7, modelBeforeAction);
                  if (destination$9 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "updated the prod api key of the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$9))),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "destination",
                                      VAL: destinationId$7
                                    }),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
                if (variant === "AutoPublishIntegration") {
                  var match$306 = match.VAL;
                  var integrationId$1 = match$306[0];
                  if (match$306[1]) {
                    var integration$9 = ModelUtils.getIntegrationById(integrationId$1, modelBeforeAction);
                    if (integration$9 !== undefined) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: [],
                                  message: React.createElement(React.Fragment, undefined, "Auto publish for the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$9)), " succeeded"),
                                  icon: React.createElement(IntegrationIcon.make, {
                                        config: integration$9.config,
                                        size: 22
                                      }),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "integration",
                                        VAL: [
                                          integration$9.id,
                                          undefined
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    } else {
                      return null;
                    }
                  }
                  var integration$10 = ModelUtils.getIntegrationById(integrationId$1, modelBeforeAction);
                  if (integration$10 === undefined) {
                    return null;
                  }
                  var match$307 = integration$10.config;
                  var exit$5 = 0;
                  if (match$307 !== undefined) {
                    if (match$307.TAG === /* Taxonomy */1) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: [],
                                  message: React.createElement(React.Fragment, undefined, "Auto publish for the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$10)), " completed with errors"),
                                  icon: React.createElement(IntegrationIcon.make, {
                                        config: integration$10.config,
                                        size: 22
                                      }),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "integration",
                                        VAL: [
                                          integration$10.id,
                                          undefined
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    }
                    exit$5 = 2;
                  } else {
                    exit$5 = 2;
                  }
                  if (exit$5 === 2) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: [],
                                message: React.createElement(React.Fragment, undefined, "Auto publish for the integration ", React.createElement("b", undefined, AvoConfig.getIntegrationName(integration$10)), " failed"),
                                icon: React.createElement(IntegrationIcon.make, {
                                      config: integration$10.config,
                                      size: 22
                                    }),
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "integration",
                                      VAL: [
                                        integration$10.id,
                                        undefined
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  }
                  
                } else {
                  if (variant === "RemoveEventTrigger") {
                    var match$308 = ModelUtils.getEventByIdWithArchive(match.VAL[0], modelBeforeAction);
                    if (match$308 !== undefined) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "removed a trigger in ", React.createElement("b", undefined, match$308.name)),
                                  openBranches: openBranches
                                });
                    } else {
                      return null;
                    }
                  }
                  if (variant === "DeprecatedAddPropertyValidationMinOrMax") {
                    var match$309 = match.VAL;
                    var match$310 = ModelUtils.getPropertyByIdWithArchive(match$309[0], modelBeforeAction);
                    if (match$310 === undefined) {
                      return null;
                    }
                    if (match$310.TAG === /* PropertyRef */0) {
                      return null;
                    }
                    var label$1 = TrackingPlanModel.constraint_ToJs(match$309[1]).toLowerCase();
                    var tmp$73 = {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "added " + label$1 + " constraint to the property ", React.createElement("b", undefined, match$310._0.name)),
                      openBranches: openBranches
                    };
                    if (linkToPropertyFromContext !== undefined) {
                      tmp$73.path = Caml_option.valFromOption(linkToPropertyFromContext);
                    }
                    return React.createElement(ActivityItem$ActivityItemAction, tmp$73);
                  }
                  if (variant === "CreateServiceAccount") {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "created the service account ", React.createElement("b", undefined, match.VAL)),
                                openBranches: openBranches,
                                onClick: (function (param) {
                                    return Curry._1(globalSend, {
                                                TAG: /* OpenModal */4,
                                                _0: {
                                                  NAME: "Settings",
                                                  VAL: /* ServiceAccounts */3
                                                }
                                              });
                                  })
                              });
                  }
                  if (variant === "RemoveGoal") {
                    var goal$5 = ModelUtils.getGoalByIdWithArchive(match.VAL, modelBeforeAction);
                    if (goal$5 !== undefined) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "removed the category ", React.createElement("b", undefined, goal$5.name)),
                                  path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                                  openBranches: openBranches
                                });
                    } else {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: "removed a category",
                                  path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                                  openBranches: openBranches
                                });
                    }
                  }
                  if (variant === "RemoveTriggerSource") {
                    var match$311 = match.VAL;
                    var eventId$23 = match$311[0];
                    var match$312 = ModelUtils.getEventByIdWithArchive(eventId$23, modelBeforeAction);
                    var match$313 = ModelUtils.getSourceById(match$311[2], modelBeforeAction);
                    if (match$312 !== undefined && match$313 !== undefined) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "removed ", React.createElement("b", undefined, AvoConfig.getSourceName(match$313)), " as a source from a trigger in ", React.createElement("b", undefined, match$312.name)),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "event",
                                        VAL: [
                                          eventId$23,
                                          undefined,
                                          match$311[1],
                                          false
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    } else {
                      return null;
                    }
                  }
                  if (variant === "UpdatePropertyAbsence") {
                    var match$314 = match.VAL;
                    var maybeEventSourceAbsence = match$314[2];
                    var propertyAbsenceUpdateType = match$314[1];
                    var match$315 = ModelUtils.getPropertyByIdWithArchive(match$314[0], modelBeforeAction);
                    if (match$315 === undefined) {
                      return null;
                    }
                    if (match$315.TAG === /* PropertyRef */0) {
                      return null;
                    }
                    var tmp$74;
                    if (typeof propertyAbsenceUpdateType === "number") {
                      tmp$74 = React.createElement(React.Fragment, undefined, " on ", React.createElement("b", undefined, "all events"));
                    } else {
                      switch (propertyAbsenceUpdateType.TAG | 0) {
                        case /* Event */0 :
                            var $$event$13 = ModelUtils.getEventByIdWithArchive(propertyAbsenceUpdateType._0, modelBeforeAction);
                            tmp$74 = $$event$13 !== undefined ? React.createElement(React.Fragment, undefined, " on the ", React.createElement("b", undefined, $$event$13.name), " event") : " on one event";
                            break;
                        case /* Source */1 :
                            var source$11 = ModelUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._0, modelBeforeAction);
                            tmp$74 = source$11 !== undefined ? React.createElement(React.Fragment, undefined, " from the ", React.createElement("b", undefined, AvoConfig.getSourceName(source$11)), " source") : " from one source";
                            break;
                        case /* EventSource */2 :
                            var match$316 = ModelUtils.getEventByIdWithArchive(propertyAbsenceUpdateType._0, modelBeforeAction);
                            var match$317 = ModelUtils.getSourceByIdWithArchive(propertyAbsenceUpdateType._1, modelBeforeAction);
                            tmp$74 = match$316 !== undefined && match$317 !== undefined ? React.createElement(React.Fragment, undefined, " on the ", React.createElement("b", undefined, match$316.name), " event when sent from the ", React.createElement("b", undefined, AvoConfig.getSourceName(match$317)), " source") : " on one event and source";
                            break;
                        
                      }
                    }
                    var tmp$75 = {
                      action: action,
                      users: users$1,
                      message: React.createElement(React.Fragment, undefined, "set the property ", React.createElement("b", undefined, match$315._0.name), " to be ", React.createElement("b", undefined, maybeEventSourceAbsence !== undefined ? (
                                  maybeEventSourceAbsence ? "sometimes sent" : "never sent"
                                ) : "always sent"), tmp$74),
                      openBranches: openBranches
                    };
                    if (linkToPropertyFromContext !== undefined) {
                      tmp$75.path = Caml_option.valFromOption(linkToPropertyFromContext);
                    }
                    return React.createElement(ActivityItem$ActivityItemAction, tmp$75);
                  }
                  if (variant === "Unarchive") {
                    var match$318 = match.VAL;
                    var variant$10 = match$318.NAME;
                    if (variant$10 === "Destination") {
                      var destinationId$8 = match$318.VAL;
                      var destination$10 = ModelUtils.getDestinationByIdWithArchive(destinationId$8, modelBeforeAction);
                      if (destination$10 !== undefined) {
                        return React.createElement(ActivityItem$ActivityItemAction, {
                                    action: action,
                                    users: users$1,
                                    message: React.createElement(React.Fragment, undefined, "unarchived the destination ", React.createElement("b", undefined, ModelUtils.getDestinationName(destination$10))),
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "destination",
                                          VAL: destinationId$8
                                        }),
                                    openBranches: openBranches
                                  });
                      } else {
                        return null;
                      }
                    }
                    if (variant$10 === "Integration") {
                      return null;
                    }
                    if (variant$10 === "GroupType") {
                      var groupTypeName$2 = ModelUtils.getGroupTypeNameWithArchive(match$318.VAL, modelBeforeAction);
                      if (groupTypeName$2 !== undefined) {
                        return React.createElement(ActivityItem$ActivityItemAction, {
                                    action: action,
                                    users: users$1,
                                    message: React.createElement(React.Fragment, undefined, "unarchived the group type ", React.createElement("b", undefined, groupTypeName$2)),
                                    openBranches: openBranches
                                  });
                      } else {
                        return null;
                      }
                    }
                    if (variant$10 === "Property") {
                      var propertyId$9 = match$318.VAL;
                      var match$319 = ModelUtils.getPropertyByIdWithArchive(propertyId$9, modelBeforeAction);
                      if (match$319 !== undefined && match$319.TAG !== /* PropertyRef */0) {
                        return React.createElement(ActivityItem$ActivityItemAction, {
                                    action: action,
                                    users: users$1,
                                    message: React.createElement(React.Fragment, undefined, "unarchived the property ", React.createElement("b", undefined, match$319._0.name)),
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "property",
                                          VAL: [
                                            propertyId$9,
                                            undefined
                                          ]
                                        }),
                                    openBranches: openBranches
                                  });
                      } else {
                        return null;
                      }
                    }
                    if (variant$10 === "Event") {
                      var eventId$24 = match$318.VAL;
                      var $$event$14 = ModelUtils.getEventByIdWithArchive(eventId$24, modelBeforeAction);
                      if ($$event$14 !== undefined) {
                        return React.createElement(ActivityItem$ActivityItemAction, {
                                    action: action,
                                    users: users$1,
                                    message: React.createElement(React.Fragment, undefined, "unarchived the event ", React.createElement("b", undefined, $$event$14.name)),
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "event",
                                          VAL: [
                                            eventId$24,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        }),
                                    openBranches: openBranches
                                  });
                      } else {
                        return null;
                      }
                    }
                    if (variant$10 === "PropertyGroup") {
                      var groupId$1 = match$318.VAL;
                      var group$3 = ModelUtils.getPropertyGroupByIdWithArchive(groupId$1, modelBeforeAction);
                      if (group$3 !== undefined) {
                        return React.createElement(ActivityItem$ActivityItemAction, {
                                    action: action,
                                    users: users$1,
                                    message: React.createElement(React.Fragment, undefined, "unarchived the property bundle ", React.createElement("b", undefined, group$3.name)),
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "propertyGroup",
                                          VAL: [
                                            groupId$1,
                                            undefined
                                          ]
                                        }),
                                    openBranches: openBranches
                                  });
                      } else {
                        return null;
                      }
                    }
                    if (variant$10 === "Goal") {
                      var goalId$5 = match$318.VAL;
                      var goal$6 = ModelUtils.getGoalByIdWithArchive(goalId$5, modelBeforeAction);
                      if (goal$6 !== undefined) {
                        return React.createElement(ActivityItem$ActivityItemAction, {
                                    action: action,
                                    users: users$1,
                                    message: React.createElement(React.Fragment, undefined, "unarchived the category ", React.createElement("b", undefined, goal$6.name)),
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "category",
                                          VAL: [
                                            goalId$5,
                                            undefined
                                          ]
                                        }),
                                    openBranches: openBranches
                                  });
                      } else {
                        return null;
                      }
                    }
                    if (variant$10 === "EventVariant") {
                      var variantIdentifier$21 = match$318.VAL;
                      var variant$11 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$21);
                      if (variant$11 === undefined) {
                        return null;
                      }
                      var $$event$15 = ModelUtils.getEventByIdWithArchive(variantIdentifier$21.baseEventId, modelBeforeAction);
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "unarchived the event variant ", React.createElement("b", undefined, variant$11.nameSuffix), $$event$15 !== undefined ? React.createElement(React.Fragment, undefined, " on the event ", React.createElement("b", undefined, $$event$15.name)) : null),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "eventVariant",
                                        VAL: [
                                          variantIdentifier$21,
                                          undefined
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    }
                    if (variant$10 === "Metric") {
                      var metricId$4 = match$318.VAL;
                      var metric$6 = ModelUtils.getMetricByIdWithArchive(metricId$4, modelBeforeAction);
                      if (metric$6 !== undefined) {
                        return React.createElement(ActivityItem$ActivityItemAction, {
                                    action: action,
                                    users: users$1,
                                    message: React.createElement(React.Fragment, undefined, "unarchived the metric ", React.createElement("b", undefined, metric$6.name)),
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "metric",
                                          VAL: [
                                            metricId$4,
                                            undefined,
                                            false
                                          ]
                                        }),
                                    openBranches: openBranches
                                  });
                      } else {
                        return null;
                      }
                    }
                    var sourceId$15 = match$318.VAL;
                    var source$12 = ModelUtils.getSourceByIdWithArchive(sourceId$15, modelBeforeAction);
                    if (source$12 !== undefined) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "unarchived the source ", React.createElement("b", undefined, AvoConfig.getSourceName(source$12))),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "source",
                                        VAL: [
                                          sourceId$15,
                                          "overview"
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    } else {
                      return null;
                    }
                  }
                  if (variant === "CompletedImport") {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: "successfully imported a tracking plan",
                                openBranches: openBranches
                              });
                  }
                  if (variant === "ClearEventVariantSpecificPropertyValueOverride") {
                    var match$320 = match.VAL;
                    var literal$8 = match$320[2];
                    var variantIdentifier$22 = match$320[0];
                    var match$321 = ModelUtils.getEventByIdWithArchive(variantIdentifier$22.baseEventId, modelBeforeAction);
                    var match$322 = ModelUtils.getPropertyByIdWithArchive(match$320[1], modelBeforeAction);
                    var match$323 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$22);
                    if (match$321 !== undefined && match$322 !== undefined && !(match$322.TAG === /* PropertyRef */0 || !(match$323 !== undefined && typeof literal$8 === "object" && literal$8.NAME === "StringLit"))) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "cleared the override for the allowed value \"" + literal$8.VAL + "\" from the property ", React.createElement("b", undefined, match$322._0.name), " on ", React.createElement("b", undefined, match$321.name + " - " + match$323.nameSuffix)),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "eventVariant",
                                        VAL: [
                                          variantIdentifier$22,
                                          undefined
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    } else {
                      return null;
                    }
                  }
                  if (variant === "UpdatePropertyPinnedValueOnEventVariant") {
                    var match$324 = match.VAL;
                    var variantIdentifier$23 = match$324[0];
                    var match$325 = ModelUtils.getEventByIdWithArchive(variantIdentifier$23.baseEventId, modelBeforeAction);
                    var match$326 = ModelUtils.getEventVariantByIdWithArchive(modelBeforeAction, variantIdentifier$23);
                    var match$327 = ModelUtils.getPropertyByIdWithArchive(match$324[1], modelBeforeAction);
                    if (match$325 !== undefined && match$326 !== undefined && match$327 !== undefined && match$327.TAG !== /* PropertyRef */0) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "pinned the ", React.createElement("b", undefined, match$327._0.name), " property on the event variant ", React.createElement("b", undefined, match$325.name + " - " + match$326.nameSuffix), " to " + PropertyUtils.printPinnedValue(match$324[2])),
                                  path: Router.Link.addDrawerItem(undefined, {
                                        NAME: "eventVariant",
                                        VAL: [
                                          variantIdentifier$23,
                                          undefined
                                        ]
                                      }),
                                  openBranches: openBranches
                                });
                    } else {
                      return null;
                    }
                  }
                  if (variant === "RemoveSourceConfig") {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: "removed config from source",
                                path: Router.Link.addDrawerItem(undefined, {
                                      NAME: "source",
                                      VAL: [
                                        match.VAL[0],
                                        "overview"
                                      ]
                                    }),
                                openBranches: openBranches
                              });
                  }
                  if (variant === "RemoveRule") {
                    var item$2 = match.VAL[1];
                    if (typeof item$2 !== "object") {
                      return null;
                    }
                    if (item$2.NAME !== "Property") {
                      return null;
                    }
                    var match$328 = ModelUtils.getPropertyByIdWithArchive(item$2.VAL, modelBeforeAction);
                    if (match$328 !== undefined && match$328.TAG !== /* PropertyRef */0) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "removed a name mapping from the property ", React.createElement("b", undefined, match$328._0.name)),
                                  openBranches: openBranches
                                });
                    } else {
                      return null;
                    }
                  }
                  if (variant === "UpdateTrackingPlanAuditConfig") {
                    var match$329 = match.VAL;
                    var configItem = match$329[0];
                    if (configItem === "ConsistentEventNameCasing" || configItem === "ConsistentPropertyNameCasing") {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "updated the tracking plan audit rule ", React.createElement("b", undefined, ValidationConfigV2.ConfigItem.toRuleName(configItem))),
                                  openBranches: openBranches
                                });
                    }
                    var pointOfBlock = match$329[2];
                    var ruleSeverity = match$329[1];
                    var tmp$76;
                    var exit$6 = 0;
                    if (ruleSeverity !== undefined || pointOfBlock !== undefined) {
                      exit$6 = 2;
                    } else {
                      tmp$76 = "";
                    }
                    if (exit$6 === 2) {
                      tmp$76 = pointOfBlock !== undefined ? (
                          pointOfBlock === "beforeRequestForReview" ? "Request for review" : "Branch merge"
                        ) : (
                          ruleSeverity === "enforce" ? "Enforced" : (
                              ruleSeverity === "warning" ? "Included in Audit" : "Disabled"
                            )
                        );
                    }
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "set the tracking plan audit rule ", React.createElement("b", undefined, ValidationConfigV2.ConfigItem.toRuleName(configItem)), " to ", React.createElement("b", undefined, tmp$76)),
                                openBranches: openBranches
                              });
                  }
                  if (variant === "AddPropertyGroupToEvent") {
                    var match$330 = match.VAL;
                    var match$331 = ModelUtils.getPropertyGroupByIdWithArchive(match$330[1], modelBeforeAction);
                    var match$332 = ModelUtils.getEventByIdWithArchive(match$330[0], modelBeforeAction);
                    if (match$331 !== undefined && match$332 !== undefined) {
                      return React.createElement(ActivityItem$ActivityItemAction, {
                                  action: action,
                                  users: users$1,
                                  message: React.createElement(React.Fragment, undefined, "added the property bundle ", React.createElement("b", undefined, match$331.name), " to the " + itemName + " ", React.createElement("b", undefined, match$332.name)),
                                  openBranches: openBranches
                                });
                    } else {
                      return null;
                    }
                  }
                  var metric$7 = ModelUtils.getMetricByIdWithArchive(match.VAL, modelBeforeAction);
                  if (metric$7 !== undefined) {
                    return React.createElement(ActivityItem$ActivityItemAction, {
                                action: action,
                                users: users$1,
                                message: React.createElement(React.Fragment, undefined, "removed the metric ", React.createElement("b", undefined, metric$7.name)),
                                path: Router.Link.getSchemaRouteLink(undefined, "metrics"),
                                openBranches: openBranches
                              });
                  } else {
                    return null;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  var $$event$16 = ModelUtils.getEventByIdWithArchive(eventId, modelBeforeAction);
  if ($$event$16 !== undefined) {
    return React.createElement(ActivityItem$ActivityItemAction, {
                action: action,
                users: users$1,
                message: React.createElement(React.Fragment, undefined, "set unique name of the " + itemName + " ", React.createElement("b", undefined, $$event$16.name), " to ", React.createElement("b", undefined, uniqueName)),
                path: Router.Link.addDrawerItem(undefined, {
                      NAME: "event",
                      VAL: [
                        eventId,
                        undefined,
                        undefined,
                        false
                      ]
                    }),
                openBranches: openBranches
              });
  } else {
    return null;
  }
}

var make = ActivityItem;

export {
  DomainActivityItem ,
  ActivityItemAction ,
  ActivityItemComment ,
  InspectorItemComment ,
  getActionContext ,
  unarchiveItemOptionBase ,
  make ,
  
}
/* actionContainerStyles Not a pure module */
