// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoEngagement__Types from "./AvoEngagement__Types.mjs";

var goForward = AvoEngagement__Types.Block.Transition.goForward;

var goBack = AvoEngagement__Types.Block.Transition.goBack;

var next = AvoEngagement__Types.Block.Transition.next;

var previous = AvoEngagement__Types.Block.Transition.previous;

var complete = AvoEngagement__Types.Block.Transition.complete;

var step1_transitions = [Curry._1(next, {
        NAME: "block",
        VAL: "step-2"
      })];

var step1_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Filtering issues"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [{
              type_: "paragraph",
              children: [{
                  text: "Use one or more filters to narrow down your issues to the ones that are important to you and your team.",
                  italic: false,
                  bold: false
                }]
            }]
        }
      }
    ]);

var step1 = {
  id: "step-1",
  transitions: step1_transitions,
  fields: step1_fields
};

var step1a_transitions = [
  Curry._1(next, {
        NAME: "block",
        VAL: "step-2"
      }),
  Curry._2(goForward, "with-filters", {
        NAME: "block",
        VAL: "step-2"
      })
];

var step1a_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Filtering issues"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [{
              type_: "paragraph",
              children: [{
                  text: "It appears your workspace doesn’t have any issues right now, but you can use one or more filters to narrow them down to find the ones that are important to you and your team",
                  italic: false,
                  bold: false
                }]
            }]
        }
      }
    ]);

var step1a = {
  id: "step-1a",
  transitions: step1a_transitions,
  fields: step1a_fields
};

var step2_transitions = [
  Curry._1(complete, undefined),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-3"
      }),
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-1"
      }),
  Curry._2(goBack, "previous-no-issues", {
        NAME: "block",
        VAL: "step-1a"
      })
];

var step2_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Set up alerts for your saved view"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [
            {
              type_: "paragraph",
              children: [{
                  text: "Once you've saved a view, you can set up alerts for your saved view.",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "That way, you and your team never miss a new issue that matches the filter parameters",
                  italic: false,
                  bold: false
                }]
            }
          ]
        }
      }
    ]);

var step2 = {
  id: "step-2",
  transitions: step2_transitions,
  fields: step2_fields
};

var step3_transitions = [
  Curry._1(complete, undefined),
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-2"
      })
];

var step3_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Save your filtered view"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [{
              type_: "paragraph",
              children: [{
                  text: "You can also just save a filtered view without setting up alerts. That way you can easily revisit specific issues whenever you want.",
                  italic: false,
                  bold: false
                }]
            }]
        }
      }
    ]);

var step3 = {
  id: "step-3",
  transitions: step3_transitions,
  fields: step3_fields
};

var content_blocks = Curry._1(AvoEngagement__Types.Blocks.fromArray, [
      step1,
      step1a,
      step2,
      step3
    ]);

var content = {
  id: "set-up-alerts",
  version: 4,
  rootId: "step-1",
  blocks: content_blocks
};

export {
  goForward ,
  goBack ,
  next ,
  previous ,
  complete ,
  step1 ,
  step1a ,
  step2 ,
  step3 ,
  content ,
  
}
/* step1 Not a pure module */
