// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

function cleanNonInsert(delta) {
  var ops = Js_dict.get(delta, "ops");
  if (ops === undefined) {
    return delta;
  }
  var newOps = Belt_Array.keep(Caml_option.valFromOption(ops), (function (op) {
          return Belt_Option.isSome(Belt_Array.getBy(Object.keys(op), (function (key) {
                            return key === "insert";
                          })));
        }));
  delta["ops"] = newOps;
  return delta;
}

function diff(t1, t2) {
  return cleanNonInsert(t1).diff(cleanNonInsert(t2));
}

function emptyOps(param) {
  return [];
}

function toStr(delta) {
  return Belt_Array.map(delta.filter(function (op) {
                    return Belt_Option.mapWithDefault(Json_decode.optional((function (param) {
                                      return Json_decode.field("insert", Json_decode.string, param);
                                    }), op), false, (function (param) {
                                  return true;
                                }));
                  }), (function (op) {
                  return Json_decode.field("insert", Json_decode.string, op);
                })).join("");
}

export {
  cleanNonInsert ,
  diff ,
  emptyOps ,
  toStr ,
  
}
/* No side effect */
