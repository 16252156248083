// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Semver from "../../../shared/bindings/semver.mjs";
import * as Semver$1 from "semver";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

function compareDotAsNumbers(version1, version2) {
  if (version1 > version2) {
    return /* Greater */0;
  } else if (version1 === version2) {
    return /* Equal */1;
  } else {
    return /* Smaller */2;
  }
}

function compareDot(version1, version2) {
  var match = Pervasives.int_of_string_opt(version1);
  var match$1 = Pervasives.int_of_string_opt(version2);
  if (match !== undefined && match$1 !== undefined) {
    return compareDotAsNumbers(match, match$1);
  }
  if (version1 > version2) {
    return /* Greater */0;
  } else if (version1 === version2) {
    return /* Equal */1;
  } else {
    return /* Smaller */2;
  }
}

function compareSemanticArrays(array1, array2) {
  var length1 = array1.length;
  var length2 = array2.length;
  if (length1 > length2) {
    return /* Greater */0;
  } else if (length1 === length2) {
    return /* Equal */1;
  } else {
    return /* Smaller */2;
  }
}

function isSemantic(version) {
  return version.indexOf(".") > -1;
}

function addPatchIfMissing(version) {
  return Belt_Option.map(version, (function (version) {
                var versionDots = version.split(".").length;
                if (versionDots !== 1 && versionDots === 2) {
                  return version + ".0";
                } else {
                  return version;
                }
              }));
}

function extractTrailingNumber(version) {
  var regex = new RegExp("\\D(\\d+)$");
  var result = regex.exec(version);
  if (result !== null) {
    return Belt_Option.flatMap(Belt_Array.get(result, 0), (function (capture) {
                  return Belt_Option.flatMap((capture == null) ? undefined : Caml_option.some(capture), Pervasives.int_of_string_opt);
                }));
  }
  
}

function compareTrailingNumbers(appVersionTrailingNumber, compareAppVersionTrailingNumber) {
  if (appVersionTrailingNumber !== undefined && compareAppVersionTrailingNumber !== undefined) {
    if (appVersionTrailingNumber > compareAppVersionTrailingNumber) {
      return /* Greater */0;
    } else if (appVersionTrailingNumber === compareAppVersionTrailingNumber) {
      return /* Equal */1;
    } else {
      return /* Smaller */2;
    }
  } else {
    return /* Equal */1;
  }
}

function compareToAppVersion(appVersion, compareAppVersion) {
  var appVersionWithPatch = addPatchIfMissing(appVersion);
  var compareVersionWithPatch = addPatchIfMissing(compareAppVersion);
  var appVersionSemantic = Belt_Option.flatMap(appVersionWithPatch, Semver.valid);
  var compareAppVersionSemantic = Belt_Option.flatMap(compareVersionWithPatch, Semver.valid);
  var appVersionTrailingNumber = Belt_Option.flatMap(appVersionWithPatch, extractTrailingNumber);
  var compareAppVersionTrailingNumber = Belt_Option.flatMap(compareVersionWithPatch, extractTrailingNumber);
  if (appVersionSemantic !== undefined) {
    if (compareAppVersionSemantic !== undefined && !Semver$1.gt(appVersionSemantic, compareAppVersionSemantic)) {
      if (Semver$1.eq(appVersionSemantic, compareAppVersionSemantic)) {
        return compareTrailingNumbers(appVersionTrailingNumber, compareAppVersionTrailingNumber);
      } else {
        return /* Smaller */2;
      }
    } else {
      return /* Greater */0;
    }
  } else if (compareAppVersionSemantic !== undefined) {
    return /* Smaller */2;
  } else if (appVersion !== undefined) {
    if (compareAppVersion !== undefined) {
      return compareDot(appVersion, compareAppVersion);
    } else {
      return /* Greater */0;
    }
  } else if (compareAppVersion !== undefined) {
    return /* Smaller */2;
  } else {
    return /* Equal */1;
  }
}

function getTopThreeAppVersions(appVersions) {
  return Belt_Array.slice(Belt_SortArray.stableSortBy(appVersions, (function (appVersion1, appVersion2) {
                    var compareResult = compareToAppVersion(appVersion1, appVersion2);
                    switch (compareResult) {
                      case /* Greater */0 :
                          return 1;
                      case /* Equal */1 :
                          return 0;
                      case /* Smaller */2 :
                          return -1;
                      
                    }
                  })), 0, 3);
}

export {
  compareDotAsNumbers ,
  compareDot ,
  compareSemanticArrays ,
  isSemantic ,
  addPatchIfMissing ,
  extractTrailingNumber ,
  compareTrailingNumbers ,
  compareToAppVersion ,
  getTopThreeAppVersions ,
  
}
/* Semver Not a pure module */
