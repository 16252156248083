// Generated by ReScript, PLEASE EDIT WITH CARE

import Case from "case";
import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

var _map = {"Sentence":"sentence","Title":"title","Pascal":"pascal","Header":"header","Capital":"capital","Upper":"upper","Kebab":"kebab","Camel":"camel","Constant":"constant","Snake":"snake","Lower":"lower","None":"None"};

var _revMap = {"sentence":"Sentence","title":"Title","pascal":"Pascal","header":"Header","capital":"Capital","upper":"Upper","kebab":"Kebab","camel":"Camel","constant":"Constant","snake":"Snake","lower":"Lower","None":"None"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

function sentence(str) {
  return Case.sentence(str);
}

function title(str) {
  return Case.title(str);
}

function pascal(str) {
  return Case.pascal(str);
}

function header(str) {
  return Case.header(str);
}

function capital(str) {
  return Case.capital(str);
}

function upper(str) {
  return Case.upper(str);
}

function kebab(str) {
  return Case.kebab(str);
}

function constant(str) {
  return Case.constant(str);
}

function snake(str) {
  return Case.snake(str);
}

function lower(str) {
  return Case.lower(str);
}

function camel(word) {
  var str = Case.sentence(word);
  return Case.camel(str);
}

function titleOverCapital(string, $$case) {
  if ($$case === "Capital" && Case.title(string) === string) {
    return "Title";
  } else {
    return $$case;
  }
}

function internalGetCase(string) {
  return titleOverCapital(string, Belt_Option.getWithDefault(tFromJs(Belt_Option.getWithDefault(Caml_option.nullable_to_opt(Case.of(string)), "")), "None"));
}

var commonStringSeparators = [
  " : ",
  ":",
  ".",
  " . "
];

function of_(string) {
  var $$case = internalGetCase(string);
  if ($$case === "None" || $$case === "Lower") {
    return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMapU(commonStringSeparators, (function (separator) {
                          var subStrings = string.split(separator);
                          var subStringsCase = Belt_Array.mapU(subStrings, (function (subString) {
                                  return tToJs(internalGetCase(subString));
                                }));
                          var caseVariance = Belt_Array.keepMap(Belt_SetString.toArray(Belt_SetString.fromArray(subStringsCase)), tFromJs);
                          var exit = 0;
                          var len = caseVariance.length;
                          if (len >= 3) {
                            return ;
                          }
                          switch (len) {
                            case 0 :
                                return ;
                            case 1 :
                                var $$case = caseVariance[0];
                                if ($$case === "None") {
                                  return ;
                                } else {
                                  return $$case;
                                }
                            case 2 :
                                var match = caseVariance[0];
                                if (match === "Camel") {
                                  var match$1 = caseVariance[1];
                                  if (match$1 !== "Lower") {
                                    return ;
                                  }
                                  exit = 1;
                                } else if (match === "Lower") {
                                  var match$2 = caseVariance[1];
                                  if (match$2 === "Camel") {
                                    exit = 1;
                                  } else {
                                    if (match$2 !== "Snake") {
                                      return ;
                                    }
                                    exit = 2;
                                  }
                                } else {
                                  if (match !== "Snake") {
                                    return ;
                                  }
                                  var match$3 = caseVariance[1];
                                  if (match$3 !== "Lower") {
                                    return ;
                                  }
                                  exit = 2;
                                }
                                break;
                            
                          }
                          switch (exit) {
                            case 1 :
                                if (Belt_Array.everyU(subStrings, (function (subString) {
                                          return camel(subString) === subString;
                                        }))) {
                                  return "Camel";
                                } else {
                                  return ;
                                }
                            case 2 :
                                if (Belt_Array.everyU(subStrings, (function (subString) {
                                          return Case.snake(subString) === subString;
                                        }))) {
                                  return "Snake";
                                } else {
                                  return ;
                                }
                            
                          }
                        })), 0), "None");
  } else {
    return $$case;
  }
}

function to_($$case, string) {
  if ($$case === "Capital") {
    return Case.capital(string);
  } else if ($$case === "Kebab") {
    return Case.kebab(string);
  } else if ($$case === "Sentence") {
    return Case.sentence(string);
  } else if ($$case === "Constant") {
    return Case.constant(string);
  } else if ($$case === "Lower") {
    return Case.lower(string);
  } else if ($$case === "Snake") {
    return Case.snake(string);
  } else if ($$case === "Pascal") {
    return Case.pascal(string);
  } else if ($$case === "Title") {
    return Case.title(string);
  } else if ($$case === "Header") {
    return Case.header(string);
  } else if ($$case === "None") {
    return string;
  } else if ($$case === "Upper") {
    return Case.upper(string);
  } else {
    return camel(string);
  }
}

function toWithSeperators($$case, string) {
  return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMapU(commonStringSeparators, (function (separator) {
                        if (!string.includes(separator)) {
                          return ;
                        }
                        var subStrings = string.split(separator);
                        var convertedStrings = Belt_Array.map(subStrings, (function (subString) {
                                return to_($$case, subString);
                              }));
                        return Belt_Array.joinWith(convertedStrings, separator, (function (i) {
                                      return i;
                                    }));
                      })), 0), to_($$case, string));
}

function getCasingWithWorkspaceCase(word, workspaceExpectedCase) {
  var $$case = of_(word);
  if ($$case === "Lower" && workspaceExpectedCase !== "None" && word === toWithSeperators(workspaceExpectedCase, word)) {
    return workspaceExpectedCase;
  } else {
    return $$case;
  }
}

function toAnalytics($$case) {
  if ($$case === "Capital") {
    return "Capital";
  } else if ($$case === "Kebab") {
    return "Kebab";
  } else if ($$case === "Sentence") {
    return "Sentence";
  } else if ($$case === "Constant") {
    return "Constant";
  } else if ($$case === "Lower") {
    return "Lower";
  } else if ($$case === "Snake") {
    return "Snake";
  } else if ($$case === "Pascal") {
    return "Pascal";
  } else if ($$case === "Title") {
    return "Title";
  } else if ($$case === "Header") {
    return "Header";
  } else if ($$case === "None") {
    return "None";
  } else if ($$case === "Upper") {
    return "Upper";
  } else {
    return "Camel";
  }
}

function toIssueReportEventCasing($$case) {
  if ($$case === "Capital") {
    return "CapitalCase";
  } else if ($$case === "Kebab") {
    return "KebabCase";
  } else if ($$case === "Sentence") {
    return "SentenceCase";
  } else if ($$case === "Constant") {
    return "ConstantCase";
  } else if ($$case === "Lower") {
    return "LowerCase";
  } else if ($$case === "Snake") {
    return "SnakeCase";
  } else if ($$case === "Pascal") {
    return "PascalCase";
  } else if ($$case === "Title") {
    return "TitleCase";
  } else if ($$case === "Header") {
    return "HeaderCase";
  } else if ($$case === "None") {
    return "Inherited";
  } else if ($$case === "Upper") {
    return "UpperCase";
  } else {
    return "CamelCase";
  }
}

function getLabel($$case) {
  return to_("Title", tToJs($$case));
}

var casingOptions = [
  "None",
  "Sentence",
  "Title",
  "Pascal",
  "Header",
  "Capital",
  "Upper",
  "Kebab",
  "Camel",
  "Constant",
  "Snake",
  "Lower"
];

function getCaseVariance(string) {
  return Belt_Array.keepMapU(casingOptions, (function ($$case) {
                if ($$case !== "None" && to_($$case, string) === string) {
                  return $$case;
                }
                
              }));
}

function toUILabel($$case) {
  if ($$case === "Capital") {
    return "Capital Case";
  } else if ($$case === "Kebab") {
    return "kebab-case";
  } else if ($$case === "Sentence") {
    return "Sentence case";
  } else if ($$case === "Constant") {
    return "CONSTANT_CASE";
  } else if ($$case === "Lower") {
    return "lower case";
  } else if ($$case === "Snake") {
    return "snake_case";
  } else if ($$case === "Pascal") {
    return "PascalCase";
  } else if ($$case === "Title") {
    return "Title Case";
  } else if ($$case === "Header") {
    return "Header-Case";
  } else if ($$case === "None") {
    return "Inherit";
  } else if ($$case === "Upper") {
    return "UPPER CASE";
  } else {
    return "camelCase";
  }
}

function getCaseSeparator($$case) {
  if ($$case === "Upper" || $$case === "Title" || $$case === "Lower" || $$case === "Sentence" || $$case === "Capital") {
    return " ";
  } else if ($$case === "Header" || $$case === "Kebab") {
    return "-";
  } else if ($$case === "Snake" || $$case === "Constant") {
    return "_";
  } else {
    return ;
  }
}

function cmp(a, b) {
  return $$String.compare(tToJs(a), tToJs(b));
}

export {
  tToJs ,
  tFromJs ,
  sentence ,
  title ,
  pascal ,
  header ,
  capital ,
  upper ,
  kebab ,
  constant ,
  snake ,
  lower ,
  camel ,
  titleOverCapital ,
  internalGetCase ,
  commonStringSeparators ,
  of_ ,
  to_ ,
  toWithSeperators ,
  getCasingWithWorkspaceCase ,
  toAnalytics ,
  toIssueReportEventCasing ,
  getLabel ,
  casingOptions ,
  getCaseVariance ,
  toUILabel ,
  getCaseSeparator ,
  cmp ,
  
}
/* case Not a pure module */
