// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";

function EventBlot(Props) {
  var eventId = Props.eventId;
  var schemaId = Props.schemaId;
  var eventName = Props.eventName;
  return React.createElement("a", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(4), Css.px(2)),
                    tl: {
                      hd: Css.color(Styles.Color.deepBlue),
                      tl: {
                        hd: Css.hover({
                              hd: Css.textDecoration("underline"),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.textDecoration("none"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              href: "/schemas/" + schemaId + "/events/" + eventId,
              target: "_blank"
            }, eventName);
}

var make = EventBlot;

var $$default = EventBlot;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* Css Not a pure module */
