// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Issues from "../../../shared/models/Issues.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as DateFns from "../DateFns.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Zustand from "../Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateFns$1 from "date-fns";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as AppVersionsStore from "../AppVersionsStore.mjs";
import * as InspectorViewApi from "./InspectorViewApi.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as ExponentialBackoff from "./ExponentialBackoff.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as InspectorIssueViewModel from "./InspectorIssueViewModel.mjs";
import * as InspectorIssuesFilterHooks from "./InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../../shared/utils/InspectorIssuesFilterUtils.mjs";

var FetchError = /* @__PURE__ */Caml_exceptions.create("InspectorIssuesStore.FetchError");

var cmp = Caml_obj.caml_compare;

var RequestComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function cmp$1(param, param$1) {
  var n = Caml.caml_string_compare(param.schemaId, param$1.schemaId);
  if (n !== 0) {
    return n;
  } else {
    return Caml.caml_int_compare(param.status, param$1.status);
  }
}

var RetryComparator = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

function empty(param) {
  return Belt_Map.make(RequestComparator);
}

var $$Map = {
  Int: Belt_Map.Int,
  $$String: Belt_Map.$$String,
  Dict: Belt_Map.Dict,
  make: Belt_Map.make,
  isEmpty: Belt_Map.isEmpty,
  has: Belt_Map.has,
  cmpU: Belt_Map.cmpU,
  cmp: Belt_Map.cmp,
  eqU: Belt_Map.eqU,
  eq: Belt_Map.eq,
  findFirstByU: Belt_Map.findFirstByU,
  findFirstBy: Belt_Map.findFirstBy,
  forEachU: Belt_Map.forEachU,
  forEach: Belt_Map.forEach,
  reduceU: Belt_Map.reduceU,
  reduce: Belt_Map.reduce,
  everyU: Belt_Map.everyU,
  every: Belt_Map.every,
  someU: Belt_Map.someU,
  some: Belt_Map.some,
  size: Belt_Map.size,
  toArray: Belt_Map.toArray,
  toList: Belt_Map.toList,
  fromArray: Belt_Map.fromArray,
  keysToArray: Belt_Map.keysToArray,
  valuesToArray: Belt_Map.valuesToArray,
  minKey: Belt_Map.minKey,
  minKeyUndefined: Belt_Map.minKeyUndefined,
  maxKey: Belt_Map.maxKey,
  maxKeyUndefined: Belt_Map.maxKeyUndefined,
  minimum: Belt_Map.minimum,
  minUndefined: Belt_Map.minUndefined,
  maximum: Belt_Map.maximum,
  maxUndefined: Belt_Map.maxUndefined,
  get: Belt_Map.get,
  getUndefined: Belt_Map.getUndefined,
  getWithDefault: Belt_Map.getWithDefault,
  getExn: Belt_Map.getExn,
  remove: Belt_Map.remove,
  removeMany: Belt_Map.removeMany,
  set: Belt_Map.set,
  updateU: Belt_Map.updateU,
  update: Belt_Map.update,
  mergeMany: Belt_Map.mergeMany,
  mergeU: Belt_Map.mergeU,
  merge: Belt_Map.merge,
  keepU: Belt_Map.keepU,
  keep: Belt_Map.keep,
  partitionU: Belt_Map.partitionU,
  partition: Belt_Map.partition,
  split: Belt_Map.split,
  mapU: Belt_Map.mapU,
  map: Belt_Map.map,
  mapWithKeyU: Belt_Map.mapWithKeyU,
  mapWithKey: Belt_Map.mapWithKey,
  getData: Belt_Map.getData,
  getId: Belt_Map.getId,
  packIdData: Belt_Map.packIdData,
  checkInvariantInternal: Belt_Map.checkInvariantInternal,
  empty: empty
};

function empty$1(param) {
  return Belt_Map.make(RetryComparator);
}

var RetryMap = {
  Int: Belt_Map.Int,
  $$String: Belt_Map.$$String,
  Dict: Belt_Map.Dict,
  make: Belt_Map.make,
  isEmpty: Belt_Map.isEmpty,
  has: Belt_Map.has,
  cmpU: Belt_Map.cmpU,
  cmp: Belt_Map.cmp,
  eqU: Belt_Map.eqU,
  eq: Belt_Map.eq,
  findFirstByU: Belt_Map.findFirstByU,
  findFirstBy: Belt_Map.findFirstBy,
  forEachU: Belt_Map.forEachU,
  forEach: Belt_Map.forEach,
  reduceU: Belt_Map.reduceU,
  reduce: Belt_Map.reduce,
  everyU: Belt_Map.everyU,
  every: Belt_Map.every,
  someU: Belt_Map.someU,
  some: Belt_Map.some,
  size: Belt_Map.size,
  toArray: Belt_Map.toArray,
  toList: Belt_Map.toList,
  fromArray: Belt_Map.fromArray,
  keysToArray: Belt_Map.keysToArray,
  valuesToArray: Belt_Map.valuesToArray,
  minKey: Belt_Map.minKey,
  minKeyUndefined: Belt_Map.minKeyUndefined,
  maxKey: Belt_Map.maxKey,
  maxKeyUndefined: Belt_Map.maxKeyUndefined,
  minimum: Belt_Map.minimum,
  minUndefined: Belt_Map.minUndefined,
  maximum: Belt_Map.maximum,
  maxUndefined: Belt_Map.maxUndefined,
  get: Belt_Map.get,
  getUndefined: Belt_Map.getUndefined,
  getWithDefault: Belt_Map.getWithDefault,
  getExn: Belt_Map.getExn,
  remove: Belt_Map.remove,
  removeMany: Belt_Map.removeMany,
  set: Belt_Map.set,
  updateU: Belt_Map.updateU,
  update: Belt_Map.update,
  mergeMany: Belt_Map.mergeMany,
  mergeU: Belt_Map.mergeU,
  merge: Belt_Map.merge,
  keepU: Belt_Map.keepU,
  keep: Belt_Map.keep,
  partitionU: Belt_Map.partitionU,
  partition: Belt_Map.partition,
  split: Belt_Map.split,
  mapU: Belt_Map.mapU,
  map: Belt_Map.map,
  mapWithKeyU: Belt_Map.mapWithKeyU,
  mapWithKey: Belt_Map.mapWithKey,
  getData: Belt_Map.getData,
  getId: Belt_Map.getId,
  packIdData: Belt_Map.packIdData,
  checkInvariantInternal: Belt_Map.checkInvariantInternal,
  empty: empty$1
};

var $$Request = {
  RequestComparator: RequestComparator,
  RetryComparator: RetryComparator,
  $$Map: $$Map,
  RetryMap: RetryMap
};

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

function makeNewRequestConfig(temporaryOpt, schemaId, status) {
  var temporary = temporaryOpt !== undefined ? temporaryOpt : false;
  return {
          schemaId: schemaId,
          timestamp: Date.now(),
          status: status,
          temporary: temporary
        };
}

function updateLoadingState(state, requestConfig, status, loadingState) {
  return {
          requests: Belt_Map.updateU(state.requests, requestConfig, (function (maybeRequest) {
                  return {
                          enrichedWithActionId: Belt_Option.mapWithDefault(maybeRequest, "", (function (param) {
                                  return param.enrichedWithActionId;
                                })),
                          inspectorIssues: Belt_Option.mapWithDefault(maybeRequest, [], (function (param) {
                                  return param.inspectorIssues;
                                })),
                          issues: Belt_Option.mapWithDefault(maybeRequest, Issues.empty, (function (param) {
                                  return param.issues;
                                })),
                          loadingState: loadingState,
                          status: status
                        };
                })),
          retries: state.retries,
          updateOnModelChange: state.updateOnModelChange,
          fetch: state.fetch,
          updateIssues: state.updateIssues,
          fetching: state.fetching
        };
}

function addRetry(retries, requestConfig) {
  return Belt_Map.updateU(retries, requestConfig, (function (maybeRetries) {
                return Belt_Option.getWithDefault(maybeRetries, -1) + 1 | 0;
              }));
}

function getOrderedRequestConfigsForSchemaId(schemaId, status, requests) {
  return Belt_SortArray.stableSortByU(Belt_Map.keysToArray(Belt_Map.keepU(requests, (function (param, _value) {
                        if (param.schemaId === schemaId) {
                          return status === param.status;
                        } else {
                          return false;
                        }
                      }))), (function (a, b) {
                return Caml.caml_float_compare(a.timestamp, b.timestamp);
              }));
}

function getLatestRequestForSchemaId(schemaId, status, requests) {
  var orderedRequestConfigs = getOrderedRequestConfigsForSchemaId(schemaId, status, requests);
  return Belt_Option.flatMap(Belt_Array.get(orderedRequestConfigs, orderedRequestConfigs.length - 1 | 0), (function (rq) {
                return Belt_Option.map(Belt_Map.get(requests, rq), (function (value) {
                              return [
                                      rq,
                                      value
                                    ];
                            }));
              }));
}

function getLatestSuccessfulRequestForSchemaId(schemaId, includeTemporaryOpt, status, m) {
  var includeTemporary = includeTemporaryOpt !== undefined ? includeTemporaryOpt : true;
  var successfulRequests = Belt_Map.keepU(m, (function (param, param$1) {
          if (param$1.loadingState === "success" && includeTemporary) {
            return true;
          } else {
            return param.temporary;
          }
        }));
  return getLatestRequestForSchemaId(schemaId, status, successfulRequests);
}

function getAllLatestFetchedRequestsAndConfigs(schemaId, includeTemporaryOpt, m) {
  var includeTemporary = includeTemporaryOpt !== undefined ? includeTemporaryOpt : true;
  var unresolved = getLatestSuccessfulRequestForSchemaId(schemaId, includeTemporary, /* Unresolved */0, m);
  var ignored = getLatestSuccessfulRequestForSchemaId(schemaId, includeTemporary, /* Ignored */1, m);
  var resolved = getLatestSuccessfulRequestForSchemaId(schemaId, includeTemporary, /* Resolved */2, m);
  return [
          unresolved,
          ignored,
          resolved
        ];
}

function getAllLatestFetchedIssuesFromRequestsAndConfigs(requestsAndConfigs) {
  return Curry._1(Issues.fromArray, Belt_Array.concatMany([
                  Belt_Option.mapWithDefault(requestsAndConfigs[0], [], (function (param) {
                          return Curry._1(Issues.toArray, param[1].issues);
                        })),
                  Belt_Option.mapWithDefault(requestsAndConfigs[1], [], (function (param) {
                          return Curry._1(Issues.toArray, param[1].issues);
                        })),
                  Belt_Option.mapWithDefault(requestsAndConfigs[2], [], (function (param) {
                          return Curry._1(Issues.toArray, param[1].issues);
                        }))
                ]));
}

function updateIssuesFromRequestConfig(requestMap, requestConfig, fn) {
  return Belt_Map.updateU(requestMap, requestConfig, (function (maybeRequest) {
                if (maybeRequest !== undefined) {
                  return {
                          enrichedWithActionId: maybeRequest.enrichedWithActionId,
                          inspectorIssues: maybeRequest.inspectorIssues,
                          issues: Curry._1(fn, maybeRequest.issues),
                          loadingState: requestConfig.temporary ? "success" : maybeRequest.loadingState,
                          status: maybeRequest.status
                        };
                } else if (requestConfig.temporary) {
                  return {
                          enrichedWithActionId: "",
                          inspectorIssues: [],
                          issues: Curry._1(fn, Issues.empty),
                          loadingState: "success",
                          status: requestConfig.status
                        };
                } else {
                  return ;
                }
              }));
}

function optionallyUpdateIssuesFromOptionalRequestConfig(requestMap, maybeRequestConfig, fn) {
  return Belt_Option.mapWithDefault(maybeRequestConfig, requestMap, (function (requestConfig) {
                return updateIssuesFromRequestConfig(requestMap, requestConfig, fn);
              }));
}

function handleUpdateIssues(requests, schemaId, issues) {
  return Belt_Array.reduceU(issues, requests, (function (requests, issue) {
                var allRequestsAndConfigs = getAllLatestFetchedRequestsAndConfigs(schemaId, true, requests);
                var maybeResolved = allRequestsAndConfigs[2];
                var maybeIgnored = allRequestsAndConfigs[1];
                var maybeUnresolved = allRequestsAndConfigs[0];
                var allIssues = getAllLatestFetchedIssuesFromRequestsAndConfigs(allRequestsAndConfigs);
                var match = Curry._2(Issues.get, allIssues, issue.id);
                var oldRequestConfig;
                if (match !== undefined) {
                  var tmp = match.issueStatus.status;
                  oldRequestConfig = typeof tmp === "number" ? Belt_Option.map(maybeUnresolved, (function (param) {
                            return param[0];
                          })) : (
                      tmp.TAG === /* Ignored */0 ? Belt_Option.map(maybeIgnored, (function (param) {
                                return param[0];
                              })) : Belt_Option.map(maybeResolved, (function (param) {
                                return param[0];
                              }))
                    );
                } else {
                  oldRequestConfig = undefined;
                }
                var match$1 = issue.issueStatus.status;
                var newRequestConfig;
                newRequestConfig = typeof match$1 === "number" ? Belt_Option.getWithDefault(Belt_Option.map(maybeUnresolved, (function (param) {
                              return param[0];
                            })), makeNewRequestConfig(true, schemaId, /* Unresolved */0)) : (
                    match$1.TAG === /* Ignored */0 ? Belt_Option.getWithDefault(Belt_Option.map(maybeIgnored, (function (param) {
                                  return param[0];
                                })), makeNewRequestConfig(true, schemaId, /* Ignored */1)) : Belt_Option.getWithDefault(Belt_Option.map(maybeResolved, (function (param) {
                                  return param[0];
                                })), makeNewRequestConfig(true, schemaId, /* Resolved */2))
                  );
                if (Belt_Option.mapWithDefault(oldRequestConfig, false, (function (param) {
                          return newRequestConfig.status === param.status;
                        }))) {
                  return updateIssuesFromRequestConfig(requests, newRequestConfig, (function (issues) {
                                return Curry._3(Issues.update, issues, issue.id, (function (param) {
                                              return issue;
                                            }));
                              }));
                } else {
                  return updateIssuesFromRequestConfig(optionallyUpdateIssuesFromOptionalRequestConfig(requests, oldRequestConfig, (function (issues) {
                                    return Curry._2(Issues.remove, issues, issue.id);
                                  })), newRequestConfig, (function (issues) {
                                return Curry._2(Issues.prepend, issues, issue);
                              }));
                }
              }));
}

var store = Zustand$1.create(function (set, get) {
      return {
              requests: Belt_Map.make(RequestComparator),
              retries: Belt_Map.make(RetryComparator),
              updateOnModelChange: (function (requestConfig, latestActionId, model) {
                  return set(function (state) {
                              return {
                                      requests: Belt_Map.updateU(state.requests, requestConfig, (function (maybeRequest) {
                                              if (maybeRequest === undefined) {
                                                return ;
                                              }
                                              if (maybeRequest.enrichedWithActionId === latestActionId) {
                                                return maybeRequest;
                                              }
                                              var issues = Curry._1(Issues.fromArray, InspectorIssueViewModel.Utils.fromIssues(model, maybeRequest.inspectorIssues));
                                              return {
                                                      enrichedWithActionId: latestActionId,
                                                      inspectorIssues: maybeRequest.inspectorIssues,
                                                      issues: issues,
                                                      loadingState: maybeRequest.loadingState,
                                                      status: maybeRequest.status
                                                    };
                                            })),
                                      retries: state.retries,
                                      updateOnModelChange: state.updateOnModelChange,
                                      fetch: state.fetch,
                                      updateIssues: state.updateIssues,
                                      fetching: state.fetching
                                    };
                            });
                }),
              fetch: (function (schemaId, model, latestActionId, status) {
                  var requestConfig = makeNewRequestConfig(undefined, schemaId, status);
                  var match = getLatestRequestForSchemaId(requestConfig.schemaId, status, Curry._1(get, undefined).requests);
                  if (match !== undefined && match[1].loadingState === "loading") {
                    return Promise.resolve(undefined);
                  }
                  set(function (state) {
                        var init = updateLoadingState(state, requestConfig, status, "loading");
                        return {
                                requests: init.requests,
                                retries: init.retries,
                                updateOnModelChange: init.updateOnModelChange,
                                fetch: init.fetch,
                                updateIssues: init.updateIssues,
                                fetching: true
                              };
                      });
                  return $$Promise.$$catch(InspectorViewApi.getIssues(Caml_option.some(status), requestConfig.schemaId).then(function (res) {
                                    if (res.NAME === "success") {
                                      return res.VAL;
                                    }
                                    throw {
                                          RE_EXN_ID: FetchError,
                                          _1: res.VAL,
                                          Error: new Error()
                                        };
                                  }).then(function (inspectorIssues) {
                                  var issues = Curry._1(Issues.fromArray, InspectorIssueViewModel.Utils.fromIssues(model, inspectorIssues));
                                  return set(function (state) {
                                              return {
                                                      requests: Belt_Map.updateU(state.requests, requestConfig, (function (_maybeRequest) {
                                                              return {
                                                                      enrichedWithActionId: Belt_Option.getWithDefault(latestActionId, ""),
                                                                      inspectorIssues: inspectorIssues,
                                                                      issues: issues,
                                                                      loadingState: "success",
                                                                      status: status
                                                                    };
                                                            })),
                                                      retries: Belt_Map.set(state.retries, requestConfig, 0),
                                                      updateOnModelChange: state.updateOnModelChange,
                                                      fetch: state.fetch,
                                                      updateIssues: state.updateIssues,
                                                      fetching: false
                                                    };
                                            });
                                }), (function (error) {
                                if (error.RE_EXN_ID === FetchError) {
                                  if (error._1) {
                                    set(function (state) {
                                          var init = updateLoadingState(state, requestConfig, status, {
                                                NAME: "error",
                                                VAL: /* NotFound */1
                                              });
                                          return {
                                                  requests: init.requests,
                                                  retries: addRetry(state.retries, requestConfig),
                                                  updateOnModelChange: init.updateOnModelChange,
                                                  fetch: init.fetch,
                                                  updateIssues: init.updateIssues,
                                                  fetching: false
                                                };
                                        });
                                  } else {
                                    set(function (state) {
                                          var init = updateLoadingState(state, requestConfig, status, {
                                                NAME: "error",
                                                VAL: /* Internal */0
                                              });
                                          return {
                                                  requests: init.requests,
                                                  retries: addRetry(state.retries, requestConfig),
                                                  updateOnModelChange: init.updateOnModelChange,
                                                  fetch: init.fetch,
                                                  updateIssues: init.updateIssues,
                                                  fetching: false
                                                };
                                        });
                                  }
                                } else {
                                  set(function (state) {
                                        var init = updateLoadingState(state, requestConfig, status, {
                                              NAME: "error",
                                              VAL: /* NotFound */1
                                            });
                                        return {
                                                requests: init.requests,
                                                retries: addRetry(state.retries, requestConfig),
                                                updateOnModelChange: init.updateOnModelChange,
                                                fetch: init.fetch,
                                                updateIssues: init.updateIssues,
                                                fetching: false
                                              };
                                      });
                                }
                                return Promise.resolve(undefined);
                              }));
                }),
              updateIssues: (function (issues, schemaId) {
                  return set(function (state) {
                              return {
                                      requests: handleUpdateIssues(state.requests, schemaId, issues),
                                      retries: state.retries,
                                      updateOnModelChange: state.updateOnModelChange,
                                      fetch: state.fetch,
                                      updateIssues: state.updateIssues,
                                      fetching: state.fetching
                                    };
                            });
                }),
              fetching: false
            };
    });

function useLatestConfig(status) {
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  return Curry._2(ZustandStore.use, store, (function (state) {
                return Belt_Option.map(getLatestSuccessfulRequestForSchemaId(schemaId, undefined, status, state.requests), (function (prim) {
                              return prim[0];
                            }));
              }));
}

function useUpdateIssue(param) {
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var updateIssues = Curry._2(ZustandStore.use, store, (function (state) {
          return state.updateIssues;
        }));
  return React.useCallback((function (issue) {
                return Curry._2(updateIssues, [issue], schemaId);
              }), [schemaId]);
}

function useUpdateIssues(param) {
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var updateIssues = Curry._2(ZustandStore.use, store, (function (state) {
          return state.updateIssues;
        }));
  return React.useCallback((function (issues) {
                return Curry._2(updateIssues, issues, schemaId);
              }), [schemaId]);
}

function useIssues(skipRefetchingOnErrorOpt, status) {
  var skipRefetchingOnError = skipRefetchingOnErrorOpt !== undefined ? skipRefetchingOnErrorOpt : false;
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var model = ModelStore.useModel(undefined);
  var latestActionId = ModelStore.useLatestActionId(undefined);
  var modelStoreIsInitialized = ModelStore.useModelIsInitialized(undefined);
  var match$1 = Curry._2(ZustandStore.useShallow, store, (function (state) {
          return [
                  state.requests,
                  state.fetch,
                  state.fetching,
                  Belt_Option.getWithDefault(Belt_Map.get(state.retries, makeNewRequestConfig(undefined, schemaId, status)), 0)
                ];
        }));
  var retries = match$1[3];
  var fetching = match$1[2];
  var $$fetch = match$1[1];
  var requests = match$1[0];
  var latestRequest = React.useMemo((function () {
          return getLatestRequestForSchemaId(schemaId, status, requests);
        }), [
        schemaId,
        requests,
        status
      ]);
  var match$2 = React.useState(function () {
        
      });
  var setLatestIssueTimestamp = match$2[1];
  var latestIssueTimestamp = match$2[0];
  var match$3 = React.useMemo((function () {
          if (latestRequest === undefined) {
            return [
                    "initial",
                    Issues.empty
                  ];
          }
          var match = latestRequest[1];
          var loadingState = match.loadingState;
          if (loadingState !== "loading") {
            return [
                    loadingState,
                    match.issues
                  ];
          }
          var match$1 = getLatestSuccessfulRequestForSchemaId(schemaId, undefined, status, requests);
          if (match$1 !== undefined) {
            return [
                    loadingState,
                    match$1[1].issues
                  ];
          } else {
            return [
                    loadingState,
                    Issues.empty
                  ];
          }
        }), [latestRequest]);
  var $$fetch$1 = React.useCallback((function (param) {
          Curry._4($$fetch, schemaId, model, latestActionId, status);
          
        }), [
        schemaId,
        model,
        latestActionId,
        status
      ]);
  var latestInspectorIssueCreated = FirebaseFetcherHooks.useLatestInspectorIssueCreated(schemaId);
  React.useMemo((function () {
          if (typeof latestInspectorIssueCreated !== "object") {
            return ;
          }
          if (latestInspectorIssueCreated.NAME !== "Success") {
            return ;
          }
          var latestIssueTimestamp = latestInspectorIssueCreated.VAL;
          return Curry._1(setLatestIssueTimestamp, (function (param) {
                        return Caml_option.some(latestIssueTimestamp.latestIssueTimestamp.toDate());
                      }));
        }), [latestInspectorIssueCreated]);
  React.useEffect((function () {
          var errorRefetchTimeoutId = {
            contents: undefined
          };
          if (modelStoreIsInitialized) {
            var match = getLatestRequestForSchemaId(schemaId, status, requests);
            if (!fetching) {
              if (match !== undefined) {
                var match$1 = match[1];
                var thisStatus = match$1.status;
                var loadingState = match$1.loadingState;
                var requestConfig = match[0];
                if (typeof loadingState === "object") {
                  if (!skipRefetchingOnError) {
                    var isFetching = {
                      contents: fetching
                    };
                    errorRefetchTimeoutId.contents = Caml_option.some(setTimeout((function (param) {
                                if (!isFetching.contents) {
                                  return Curry._1($$fetch$1, undefined);
                                }
                                
                              }), ExponentialBackoff.getTimeoutValueMs(undefined, retries)));
                  }
                  
                } else if (loadingState === "success") {
                  if (requestConfig.temporary) {
                    Curry._1($$fetch$1, undefined);
                  } else if (latestIssueTimestamp !== undefined) {
                    if (thisStatus === /* Unresolved */0 && DateFns.isAfter(new Date(requestConfig.timestamp), Caml_option.valFromOption(latestIssueTimestamp))) {
                      Curry._1($$fetch$1, undefined);
                    }
                    
                  } else if (DateFns$1.differenceInSeconds(new Date(), new Date(requestConfig.timestamp)) >= 300.0 || status !== thisStatus) {
                    Curry._1($$fetch$1, undefined);
                  }
                  
                }
                
              } else {
                Curry._1($$fetch$1, undefined);
              }
            }
            
          }
          return (function (param) {
                    return Belt_Option.forEach(errorRefetchTimeoutId.contents, (function (prim) {
                                  clearTimeout(prim);
                                  
                                }));
                  });
        }), [
        schemaId,
        modelStoreIsInitialized,
        status,
        requests,
        latestIssueTimestamp,
        fetching
      ]);
  var updateOnModelChange = Curry._2(ZustandStore.use, store, (function (state) {
          return state.updateOnModelChange;
        }));
  React.useEffect((function () {
          if (latestRequest !== undefined) {
            var match = latestRequest[1].loadingState;
            var exit = 0;
            if (typeof match === "object") {
              if (match.NAME === "error") {
                exit = 1;
              }
              
            } else if (match === "success") {
              exit = 1;
            }
            if (exit === 1) {
              if (latestActionId !== undefined) {
                Curry._3(updateOnModelChange, latestRequest[0], latestActionId, model);
              }
              
            }
            
          }
          
        }), [latestActionId]);
  return {
          loadingState: match$3[0],
          issues: match$3[1]
        };
}

function useAllFetchedIssues(param) {
  var match = SchemaBundleContext.use(undefined);
  var schemaId = match.schemaId;
  var requests = Curry._2(ZustandStore.use, store, (function (state) {
          return state.requests;
        }));
  return React.useMemo((function () {
                return getAllLatestFetchedIssuesFromRequestsAndConfigs(getAllLatestFetchedRequestsAndConfigs(schemaId, true, requests));
              }), [requests]);
}

function useLatestIssueStatusFromActivityLog(issueId) {
  var match = SchemaBundleContext.use(undefined);
  var match$1 = FirebaseFetcherHooks.useActionWithContentsQueryMatch(match.schemaId, undefined, "UpdateInspectorIssueStatus.issueId", issueId);
  var action;
  if (typeof match$1 !== "object") {
    return ;
  }
  if (match$1.NAME !== "Loaded") {
    return ;
  }
  var match$2 = match$1.VAL;
  if (!match$2) {
    return ;
  }
  var action$1 = match$2.hd;
  action = action$1;
  var match$3 = action.contents;
  if (typeof match$3 !== "object") {
    return ;
  }
  if (match$3.NAME !== "UpdateInspectorIssueStatus") {
    return ;
  }
  var match$4 = match$3.VAL;
  if (match$4.issueId === issueId) {
    return match$4.issueStatus;
  }
  
}

function useIssue(id) {
  var idRef = React.useRef(id);
  var latestFetch = React.useRef(Date.now());
  var model = ModelStore.useModel(undefined);
  var match = InspectorIssuesFilterHooks.useIssueStatusQuery(undefined);
  var match$1 = useIssues(undefined, match[0]);
  var issues = match$1.issues;
  var loadingState = match$1.loadingState;
  var allIssues = useAllFetchedIssues(undefined);
  var stringifiedIssue = React.useMemo((function () {
          return Belt_Option.mapWithDefault(Belt_Option.mapWithDefault(Curry._2(Issues.get, issues, id), Curry._2(Issues.get, allIssues, id), (function (issue) {
                            return issue;
                          })), "noop", (function (issue) {
                        return JSON.stringify(InspectorIssueViewModel.encode(issue));
                      }));
        }), [
        id,
        issues,
        allIssues
      ]);
  var issue = React.useMemo((function () {
          return Belt_Option.mapWithDefault(Curry._2(Issues.get, issues, id), Curry._2(Issues.get, allIssues, id), (function (issue) {
                        return issue;
                      }));
        }), [stringifiedIssue]);
  var match$2 = React.useState(function () {
        if (issue === undefined) {
          return "initial";
        } else {
          return "success";
        }
      });
  var setFetchStatus = match$2[1];
  var fetchStatus = match$2[0];
  var activityLogSyncedIssueStatus = useLatestIssueStatusFromActivityLog(id);
  var updateIssue = useUpdateIssue(undefined);
  var fetchIssue = function (id$1) {
    if (!(fetchStatus !== "loading" || idRef.current !== id$1)) {
      return ;
    }
    Curry._1(setFetchStatus, (function (param) {
            return "loading";
          }));
    var fetchTime = Date.now();
    latestFetch.current = fetchTime;
    $$Promise.$$catch(InspectorViewApi.getIssue(id$1).then(function (param) {
              if (idRef.current === param.issueId && fetchTime === latestFetch.current) {
                Curry._1(setFetchStatus, (function (param) {
                        return "success";
                      }));
                return Belt_Option.forEach(Belt_Array.get(InspectorIssueViewModel.Utils.fromIssues(model, [param]), 0), updateIssue);
              }
              
            }), (function (param) {
            if (latestFetch.current !== fetchTime) {
              return Promise.resolve(undefined);
            }
            if (param.RE_EXN_ID === InspectorViewApi.ClientError && param._1 === 404) {
              return $$Promise.$$catch(InspectorViewApi.getRelatedIssues(id).then(function (issues) {
                              return Curry._1(setFetchStatus, (function (param) {
                                            return Belt_Option.mapWithDefault(Belt_Array.get(issues, 0), {
                                                        NAME: "error",
                                                        VAL: /* NotFound */1
                                                      }, (function (param) {
                                                          return {
                                                                  NAME: "replaceSharedId",
                                                                  VAL: param.issueId
                                                                };
                                                        }));
                                          }));
                            }), (function (param) {
                            Curry._1(setFetchStatus, (function (param) {
                                    return {
                                            NAME: "error",
                                            VAL: /* NotFound */1
                                          };
                                  }));
                            return Promise.resolve(undefined);
                          }));
            }
            Curry._1(setFetchStatus, (function (param) {
                    return {
                            NAME: "error",
                            VAL: /* Internal */0
                          };
                  }));
            return Promise.resolve(undefined);
          }));
    
  };
  React.useEffect((function () {
          var exit = 0;
          if (typeof loadingState === "object") {
            if (loadingState.NAME === "error") {
              exit = 1;
            }
            
          } else if (loadingState === "success") {
            exit = 1;
          }
          if (exit === 1) {
            if (Belt_Option.isNone(issue) && Belt_Option.mapWithDefault(Curry._2(Issues.get, issues, id), Curry._2(Issues.get, allIssues, id), (function (issue) {
                      return issue;
                    })) === undefined) {
              fetchIssue(id);
            }
            
          }
          
        }), [loadingState]);
  Hooks.useDidUpdate1((function (param) {
          if (activityLogSyncedIssueStatus !== undefined && issue !== undefined && Caml_obj.caml_notequal(activityLogSyncedIssueStatus.status, issue.issueStatus.status)) {
            Curry._1(updateIssue, {
                  id: issue.id,
                  sharedId: issue.sharedId,
                  issueType: issue.issueType,
                  issueStatus: activityLogSyncedIssueStatus,
                  eventName: issue.eventName,
                  propertyName: issue.propertyName,
                  source: issue.source,
                  categories: issue.categories,
                  tags: issue.tags,
                  firstSeen: issue.firstSeen,
                  lastSeen: issue.lastSeen,
                  eventCount: issue.eventCount,
                  issueCount: issue.issueCount,
                  newestAppVersion: issue.newestAppVersion,
                  oldestAppVersion: issue.oldestAppVersion,
                  regression: issue.regression
                });
          }
          
        }), [activityLogSyncedIssueStatus]);
  return React.useMemo((function () {
                return {
                        issue: issue,
                        fetchStatus: fetchStatus
                      };
              }), [
              issue,
              fetchStatus
            ]);
}

function useAppVersionsInSyncWithIssues(takeLast, statusOpt, param) {
  var status = statusOpt !== undefined ? statusOpt : /* Unresolved */0;
  var latestTimestamp = Belt_Option.map(useLatestConfig(status), (function (config) {
          return config.timestamp;
        }));
  return AppVersionsStore.use(latestTimestamp, takeLast, undefined);
}

function useFilteredIssues(statusOpt, skipRefetchingOnErrorOpt, param) {
  var status = statusOpt !== undefined ? statusOpt : /* Unresolved */0;
  var skipRefetchingOnError = skipRefetchingOnErrorOpt !== undefined ? skipRefetchingOnErrorOpt : false;
  var match = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match.filters;
  var match$1 = useIssues(skipRefetchingOnError, status);
  var issues = match$1.issues;
  var match$2 = useAppVersionsInSyncWithIssues(3, status, undefined);
  var appVersions = match$2[0];
  var filteredIssues = React.useMemo((function () {
          if (filters.length !== 0) {
            return InspectorIssuesFilterUtils.filterIssueViewModels(undefined, appVersions, issues, filters);
          } else {
            return issues;
          }
        }), [
        issues,
        filters,
        appVersions
      ]);
  return {
          loadingState: match$1.loadingState,
          issues: issues,
          filteredIssues: filteredIssues
        };
}

export {
  FetchError ,
  $$Request ,
  StoreConfig ,
  ZustandStore ,
  makeNewRequestConfig ,
  updateLoadingState ,
  addRetry ,
  getOrderedRequestConfigsForSchemaId ,
  getLatestRequestForSchemaId ,
  getLatestSuccessfulRequestForSchemaId ,
  getAllLatestFetchedRequestsAndConfigs ,
  getAllLatestFetchedIssuesFromRequestsAndConfigs ,
  updateIssuesFromRequestConfig ,
  optionallyUpdateIssuesFromOptionalRequestConfig ,
  handleUpdateIssues ,
  store ,
  useLatestConfig ,
  useUpdateIssue ,
  useUpdateIssues ,
  useIssues ,
  useAllFetchedIssues ,
  useIssue ,
  useAppVersionsInSyncWithIssues ,
  useFilteredIssues ,
  
}
/* RequestComparator Not a pure module */
