// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import Shortid from "shortid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProductTour__OnElement from "./ProductTour__OnElement.mjs";

function ProductTour__Windowed(Props) {
  var arrowOffset = Props.arrowOffset;
  var borderRadius = Props.borderRadius;
  var delay = Props.delay;
  var elementClickable = Props.elementClickable;
  var customHeight = Props.height;
  var customLeft = Props.left;
  var lockBackground = Props.lockBackground;
  var onClick = Props.onClick;
  var onOutsideClick = Props.onOutsideClick;
  var offset = Props.offset;
  var opened = Props.opened;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var onStepActive = Props.onStepActive;
  var simulateClick = Props.simulateClick;
  var customTop = Props.top;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var waitForId = Props.waitForId;
  var customWidth = Props.width;
  var withOutline = Props.withOutline;
  var children = Props.children;
  var forId = React.useMemo((function () {
          return Shortid();
        }), []);
  var tmp = {
    forId: forId,
    opened: opened,
    children: children
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (borderRadius !== undefined) {
    tmp.borderRadius = Caml_option.valFromOption(borderRadius);
  }
  if (delay !== undefined) {
    tmp.delay = Caml_option.valFromOption(delay);
  }
  if (elementClickable !== undefined) {
    tmp.elementClickable = Caml_option.valFromOption(elementClickable);
  }
  if (lockBackground !== undefined) {
    tmp.lockBackground = Caml_option.valFromOption(lockBackground);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onOutsideClick !== undefined) {
    tmp.onOutsideClick = Caml_option.valFromOption(onOutsideClick);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (positionDependencies !== undefined) {
    tmp.positionDependencies = Caml_option.valFromOption(positionDependencies);
  }
  if (onStepActive !== undefined) {
    tmp.onStepActive = Caml_option.valFromOption(onStepActive);
  }
  if (simulateClick !== undefined) {
    tmp.simulateClick = Caml_option.valFromOption(simulateClick);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
  }
  if (waitForId !== undefined) {
    tmp.waitForId = Caml_option.valFromOption(waitForId);
  }
  if (withOutline !== undefined) {
    tmp.withOutline = Caml_option.valFromOption(withOutline);
  }
  return React.createElement(React.Fragment, undefined, opened ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Caml_obj.caml_equal(elementClickable, true) || elementClickable === undefined ? Css.pointerEvents("none") : Styles.emptyStyle,
                          tl: {
                            hd: Css.position("fixed"),
                            tl: {
                              hd: Css.left(customLeft),
                              tl: {
                                hd: Css.top(customTop),
                                tl: {
                                  hd: Css.width(customWidth),
                                  tl: {
                                    hd: Css.height(customHeight),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }),
                    id: forId
                  }) : null, React.createElement(ProductTour__OnElement.make, tmp));
}

var OnElement;

var make = ProductTour__Windowed;

export {
  OnElement ,
  make ,
  
}
/* Css Not a pure module */
