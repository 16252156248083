// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var context = React.createContext(undefined);

var provider = context.Provider;

function use(param) {
  return Belt_Option.getExn(React.useContext(context));
}

function SendActionsContext(Props) {
  var sendActions = Props.sendActions;
  var children = Props.children;
  return React.createElement(provider, {
              value: sendActions,
              children: children
            });
}

var make = SendActionsContext;

export {
  context ,
  provider ,
  use ,
  make ,
  
}
/* context Not a pure module */
