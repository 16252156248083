// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Semver from "semver";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function valid(str) {
  return Caml_option.nullable_to_opt(Semver.valid(str));
}

function t_valid(t) {
  return Caml_option.nullable_to_opt(Semver.valid(t));
}

function coerceAndValidate(str) {
  var t = Semver.coerce(str, {});
  return Caml_option.nullable_to_opt(Semver.valid(t));
}

export {
  valid ,
  t_valid ,
  coerceAndValidate ,
  
}
/* semver Not a pure module */
