// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Parser from "../../model/src/Parser.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "blocked",
                Json_encode.array((function (prim) {
                        return prim;
                      }), t.blocked)
              ],
              tl: {
                hd: [
                  "predefined",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), t.predefined)
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          blocked: Json_decode.field("blocked", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          predefined: Json_decode.field("predefined", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

function encodeByEvent(byEvent) {
  return Json_encode.object_({
              hd: [
                "eventName",
                Printer.printEvent(byEvent[0])
              ],
              tl: {
                hd: [
                  "values",
                  encode(byEvent[1])
                ],
                tl: /* [] */0
              }
            });
}

function decodeByEvent(json) {
  return [
          Json_decode.field("eventName", Parser.parseEvent, json),
          Json_decode.field("values", decode, json)
        ];
}

var UnexpectedValues = {
  encode: encode,
  decode: decode,
  encodeByEvent: encodeByEvent,
  decodeByEvent: decodeByEvent
};

function getSeverity(enabled, enforced) {
  if (enabled) {
    if (enforced) {
      return "enforce";
    } else {
      return "warning";
    }
  }
  
}

function getEventDiscrepancySeverity(discrepancy) {
  return getSeverity(discrepancy.VAL[0], discrepancy.VAL[1]);
}

function getPropertyDiscrepancySeverity(discrepancy) {
  return getSeverity(discrepancy.VAL[0], discrepancy.VAL[1]);
}

function discrepancyTypeToString(discrepancy) {
  var variant = discrepancy.NAME;
  if (variant === "SameEventName") {
    return "SameEventName";
  } else if (variant === "SamePropertyName") {
    return "SamePropertyName";
  } else if (variant === "PropertiesWithAnyType") {
    return "PropertiesWithAnyType";
  } else if (variant === "EventsWithoutDescription") {
    return "EventsWithoutDescription";
  } else if (variant === "EventsWithoutExpectedValues") {
    return "EventsWithoutExpectedValues";
  } else if (variant === "EventsWithUnexpectedCase") {
    return "EventsWithUnexpectedCase";
  } else if (variant === "PropertyConflictOnEvent") {
    return "PropertyConflictOnEvent";
  } else if (variant === "PropertiesWithoutDescription") {
    return "PropertiesWithoutDescription";
  } else {
    return "PropertiesWithUnexpectedCase";
  }
}

function discrepancyToDescription(discrepancy) {
  var variant = discrepancy.NAME;
  if (variant === "SameEventName") {
    return "Event name already exists";
  } else if (variant === "SamePropertyName") {
    return "Property name already exists";
  } else if (variant === "PropertiesWithAnyType") {
    return "Property type is any";
  } else if (variant === "EventsWithoutDescription") {
    return "Description missing";
  } else if (variant === "EventsWithoutExpectedValues") {
    return "Invalid event name";
  } else if (variant === "EventsWithUnexpectedCase") {
    return "Event name is not in expected case";
  } else if (variant === "PropertyConflictOnEvent") {
    return "Property conflict on event";
  } else if (variant === "PropertiesWithoutDescription") {
    return "Description missing";
  } else {
    return "Property name is not in expected case";
  }
}

function getSeverity$1(discrepancy) {
  var variant = discrepancy.NAME;
  if (variant === "PropertyConflictOnEvent" || variant === "EventsWithUnexpectedCase" || variant === "EventsWithoutExpectedValues" || variant === "SamePropertyName" || variant === "SameEventName") {
    return getEventDiscrepancySeverity(discrepancy);
  } else {
    return getPropertyDiscrepancySeverity(discrepancy);
  }
}

export {
  UnexpectedValues ,
  getEventDiscrepancySeverity ,
  getPropertyDiscrepancySeverity ,
  discrepancyTypeToString ,
  discrepancyToDescription ,
  getSeverity$1 as getSeverity,
  
}
/* Parser Not a pure module */
