// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoEngagement__Types from "./AvoEngagement__Types.mjs";

var next = AvoEngagement__Types.Block.Transition.next;

var previous = AvoEngagement__Types.Block.Transition.previous;

var complete = AvoEngagement__Types.Block.Transition.complete;

var step1_transitions = [Curry._1(next, {
        NAME: "block",
        VAL: "step-2"
      })];

var step1_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Your new demo branch!"
        }
      },
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "This demo branch is a sandbox where you can draft changes to your tracking plan without affecting the main version of it."
        }
      },
      {
        id: "element-id",
        value: {
          NAME: "string",
          VAL: "sidebar-tour-branches"
        }
      }
    ]);

var step1 = {
  id: "step-1",
  transitions: step1_transitions,
  fields: step1_fields
};

var step2_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-1"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-3"
      })
];

var step2_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Your tracking plan"
        }
      },
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "This is the view of the events in your tracking plan. Here you can add new events and categories and customize the view."
        }
      }
    ]);

var step2 = {
  id: "step-2",
  transitions: step2_transitions,
  fields: step2_fields
};

var step3_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-2"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-4"
      })
];

var step3_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "New and updated events pop up in the workbench here at the top. Let's take a look at one of the demo events and run through the workflow."
        }
      },
      {
        id: "body-2",
        value: {
          NAME: "string",
          VAL: "Click the \"Onboarding Started\" event to explore the event details."
        }
      },
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Updating the tracking plan"
        }
      }
    ]);

var step3 = {
  id: "step-3",
  transitions: step3_transitions,
  fields: step3_fields
};

var step4_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-3"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-5"
      })
];

var step4_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Defining Events"
        }
      },
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "A well defined event helps developers implement it consistently and your entire team to understand your data.  If you are unsure how to fill in a section of the event details, you can click on any tooltip to get more information and examples."
        }
      },
      {
        id: "imagery",
        value: {
          NAME: "string",
          VAL: ""
        }
      }
    ]);

var step4 = {
  id: "step-4",
  transitions: step4_transitions,
  fields: step4_fields
};

var step5_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-4"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-6"
      })
];

var step5_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Reviewing branch changes"
        }
      },
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "When you are done drafting for now, you can go into \"View changes\" to review your changes and get instructions to share with a developer to implement them."
        }
      }
    ]);

var step5 = {
  id: "step-5",
  transitions: step5_transitions,
  fields: step5_fields
};

var step6_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-5"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-7"
      })
];

var step6_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "All changes made on the branch are summarized on this page so you can review them and discuss with your teammates."
        }
      },
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Review and discuss your changes"
        }
      }
    ]);

var step6 = {
  id: "step-6",
  transitions: step6_transitions,
  fields: step6_fields
};

var step7_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-6"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-8"
      })
];

var step7_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Share code changes with a developer"
        }
      },
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "Check out the code changes tab to get code snippets for every event that has changed on this branch, helping developers implement it with ease."
        }
      }
    ]);

var step7 = {
  id: "step-7",
  transitions: step7_transitions,
  fields: step7_fields
};

var step8_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-7"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-9"
      })
];

var step8_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Code Changes Screen"
        }
      },
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "You can share the page with a developer that's a member in your workspace or create a public link to share with anyone, even if they're not in Avo yet."
        }
      }
    ]);

var step8 = {
  id: "step-8",
  transitions: step8_transitions,
  fields: step8_fields
};

var step9_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-8"
      }),
  Curry._1(complete, undefined)
];

var step9_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "Navigate to the main branch to create your own branch and either import your tracking plan or add your own events. Click the Avo logo to find an overview of all branches."
        }
      },
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "What's next?"
        }
      }
    ]);

var step9 = {
  id: "step-9",
  transitions: step9_transitions,
  fields: step9_fields
};

var content_blocks = Curry._1(AvoEngagement__Types.Blocks.fromArray, [
      step1,
      step2,
      step3,
      step4,
      step5,
      step6,
      step7,
      step8,
      step9
    ]);

var content = {
  id: "demo-branch-product-tour",
  version: 6,
  rootId: "step-1",
  blocks: content_blocks
};

export {
  content ,
  
}
/* step1 Not a pure module */
