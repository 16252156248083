// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function toString(formatType) {
  switch (formatType) {
    case /* JsonSchema */0 :
        return "JsonSchema";
    case /* AvoJson */1 :
        return "AvoJson";
    case /* SnowplowSchemas */2 :
        return "SnowplowSchemas";
    
  }
}

function fromString(formatType) {
  switch (formatType) {
    case "AvoJson" :
        return /* AvoJson */1;
    case "JsonSchema" :
        return /* JsonSchema */0;
    case "SnowplowSchemas" :
        return /* SnowplowSchemas */2;
    default:
      return Pervasives.failwith("WebhookPayloadFormat: Unknown webhook payload type " + formatType);
  }
}

function toFormat(formatType) {
  switch (formatType) {
    case /* JsonSchema */0 :
        return /* JsonSchema */0;
    case /* AvoJson */1 :
        return /* AvoJson */1;
    case /* SnowplowSchemas */2 :
        return /* SnowplowSchemas */{
                _0: {
                  vendor: undefined
                }
              };
    
  }
}

function prettyPrint(formatType) {
  switch (formatType) {
    case /* JsonSchema */0 :
        return "Json Schema";
    case /* AvoJson */1 :
        return "Avo Json";
    case /* SnowplowSchemas */2 :
        return "Snowplow Schemas";
    
  }
}

var Type = {
  toString: toString,
  fromString: fromString,
  toFormat: toFormat,
  prettyPrint: prettyPrint
};

function toString$1(t) {
  if (typeof t === "number") {
    if (t !== 0) {
      return "AvoJson";
    } else {
      return "JsonSchema";
    }
  } else {
    return "SnowplowSchemas";
  }
}

function encode(t) {
  if (typeof t !== "number") {
    var vendor = t._0.vendor;
    if (vendor !== undefined) {
      return Json_encode.object_({
                  hd: [
                    "type",
                    toString$1(t)
                  ],
                  tl: {
                    hd: [
                      "vendor",
                      vendor
                    ],
                    tl: /* [] */0
                  }
                });
    }
    
  }
  return Json_encode.object_({
              hd: [
                "type",
                toString$1(t)
              ],
              tl: /* [] */0
            });
}

function decode(json) {
  var typeString = Json_decode.field("type", Json_decode.string, json);
  switch (typeString) {
    case "AvoJson" :
        return /* AvoJson */1;
    case "JsonSchema" :
        return /* JsonSchema */0;
    case "SnowplowSchemas" :
        return /* SnowplowSchemas */{
                _0: {
                  vendor: Json_decode.optional((function (param) {
                          return Json_decode.field("vendor", Json_decode.string, param);
                        }), json)
                }
              };
    default:
      return Pervasives.failwith("WebhookPayloadFormat: Unknown webhook payload type " + typeString);
  }
}

function prettyPrint$1(formatType) {
  if (typeof formatType === "number") {
    if (formatType !== 0) {
      return "Avo Json";
    } else {
      return "Json Schema";
    }
  } else {
    return "Snowplow Schemas";
  }
}

function toAnalytics(t) {
  if (typeof t === "number") {
    if (t !== 0) {
      return "AvoJson";
    } else {
      return "JsonSchema";
    }
  } else {
    return "SnowplowSchemas";
  }
}

export {
  Type ,
  toString$1 as toString,
  encode ,
  decode ,
  prettyPrint$1 as prettyPrint,
  toAnalytics ,
  
}
/* No side effect */
