// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function Picture(Props) {
  var picture = Props.picture;
  var width = Props.width;
  var height = Props.height;
  var renderImgUrl = function (url, width, height) {
    if (width !== undefined) {
      if (height !== undefined) {
        return Pervasives.failwith("Can not crop width and height.");
      } else {
        return url + "=w" + String((width << 1));
      }
    } else if (height !== undefined) {
      return url + "=h" + String((height << 1));
    } else {
      return url;
    }
  };
  var match;
  if (width !== undefined) {
    if (height !== undefined) {
      var heightRatio = height / picture.height;
      var widthRatio = width / picture.width;
      match = heightRatio < widthRatio ? [
          width,
          undefined
        ] : [
          undefined,
          height
        ];
    } else {
      match = [
        width,
        undefined
      ];
    }
  } else {
    match = height !== undefined ? [
        undefined,
        height
      ] : [
        undefined,
        undefined
      ];
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.backgroundImage({
                          NAME: "url",
                          VAL: renderImgUrl(picture.url, match[0], match[1])
                        }),
                    tl: {
                      hd: Css.backgroundPosition("center"),
                      tl: {
                        hd: Css.backgroundSize("cover"),
                        tl: {
                          hd: Css.width(Belt_Option.mapWithDefault(width, "auto", Css.px)),
                          tl: {
                            hd: Css.height(Belt_Option.mapWithDefault(height, "auto", Css.px)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }),
              width: width !== undefined ? String(width) : "auto"
            });
}

var make = Picture;

export {
  make ,
  
}
/* Css Not a pure module */
