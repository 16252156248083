// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoModel from "../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "../../app/src/ModelUtils_mapped.mjs";
import * as BeltListExtensions from "../../app/src/BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";

function getEventSpecificNestedPropertyPinnedValue(nestedPropertyId, parentPropertyId, eventId, model) {
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    if (typeof match !== "object") {
                      return ;
                    }
                    if (match.NAME !== "EventObjectField") {
                      return ;
                    }
                    var match$1 = rule.definition;
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "PinnedValue") {
                      return ;
                    }
                    var match$2 = match.VAL;
                    if (match$2[0] === eventId && match$2[1] === parentPropertyId && match$2[2] === nestedPropertyId) {
                      return match$1.VAL;
                    }
                    
                  })));
}

function getEventSpecificNestedPropertyPinnedValue_mapped(nestedPropertyId, parentPropertyId, eventId, rules) {
  return Belt_Array.get(Belt_Array.keepMap(rules, (function (rule) {
                    var match = rule.item;
                    if (typeof match !== "object") {
                      return ;
                    }
                    if (match.NAME !== "EventObjectField") {
                      return ;
                    }
                    var match$1 = rule.definition;
                    if (typeof match$1 !== "object") {
                      return ;
                    }
                    if (match$1.NAME !== "PinnedValue") {
                      return ;
                    }
                    var match$2 = match.VAL;
                    if (match$2[0] === eventId && match$2[1] === parentPropertyId && match$2[2] === nestedPropertyId) {
                      return match$1.VAL;
                    }
                    
                  })), 0);
}

function getNestedPropertyPinnedValue(nestedPropertyRef, parentPropertyId, eventId, model) {
  var eventSpecificNestedPropertyPinnedValue = getEventSpecificNestedPropertyPinnedValue(nestedPropertyRef.id, parentPropertyId, eventId, model);
  var maybeNestedProperty = ModelUtils.resolvePropertyById(nestedPropertyRef.id, model);
  var match = nestedPropertyRef.pinnedValue;
  if (maybeNestedProperty === undefined) {
    return ;
  }
  if (eventSpecificNestedPropertyPinnedValue !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(eventSpecificNestedPropertyPinnedValue, maybeNestedProperty, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
    return [
            eventSpecificNestedPropertyPinnedValue,
            /* EventSpecific */0
          ];
  }
  if (match !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(match, maybeNestedProperty, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
    return [
            match,
            /* Global */1
          ];
  }
  
}

function getNestedPropertyPinnedValue_mapped(nestedPropertyRef, parentPropertyId, eventId, properties, rules, model) {
  var eventSpecificNestedPropertyPinnedValue = getEventSpecificNestedPropertyPinnedValue_mapped(nestedPropertyRef.id, parentPropertyId, eventId, rules);
  var maybeNestedProperty = Curry._2(TrackingPlanMappedModel.Properties.get, properties, nestedPropertyRef.id);
  var match = nestedPropertyRef.pinnedValue;
  if (maybeNestedProperty === undefined) {
    return ;
  }
  if (eventSpecificNestedPropertyPinnedValue !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(eventSpecificNestedPropertyPinnedValue, maybeNestedProperty, ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
    return [
            eventSpecificNestedPropertyPinnedValue,
            /* EventSpecific */0
          ];
  }
  if (match !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(match, maybeNestedProperty, ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
    return [
            match,
            /* Global */1
          ];
  }
  
}

function hasPropertyNameMapping(model, eventAndUserProperties, systemProperties, pinnedProperties, $$event) {
  var properties = Belt_List.concat(Belt_List.concat(eventAndUserProperties, systemProperties), pinnedProperties);
  return Belt_List.some(model.rules, (function (rule) {
                return Belt_List.some(properties, (function (property) {
                              var match = rule.item;
                              var match$1 = rule.definition;
                              if (typeof match !== "object") {
                                return false;
                              }
                              var variant = match.NAME;
                              if (variant === "PropertyRef") {
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "NameMapping") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (match$2.destination === undefined) {
                                  return false;
                                }
                                if (match$2.name === undefined) {
                                  return false;
                                }
                                var match$3 = match.VAL;
                                if (match$3[0] === $$event.id) {
                                  return match$3[1] === property.id;
                                } else {
                                  return false;
                                }
                              }
                              if (variant !== "Property") {
                                return false;
                              }
                              if (typeof match$1 !== "object") {
                                return false;
                              }
                              if (match$1.NAME !== "NameMapping") {
                                return false;
                              }
                              var match$4 = match$1.VAL;
                              if (match$4.destination !== undefined && match$4.name !== undefined) {
                                return match.VAL === property.id;
                              } else {
                                return false;
                              }
                            }));
              }));
}

function hasSpecificEventNameMappingForParticularDestinationId(model, destinationId, eventId) {
  return Belt_List.someU(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return false;
                }
                if (match.NAME !== "Event") {
                  return false;
                }
                if (typeof match$1 !== "object") {
                  return false;
                }
                if (match$1.NAME !== "NameMapping") {
                  return false;
                }
                var match$2 = match$1.VAL.destination;
                if (match$2 !== undefined && typeof match$2 === "object" && match$2.NAME === "Destination" && match.VAL === eventId) {
                  return destinationId === match$2.VAL;
                } else {
                  return false;
                }
              }));
}

function getNestedProperties(property, model) {
  return BeltListExtensions.flatMapU(property.validations, (function (validation) {
                if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                  return Belt_List.keepMapU(validation.VAL, (function (propertyRef) {
                                return Belt_Option.map(AvoModel.getPropertyById(model, propertyRef.id), (function (property) {
                                              return [
                                                      propertyRef,
                                                      property
                                                    ];
                                            }));
                              }));
                } else {
                  return /* [] */0;
                }
              }));
}

function getNestedPropertiesForPropertyList(props, model) {
  return Belt_List.mapU(Belt_List.reduceU(props, /* [] */0, (function (carry, property) {
                    return Belt_List.concat(carry, getNestedProperties(property, model));
                  })), (function (param) {
                return param[1];
              }));
}

function hasSpecificPropertyNameMappingForParticularDestinationId(model, destinationId, properties, $$event) {
  return Belt_List.someU(model.rules, (function (rule) {
                return Belt_List.someU(properties, (function (property) {
                              var match = rule.item;
                              var match$1 = rule.definition;
                              if (typeof match !== "object") {
                                return false;
                              }
                              var variant = match.NAME;
                              if (variant === "PropertyRef") {
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "NameMapping") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                var match$3 = match$2.destination;
                                if (match$3 === undefined) {
                                  return false;
                                }
                                if (typeof match$3 !== "object") {
                                  return false;
                                }
                                if (match$3.NAME !== "Destination") {
                                  return false;
                                }
                                if (match$2.name === undefined) {
                                  return false;
                                }
                                var match$4 = match.VAL;
                                if (match$4[0] === $$event.id && match$4[1] === property.id) {
                                  return match$3.VAL === destinationId;
                                } else {
                                  return false;
                                }
                              }
                              if (variant !== "Property") {
                                return false;
                              }
                              if (typeof match$1 !== "object") {
                                return false;
                              }
                              if (match$1.NAME !== "NameMapping") {
                                return false;
                              }
                              var match$5 = match$1.VAL;
                              var match$6 = match$5.destination;
                              if (match$6 !== undefined && typeof match$6 === "object" && match$6.NAME === "Destination" && match$5.name !== undefined && match.VAL === property.id) {
                                return match$6.VAL === destinationId;
                              } else {
                                return false;
                              }
                            }));
              }));
}

function getSpecificPropertyNameMappingForParticularDestinationId(model, destinationId, properties, $$event) {
  var propertyIdToHasNestedPropertiesNameMapping = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(properties, (function (property) {
                  var nestedProperties = getNestedPropertiesForPropertyList({
                        hd: property,
                        tl: /* [] */0
                      }, model);
                  var hasNestedPropertiesNameMapping = hasSpecificPropertyNameMappingForParticularDestinationId(model, destinationId, nestedProperties, $$event);
                  return [
                          property.id,
                          hasNestedPropertiesNameMapping
                        ];
                }))));
  var nameMappingWithoutNested = Belt_List.toArray(BeltListExtensions.flatMapU(model.rules, (function (rule) {
              return Belt_List.keepMapU(properties, (function (property) {
                            var hasNestedPropertiesNameMapping = Belt_Option.getWithDefault(Belt_MapString.get(propertyIdToHasNestedPropertiesNameMapping, property.id), false);
                            var match = rule.item;
                            var match$1 = rule.definition;
                            if (typeof match !== "object") {
                              return ;
                            }
                            var variant = match.NAME;
                            if (variant === "PropertyRef") {
                              if (typeof match$1 !== "object") {
                                return ;
                              }
                              if (match$1.NAME !== "NameMapping") {
                                return ;
                              }
                              var match$2 = match$1.VAL;
                              var match$3 = match$2.destination;
                              if (match$3 === undefined) {
                                return ;
                              }
                              if (typeof match$3 !== "object") {
                                return ;
                              }
                              if (match$3.NAME !== "Destination") {
                                return ;
                              }
                              var mappedName = match$2.name;
                              if (mappedName === undefined) {
                                return ;
                              }
                              var match$4 = match.VAL;
                              if (match$4[0] === $$event.id && match$4[1] === property.id && match$3.VAL === destinationId) {
                                return {
                                        property: property,
                                        oldName: property.name,
                                        newName: mappedName,
                                        hasNestedPropertiesNameMapping: hasNestedPropertiesNameMapping
                                      };
                              } else {
                                return ;
                              }
                            }
                            if (variant !== "Property") {
                              return ;
                            }
                            if (typeof match$1 !== "object") {
                              return ;
                            }
                            if (match$1.NAME !== "NameMapping") {
                              return ;
                            }
                            var match$5 = match$1.VAL;
                            var match$6 = match$5.destination;
                            if (match$6 === undefined) {
                              return ;
                            }
                            if (typeof match$6 !== "object") {
                              return ;
                            }
                            if (match$6.NAME !== "Destination") {
                              return ;
                            }
                            var mappedName$1 = match$5.name;
                            if (mappedName$1 !== undefined && match.VAL === property.id && match$6.VAL === destinationId) {
                              return {
                                      property: property,
                                      oldName: property.name,
                                      newName: mappedName$1,
                                      hasNestedPropertiesNameMapping: hasNestedPropertiesNameMapping
                                    };
                            }
                            
                          }));
            })));
  return Belt_Array.concat(nameMappingWithoutNested, Belt_List.toArray(Belt_List.keepMapU(properties, (function (property) {
                        var nestedProperties = getNestedPropertiesForPropertyList({
                              hd: property,
                              tl: /* [] */0
                            }, model);
                        var hasNestedPropertiesNameMapping = hasSpecificPropertyNameMappingForParticularDestinationId(model, destinationId, nestedProperties, $$event);
                        if (hasNestedPropertiesNameMapping && !Belt_Array.someU(nameMappingWithoutNested, (function (nameMapping) {
                                  return Caml_obj.caml_equal(nameMapping.property, property);
                                }))) {
                          return {
                                  property: property,
                                  oldName: property.name,
                                  newName: property.name,
                                  hasNestedPropertiesNameMapping: true
                                };
                        }
                        
                      }))));
}

function getAllPropertyNameMappings(model, destinationId, destinationIds, eventId, property) {
  var destinationIds$1 = Belt_Option.getWithDefault(destinationIds, /* [] */0);
  var destinationIds$2 = destinationId !== undefined ? ({
        hd: destinationId,
        tl: destinationIds$1
      }) : destinationIds$1;
  return Belt_List.keepMap(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return ;
                }
                var variant = match.NAME;
                if (variant === "PropertyRef") {
                  if (typeof match$1 !== "object") {
                    return ;
                  }
                  if (match$1.NAME !== "NameMapping") {
                    return ;
                  }
                  var match$2 = match$1.VAL;
                  var match$3 = match$2.destination;
                  if (match$3 === undefined) {
                    return ;
                  }
                  var match$4 = match.VAL;
                  var propertyId = match$4[1];
                  var refEventId = match$4[0];
                  if (typeof match$3 === "object") {
                    var mappedName = match$2.name;
                    if (mappedName === undefined) {
                      return ;
                    }
                    var itemId = match$3.VAL;
                    if (!(Caml_obj.caml_equal(refEventId, eventId) && propertyId === property.id && Belt_List.someU(destinationIds$2, (function (targetDestinationId) {
                                return targetDestinationId === itemId;
                              })))) {
                      return ;
                    }
                    var destinationName = ModelUtils.getDestinationNameById(model, itemId);
                    return {
                            TAG: /* DestinationSpecific */1,
                            _0: [
                              destinationName,
                              mappedName
                            ]
                          };
                  }
                  var mappedName$1 = match$2.name;
                  if (mappedName$1 !== undefined && Caml_obj.caml_equal(refEventId, eventId) && propertyId === property.id) {
                    return {
                            TAG: /* AllDestinations */0,
                            _0: mappedName$1
                          };
                  } else {
                    return ;
                  }
                }
                if (variant !== "Property") {
                  return ;
                }
                if (typeof match$1 !== "object") {
                  return ;
                }
                if (match$1.NAME !== "NameMapping") {
                  return ;
                }
                var match$5 = match$1.VAL;
                var match$6 = match$5.destination;
                if (match$6 === undefined) {
                  return ;
                }
                var propertyId$1 = match.VAL;
                if (typeof match$6 === "object") {
                  var mappedName$2 = match$5.name;
                  if (mappedName$2 === undefined) {
                    return ;
                  }
                  var itemId$1 = match$6.VAL;
                  if (!(propertyId$1 === property.id && Belt_List.someU(destinationIds$2, (function (targetDestinationId) {
                              return targetDestinationId === itemId$1;
                            })))) {
                    return ;
                  }
                  var destinationName$1 = ModelUtils.getDestinationNameById(model, itemId$1);
                  return {
                          TAG: /* DestinationSpecific */1,
                          _0: [
                            destinationName$1,
                            mappedName$2
                          ]
                        };
                }
                var mappedName$3 = match$5.name;
                if (mappedName$3 !== undefined && propertyId$1 === property.id) {
                  return {
                          TAG: /* AllDestinations */0,
                          _0: mappedName$3
                        };
                }
                
              }));
}

function remapPropertyName(model, destinationId, destinationIds, eventId, property) {
  var nameMappingType = getAllPropertyNameMappings(model, destinationId, destinationIds, eventId, property);
  var maybeAllDestinationsNameMapping = Belt_List.getByU(nameMappingType, (function (nameMapping) {
          if (nameMapping.TAG === /* AllDestinations */0) {
            return true;
          } else {
            return false;
          }
        }));
  var destinationSpecificNameMappings = Belt_List.keepMapU(nameMappingType, (function (nameMapping) {
          if (nameMapping.TAG === /* AllDestinations */0) {
            return ;
          } else {
            return nameMapping._0;
          }
        }));
  var destinationsMappedNamesSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.mapU(destinationSpecificNameMappings, (function (param) {
                  return param[1];
                }))));
  var maybeNewName;
  var exit = 0;
  if (destinationSpecificNameMappings || !(maybeAllDestinationsNameMapping !== undefined && maybeAllDestinationsNameMapping.TAG === /* AllDestinations */0)) {
    exit = 1;
  } else {
    maybeNewName = maybeAllDestinationsNameMapping._0;
  }
  if (exit === 1) {
    maybeNewName = Belt_SetString.size(destinationsMappedNamesSet) === 1 ? Belt_Option.mapU(Belt_List.get(destinationSpecificNameMappings, 0), (function (param) {
              return param[1];
            })) : (
        maybeAllDestinationsNameMapping !== undefined && maybeAllDestinationsNameMapping.TAG === /* AllDestinations */0 ? maybeAllDestinationsNameMapping._0 : undefined
      );
  }
  return Belt_Option.getWithDefault(maybeNewName, property.name);
}

function keepProperty(destination, property) {
  var match = destination.type_;
  var match$1 = property.name;
  if (match$1 === "mparticle_event_type_id" || match === "MParticle" && match$1 === "schema") {
    return false;
  } else {
    return true;
  }
}

function getNameMappedNameForDestination(includeLocalWorkspaceNameMappingOpt, model, destinationId, $$event) {
  var includeLocalWorkspaceNameMapping = includeLocalWorkspaceNameMappingOpt !== undefined ? includeLocalWorkspaceNameMappingOpt : false;
  return Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    var match$1 = rule.definition;
                    if (match.NAME !== "Event") {
                      return ;
                    }
                    var eventId = match.VAL;
                    var variant = match$1.NAME;
                    if (variant === "NameMapping") {
                      var match$2 = match$1.VAL;
                      var match$3 = match$2.destination;
                      if (match$3 === undefined) {
                        return ;
                      }
                      if (typeof match$3 === "object") {
                        var name = match$2.name;
                        if (name !== undefined && eventId === $$event.id && match$3.VAL === destinationId) {
                          return name;
                        } else {
                          return ;
                        }
                      }
                      var name$1 = match$2.name;
                      if (name$1 !== undefined && eventId === $$event.id) {
                        return name$1;
                      } else {
                        return ;
                      }
                    }
                    if (variant !== "NameInLocalWorkspace") {
                      return ;
                    }
                    var match$4 = match$1.VAL;
                    var match$5 = match$4.workspace;
                    if (match$5 === undefined) {
                      return ;
                    }
                    if (typeof match$5 === "object") {
                      var name$2 = match$4.name;
                      if (name$2 !== undefined && includeLocalWorkspaceNameMapping && eventId === $$event.id && match$5.VAL === destinationId) {
                        return name$2;
                      } else {
                        return ;
                      }
                    }
                    var name$3 = match$4.name;
                    if (name$3 !== undefined && includeLocalWorkspaceNameMapping && eventId === $$event.id) {
                      return name$3;
                    }
                    
                  })));
}

function remapEventName(model, destinationId, $$event) {
  var maybeNewName = getNameMappedNameForDestination(undefined, model, destinationId, $$event);
  return Belt_Option.getWithDefault(maybeNewName, $$event.name);
}

function getSegmentIntegrations(model, source, $$event) {
  var options = source.segmentDestinationOptions;
  return Belt_List.map(options, (function (param) {
                var key = param[0];
                var override = Belt_List.head(Belt_List.keepMap(model.rules, (function (rule) {
                            var match = rule.item;
                            var match$1 = rule.definition;
                            if (typeof match !== "object") {
                              return ;
                            }
                            if (match.NAME !== "Event") {
                              return ;
                            }
                            if (typeof match$1 !== "object") {
                              return ;
                            }
                            if (match$1.NAME !== "SegmentIntegrationOption") {
                              return ;
                            }
                            var match$2 = match$1.VAL;
                            if ($$event.id === match.VAL && key === match$2[0]) {
                              return match$2[1];
                            }
                            
                          })));
                return [
                        key,
                        Belt_Option.getWithDefault(override, param[1])
                      ];
              }));
}

function getMainNameMappedName(propertyName, propertyNameMappings) {
  return Belt_Option.mapWithDefaultU(Belt_List.getBy(propertyNameMappings, (function (nameMap) {
                    if (nameMap.TAG === /* AllDestinations */0) {
                      return true;
                    } else {
                      return false;
                    }
                  })), propertyName, (function (nameMap) {
                if (nameMap.TAG === /* AllDestinations */0) {
                  return nameMap._0;
                } else {
                  return propertyName;
                }
              }));
}

function getDestinationSpecificRules(nameMaps) {
  return Belt_List.toArray(Belt_List.keepMapU(nameMaps, (function (nameMap) {
                    if (nameMap.TAG === /* AllDestinations */0) {
                      return ;
                    } else {
                      return nameMap._0;
                    }
                  })));
}

function getPropertyNameMaps(properties, eventId, destinations, model) {
  return Belt_Array.reduce(properties, undefined, (function (propertyMap, property) {
                var destinationNameMap = getAllPropertyNameMappings(model, undefined, destinations, eventId, property);
                return Belt_MapString.set(propertyMap, property.id, destinationNameMap);
              }));
}

export {
  getEventSpecificNestedPropertyPinnedValue ,
  getEventSpecificNestedPropertyPinnedValue_mapped ,
  getNestedPropertyPinnedValue ,
  getNestedPropertyPinnedValue_mapped ,
  hasPropertyNameMapping ,
  hasSpecificEventNameMappingForParticularDestinationId ,
  getNestedProperties ,
  getNestedPropertiesForPropertyList ,
  hasSpecificPropertyNameMappingForParticularDestinationId ,
  getSpecificPropertyNameMappingForParticularDestinationId ,
  getAllPropertyNameMappings ,
  remapPropertyName ,
  keepProperty ,
  getNameMappedNameForDestination ,
  remapEventName ,
  getSegmentIntegrations ,
  getMainNameMappedName ,
  getDestinationSpecificRules ,
  getPropertyNameMaps ,
  
}
/* AvoModel Not a pure module */
