// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App from "firebase/app";
import FirebaseConfigJs from "app/firebaseConfig.js";

var Transaction = {};

var $$Storage = {};

function onAuthStateChanged(auth, cb) {
  return auth.onAuthStateChanged(function (maybeUser) {
              return Curry._1(cb, (maybeUser == null) ? undefined : Caml_option.some(maybeUser));
            });
}

function unsafeGetCurrentUser(auth) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser));
}

function getCurrentUserToken(auth) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser)).getIdToken();
}

var Auth_googleOauthScopes = {
  hd: "profile",
  tl: {
    hd: "email",
    tl: /* [] */0
  }
};

var Auth = {
  googleOauthScopes: Auth_googleOauthScopes,
  onAuthStateChanged: onAuthStateChanged,
  unsafeGetCurrentUser: unsafeGetCurrentUser,
  getCurrentUserToken: getCurrentUserToken
};

function Collection(Config) {
  var get = function (fb) {
    return fb.collection(Config.name);
  };
  return {
          get: get
        };
}

var Query = {};

function app(param) {
  if (App.default.apps.length !== 0) {
    return App.default.app();
  }
  var app$1 = App.default.initializeApp(FirebaseConfigJs);
  app$1.firestore().settings({
        experimentalAutoDetectLongPolling: true,
        merge: true
      });
  return app$1;
}

function getAuth(param) {
  return app(undefined).auth();
}

import "firebase/auth"
;

import "firebase/firestore"
;

import "firebase/storage"
;

var apiUrl = "/api";

export {
  Transaction ,
  apiUrl ,
  $$Storage ,
  Auth ,
  Collection ,
  Query ,
  app ,
  getAuth ,
  
}
/*  Not a pure module */
