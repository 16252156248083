// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function buttonStyles(size, customStyle, noFocus) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Belt_Option.mapWithDefault(size, Curry._1(Css.style, {
                          hd: Css.fontSize("inherit_"),
                          tl: {
                            hd: Css.letterSpacing(Css.inherit_),
                            tl: /* [] */0
                          }
                        }), $$Text.sizeStyles),
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: Css.paddingTop(Css.px(2)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(2)),
                          tl: {
                            hd: noFocus ? Css.focus(/* [] */0) : Css.focus({
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                      tl: /* [] */0
                                    }
                                  }),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(customStyle === "DarkOrange" ? Styles.Color.darken(Styles.Color.darkOrange, 5) : (
                                            customStyle === "Red" || customStyle === "Warning" ? Styles.Color.darkRed : (
                                                customStyle === "MagentaSecondary" || customStyle === "Magenta" ? Styles.Color.avoPinkShade2 : (
                                                    customStyle === "White" ? Styles.Color.white : (
                                                        customStyle === "Orange" ? Styles.Color.darken(Styles.Color.orange, 5) : (
                                                            customStyle === "Success" ? Styles.Color.green : (
                                                                customStyle === "Purple" ? Styles.Color.primaryPurpleShade2 : (
                                                                    customStyle === "Blue" ? Styles.Color.deepBlue : (
                                                                        customStyle === "Grey" ? Styles.Color.light11 : Styles.Color.light10
                                                                      )
                                                                  )
                                                              )
                                                          )
                                                      )
                                                  )
                                              )
                                          )),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.flexDirection("row"),
                                  tl: {
                                    hd: Css.display("inlineFlex"),
                                    tl: {
                                      hd: Css.disabled({
                                            hd: Css.opacity(0.5),
                                            tl: {
                                              hd: Css.cursor("default"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.cursor("pointer"),
                                        tl: {
                                          hd: Css.color(customStyle === "DarkOrange" ? Styles.Color.darkOrange : (
                                                  customStyle === "Warning" ? Styles.Color.light08 : (
                                                      customStyle === "Magenta" ? Styles.Color.avoPink : (
                                                          customStyle === "White" ? Styles.Color.light07 : (
                                                              customStyle === "Orange" ? Styles.Color.orange : (
                                                                  customStyle === "Red" ? Styles.Color.red : (
                                                                      customStyle === "Success" ? Styles.Color.green : (
                                                                          customStyle === "Purple" ? Styles.Color.primaryPurpleShade1 : (
                                                                              customStyle === "Blue" ? Styles.Color.darkBlue : (
                                                                                  customStyle === "Grey" ? Styles.Color.light10 : (
                                                                                      customStyle === "MagentaSecondary" ? Styles.Color.avoPinkShade2 : Styles.Color.light12
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )),
                                          tl: {
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.backgroundColor("transparent"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.active({
                                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            });
}

var make = React.forwardRef(function (Props, ref_) {
      var onClick = Props.onClick;
      var id = Props.id;
      var buttonStyleOpt = Props.style;
      var size = Props.size;
      var disabledOpt = Props.disabled;
      var noFocusOpt = Props.noFocus;
      var className = Props.className;
      var children = Props.children;
      var buttonStyle = buttonStyleOpt !== undefined ? buttonStyleOpt : "Grey";
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var noFocus = noFocusOpt !== undefined ? noFocusOpt : false;
      var tmp = {
        className: Curry._1(Css.merge, {
              hd: buttonStyles(size, buttonStyle, noFocus),
              tl: {
                hd: Belt_Option.getWithDefault(className, Curry._1(Css.style, /* [] */0)),
                tl: /* [] */0
              }
            }),
        disabled: disabled
      };
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      if (id !== undefined) {
        tmp.id = Caml_option.valFromOption(id);
      }
      if (onClick !== undefined) {
        tmp.onClick = Caml_option.valFromOption(onClick);
      }
      return React.createElement("button", tmp, children);
    });

export {
  buttonStyles ,
  make ,
  
}
/* make Not a pure module */
