// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MappedModules from "../../../shared/MappedModules.mjs";

function getId(domain) {
  return domain.id;
}

function eq(a, b) {
  return a.id === b.id;
}

var include = MappedModules.MakeOrderedMapModule({
      getId: getId,
      eq: eq
    });

var MapModule = include.MapModule;

var has = include.has;

var get = include.get;

var getExn = include.getExn;

var getAtIndex = include.getAtIndex;

var getBy = include.getBy;

var toArray = include.toArray;

var fromArray = include.fromArray;

var toList = include.toList;

var fromList = include.fromList;

var keys = include.keys;

var empty = include.empty;

var update = include.update;

var updateExisting = include.updateExisting;

var prepend = include.prepend;

var append = include.append;

var updateMany = include.updateMany;

var updateManyExisting = include.updateManyExisting;

var mergeMany = include.mergeMany;

var remove = include.remove;

var reduce = include.reduce;

var mapToArray = include.mapToArray;

var mapToMap = include.mapToMap;

var mapToT = include.mapToT;

var forEach = include.forEach;

var keep = include.keep;

var keepMap = include.keepMap;

var some = include.some;

var every = include.every;

var size = include.size;

var sizeToString = include.sizeToString;

var reorderV2 = include.reorderV2;

var reorderBuggy = include.reorderBuggy;

var eq$1 = include.eq;

var toArrayWithKeys = include.toArrayWithKeys;

var encodeDict = include.encodeDict;

var slice = include.slice;

var take = include.take;

export {
  MapModule ,
  has ,
  get ,
  getExn ,
  getAtIndex ,
  getBy ,
  toArray ,
  fromArray ,
  toList ,
  fromList ,
  keys ,
  empty ,
  update ,
  updateExisting ,
  prepend ,
  append ,
  updateMany ,
  updateManyExisting ,
  mergeMany ,
  remove ,
  reduce ,
  mapToArray ,
  mapToMap ,
  mapToT ,
  forEach ,
  keep ,
  keepMap ,
  some ,
  every ,
  size ,
  sizeToString ,
  reorderV2 ,
  reorderBuggy ,
  eq$1 as eq,
  toArrayWithKeys ,
  encodeDict ,
  slice ,
  take ,
  
}
/* include Not a pure module */
