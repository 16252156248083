// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as InspectorIssueType from "./InspectorIssueType.mjs";
import * as InspectorIssueStatus from "./InspectorIssueStatus.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "appVersion",
                t.appVersion
              ],
              tl: {
                hd: [
                  "issueCount",
                  t.issueCount
                ],
                tl: {
                  hd: [
                    "eventCount",
                    t.eventCount
                  ],
                  tl: {
                    hd: [
                      "lastSeen",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), Belt_Option.map(t.lastSeen, (function (prim) {
                                  return prim.toISOString();
                                })))
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function decode(json) {
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("lastSeen", Json_decode.string, param);
        }), json);
  return {
          appVersion: Json_decode.field("appVersion", Json_decode.string, json),
          issueCount: Json_decode.field("issueCount", Json_decode.$$float, json),
          eventCount: Json_decode.field("eventCount", Json_decode.$$float, json),
          lastSeen: Belt_Option.map(__x, (function (prim) {
                  return new Date(prim);
                }))
        };
}

var AppVersionStats = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "issueId",
                t.issueId
              ],
              tl: {
                hd: [
                  "sharedIssueId",
                  t.sharedIssueId
                ],
                tl: {
                  hd: [
                    "schemaId",
                    t.schemaId
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      t.sourceId
                    ],
                    tl: {
                      hd: [
                        "eventName",
                        t.eventName
                      ],
                      tl: {
                        hd: [
                          "propertyName",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), t.propertyName)
                        ],
                        tl: {
                          hd: [
                            "issueType",
                            InspectorIssueType.encode(t.issueType)
                          ],
                          tl: {
                            hd: [
                              "oldestAppVersion",
                              t.oldestAppVersion
                            ],
                            tl: {
                              hd: [
                                "newestAppVersion",
                                t.newestAppVersion
                              ],
                              tl: {
                                hd: [
                                  "firstSeen",
                                  t.firstSeen.toISOString()
                                ],
                                tl: {
                                  hd: [
                                    "lastSeen",
                                    t.lastSeen.toISOString()
                                  ],
                                  tl: {
                                    hd: [
                                      "issueCount",
                                      t.issueCount
                                    ],
                                    tl: {
                                      hd: [
                                        "eventCount",
                                        t.eventCount
                                      ],
                                      tl: {
                                        hd: [
                                          "appVersions",
                                          Json_encode.dict(encode, t.appVersions)
                                        ],
                                        tl: {
                                          hd: [
                                            "issueStatus",
                                            InspectorIssueStatus.encode(t.issueStatus)
                                          ],
                                          tl: {
                                            hd: [
                                              "regression",
                                              t.regression
                                            ],
                                            tl: {
                                              hd: [
                                                "branchIds",
                                                Json_encode.array((function (prim) {
                                                        return prim;
                                                      }), t.branchIds)
                                              ],
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function decode$1(json) {
  return {
          issueId: Json_decode.field("issueId", Json_decode.string, json),
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          propertyName: Json_decode.optional((function (param) {
                  return Json_decode.field("propertyName", Json_decode.string, param);
                }), json),
          issueType: Json_decode.field("issueType", InspectorIssueType.decode, json),
          sharedIssueId: Json_decode.field("sharedIssueId", Json_decode.string, json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          appVersions: Json_decode.field("appVersions", (function (param) {
                  return Json_decode.dict(decode, param);
                }), json),
          newestAppVersion: Json_decode.field("newestAppVersion", Json_decode.string, json),
          oldestAppVersion: Json_decode.field("oldestAppVersion", Json_decode.string, json),
          issueCount: Json_decode.field("issueCount", Json_decode.$$float, json),
          eventCount: Json_decode.field("eventCount", Json_decode.$$float, json),
          lastSeen: new Date(Json_decode.field("lastSeen", Json_decode.string, json)),
          firstSeen: new Date(Json_decode.field("firstSeen", Json_decode.string, json)),
          issueStatus: Json_decode.field("issueStatus", InspectorIssueStatus.decode, json),
          regression: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("regression", Json_decode.bool, param);
                    }), json), false),
          branchIds: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("branch_ids", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), [])
        };
}

function decodeFromPostgres(json) {
  try {
    return {
            TAG: /* Ok */0,
            _0: {
              schemaId: Json_decode.field("schema_id", Json_decode.string, json),
              sourceId: Json_decode.field("source_id", Json_decode.string, json),
              issueId: Json_decode.field("issue_id", Json_decode.string, json),
              sharedIssueId: Json_decode.field("shared_issue_id", Json_decode.string, json)
            }
          };
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    return {
            TAG: /* Error */1,
            _0: err
          };
  }
}

var SharedIssuesIndex = {
  decodeFromPostgres: decodeFromPostgres
};

export {
  AppVersionStats ,
  encode$1 as encode,
  decode$1 as decode,
  SharedIssuesIndex ,
  
}
/* InspectorIssueStatus Not a pure module */
