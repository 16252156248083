// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DidMount from "./DidMount.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CreateMore from "./CreateMore.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as NameInputUtils from "./NameInputUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function NameInput(Props) {
  var name = Props.name;
  var existingNamesOpt = Props.existingNames;
  var expectedCase = Props.expectedCase;
  var forceCaseOpt = Props.forceCase;
  var autoFocusOpt = Props.autoFocus;
  var onChange = Props.onChange;
  var submitLabelOpt = Props.submitLabel;
  var placeholderOpt = Props.placeholder;
  var onCloseOpt = Props.onClose;
  var fullscreenOpt = Props.fullscreen;
  var dirtyOpt = Props.dirty;
  var disabledOpt = Props.disabled;
  var inputStylesOpt = Props.inputStyles;
  var itemType = Props.itemType;
  var actionType = Props.actionType;
  var getPossibleItemLink = Props.getPossibleItemLink;
  var createMore = Props.createMore;
  var existingNames = existingNamesOpt !== undefined ? existingNamesOpt : [];
  var forceCase = forceCaseOpt !== undefined ? forceCaseOpt : false;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var submitLabel = submitLabelOpt !== undefined ? submitLabelOpt : "Update Name";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Event Name";
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  var fullscreen = fullscreenOpt !== undefined ? fullscreenOpt : false;
  var dirty = dirtyOpt !== undefined ? dirtyOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var inputStyles = inputStylesOpt !== undefined ? inputStylesOpt : /* [] */0;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var memberRole = WorkspaceContext.useMemberRole(undefined);
  var memberIsAdmin = memberRole === "Admin";
  var globalSend = GlobalSendContext.use(undefined);
  var hasAvoIntelligence = AppFeatureFlag.useFeatureFlag("AvoIntelligence") && (itemType === "Event" || itemType === "Property");
  var showAdvancedNamingTip = memberIsAdmin && itemType === "Event";
  var forceCasingAvailability = AvoLimits.ForcePreferredCasing.computeAvailability(workspace);
  var forceCase$1 = forceCase && forceCasingAvailability !== "Unavailable";
  var expectedCase$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(expectedCase, NameUtils.getCommonCase__array(existingNames));
        }), [expectedCase]);
  var name$1 = NameUtils.replaceNewlinesWithWhitespace(name);
  var match = React.useReducer((function (state, action) {
          switch (action.TAG | 0) {
            case /* ToggleFocus */0 :
                if (disabled) {
                  return state;
                }
                var hasFocus = action._0;
                return {
                        hasFocus: fullscreen || hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: fullscreen || hasFocus ? state.interactedWith : false,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: state.validation,
                        intelligenceFeedback: state.intelligenceFeedback
                      };
            case /* UpdateName */1 :
                return {
                        hasFocus: state.hasFocus,
                        name: action._0,
                        inputRef: state.inputRef,
                        interactedWith: true,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: "Loading",
                        intelligenceFeedback: "Init"
                      };
            case /* UpdateValidation */2 :
                return {
                        hasFocus: state.hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: state.interactedWith,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: action._0,
                        intelligenceFeedback: state.intelligenceFeedback
                      };
            case /* UpdateIntelligenceFeedback */3 :
                return {
                        hasFocus: state.hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: state.interactedWith,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: state.validation,
                        intelligenceFeedback: action._0
                      };
            
          }
        }), {
        hasFocus: autoFocus,
        name: name$1,
        inputRef: {
          contents: undefined
        },
        interactedWith: false,
        numberOfSuggestions: {
          contents: 0
        },
        validation: "Init",
        intelligenceFeedback: "Init"
      });
  var send = match[1];
  var state = match[0];
  var schemaBundle = SchemaBundleContext.use(undefined);
  React.useEffect((function () {
          if (fullscreen) {
            NameInputUtils.validateInputWithAction(send, existingNames, name$1, state.name, expectedCase$1);
          }
          
        }), []);
  React.useEffect((function () {
          if (state.interactedWith && state.validation === "Loading") {
            NameInputUtils.validateInputWithAction(send, existingNames, name$1, state.name, expectedCase$1);
            if (hasAvoIntelligence) {
              NameInputUtils.debouncedIntelligenceFeedback(send, [
                    schemaBundle,
                    workspace.id,
                    hasAvoIntelligence
                  ], [
                    itemType,
                    actionType
                  ], existingNames, state.name, expectedCase$1);
            }
            
          }
          
        }), [state.name]);
  var isDirty = dirty || state.name !== name$1;
  var focusInput = function (param) {
    var inputRef = state.inputRef.contents;
    if (inputRef !== undefined) {
      Caml_option.valFromOption(inputRef).focus();
      return ;
    }
    
  };
  var handleBlur = function (param) {
    var inputRef = state.inputRef.contents;
    if (inputRef !== undefined) {
      Caml_option.valFromOption(inputRef).blur();
    }
    return Curry._1(send, {
                TAG: /* ToggleFocus */0,
                _0: false
              });
  };
  var reset = function (param) {
    Curry._1(send, {
          TAG: /* UpdateName */1,
          _0: name$1
        });
    setTimeout((function (param) {
            return focusInput(undefined);
          }), 0);
    
  };
  var handleSubmit = function (param) {
    var match = state.validation;
    if (typeof match !== "object") {
      return ;
    }
    if (match.NAME !== "Result") {
      return ;
    }
    var match$1 = match.VAL;
    if (!match$1.isIllegal) {
      Curry._4(onChange, state.name.trim(), Case.getCasingWithWorkspaceCase(state.name, expectedCase$1), !match$1.inconsistentCase, reset);
      return handleBlur(undefined);
    }
    
  };
  var handleEscape = function (explicit) {
    if (explicit || state.name === "") {
      Curry._1(send, {
            TAG: /* UpdateName */1,
            _0: name$1
          });
    }
    handleBlur(undefined);
    return Curry._1(onClose, undefined);
  };
  var logClose = function (closingMethod, changeMade) {
    return AnalyticsRe.nameEditorClosed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, closingMethod, itemType, actionType, Case.toAnalytics(Case.getCasingWithWorkspaceCase(state.name, expectedCase$1)), state.numberOfSuggestions.contents, state.interactedWith ? "Keystroke" : "None", changeMade, schemaBundle.branchId, schemaBundle.schemaId);
  };
  var tmp;
  if (state.hasFocus) {
    var match$1 = state.validation;
    var tmp$1;
    if (match$1 === "Loading") {
      tmp$1 = React.createElement(LoadingCircle.make, {
            size: 12,
            color: Styles.Color.light08
          });
    } else if (match$1 === "Init" || !(isDirty && typeof match$1 === "object" && match$1.NAME === "Result")) {
      tmp$1 = null;
    } else {
      var match$2 = match$1.VAL;
      var name$2 = match$2.conflictingName;
      if (match$2.isEmpty) {
        tmp$1 = React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.red,
              children: null
            }, React.createElement(DidMount.make, {
                  didMount: (function (param) {
                      state.numberOfSuggestions = {
                        contents: state.numberOfSuggestions.contents + 1 | 0
                      };
                      return AnalyticsRe.namingFeedbackReceived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Error", itemType, actionType, undefined, "EmptyName", schemaBundle.branchId, schemaBundle.schemaId);
                    })
                }), "Name can't be empty.");
      } else if (name$2 !== undefined) {
        tmp$1 = React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.red,
                  children: null,
                  key: name$2
                }, React.createElement(DidMount.make, {
                      didMount: (function (param) {
                          state.numberOfSuggestions = {
                            contents: state.numberOfSuggestions.contents + 1 | 0
                          };
                          return AnalyticsRe.namingFeedbackReceived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Error", itemType, actionType, undefined, "AlreadyExists", schemaBundle.branchId, schemaBundle.schemaId);
                        })
                    }), "\"" + name$2 + "\" already exists."), getPossibleItemLink !== undefined ? Belt_Option.mapWithDefault(Curry._1(getPossibleItemLink, name$2), null, (function (param) {
                      return React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.color(Styles.Color.darkBlue),
                                          tl: {
                                            hd: Icon.color(Styles.Color.darkBlue),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css.color(Styles.Color.deepBlue),
                                                    tl: {
                                                      hd: Icon.color(Styles.Color.deepBlue),
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement(Spacer.make, {
                                      width: 4
                                    }), React.createElement(Link.make, {
                                      path: param[0],
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.flexShrink(0.0),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      onClick: (function (param) {
                                          return handleEscape(true);
                                        }),
                                      children: null
                                    }, React.createElement($$Text.make, {
                                          element: "Span",
                                          size: "Small",
                                          children: "Go to" + Belt_Option.mapWithDefault(param[1], "", (function (t) {
                                                  return " " + t;
                                                }))
                                        }), React.createElement(Icon.make, {
                                          type_: "arrowRight",
                                          size: "small"
                                        })));
                    })) : null);
      } else {
        var similarName = Belt_Array.get(match$2.similarNames, 0);
        var warnings = Belt_Array.concatMany([
              match$2.inconsistentCase ? [React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            Curry._1(send, {
                                  TAG: /* UpdateName */1,
                                  _0: NameUtils.getCorrectCase(expectedCase$1, state.name)
                                });
                            focusInput(undefined);
                            AnalyticsRe.namingFeedbackApplied(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Suggestion", itemType, actionType, "ConsistentCasing", NameUtils.getCorrectCase(expectedCase$1, state.name), state.name, schemaBundle.branchId, schemaBundle.schemaId);
                            
                          }),
                        style: "Blue",
                        size: "Small",
                        children: null
                      }, React.createElement(DidMount.make, {
                            didMount: (function (param) {
                                state.numberOfSuggestions = {
                                  contents: state.numberOfSuggestions.contents + 1 | 0
                                };
                                return AnalyticsRe.namingFeedbackReceived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Suggestion", itemType, actionType, Case.toAnalytics(expectedCase$1), "ConsistentCasing", schemaBundle.branchId, schemaBundle.schemaId);
                              })
                          }), (
                        forceCase$1 ? "Use" : "Try"
                      ) + " \"" + NameUtils.getCorrectCase(expectedCase$1, state.name) + "\" for consistent casing.")] : [],
              similarName !== undefined ? [React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: null,
                            key: similarName
                          }, React.createElement(DidMount.make, {
                                didMount: (function (param) {
                                    state.numberOfSuggestions = {
                                      contents: state.numberOfSuggestions.contents + 1 | 0
                                    };
                                    return AnalyticsRe.namingFeedbackReceived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Warning", itemType, actionType, undefined, "SimilarToExisting", schemaBundle.branchId, schemaBundle.schemaId);
                                  })
                              }), "Similar to \"" + similarName + "\"."), getPossibleItemLink !== undefined ? Belt_Option.mapWithDefault(Curry._1(getPossibleItemLink, similarName), null, (function (param) {
                                return React.createElement("span", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.darkBlue),
                                                    tl: {
                                                      hd: Icon.color(Styles.Color.darkBlue),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.deepBlue),
                                                              tl: {
                                                                hd: Icon.color(Styles.Color.deepBlue),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                })
                                          }, React.createElement(Spacer.make, {
                                                width: 6
                                              }), React.createElement(Link.make, {
                                                path: param[0],
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                onClick: (function (param) {
                                                    return handleEscape(true);
                                                  }),
                                                children: null
                                              }, React.createElement($$Text.make, {
                                                    element: "Span",
                                                    size: "Small",
                                                    children: "Go to" + Belt_Option.mapWithDefault(param[1], "", (function (t) {
                                                            return " " + t;
                                                          }))
                                                  }), React.createElement(Icon.make, {
                                                    type_: "arrowRight",
                                                    size: "small"
                                                  })));
                              })) : null)] : []
            ]);
        tmp$1 = React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light10,
              children: Belt_Array.map(warnings, (function (warning) {
                      return React.createElement("div", undefined, warning);
                    }))
            });
      }
    }
    var match$3 = state.validation;
    var tmp$2;
    if (typeof match$3 === "object") {
      var match$4 = match$3.VAL;
      tmp$2 = match$4.isIllegal || forceCase$1 && match$4.inconsistentCase ? true : false;
    } else {
      tmp$2 = true;
    }
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(hasAvoIntelligence ? (
                                showAdvancedNamingTip ? -150 : -100
                              ) : (
                                showAdvancedNamingTip ? -100 : -50
                              ))),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.height(Css.px(50)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(9)),
                          tl: {
                            hd: Css.paddingRight(Css.px(9)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.light02),
                              tl: {
                                hd: Css.borderBottomRightRadius(Css.px(10)),
                                tl: {
                                  hd: Css.borderBottomLeftRadius(Css.px(10)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.gap(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, React.createElement(Button.make, {
              label: "Cancel",
              onClick: (function (param) {
                  handleEscape(true);
                  return logClose("PressCancelButton", false);
                }),
              style: "ghost"
            }), tmp$1, React.createElement(Spacer.make, {
              grow: 1.0
            }), createMore !== undefined ? React.createElement(CreateMore.make, {
                toggled: createMore[0],
                onToggle: createMore[1]
              }) : null, React.createElement(Button.make, {
              disabled: tmp$2,
              label: submitLabel,
              onClick: (function (param) {
                  handleSubmit(undefined);
                  return logClose("PressConfirmButton", name$1 !== state.name);
                })
            }));
  } else {
    tmp = null;
  }
  var tmp$3;
  if (state.hasFocus && hasAvoIntelligence) {
    var match$5 = state.intelligenceFeedback;
    var tmp$4;
    if (typeof match$5 === "object") {
      var feedbackChunks = Belt_Array.keepMap(match$5.VAL.split(/(<.+?>)/g), (function (chunk) {
              return chunk;
            }));
      tmp$4 = React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.width(Css.px(500)),
                  tl: /* [] */0
                })
          }, React.createElement($$Text.make, {
                size: "Small",
                weight: "Semi",
                color: Styles.Color.light11,
                children: Belt_Array.map(feedbackChunks, (function (chunk) {
                        if (!chunk.startsWith("<")) {
                          return chunk;
                        }
                        var cleanName = chunk.replace("<", "").replace(">", "");
                        var displayName = chunk.replace("<", "\"").replace(">", "\"");
                        return React.createElement("a", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("inlineBlock"),
                                          tl: {
                                            hd: Css.padding2(Css.px(0), Css.px(2)),
                                            tl: {
                                              hd: Css.cursor("pointer"),
                                              tl: {
                                                hd: Css.color(Styles.Color.darkBlue),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }),
                                    onClick: (function (param) {
                                        AnalyticsRe.namingFeedbackApplied(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Suggestion", itemType, actionType, "Intelligence", cleanName, state.name, schemaBundle.branchId, schemaBundle.schemaId);
                                        return Curry._1(send, {
                                                    TAG: /* UpdateName */1,
                                                    _0: cleanName
                                                  });
                                      })
                                  }, React.createElement($$Text.make, {
                                        size: "Small",
                                        weight: "Semi",
                                        color: Styles.Color.darkBlue,
                                        children: displayName
                                      }));
                      }))
              }), React.createElement(DidMount.make, {
                didMount: (function (param) {
                    state.numberOfSuggestions = {
                      contents: state.numberOfSuggestions.contents + 1 | 0
                    };
                    return AnalyticsRe.namingFeedbackReceived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Suggestion", itemType, actionType, undefined, "Intelligence", schemaBundle.branchId, schemaBundle.schemaId);
                  })
              }));
    } else {
      tmp$4 = match$5 === "Init" ? React.createElement($$Text.make, {
              size: "Small",
              color: Styles.Color.light09,
              children: "Start typing to receive feedback from Avo Intelligence"
            }) : React.createElement(LoadingCircle.make, {
              size: 12,
              color: Styles.Color.paleBlue
            });
    }
    tmp$3 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(showAdvancedNamingTip ? -100 : -50)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.height(Css.px(50)),
                        tl: {
                          hd: Css.padding(Css.px(20)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(1), Css.px(6)),
                    tl: {
                      hd: Css.marginRight(Css.px(8)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.darkBlue),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  color: Styles.Color.white,
                  children: "AI BETA"
                })), tmp$4);
  } else {
    tmp$3 = null;
  }
  return React.createElement("div", undefined, state.hasFocus ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("fixed"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.1)),
                                      tl: {
                                        hd: Css.cursor("pointer"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    onClick: (function (param) {
                        handleEscape(false);
                        return logClose("ClickOutside", false);
                      })
                  }) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.zIndex(state.hasFocus ? Styles.ZIndex.aboveAll : 0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  onClick: (function ($$event) {
                      $$event.preventDefault();
                      
                    })
                }, React.createElement("input", {
                      ref: (function (inputRef) {
                          state.inputRef.contents = (inputRef == null) ? undefined : Caml_option.some(inputRef);
                          
                        }),
                      className: Curry._1(Css.style, Belt_List.concatMany([
                                Styles.input,
                                {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.top(Css.px(0)),
                                    tl: {
                                      hd: Css.right(Css.px(0)),
                                      tl: {
                                        hd: Css.bottom(Css.px(0)),
                                        tl: {
                                          hd: Css.left(Css.px(0)),
                                          tl: {
                                            hd: Css.width(Css.pct(100)),
                                            tl: {
                                              hd: Css.padding(Css.px(20)),
                                              tl: {
                                                hd: Css.paddingLeft(Css.px(24)),
                                                tl: {
                                                  hd: Css.outlineWidth(Css.px(0)),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: {
                                                      hd: Css.fontSize(Styles.FontSize.medium),
                                                      tl: {
                                                        hd: Css.backgroundColor(state.hasFocus ? Styles.Color.white : "transparent"),
                                                        tl: {
                                                          hd: Css.borderTopLeftRadius(Styles.Border.radius),
                                                          tl: {
                                                            hd: Css.borderTopRightRadius(Styles.Border.radius),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                inputStyles
                              ])),
                      autoFocus: autoFocus,
                      disabled: disabled,
                      placeholder: name$1 === "" ? Case.to_(expectedCase$1, placeholder) : name$1,
                      value: state.hasFocus ? state.name : name$1,
                      onKeyDown: (function ($$event) {
                          var match = $$event.key;
                          switch (match) {
                            case "Enter" :
                                handleSubmit(undefined);
                                return logClose("PressEnterKey", name$1 !== state.name);
                            case "Escape" :
                                $$event.stopPropagation();
                                handleEscape(true);
                                return logClose("PressEscapeKey", false);
                            default:
                              return ;
                          }
                        }),
                      onFocus: (function (param) {
                          return Curry._1(send, {
                                      TAG: /* ToggleFocus */0,
                                      _0: true
                                    });
                        }),
                      onChange: (function ($$event) {
                          return Curry._1(send, {
                                      TAG: /* UpdateName */1,
                                      _0: $$event.target.value
                                    });
                        })
                    })), tmp, state.hasFocus && showAdvancedNamingTip ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.left(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(-50)),
                                tl: {
                                  hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.light02),
                                    tl: {
                                      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                                      tl: {
                                        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                        tl: {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.justifyContent("spaceBetween"),
                                              tl: {
                                                hd: Css.height(Css.px(50)),
                                                tl: {
                                                  hd: Css.paddingLeft(Css.px(24)),
                                                  tl: {
                                                    hd: Css.paddingRight(Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.gap(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light11,
                            children: "Set advanced rules for your event naming framework"
                          }), React.createElement(SimpleTooltip.make, {
                            tooltip: "Using the naming framework will enable you to define a name structure, predefined allowed values, blocked words and custom casing rules",
                            children: React.createElement(Icon.make, {
                                  type_: "information",
                                  size: "small",
                                  color: Styles.Color.light10
                                })
                          })), React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenSlideOver */6,
                                  _0: {
                                    NAME: "DiscrepanciesConfig",
                                    VAL: "eventNamingConventions"
                                  }
                                });
                            return Curry._1(onClose, undefined);
                          }),
                        style: "Blue",
                        size: "Small",
                        children: "Advanced rules ->"
                      })) : null, tmp$3);
}

var make = NameInput;

export {
  make ,
  
}
/* Css Not a pure module */
