// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as CreateEventForVariantUseCase from "../../model/src/variants/createEventForVariantUseCase.mjs";

function getEventNamespaceWithIdAndIsVariant(events) {
  var uniqueNames = Belt_List.keepMapU(events, (function ($$event) {
          return Belt_Option.map($$event.uniqueName, (function (uniqueName) {
                        return [
                                uniqueName,
                                $$event.id,
                                false
                              ];
                      }));
        }));
  var eventNames = Belt_List.mapU(events, (function ($$event) {
          return [
                  $$event.name,
                  $$event.id,
                  false
                ];
        }));
  var eventVariantNames = BeltListExtensions.flatMapU(events, (function ($$event) {
          var variants = $$event.variants;
          if (variants.length !== 0) {
            return Belt_List.fromArray(Belt_Array.mapU(variants, (function (variant) {
                              return [
                                      CreateEventForVariantUseCase.variantEventUniqueName($$event, variant.nameSuffix),
                                      VariantEventIdUseCase.variantEventId({
                                            baseEventId: $$event.id,
                                            variantId: variant.id
                                          }),
                                      true
                                    ];
                            })));
          } else {
            return /* [] */0;
          }
        }));
  return Belt_List.concatMany([
              uniqueNames,
              eventNames,
              eventVariantNames
            ]);
}

function getEventNamespaceWithIds(events) {
  return Belt_List.map(getEventNamespaceWithIdAndIsVariant(events), (function (param) {
                return [
                        param[0],
                        param[1]
                      ];
              }));
}

function getEventNamespace(events) {
  return Belt_List.map(getEventNamespaceWithIdAndIsVariant(events), (function (param) {
                return param[0];
              }));
}

function getEventNamespaceWithIsVariant(events) {
  return Belt_List.map(getEventNamespaceWithIdAndIsVariant(events), (function (param) {
                return [
                        param[0],
                        param[2]
                      ];
              }));
}

export {
  getEventNamespaceWithIdAndIsVariant ,
  getEventNamespaceWithIds ,
  getEventNamespace ,
  getEventNamespaceWithIsVariant ,
  
}
/* CreateEventForVariantUseCase Not a pure module */
