// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Diff from "diff";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function getChangeKey(index, change) {
  return String(index) + (change.value + ((
              change.added ? "-added" : ""
            ) + (
              change.removed ? "-removed" : ""
            )));
}

var hasDiff = Caml_obj.caml_notequal;

function printLines(from, to_) {
  return Belt_Array.map(Diff.diffLines(from, to_), (function (lines) {
                  return Belt_Array.map(Belt_Array.keep(lines.value.split("\n"), (function (line) {
                                      return line.trim() !== "";
                                    })), (function (line) {
                                  return (
                                          lines.added ? "+" : (
                                              lines.removed ? "-" : " "
                                            )
                                        ) + " " + line;
                                })).join("\n");
                })).join("\n");
}

function renderStringDiff(customDisplayOpt, strikethroughOpt, wordDiffOpt, from, to_) {
  var customDisplay = customDisplayOpt !== undefined ? customDisplayOpt : "inline";
  var strikethrough = strikethroughOpt !== undefined ? strikethroughOpt : false;
  var wordDiff = wordDiffOpt !== undefined ? wordDiffOpt : true;
  if (from === "") {
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.display(customDisplay),
                      tl: {
                        hd: Css.color(Styles.Color.darkGreen),
                        tl: /* [] */0
                      }
                    })
              }, to_);
  }
  if (to_ === "") {
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.display(customDisplay),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.lightRed),
                        tl: {
                          hd: Css.color(Styles.Color.darkRed),
                          tl: {
                            hd: Css.textDecoration(strikethrough ? "lineThrough" : "none"),
                            tl: /* [] */0
                          }
                        }
                      }
                    })
              }, from);
  }
  var changes = wordDiff ? Diff.diffWordsWithSpace(from, to_) : Diff.diffLines(from, to_);
  return Belt_Array.mapWithIndexU(changes, (function (index, change) {
                var match = change.added;
                var match$1 = change.removed;
                return React.createElement("span", {
                            key: getChangeKey(index, change),
                            className: Curry._1(Css.style, match ? ({
                                      hd: Css.display(customDisplay),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.lightGreen),
                                        tl: {
                                          hd: Css.color(Styles.Color.darkGreen),
                                          tl: /* [] */0
                                        }
                                      }
                                    }) : (
                                    match$1 ? ({
                                          hd: Css.display(customDisplay),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.lightRed),
                                            tl: {
                                              hd: Css.color(Styles.Color.darkRed),
                                              tl: {
                                                hd: Css.textDecoration(strikethrough ? "lineThrough" : "none"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }) : /* [] */0
                                  ))
                          }, change.value);
              }));
}

export {
  getChangeKey ,
  hasDiff ,
  printLines ,
  renderStringDiff ,
  
}
/* Css Not a pure module */
