// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RouterTypes from "../../app/src/RouterTypes.mjs";

function get(baseRoute, branch, schemaRoute, drawerItems) {
  return "/" + Belt_Array.keep(typeof baseRoute === "object" ? (
                  baseRoute.NAME === "schema" ? [
                      RouterTypes.BaseRoute.toUrlFragment(baseRoute),
                      Belt_Option.mapWithDefault(branch, "", (function (branch) {
                              return RouterTypes.Branch.toUrlFragment(branch);
                            })),
                      Belt_Option.mapWithDefault(schemaRoute, "", (function (schemaRoute) {
                              return RouterTypes.SchemaRoute.toUrlFragment(schemaRoute);
                            })),
                      RouterTypes.DrawerItems.toUrlFragment(drawerItems)
                    ] : [RouterTypes.BaseRoute.toUrlFragment(baseRoute)]
                ) : [RouterTypes.BaseRoute.toUrlFragment(baseRoute)], (function (i) {
                  return i !== "";
                })).join("/");
}

function getFullPath(baseRoute, branch, schemaRoute, drawerItems) {
  return window.location.origin + get(baseRoute, branch, schemaRoute, drawerItems);
}

var Link = {
  get: get,
  getFullPath: getFullPath
};

var domain = "https://avo.app";

export {
  domain ,
  Link ,
  
}
/* No side effect */
