// Generated by ReScript, PLEASE EDIT WITH CARE


function getTimeoutValueMs(initialBackoffOpt, retryNumber) {
  var initialBackoff = initialBackoffOpt !== undefined ? initialBackoffOpt : 5000;
  if (retryNumber > 5) {
    return 300000;
  } else {
    return initialBackoff * Math.pow(2.0, retryNumber - 1 | 0) | 0;
  }
}

export {
  getTimeoutValueMs ,
  
}
/* No side effect */
