// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ValidationConfigV2 from "./ValidationConfigV2.mjs";

function getOptionForCasing($$case, nameType, commonCase) {
  return [
          {
            NAME: "Label",
            VAL: $$case === "None" ? (
                nameType === "advancedAllowedValues" ? "Custom" : (
                    nameType === "simple" || nameType === "advancedCase" ? "Inherit (" + Case.toUILabel(commonCase) + ")" : Case.toUILabel($$case)
                  )
              ) : Case.toUILabel($$case)
          },
          Case.tToJs($$case)
        ];
}

function ignoreAllowedValues(nameComponents) {
  return Belt_Array.map(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return nameComponent;
                }
                var nameBuildingBlock = nameComponent.VAL;
                return {
                        NAME: "nameBuildingBlock",
                        VAL: {
                          name: nameBuildingBlock.name,
                          case: nameBuildingBlock.case,
                          allowedValues: Curry._1(ValidationConfigV2.Rule.empty, {
                                NAME: "allowedValues",
                                VAL: []
                              }),
                          blockedValues: Curry._1(ValidationConfigV2.Rule.empty, {
                                NAME: "blockedValues",
                                VAL: []
                              })
                        }
                      };
              }));
}

function getFramework(nameComponents, $$case) {
  var nameComponentCmp = function (a, b) {
    return JSON.stringify(ValidationConfigV2.NameComponent.encode(a)) === JSON.stringify(ValidationConfigV2.NameComponent.encode(b));
  };
  var match = Belt_Array.keepMap(nameComponents, (function (component) {
          if (component.NAME === "separator") {
            return ;
          } else {
            return component.VAL.name.toLowerCase();
          }
        }));
  if (match.length !== 2) {
    return "custom";
  }
  var match$1 = match[0];
  switch (match$1) {
    case "action" :
        var match$2 = match[1];
        if (match$2 === "object" && typeof $$case === "object" && $$case.NAME === "simple" && Belt_Array.eq(ValidationConfigV2.NameComponent.actionObject($$case.VAL), ignoreAllowedValues(nameComponents), nameComponentCmp)) {
          return "actionObject";
        } else {
          return "custom";
        }
    case "object" :
        var match$3 = match[1];
        if (match$3 === "action" && typeof $$case === "object" && $$case.NAME === "simple" && Belt_Array.eq(ValidationConfigV2.NameComponent.objectAction($$case.VAL), ignoreAllowedValues(nameComponents), nameComponentCmp)) {
          return "objectAction";
        } else {
          return "custom";
        }
    default:
      return "custom";
  }
}

function caseToAnalytics($$case) {
  if ($$case === "Capital") {
    return "CapitalCase";
  } else if ($$case === "Kebab") {
    return "KebabCase";
  } else if ($$case === "Sentence") {
    return "SentenceCase";
  } else if ($$case === "Constant") {
    return "ConstantCase";
  } else if ($$case === "Lower") {
    return "LowerCase";
  } else if ($$case === "Snake") {
    return "SnakeCase";
  } else if ($$case === "Pascal") {
    return "PascalCase";
  } else if ($$case === "Title") {
    return "TitleCase";
  } else if ($$case === "Header") {
    return "HeaderCase";
  } else if ($$case === "None") {
    return "Inherited";
  } else if ($$case === "Upper") {
    return "UpperCase";
  } else {
    return "CamelCase";
  }
}

function nameComponentsToAnalyticsCase(nameComponents) {
  var isBasic = ValidationConfigV2.NameComponent.hasBasicNameCasing(nameComponents);
  var $$case = Belt_Option.getWithDefault(ValidationConfigV2.NameComponent.getSimpleNameCasing(nameComponents), "None");
  if ($$case === "None" && !isBasic) {
    return "Advanced";
  } else {
    return caseToAnalytics($$case);
  }
}

function nameComponentsToAnalyticsNamingConvention(nameComponents) {
  var isBasic = ValidationConfigV2.NameComponent.hasBasicNameCasing(nameComponents);
  var $$case = ValidationConfigV2.NameComponent.getCase(nameComponents);
  var framework = getFramework(nameComponents, $$case);
  var hasAllowedValues = ValidationConfigV2.NameComponent.getAllowedValues(nameComponents).length !== 0;
  var hasBlockedValues = ValidationConfigV2.NameComponent.getBlockedValues(nameComponents).length !== 0;
  var match = hasAllowedValues || hasBlockedValues;
  if (isBasic) {
    return "Basic";
  } else if (framework === "custom") {
    return "CustomFramework";
  } else if (match) {
    return "PredefinedFrameworkWithPredefinedValues";
  } else {
    return "PredefinedFramework";
  }
}

function simplifyNameComponents(cleanAllowedValuesOpt, nameComponents) {
  var cleanAllowedValues = cleanAllowedValuesOpt !== undefined ? cleanAllowedValuesOpt : false;
  return Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                if (typeof nameComponent !== "object") {
                  return nameComponent;
                }
                if (nameComponent.NAME !== "nameBuildingBlock") {
                  return nameComponent;
                }
                var buildingBlock = nameComponent.VAL;
                return {
                        NAME: "nameBuildingBlock",
                        VAL: {
                          name: buildingBlock.name,
                          case: buildingBlock.case,
                          allowedValues: {
                            value: {
                              NAME: "allowedValues",
                              VAL: cleanAllowedValues ? Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.keep(Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getAllowedValues, buildingBlock), (function (v) {
                                                return v !== "";
                                              })))) : Curry._1(ValidationConfigV2.Value.getAllowedValues, buildingBlock.allowedValues.value)
                            },
                            severity: buildingBlock.allowedValues.severity
                          },
                          blockedValues: buildingBlock.blockedValues
                        }
                      };
              }));
}

var _map = {"basic":"basic","advanced":"advanced"};

function conventionToJs(param) {
  return param;
}

function conventionFromJs(param) {
  return _map[param];
}

function getNameComponents(cleanAllowedValuesOpt, conventionType, simple, advanced) {
  var cleanAllowedValues = cleanAllowedValuesOpt !== undefined ? cleanAllowedValuesOpt : false;
  var tmp;
  if (conventionType === "advanced") {
    tmp = advanced;
  } else {
    var init = Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.empty, "Snake");
    tmp = [{
        NAME: "nameBuildingBlock",
        VAL: {
          name: init.name,
          case: simple,
          allowedValues: init.allowedValues,
          blockedValues: init.blockedValues
        }
      }];
  }
  return simplifyNameComponents(cleanAllowedValues, tmp);
}

function validateComponents(nameComponents) {
  return Belt_Array.concatMany(Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                    if (nameComponent.NAME === "separator") {
                      return ;
                    }
                    var match = nameComponent.VAL;
                    var nameValidation = match.name.trim() === "" ? [{
                          NAME: "error",
                          VAL: "nameMissing"
                        }] : [];
                    var $$case = Curry._1(ValidationConfigV2.Value.getCase, match.case.value);
                    var allowedValuesValidation;
                    if ($$case !== "None") {
                      var allowedValues = Belt_Array.keep(Curry._1(ValidationConfigV2.Value.getAllowedValues, match.allowedValues.value), (function (v) {
                              return v.trim() !== "";
                            }));
                      allowedValuesValidation = Belt_Array.keepMap(allowedValues, (function (allowedValue) {
                              if (Case.to_($$case, allowedValue) !== allowedValue) {
                                return {
                                        NAME: "error",
                                        VAL: {
                                          NAME: "caseMismatch",
                                          VAL: {
                                            NAME: "allowedValue",
                                            VAL: allowedValue
                                          }
                                        }
                                      };
                              }
                              
                            }));
                    } else {
                      allowedValuesValidation = [];
                    }
                    var arr = Belt_Array.concat(nameValidation, allowedValuesValidation);
                    if (arr.length === 0) {
                      return ;
                    } else {
                      return arr;
                    }
                  })));
}

function ruleToAnalyticsAction(rule) {
  var match = rule.severity;
  if (match === "enforce") {
    return "Enforce";
  } else if (match === "warning") {
    return "Warning";
  } else {
    return "Off";
  }
}

function getCasedValue($$case, newValue) {
  if ($$case === "None") {
    return newValue;
  }
  var separator = Case.getCaseSeparator($$case);
  var chars = newValue.split("");
  var beforeLastValidated = Case.to_($$case, Belt_Array.slice(chars, 0, chars.length - 1 | 0).join(""));
  var match = Belt_Array.get(chars, chars.length - 1 | 0);
  if (match !== undefined) {
    if (match === " ") {
      if (separator !== undefined) {
        return beforeLastValidated + separator;
      } else {
        return beforeLastValidated;
      }
    } else if (separator !== undefined && match === separator) {
      return beforeLastValidated + separator;
    } else {
      return Case.to_($$case, newValue);
    }
  } else {
    return Case.to_($$case, newValue);
  }
}

export {
  getOptionForCasing ,
  ignoreAllowedValues ,
  getFramework ,
  caseToAnalytics ,
  nameComponentsToAnalyticsCase ,
  nameComponentsToAnalyticsNamingConvention ,
  simplifyNameComponents ,
  conventionToJs ,
  conventionFromJs ,
  getNameComponents ,
  validateComponents ,
  ruleToAnalyticsAction ,
  getCasedValue ,
  
}
/* Case Not a pure module */
