// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoEngagement__Types from "./AvoEngagement__Types.mjs";

var next = AvoEngagement__Types.Block.Transition.next;

var previous = AvoEngagement__Types.Block.Transition.previous;

var complete = AvoEngagement__Types.Block.Transition.complete;

var step1_transitions = [Curry._1(next, {
        NAME: "block",
        VAL: "step-2"
      })];

var step1_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Save your filtered view"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [{
              type_: "paragraph",
              children: [{
                  text: "Save this filtered view to easily revisit it or share it with your team, or to set up alerts for new issues that match the parameters you defined",
                  italic: false,
                  bold: false
                }]
            }]
        }
      }
    ]);

var step1 = {
  id: "step-1",
  transitions: step1_transitions,
  fields: step1_fields
};

var step2_transitions = [
  Curry._1(next, {
        NAME: "block",
        VAL: "step-3"
      }),
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-1"
      })
];

var step2_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Set up alerts for your saved view"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [
            {
              type_: "paragraph",
              children: [{
                  text: "Once you've saved a view, you can set up alerts for your saved view",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "That way, you and your team never miss a new issue that matches the filter parameters",
                  italic: false,
                  bold: false
                }]
            }
          ]
        }
      }
    ]);

var step2 = {
  id: "step-2",
  transitions: step2_transitions,
  fields: step2_fields
};

var step3_transitions = [
  Curry._1(complete, undefined),
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-2"
      })
];

var step3_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "View all your saved views"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [{
              type_: "paragraph",
              children: [{
                  text: "Navigate here to find all views you and your team have created",
                  italic: false,
                  bold: false
                }]
            }]
        }
      }
    ]);

var step3 = {
  id: "step-3",
  transitions: step3_transitions,
  fields: step3_fields
};

var content_blocks = Curry._1(AvoEngagement__Types.Blocks.fromArray, [
      step1,
      step2,
      step3
    ]);

var content = {
  id: "how-to-save-a-view-and-get-alerts",
  version: 1,
  rootId: "step-1",
  blocks: content_blocks
};

export {
  content ,
  
}
/* step1 Not a pure module */
