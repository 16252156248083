// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";

var backdropStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.zIndex(Styles.ZIndex.aboveAll),
                      tl: {
                        hd: Css.backgroundColor({
                              NAME: "rgba",
                              VAL: [
                                0,
                                0,
                                0,
                                {
                                  NAME: "num",
                                  VAL: 0.1
                                }
                              ]
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.maxWidth(Css.pct(90)),
          tl: {
            hd: Css.width(Css.px(480)),
            tl: {
              hd: Css.maxHeight(Css.pct(100)),
              tl: {
                hd: Css.boxShadow(Styles.Shadow.standard),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.cursor("default"),
                    tl: {
                      hd: Css.overflow("hidden"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function GenericModal(Props) {
  var onClose = Props.onClose;
  var children = Props.children;
  return React.createElement("div", {
              className: backdropStyles,
              onClick: (function (param) {
                  return Curry._1(onClose, undefined);
                })
            }, React.createElement("div", {
                  className: contentStyles,
                  onClick: (function (prim) {
                      prim.stopPropagation();
                      
                    })
                }, children(onClose)));
}

var make = GenericModal;

export {
  backdropStyles ,
  contentStyles ,
  make ,
  
}
/* backdropStyles Not a pure module */
