// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Router from "../Router.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as App from "firebase/app";
import * as InspectorIssue from "../../../shared/models/InspectorIssue.mjs";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as InspectorViewModel from "./InspectorViewModel.mjs";
import * as InspectorIssueStatus from "../../../shared/models/InspectorIssueStatus.mjs";
import * as AnalyticsUtilsBundles from "../analyticsUtilsBundles.mjs";
import * as GetInspectorEventShapesEndpoint from "../../../shared/endpoints/GetInspectorEventShapesEndpoint.mjs";
import * as UpdateInspectorIssueStatusEndpoint from "../../../shared/endpoints/UpdateInspectorIssueStatusEndpoint.mjs";

function updateStatsCache(schemaId, json) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("inspectorStats").doc("stats").set({
              stats: json,
              lastUpdatedAt: new Date()
            });
}

function getFreshStats(schemaId) {
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                    return fetch("/api/workspaces/" + schemaId + "/inspector/stats", Fetch.RequestInit.make(/* Get */0, [
                                      [
                                        "Accept",
                                        "application/json"
                                      ],
                                      [
                                        "Content-Type",
                                        "application/json"
                                      ],
                                      [
                                        "Authorization",
                                        "Bearer " + token
                                      ]
                                    ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                  }).then(function (res) {
                  var unknownErrorCode = res.status;
                  if (unknownErrorCode !== 200) {
                    if (unknownErrorCode !== 401) {
                      console.error("Internal error from API", unknownErrorCode);
                      return Promise.resolve({
                                  NAME: "Error",
                                  VAL: "Unknown"
                                });
                    } else {
                      return Promise.resolve({
                                  NAME: "Error",
                                  VAL: "InvalidToken"
                                });
                    }
                  } else {
                    return res.json().then(function (json) {
                                  return Promise.resolve([
                                              Json_decode.array(InspectorViewModel.InspectorStats.decode, json),
                                              json
                                            ]);
                                }).then(function (param) {
                                updateStatsCache(schemaId, param[1]);
                                return Promise.resolve({
                                            NAME: "Success",
                                            VAL: param[0]
                                          });
                              });
                  }
                }), (function (error) {
                console.error("/stats fetch error", error);
                return Promise.resolve({
                            NAME: "Error",
                            VAL: "Unknown"
                          });
              }));
}

function getStatsWithCache(schemaId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("inspectorStats").doc("stats").get().then(function (snapshot) {
                if (!snapshot.exists) {
                  return Promise.resolve(undefined);
                }
                var data = snapshot.data();
                var tmp;
                try {
                  tmp = Json_decode.array(InspectorViewModel.InspectorStats.decode, data.stats);
                }
                catch (exn){
                  tmp = undefined;
                }
                return Promise.resolve(tmp);
              }).then(function (maybeStatsCache) {
              if (maybeStatsCache === undefined) {
                return getFreshStats(schemaId);
              }
              var hasSeenProd = Belt_Array.some(Belt_Array.concatMany(Belt_Array.map(maybeStatsCache, (function (sourceStat) {
                              return sourceStat.envs;
                            }))), (function (env) {
                      return env.env === "prod";
                    }));
              if (!hasSeenProd) {
                getFreshStats(schemaId);
              }
              return Promise.resolve({
                          NAME: "Success",
                          VAL: maybeStatsCache
                        });
            });
}

function getApiKey(schemaId, sourceId) {
  return Firebase.app(undefined).firestore().collection("apiKeys").where("schemaId", "==", schemaId).where("sourceId", "==", sourceId).get().then(function (snapshot) {
              return Promise.resolve(Belt_Option.map(Belt_Array.get(Belt_Array.map(snapshot.docs, (function (prim) {
                                        return prim.data();
                                      })), 0), (function (apiKey) {
                                return apiKey.id;
                              })));
            });
}

function generateApiKey(schemaId, sourceId, schemaName, sourceName) {
  var keyRef = Firebase.app(undefined).firestore().collection("apiKeys").doc();
  var id = keyRef.id;
  var key = {
    createdAt: App.default.firestore.FieldValue.serverTimestamp(),
    createdBy: Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid,
    id: id,
    schemaId: schemaId,
    sourceId: sourceId
  };
  return keyRef.set(key).then(function (param) {
              var sourceBundle = AnalyticsUtilsBundles.source(sourceId, sourceName);
              AnalyticsRe.inspectorApiKeyGenerated(sourceBundle.sourceId, sourceBundle.sourceName, Date.now(), id, schemaId, schemaName, new Date().toISOString(), schemaId);
              return Promise.resolve(id);
            });
}

function getApiOrGenerateKey(schemaId, sourceId, schemaName, sourceName) {
  return getApiKey(schemaId, sourceId).then(function (maybeApiKey) {
              if (maybeApiKey !== undefined) {
                return Promise.resolve(maybeApiKey);
              } else {
                return generateApiKey(schemaId, sourceId, schemaName, sourceName);
              }
            });
}

function withToken(param) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken();
}

function getIssues(statusOpt, schemaId) {
  var status = statusOpt !== undefined ? Caml_option.valFromOption(statusOpt) : undefined;
  return $$Promise.$$catch(withToken(undefined).then(function (token) {
                    return fetch("/api/workspaces/" + schemaId + "/inspector/issues/v4" + (
                                status !== undefined ? "?status=" + InspectorIssueStatus.StatusQueryParam.toString(status) : ""
                              ), Fetch.RequestInit.make(/* Get */0, [
                                      [
                                        "Accept",
                                        "application/json"
                                      ],
                                      [
                                        "Accept-Encoding",
                                        "gzip"
                                      ],
                                      [
                                        "Content-Type",
                                        "application/json"
                                      ],
                                      [
                                        "Authorization",
                                        "Bearer " + token
                                      ]
                                    ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                  }).then(function (res) {
                  var internalError = res.status;
                  if (internalError !== 200) {
                    if (internalError >= 500) {
                      console.error("Internal error recevied from inspector API");
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* Internal */0
                                });
                    } else if (internalError !== 400 && internalError !== 404) {
                      console.warn("Received an unfamiliar status code from Inspector API", internalError);
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* Internal */0
                                });
                    } else {
                      console.warn("Table does not exist");
                      return Promise.resolve({
                                  NAME: "error",
                                  VAL: /* NotFound */1
                                });
                    }
                  } else {
                    return res.json().then(function (json) {
                                return {
                                        NAME: "success",
                                        VAL: Json_decode.field("issues", (function (param) {
                                                return Json_decode.array(InspectorIssue.decode, param);
                                              }), json)
                                      };
                              });
                  }
                }), (function (error) {
                console.error("/inspector/issues fetch error", error);
                return Promise.resolve({
                            NAME: "error",
                            VAL: /* Internal */0
                          });
              }));
}

var ClientError = /* @__PURE__ */Caml_exceptions.create("InspectorViewApi.ClientError");

var ServerError = /* @__PURE__ */Caml_exceptions.create("InspectorViewApi.ServerError");

function getIssue(issueId) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var url = "/api/workspaces/" + schemaId + "/inspector/issues/v3/" + issueId;
  return withToken(undefined).then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Get */0, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (result) {
                            var status = result.status;
                            if (status >= 400 && status < 500) {
                              throw {
                                    RE_EXN_ID: ClientError,
                                    _1: status,
                                    Error: new Error()
                                  };
                            }
                            if (status >= 500) {
                              throw {
                                    RE_EXN_ID: ServerError,
                                    _1: status,
                                    Error: new Error()
                                  };
                            }
                            return result.json();
                          }).then(InspectorIssue.decode);
            });
}

function getRelatedIssues(sharedIssueId) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var url = "/api/workspaces/" + schemaId + "/inspector/issues/sharedId/" + sharedIssueId;
  return withToken(undefined).then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Get */0, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                            return prim.json();
                          }).then(function (json) {
                          return Json_decode.array(InspectorIssue.decode, json);
                        });
            });
}

function updateIssueStatus(status, trigger, issueId) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var url = "/api/workspaces/" + schemaId + "/inspector/issues/" + issueId + "/status";
  return withToken(undefined).then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Patch */8, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], Caml_option.some(JSON.stringify(UpdateInspectorIssueStatusEndpoint.RequestBody.encode({
                                                status: status,
                                                trigger: trigger
                                              }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                            return prim.json();
                          }).then(UpdateInspectorIssueStatusEndpoint.ResponseBody.decode);
            });
}

function multiUpdateIssueStatus(status, trigger, issueIds) {
  return withToken(undefined).then(function (token) {
              return fetch("/api/workspaces/" + Router.Schema.getSchemaId(undefined) + "/inspector/issues/status", Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], Caml_option.some(JSON.stringify(Curry._1(UpdateInspectorIssueStatusEndpoint.MultiUpdate.RequestBody.encode, {
                                                issueIds: issueIds,
                                                status: status,
                                                trigger: trigger
                                              }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                            return prim.json();
                          }).then(function (param) {
                          return Curry._1(UpdateInspectorIssueStatusEndpoint.MultiUpdate.ResponseBody.decode, param);
                        });
            });
}

function getEventsShapes(eventNames) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var url = "/api/workspaces/" + schemaId + "/inspector/events/shapes";
  return withToken(undefined).then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], Caml_option.some(JSON.stringify(GetInspectorEventShapesEndpoint.RequestBody.encode({
                                                eventNames: eventNames
                                              }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                            return prim.json();
                          }).then(GetInspectorEventShapesEndpoint.ResponseBody.decode);
            });
}

function getAllEventsShapes(timeWindow, environment) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var queryParams = Belt_Array.map([
          [
            "timeWindow",
            timeWindow === "Last1Hour" ? "1h" : (
                timeWindow === "Last24Hours" ? "24h" : (
                    timeWindow === "Last7Days" ? "7d" : "30d"
                  )
              )
          ],
          [
            "env",
            environment === "Development" ? "dev" : (
                environment === "Production" ? "prod" : "staging"
              )
          ]
        ], (function (param) {
            return param[0] + "=" + param[1];
          })).join("&");
  var url = "/api/workspaces/" + schemaId + "/inspector/events/shapes/all?" + queryParams;
  return withToken(undefined).then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Get */0, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
                            return prim.json();
                          }).then(GetInspectorEventShapesEndpoint.ResponseBody.decode);
            });
}

export {
  updateStatsCache ,
  getFreshStats ,
  getStatsWithCache ,
  getApiKey ,
  generateApiKey ,
  getApiOrGenerateKey ,
  withToken ,
  getIssues ,
  ClientError ,
  ServerError ,
  getIssue ,
  getRelatedIssues ,
  updateIssueStatus ,
  multiUpdateIssueStatus ,
  getEventsShapes ,
  getAllEventsShapes ,
  
}
/* Router Not a pure module */
