// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as CreateMoreProductTour from "./CreateMoreProductTour.mjs";

function CreateMore(Props) {
  var toggled = Props.toggled;
  var onToggle = Props.onToggle;
  CreateMoreProductTour.use(undefined);
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.gap(Css.px(8)),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              id: "create-more",
              onClick: onToggle
            }, React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light10,
                  children: "Create more"
                }), React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(2), Css.px(4)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.light04),
                          tl: {
                            hd: Css.borderRadius(Css.px(3)),
                            tl: {
                              hd: Css.border(Css.pxFloat(0.5), Css.solid, Styles.Color.light08),
                              tl: {
                                hd: Css.fontSize(Css.px(10)),
                                tl: {
                                  hd: Css.fontFamily(Styles.FontFamily.polar),
                                  tl: {
                                    hd: Css.color(Styles.Color.light11),
                                    tl: {
                                      hd: Css.marginTop(Css.px(-2)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, "ctrl+m"), React.createElement(Switch.make, {
                  value: toggled,
                  onChange: (function (param) {
                      
                    }),
                  color: "avoPink",
                  size: "Tiny"
                }));
}

var make = CreateMore;

export {
  make ,
  
}
/* Css Not a pure module */
