// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_module from "rescript/lib/es6/caml_module.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as ValidationConfig from "../../../shared/models/ValidationConfig.mjs";

function toAnalytics(configItem) {
  return configItem;
}

function toRuleName(t) {
  if (t === "PreferredPropertyNameCasing") {
    return "Preferred property name casing";
  } else if (t === "NoConflictingProperties") {
    return "No conflicting properties";
  } else if (t === "ConsistentPropertyNameCasing") {
    return "Property naming conventions";
  } else if (t === "AllEventsHaveDescriptions") {
    return "All events have descriptions";
  } else if (t === "BranchEnforcementStatus") {
    return "Branch enforcement status";
  } else if (t === "ConsistentEventNameCasing") {
    return "Event naming conventions";
  } else if (t === "ForcePropertyCasing") {
    return "Force property casing";
  } else if (t === "ForceEventCasing") {
    return "Force event casing";
  } else if (t === "AllPropertiesHaveTypes") {
    return "All properties have types";
  } else if (t === "UniqueEventNames") {
    return "Unique event names";
  } else if (t === "AllPropertiesHaveDescriptions") {
    return "All properties have descriptions";
  } else if (t === "PreferredEventNameCasing") {
    return "Preferred event name casing";
  } else {
    return "Unique property names";
  }
}

var ConfigItem = {
  toAnalytics: toAnalytics,
  toRuleName: toRuleName
};

var _map = {"none":"none","warning":"warning","enforce":"enforce"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var RuleSeverity = {
  tToJs: tToJs,
  tFromJs: tFromJs
};

var Value = Caml_module.init_mod([
      "ValidationConfigV2.res",
      80,
      4
    ], {
      TAG: /* Module */0,
      _0: [
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "RuleValues"
        ],
        [
          {
            TAG: /* Module */0,
            _0: [[
                /* Function */0,
                "encode"
              ]]
          },
          "NoValue"
        ],
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "NameComponents"
        ],
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "AllowedValues"
        ],
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "BlockedValues"
        ],
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "Case"
        ],
        [
          /* Function */0,
          "encode"
        ],
        [
          /* Function */0,
          "decode"
        ],
        [
          /* Function */0,
          "getCase"
        ],
        [
          /* Function */0,
          "getNameComponents"
        ],
        [
          /* Function */0,
          "getAllowedValues"
        ],
        [
          /* Function */0,
          "getBlockedValues"
        ]
      ]
    });

var Rule = Caml_module.init_mod([
      "ValidationConfigV2.res",
      213,
      4
    ], {
      TAG: /* Module */0,
      _0: [
        [
          /* Function */0,
          "encode"
        ],
        [
          /* Function */0,
          "decode"
        ],
        [
          /* Function */0,
          "notChecked"
        ],
        [
          /* Function */0,
          "isChecked"
        ],
        [
          /* Function */0,
          "isEnforced"
        ],
        [
          /* Function */0,
          "empty"
        ]
      ]
    });

var _map$1 = {"separator":"separator","nameBuildingBlock":"nameBuildingBlock"};

function encode(nameBuildingBlock) {
  return Json_encode.object_({
              hd: [
                "name",
                nameBuildingBlock.name
              ],
              tl: {
                hd: [
                  "case",
                  Curry._1(Rule.encode, nameBuildingBlock.case)
                ],
                tl: {
                  hd: [
                    "allowedValues",
                    Curry._1(Rule.encode, nameBuildingBlock.allowedValues)
                  ],
                  tl: {
                    hd: [
                      "blockedValues",
                      Curry._1(Rule.encode, nameBuildingBlock.blockedValues)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function decode(t) {
  return {
          name: Json_decode.field("name", Json_decode.string, t),
          case: Json_decode.field("case", Rule.decode, t),
          allowedValues: Json_decode.field("allowedValues", Rule.decode, t),
          blockedValues: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("blockedValues", Rule.decode, param);
                    }), t), {
                value: {
                  NAME: "blockedValues",
                  VAL: []
                },
                severity: "warning"
              })
        };
}

function empty($$case) {
  return {
          name: "Object",
          case: {
            value: {
              NAME: "case",
              VAL: $$case
            },
            severity: "warning"
          },
          allowedValues: {
            value: {
              NAME: "allowedValues",
              VAL: []
            },
            severity: "warning"
          },
          blockedValues: {
            value: {
              NAME: "blockedValues",
              VAL: []
            },
            severity: "warning"
          }
        };
}

function getAllowedValues(t) {
  var match = t.allowedValues.value;
  if (typeof match === "object" && match.NAME === "allowedValues") {
    return match.VAL;
  } else {
    return Pervasives.failwith("Unexpected value");
  }
}

function getBlockedValues(t) {
  var match = t.blockedValues.value;
  if (typeof match === "object" && match.NAME === "blockedValues") {
    return match.VAL;
  } else {
    return Pervasives.failwith("Unexpected value");
  }
}

function clean(t) {
  var init = t.allowedValues;
  var value = t.allowedValues.value;
  var tmp;
  if (typeof value === "object" && value.NAME === "allowedValues") {
    var cleanedAllowedValues = Belt_Array.keep(value.VAL, (function (allowedValue) {
            return allowedValue.trim() !== "";
          }));
    tmp = {
      NAME: "allowedValues",
      VAL: cleanedAllowedValues
    };
  } else {
    tmp = value;
  }
  var init$1 = t.blockedValues;
  var value$1 = t.blockedValues.value;
  var tmp$1;
  if (typeof value$1 === "object" && value$1.NAME === "blockedValues") {
    var cleanedBlockedValues = Belt_Array.keep(value$1.VAL, (function (blockedValue) {
            return blockedValue.trim() !== "";
          }));
    tmp$1 = {
      NAME: "blockedValues",
      VAL: cleanedBlockedValues
    };
  } else {
    tmp$1 = value$1;
  }
  return {
          name: t.name,
          case: t.case,
          allowedValues: {
            value: tmp,
            severity: init.severity
          },
          blockedValues: {
            value: tmp$1,
            severity: init$1.severity
          }
        };
}

function getBuildingBlockInputType(t) {
  var match = Curry._1(Value.getAllowedValues, t.allowedValues.value);
  if (match.length !== 0) {
    return "Select";
  } else {
    return "Input";
  }
}

function clean$1(t) {
  if (t.NAME === "separator") {
    return t;
  } else {
    return {
            NAME: "nameBuildingBlock",
            VAL: clean(t.VAL)
          };
  }
}

function objectAction($$case) {
  var init = empty($$case);
  var separator = Case.getCaseSeparator($$case);
  var init$1 = empty($$case);
  return Belt_Array.concatMany([
              [{
                  NAME: "nameBuildingBlock",
                  VAL: {
                    name: "Object",
                    case: init.case,
                    allowedValues: init.allowedValues,
                    blockedValues: init.blockedValues
                  }
                }],
              separator !== undefined ? [{
                    NAME: "separator",
                    VAL: separator
                  }] : [],
              [{
                  NAME: "nameBuildingBlock",
                  VAL: {
                    name: "Action",
                    case: init$1.case,
                    allowedValues: init$1.allowedValues,
                    blockedValues: init$1.blockedValues
                  }
                }]
            ]);
}

function actionObject($$case) {
  return Belt_Array.reverse(objectAction($$case));
}

function encode$1(seperator) {
  return seperator;
}

var decode$1 = Json_decode.string;

var Separator = {
  encode: encode$1,
  decode: decode$1,
  empty: "__"
};

function convertCase(nameComponents, $$case) {
  var noSeparators = Belt_Array.keepMapU(nameComponents, (function (component) {
          if (component.NAME === "separator") {
            return ;
          }
          var nameBuildingBlock = component.VAL;
          var init = nameBuildingBlock.case;
          var init$1 = nameBuildingBlock.allowedValues;
          var init$2 = nameBuildingBlock.allowedValues;
          return {
                  NAME: "nameBuildingBlock",
                  VAL: {
                    name: nameBuildingBlock.name,
                    case: {
                      value: {
                        NAME: "case",
                        VAL: $$case
                      },
                      severity: init.severity
                    },
                    allowedValues: {
                      value: {
                        NAME: "allowedValues",
                        VAL: Belt_Array.mapU(Curry._1(Value.getAllowedValues, nameBuildingBlock.allowedValues.value), (function (allowedValue) {
                                return Case.to_($$case, allowedValue);
                              }))
                      },
                      severity: init$1.severity
                    },
                    blockedValues: {
                      value: {
                        NAME: "blockedValues",
                        VAL: Belt_Array.mapU(Curry._1(Value.getBlockedValues, nameBuildingBlock.blockedValues.value), (function (blockedValue) {
                                return Case.to_($$case, blockedValue);
                              }))
                      },
                      severity: init$2.severity
                    }
                  }
                };
        }));
  var separator = Case.getCaseSeparator($$case);
  if (separator === undefined) {
    return noSeparators;
  }
  var findIndex = function (index) {
    return Math.floor(index / 2.0) | 0;
  };
  return Belt_Array.makeBy((noSeparators.length << 1) - 1 | 0, (function (index) {
                if (index % 2 === 0) {
                  return Belt_Array.getExn(noSeparators, findIndex(index));
                } else {
                  return {
                          NAME: "separator",
                          VAL: separator
                        };
                }
              }));
}

function encode$2(nameComponent) {
  if (nameComponent.NAME === "separator") {
    return Json_encode.object_({
                hd: [
                  "separator",
                  nameComponent.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "nameBuildingBlock",
                  encode(nameComponent.VAL)
                ],
                tl: /* [] */0
              });
  }
}

function decode$2(json) {
  var obj = Js_json.classify(json);
  if (typeof obj === "number") {
    return Pervasives.failwith("Unexpected name component");
  }
  if (obj.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("Unexpected name component");
  }
  var match = Belt_Array.getExn(Js_dict.entries(obj._0), 0);
  var jsonObj = match[1];
  var match$1 = _map$1[match[0]];
  if (match$1 !== undefined) {
    if (match$1 === "separator") {
      return {
              NAME: "separator",
              VAL: Json_decode.string(jsonObj)
            };
    } else {
      return {
              NAME: "nameBuildingBlock",
              VAL: decode(jsonObj)
            };
    }
  } else {
    return Pervasives.failwith("Unexpected name component");
  }
}

function hasConsistentNameCasing(nameComponents) {
  return Belt_Array.everyU(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return true;
                } else {
                  return Curry._1(Rule.isChecked, nameComponent.VAL.case);
                }
              }));
}

function hasEnforcedNameCasing(nameComponents) {
  return Belt_Array.everyU(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return true;
                } else {
                  return Curry._1(Rule.isEnforced, nameComponent.VAL.case);
                }
              }));
}

function hasBasicNameCasing(nameComponents) {
  if (nameComponents.length !== 1) {
    return false;
  }
  var match = Belt_Array.get(nameComponents, 0);
  if (match !== undefined) {
    return match.NAME !== "separator";
  } else {
    return false;
  }
}

var CaseComparator = Belt_Id.MakeComparable({
      cmp: Case.cmp
    });

function findCommonCaseValues(array) {
  var firstValues = Belt_Array.get(array, 0);
  if (firstValues === undefined) {
    return [];
  }
  var firstSet = Belt_Set.fromArray(firstValues, CaseComparator);
  return Belt_Set.toArray(Belt_Array.reduceU(Belt_Array.sliceToEnd(array, 1), firstSet, (function (curr, values) {
                    var currentSet = Belt_Set.fromArray(values, CaseComparator);
                    return Belt_Set.keepU(curr, (function (currentValue) {
                                  return Belt_Set.has(currentSet, currentValue);
                                }));
                  })));
}

function getCaseVariances(values) {
  return findCommonCaseValues(Belt_Array.mapU(values, Case.getCaseVariance));
}

function getCase(nameComponents) {
  var testComponent = "Test  by  Testing of tesTing and TestTing ";
  var commonCase = findCommonCaseValues(Belt_Array.keepMapU(nameComponents, (function (nameComponent) {
              if (nameComponent.NAME === "separator") {
                return ;
              }
              var match = nameComponent.VAL;
              var allowedValues = match.allowedValues;
              var $$case = match.case;
              if (Curry._1(Value.getCase, $$case.value) === "None" && Curry._1(Value.getAllowedValues, allowedValues.value).length !== 0) {
                return getCaseVariances(Curry._1(Value.getAllowedValues, allowedValues.value));
              } else {
                return [Curry._1(Value.getCase, $$case.value)];
              }
            })));
  if (commonCase.length !== 1) {
    return "hybrid";
  }
  var commonCase$1 = Belt_Array.getExn(commonCase, 0);
  var expectedSeparator = Case.getCaseSeparator(commonCase$1);
  var separatorsAreConsistentWithCommonCase = expectedSeparator !== undefined ? Belt_Array.everyU(Belt_Array.keepMapU(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME !== "separator") {
                  return ;
                }
                var separator = nameComponent.VAL;
                if (separator === "") {
                  return ;
                } else {
                  return separator;
                }
              })), (function (separator) {
            return separator === expectedSeparator;
          })) : Belt_Array.everyU(nameComponents, (function (nameComponent) {
            if (nameComponent.NAME === "separator" && nameComponent.VAL !== "") {
              return false;
            } else {
              return true;
            }
          }));
  if (!separatorsAreConsistentWithCommonCase) {
    return "hybrid";
  }
  var compiledName = Belt_Array.mapU(nameComponents, (function (nameComponent) {
            if (nameComponent.NAME === "separator") {
              return nameComponent.VAL;
            }
            var match = nameComponent.VAL;
            var $$case = match.case;
            var allowedValueStrings = Curry._1(Value.getAllowedValues, match.allowedValues.value);
            if (!(Curry._1(Value.getCase, $$case.value) === "None" && allowedValueStrings.length !== 0)) {
              return Case.to_(Curry._1(Value.getCase, $$case.value), testComponent);
            }
            var allowedValuesHaveSameCase = getCaseVariances(allowedValueStrings).length !== 0;
            if (allowedValuesHaveSameCase) {
              return Belt_Array.getExn(allowedValueStrings, 0);
            } else {
              return testComponent;
            }
          })).join("");
  var compiledNameCase = Case.of_(compiledName);
  if (compiledNameCase === commonCase$1) {
    return {
            NAME: "simple",
            VAL: compiledNameCase
          };
  } else {
    return "hybrid";
  }
}

function getSimpleNameCasing(nameComponents) {
  if (!hasBasicNameCasing(nameComponents)) {
    return ;
  }
  var match = Belt_Array.get(nameComponents, 0);
  if (match !== undefined && typeof match === "object" && match.NAME === "nameBuildingBlock") {
    return Curry._1(Value.getCase, match.VAL.case.value);
  } else {
    return Pervasives.failwith("Unexpected name component");
  }
}

function getSeparators(nameComponents) {
  return Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return nameComponent.VAL;
                }
                
              }));
}

function getAllowedValues$1(nameComponents) {
  return Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return ;
                }
                var allowedValues = Curry._1(Value.getAllowedValues, nameComponent.VAL.allowedValues.value);
                if (allowedValues.length !== 0) {
                  return allowedValues;
                }
                
              }));
}

function getBlockedValues$1(nameComponents) {
  return Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return ;
                }
                var blockedValues = Curry._1(Value.getBlockedValues, nameComponent.VAL.blockedValues.value);
                if (blockedValues.length !== 0) {
                  return blockedValues;
                }
                
              }));
}

function getBuildingBlocks(nameComponents) {
  return Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return ;
                } else {
                  return nameComponent.VAL;
                }
              }));
}

function getComponentNamesIncludingSeparators(nameComponents) {
  return Belt_Array.map(nameComponents, (function (component) {
                if (component.NAME === "separator") {
                  return component.VAL;
                } else {
                  return component.VAL.name;
                }
              }));
}

function getSimplestNameCasing(nameComponents) {
  return Belt_Array.getExn(Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                    if (nameComponent.NAME === "separator") {
                      return ;
                    } else {
                      return nameComponent.VAL.case;
                    }
                  })), 0);
}

function getRuleComplexity(nameComponents) {
  if (!hasBasicNameCasing(nameComponents)) {
    return {
            NAME: "advanced",
            VAL: nameComponents
          };
  }
  var match = Belt_Array.getExn(nameComponents, 0);
  if (match.NAME === "separator") {
    return Pervasives.failwith("Unexpected name component");
  } else {
    return {
            NAME: "basic",
            VAL: match.VAL.case
          };
  }
}

function getNameConfigAsString(nameComponents) {
  return Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                  if (nameComponent.NAME === "separator") {
                    return ;
                  }
                  var nameBuildingBLock = nameComponent.VAL;
                  return nameBuildingBLock.name + "(" + Case.tToJs(Curry._1(Value.getCase, nameBuildingBLock.case.value)) + ":" + getBuildingBlockInputType(nameBuildingBLock) + ")";
                })).join("||");
}

function getNamesAsStructuredString(nameComponents) {
  return Belt_Array.reduce(nameComponents, "", (function (name, nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return name + nameComponent.VAL;
                } else {
                  return name + ("<" + nameComponent.VAL.name + ">");
                }
              }));
}

function getComponentCasesAsStructuredString(nameComponents) {
  return Belt_Array.reduce(nameComponents, "", (function (name, nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return name + nameComponent.VAL;
                }
                var match = nameComponent.VAL.case.value;
                if (typeof match === "object" && match.NAME === "case") {
                  return name + ("<" + Case.toUILabel(match.VAL) + ">");
                } else {
                  return name;
                }
              }));
}

var NameComponent_NameComponentBlocks = {};

var NameComponent_NameBuildingBlock = {
  encode: encode,
  decode: decode,
  getAllowedValues: getAllowedValues,
  getBlockedValues: getBlockedValues,
  empty: empty,
  clean: clean
};

var NameComponent_CaseComparator = {};

var NameComponent = {
  NameComponentBlocks: NameComponent_NameComponentBlocks,
  NameBuildingBlock: NameComponent_NameBuildingBlock,
  Separator: Separator,
  objectAction: objectAction,
  actionObject: actionObject,
  convertCase: convertCase,
  encode: encode$2,
  decode: decode$2,
  hasConsistentNameCasing: hasConsistentNameCasing,
  hasEnforcedNameCasing: hasEnforcedNameCasing,
  hasBasicNameCasing: hasBasicNameCasing,
  CaseComparator: NameComponent_CaseComparator,
  findCommonCaseValues: findCommonCaseValues,
  getCaseVariances: getCaseVariances,
  getCase: getCase,
  getSimpleNameCasing: getSimpleNameCasing,
  getSeparators: getSeparators,
  getAllowedValues: getAllowedValues$1,
  getBlockedValues: getBlockedValues$1,
  getBuildingBlocks: getBuildingBlocks,
  getComponentNamesIncludingSeparators: getComponentNamesIncludingSeparators,
  getSimplestNameCasing: getSimplestNameCasing,
  getRuleComplexity: getRuleComplexity,
  getNameConfigAsString: getNameConfigAsString,
  getNamesAsStructuredString: getNamesAsStructuredString,
  getComponentCasesAsStructuredString: getComponentCasesAsStructuredString,
  clean: clean$1
};

var _map$2 = {"none":"none","nameComponents":"nameComponents","case":"case","allowedValues":"allowedValues","blockedValues":"blockedValues"};

function encode$3(param) {
  return Json_encode.object_({
              hd: [
                "none",
                null
              ],
              tl: /* [] */0
            });
}

var NoValue = {
  encode: encode$3
};

function decode$3(json) {
  return Json_decode.field("nameComponents", (function (param) {
                return Json_decode.array(decode$2, param);
              }), json);
}

function decode$4(json) {
  return Json_decode.field("allowedValues", (function (param) {
                return Json_decode.array(Json_decode.string, param);
              }), json);
}

function decode$5(json) {
  return Json_decode.field("blockedValues", (function (param) {
                return Json_decode.array(Json_decode.string, param);
              }), json);
}

function decode$6(json) {
  return Belt_Option.getWithDefault(Case.tFromJs(Json_decode.field("case", Json_decode.string, json)), "None");
}

function encode$4(value) {
  if (typeof value !== "object") {
    return encode$3(undefined);
  }
  var variant = value.NAME;
  if (variant === "allowedValues") {
    var t = value.VAL;
    return Json_encode.object_({
                hd: [
                  "allowedValues",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), t)
                ],
                tl: /* [] */0
              });
  } else if (variant === "blockedValues") {
    var t$1 = value.VAL;
    return Json_encode.object_({
                hd: [
                  "blockedValues",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), t$1)
                ],
                tl: /* [] */0
              });
  } else if (variant === "nameComponents") {
    var t$2 = value.VAL;
    return Json_encode.object_({
                hd: [
                  "nameComponents",
                  Json_encode.array(encode$2, t$2)
                ],
                tl: /* [] */0
              });
  } else {
    var t$3 = value.VAL;
    return Json_encode.object_({
                hd: [
                  "case",
                  Case.tToJs(t$3)
                ],
                tl: /* [] */0
              });
  }
}

function decode$7(json) {
  var obj = Js_json.classify(json);
  if (typeof obj === "number") {
    return Pervasives.failwith("Unexpected rule");
  }
  if (obj.TAG !== /* JSONObject */2) {
    return Pervasives.failwith("Unexpected rule");
  }
  var match = Belt_Array.getExn(Js_dict.entries(obj._0), 0);
  var match$1 = _map$2[match[0]];
  if (match$1 !== undefined) {
    if (match$1 === "none") {
      return "none";
    } else if (match$1 === "allowedValues") {
      return {
              NAME: "allowedValues",
              VAL: decode$4(json)
            };
    } else if (match$1 === "blockedValues") {
      return {
              NAME: "blockedValues",
              VAL: decode$5(json)
            };
    } else if (match$1 === "nameComponents") {
      return {
              NAME: "nameComponents",
              VAL: decode$3(json)
            };
    } else {
      return {
              NAME: "case",
              VAL: decode$6(json)
            };
    }
  } else {
    return Pervasives.failwith("Unexpected rule");
  }
}

function getCase$1(value) {
  if (typeof value !== "object") {
    return "None";
  }
  var variant = value.NAME;
  if (variant === "nameComponents" || variant === "blockedValues" || variant === "allowedValues") {
    return "None";
  } else {
    return value.VAL;
  }
}

function getNameComponents(value) {
  if (typeof value === "object") {
    if (value.NAME === "nameComponents") {
      return value.VAL;
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function getAllowedValues$2(value) {
  if (typeof value === "object") {
    if (value.NAME === "allowedValues") {
      return value.VAL;
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function getBlockedValues$2(value) {
  if (typeof value === "object") {
    if (value.NAME === "blockedValues") {
      return value.VAL;
    } else {
      return [];
    }
  } else {
    return [];
  }
}

Caml_module.update_mod({
      TAG: /* Module */0,
      _0: [
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "RuleValues"
        ],
        [
          {
            TAG: /* Module */0,
            _0: [[
                /* Function */0,
                "encode"
              ]]
          },
          "NoValue"
        ],
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "NameComponents"
        ],
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "AllowedValues"
        ],
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "BlockedValues"
        ],
        [
          {
            TAG: /* Module */0,
            _0: []
          },
          "Case"
        ],
        [
          /* Function */0,
          "encode"
        ],
        [
          /* Function */0,
          "decode"
        ],
        [
          /* Function */0,
          "getCase"
        ],
        [
          /* Function */0,
          "getNameComponents"
        ],
        [
          /* Function */0,
          "getAllowedValues"
        ],
        [
          /* Function */0,
          "getBlockedValues"
        ]
      ]
    }, Value, {
      RuleValues: {},
      NoValue: NoValue,
      NameComponents: {},
      AllowedValues: {},
      BlockedValues: {},
      Case: {},
      encode: encode$4,
      decode: decode$7,
      getCase: getCase$1,
      getNameComponents: getNameComponents,
      getAllowedValues: getAllowedValues$2,
      getBlockedValues: getBlockedValues$2
    });

function encode$5(rule) {
  return Json_encode.object_({
              hd: [
                "value",
                Curry._1(Value.encode, rule.value)
              ],
              tl: {
                hd: [
                  "severity",
                  rule.severity
                ],
                tl: /* [] */0
              }
            });
}

function decode$8(json) {
  return {
          value: Json_decode.field("value", Value.decode, json),
          severity: Belt_Option.getWithDefault(_map[Json_decode.field("severity", Json_decode.string, json)], "warning")
        };
}

function notChecked(rule) {
  return rule.severity === "none";
}

function isChecked(rule) {
  return rule.severity !== "none";
}

function isEnforced(rule) {
  return rule.severity === "enforce";
}

function empty$1(value) {
  return {
          value: value,
          severity: "warning"
        };
}

Caml_module.update_mod({
      TAG: /* Module */0,
      _0: [
        [
          /* Function */0,
          "encode"
        ],
        [
          /* Function */0,
          "decode"
        ],
        [
          /* Function */0,
          "notChecked"
        ],
        [
          /* Function */0,
          "isChecked"
        ],
        [
          /* Function */0,
          "isEnforced"
        ],
        [
          /* Function */0,
          "empty"
        ]
      ]
    }, Rule, {
      encode: encode$5,
      decode: decode$8,
      notChecked: notChecked,
      isChecked: isChecked,
      isEnforced: isEnforced,
      empty: empty$1
    });

function encode$6(events) {
  return Json_encode.object_({
              hd: [
                "nameComponents",
                Json_encode.array(encode$2, events.nameComponents)
              ],
              tl: {
                hd: [
                  "uniqueName",
                  Curry._1(Rule.encode, events.uniqueName)
                ],
                tl: {
                  hd: [
                    "description",
                    Curry._1(Rule.encode, events.description)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$9(json) {
  var nameComponents = Json_decode.optional((function (param) {
          return Json_decode.field("nameComponents", (function (param) {
                        return Json_decode.array(decode$2, param);
                      }), param);
        }), json);
  var oldCase = Json_decode.optional((function (param) {
          return Json_decode.field("case", Rule.decode, param);
        }), json);
  var nameComponents$1;
  if (nameComponents !== undefined) {
    nameComponents$1 = nameComponents;
  } else if (oldCase !== undefined) {
    nameComponents$1 = [{
        NAME: "nameBuildingBlock",
        VAL: {
          name: "name",
          case: oldCase,
          allowedValues: {
            value: {
              NAME: "allowedValues",
              VAL: []
            },
            severity: "none"
          },
          blockedValues: {
            value: {
              NAME: "blockedValues",
              VAL: []
            },
            severity: "none"
          }
        }
      }];
  } else {
    var nameBuildingBlock_case = {
      value: "none",
      severity: "warning"
    };
    var nameBuildingBlock_allowedValues = {
      value: {
        NAME: "allowedValues",
        VAL: []
      },
      severity: "none"
    };
    var nameBuildingBlock_blockedValues = {
      value: {
        NAME: "blockedValues",
        VAL: []
      },
      severity: "none"
    };
    var nameBuildingBlock = {
      name: "name",
      case: nameBuildingBlock_case,
      allowedValues: nameBuildingBlock_allowedValues,
      blockedValues: nameBuildingBlock_blockedValues
    };
    nameComponents$1 = [{
        NAME: "nameBuildingBlock",
        VAL: nameBuildingBlock
      }];
  }
  return {
          nameComponents: nameComponents$1,
          uniqueName: Json_decode.field("uniqueName", Rule.decode, json),
          description: Json_decode.field("description", Rule.decode, json)
        };
}

var Events = {
  encode: encode$6,
  decode: decode$9
};

function fromV1(eventsV1) {
  return {
          nameComponents: {
            value: {
              NAME: "nameComponents",
              VAL: eventsV1.nameComponents
            },
            severity: "warning"
          },
          uniqueName: eventsV1.uniqueName,
          description: eventsV1.description
        };
}

function encode$7(events) {
  return Json_encode.object_({
              hd: [
                "nameComponents",
                Curry._1(Rule.encode, events.nameComponents)
              ],
              tl: {
                hd: [
                  "uniqueName",
                  Curry._1(Rule.encode, events.uniqueName)
                ],
                tl: {
                  hd: [
                    "description",
                    Curry._1(Rule.encode, events.description)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$10(json) {
  var partial_arg_0 = Rule.decode;
  var partial_arg_1 = {
    hd: (function (json) {
        return {
                value: {
                  NAME: "nameComponents",
                  VAL: Json_decode.array(decode$2, json)
                },
                severity: "warning"
              };
      }),
    tl: /* [] */0
  };
  var partial_arg = {
    hd: partial_arg_0,
    tl: partial_arg_1
  };
  var partial_arg$1 = function (param) {
    return Json_decode.oneOf(partial_arg, param);
  };
  var nameComponents = Json_decode.optional((function (param) {
          return Json_decode.field("nameComponents", partial_arg$1, param);
        }), json);
  var oldCase = Json_decode.optional((function (param) {
          return Json_decode.field("case", Rule.decode, param);
        }), json);
  var nameComponents$1;
  if (nameComponents !== undefined) {
    nameComponents$1 = nameComponents;
  } else if (oldCase !== undefined) {
    nameComponents$1 = {
      value: {
        NAME: "nameComponents",
        VAL: [{
            NAME: "nameBuildingBlock",
            VAL: {
              name: "name",
              case: oldCase,
              allowedValues: {
                value: {
                  NAME: "allowedValues",
                  VAL: []
                },
                severity: "none"
              },
              blockedValues: {
                value: {
                  NAME: "blockedValues",
                  VAL: []
                },
                severity: "none"
              }
            }
          }]
      },
      severity: "warning"
    };
  } else {
    var nameBuildingBlock_case = {
      value: "none",
      severity: "warning"
    };
    var nameBuildingBlock_allowedValues = {
      value: {
        NAME: "allowedValues",
        VAL: []
      },
      severity: "none"
    };
    var nameBuildingBlock_blockedValues = {
      value: {
        NAME: "blockedValues",
        VAL: []
      },
      severity: "none"
    };
    var nameBuildingBlock = {
      name: "name",
      case: nameBuildingBlock_case,
      allowedValues: nameBuildingBlock_allowedValues,
      blockedValues: nameBuildingBlock_blockedValues
    };
    nameComponents$1 = {
      value: {
        NAME: "nameComponents",
        VAL: [{
            NAME: "nameBuildingBlock",
            VAL: nameBuildingBlock
          }]
      },
      severity: "warning"
    };
  }
  return {
          nameComponents: nameComponents$1,
          uniqueName: Json_decode.field("uniqueName", Rule.decode, json),
          description: Json_decode.field("description", Rule.decode, json)
        };
}

function getNameComponents$1(events) {
  return Curry._1(Value.getNameComponents, events.nameComponents.value);
}

function getCase$2(events) {
  return getCase(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function getAllowedValues$3(events) {
  return getAllowedValues$1(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function getBlockedValues$3(events) {
  return getBlockedValues$1(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function getSimpleNameCasing$1(events) {
  return getSimpleNameCasing(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function getSimplestNameCasing$1(events) {
  return getSimplestNameCasing(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function getNamesAsStructuredString$1(events) {
  return getNamesAsStructuredString(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function getComponentCasesAsStructuredString$1(events) {
  return getComponentCasesAsStructuredString(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function getRuleComplexity$1(events) {
  return getRuleComplexity(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function getNameConfigAsString$1(events) {
  return getNameConfigAsString(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function hasBasicNameCasing$1(events) {
  return hasBasicNameCasing(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function hasConsistentNameCasing$1(events) {
  return hasConsistentNameCasing(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function hasEnforcedNameCasing$1(events) {
  return hasEnforcedNameCasing(Curry._1(Value.getNameComponents, events.nameComponents.value));
}

function hasEnforcedDescription(events) {
  return events.description.severity === "enforce";
}

function hasEnforcedUniqueName(events) {
  return events.uniqueName.severity === "enforce";
}

function hasValueChecking(events) {
  if (getAllowedValues$1(Curry._1(Value.getNameComponents, events.nameComponents.value)).length !== 0) {
    return true;
  } else {
    return getBlockedValues$1(Curry._1(Value.getNameComponents, events.nameComponents.value)).length !== 0;
  }
}

var EventsV2 = {
  fromV1: fromV1,
  encode: encode$7,
  decode: decode$10,
  getNameComponents: getNameComponents$1,
  getCase: getCase$2,
  getAllowedValues: getAllowedValues$3,
  getBlockedValues: getBlockedValues$3,
  getSimpleNameCasing: getSimpleNameCasing$1,
  getSimplestNameCasing: getSimplestNameCasing$1,
  getNamesAsStructuredString: getNamesAsStructuredString$1,
  getComponentCasesAsStructuredString: getComponentCasesAsStructuredString$1,
  getRuleComplexity: getRuleComplexity$1,
  getNameConfigAsString: getNameConfigAsString$1,
  hasBasicNameCasing: hasBasicNameCasing$1,
  hasConsistentNameCasing: hasConsistentNameCasing$1,
  hasEnforcedNameCasing: hasEnforcedNameCasing$1,
  hasEnforcedDescription: hasEnforcedDescription,
  hasEnforcedUniqueName: hasEnforcedUniqueName,
  hasValueChecking: hasValueChecking
};

function encode$8(properties) {
  return Json_encode.object_({
              hd: [
                "case",
                Curry._1(Rule.encode, properties.case)
              ],
              tl: {
                hd: [
                  "uniqueName",
                  Curry._1(Rule.encode, properties.uniqueName)
                ],
                tl: {
                  hd: [
                    "description",
                    Curry._1(Rule.encode, properties.description)
                  ],
                  tl: {
                    hd: [
                      "conflictsOnEvent",
                      Curry._1(Rule.encode, properties.conflictsOnEvent)
                    ],
                    tl: {
                      hd: [
                        "anyType",
                        Curry._1(Rule.encode, properties.anyType)
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function decode$11(json) {
  return {
          case: Json_decode.field("case", Rule.decode, json),
          uniqueName: Json_decode.field("uniqueName", Rule.decode, json),
          description: Json_decode.field("description", Rule.decode, json),
          conflictsOnEvent: Json_decode.field("conflictsOnEvent", Rule.decode, json),
          anyType: Json_decode.field("anyType", Rule.decode, json)
        };
}

var Properties = {
  encode: encode$8,
  decode: decode$11
};

var _map$3 = {"beforeRequestForReview":"beforeRequestForReview","beforeMerge":"beforeMerge"};

function tToJs$1(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$3[param];
}

var options = [
  "beforeRequestForReview",
  "beforeMerge"
];

function toUILabel(pointOfBlock) {
  if (pointOfBlock === "beforeRequestForReview") {
    return "Request for review";
  } else {
    return "Branch merge";
  }
}

function toBlockingLabel(pointOfBlock) {
  if (pointOfBlock === "beforeRequestForReview") {
    return "Blocking branch review";
  } else {
    return "Blocking branch merge";
  }
}

function encode$9(pointOfBlock) {
  return pointOfBlock;
}

function decode$12(json) {
  return Belt_Option.getWithDefault(_map$3[Json_decode.string(json)], "beforeMerge");
}

var PointOfBlock = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1,
  options: options,
  toUILabel: toUILabel,
  toBlockingLabel: toBlockingLabel,
  encode: encode$9,
  decode: decode$12
};

function encode$10(t) {
  return Json_encode.object_({
              hd: [
                "events",
                encode$7(t.events)
              ],
              tl: {
                hd: [
                  "properties",
                  encode$8(t.properties)
                ],
                tl: {
                  hd: [
                    "pointOfBlock",
                    t.pointOfBlock
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$13(json) {
  return {
          events: Json_decode.field("events", decode$10, json),
          properties: Json_decode.field("properties", decode$11, json),
          pointOfBlock: Json_decode.field("pointOfBlock", decode$12, json)
        };
}

function nameBuildingBlock(severity) {
  return {
          name: "name",
          case: {
            value: {
              NAME: "case",
              VAL: "None"
            },
            severity: severity
          },
          allowedValues: {
            value: {
              NAME: "allowedValues",
              VAL: []
            },
            severity: "none"
          },
          blockedValues: {
            value: {
              NAME: "blockedValues",
              VAL: []
            },
            severity: "none"
          }
        };
}

function emptyNameComponents(severity) {
  return {
          value: {
            NAME: "nameComponents",
            VAL: [{
                NAME: "nameBuildingBlock",
                VAL: nameBuildingBlock(severity)
              }]
          },
          severity: severity
        };
}

var empty_events = {
  nameComponents: emptyNameComponents("warning"),
  uniqueName: {
    value: "none",
    severity: "warning"
  },
  description: {
    value: "none",
    severity: "warning"
  }
};

var empty_properties = {
  case: {
    value: {
      NAME: "case",
      VAL: "None"
    },
    severity: "warning"
  },
  uniqueName: {
    value: "none",
    severity: "warning"
  },
  description: {
    value: "none",
    severity: "warning"
  },
  conflictsOnEvent: {
    value: "none",
    severity: "warning"
  },
  anyType: {
    value: "none",
    severity: "warning"
  }
};

var empty$2 = {
  events: empty_events,
  properties: empty_properties,
  pointOfBlock: "beforeMerge"
};

var emptyStrict_events = {
  nameComponents: emptyNameComponents("enforce"),
  uniqueName: {
    value: "none",
    severity: "enforce"
  },
  description: {
    value: "none",
    severity: "enforce"
  }
};

var emptyStrict_properties = {
  case: {
    value: {
      NAME: "case",
      VAL: "None"
    },
    severity: "enforce"
  },
  uniqueName: {
    value: "none",
    severity: "enforce"
  },
  description: {
    value: "none",
    severity: "enforce"
  },
  conflictsOnEvent: {
    value: "none",
    severity: "enforce"
  },
  anyType: {
    value: "none",
    severity: "enforce"
  }
};

var emptyStrict = {
  events: emptyStrict_events,
  properties: emptyStrict_properties,
  pointOfBlock: "beforeMerge"
};

function convertSeverity(value, forceAudit) {
  if (value) {
    if (forceAudit) {
      return "enforce";
    } else {
      return "warning";
    }
  } else {
    return "none";
  }
}

function convertCasingV1ToNameComponents($$case, severity) {
  var nameBuildingBlock_case = {
    value: {
      NAME: "case",
      VAL: $$case
    },
    severity: severity
  };
  var nameBuildingBlock_allowedValues = {
    value: {
      NAME: "allowedValues",
      VAL: []
    },
    severity: "none"
  };
  var nameBuildingBlock_blockedValues = {
    value: {
      NAME: "blockedValues",
      VAL: []
    },
    severity: "none"
  };
  var nameBuildingBlock = {
    name: "name",
    case: nameBuildingBlock_case,
    allowedValues: nameBuildingBlock_allowedValues,
    blockedValues: nameBuildingBlock_blockedValues
  };
  return [{
            NAME: "nameBuildingBlock",
            VAL: nameBuildingBlock
          }];
}

function convertCasingV1ToNameComponentsV2($$case, severity) {
  var nameBuildingBlocks = convertCasingV1ToNameComponents($$case, severity);
  return {
          value: {
            NAME: "nameComponents",
            VAL: nameBuildingBlocks
          },
          severity: severity
        };
}

function enforceIfTrue(value) {
  if (value) {
    return "enforce";
  } else {
    return "warning";
  }
}

function convertFromV1(validationConfigV1, forceAudit) {
  return {
          events: {
            nameComponents: convertCasingV1ToNameComponentsV2(validationConfigV1.preferredEventCase, validationConfigV1.forceEventCase ? "enforce" : convertSeverity(validationConfigV1.eventsWithUnexpectedCase, forceAudit)),
            uniqueName: {
              value: "none",
              severity: convertSeverity(validationConfigV1.sameEventName, forceAudit)
            },
            description: {
              value: "none",
              severity: convertSeverity(validationConfigV1.eventsWithoutDescription, forceAudit)
            }
          },
          properties: {
            case: {
              value: {
                NAME: "case",
                VAL: validationConfigV1.preferredPropertyCase
              },
              severity: validationConfigV1.forcePropertyCase ? "enforce" : convertSeverity(validationConfigV1.propertiesWithUnexpectedCase, forceAudit)
            },
            uniqueName: {
              value: "none",
              severity: convertSeverity(validationConfigV1.samePropertyName, forceAudit)
            },
            description: {
              value: "none",
              severity: convertSeverity(validationConfigV1.propertiesWithoutDescription, forceAudit)
            },
            conflictsOnEvent: {
              value: "none",
              severity: convertSeverity(validationConfigV1.propertyConflictOnEvent, forceAudit)
            },
            anyType: {
              value: "none",
              severity: convertSeverity(validationConfigV1.propertiesWithAnyType, forceAudit)
            }
          },
          pointOfBlock: "beforeMerge"
        };
}

function parseWithV1(configV1Json, configV2Json, withForcedBranchAudit) {
  if (configV2Json == null) {
    return Belt_Option.mapWithDefaultU((configV1Json == null) ? undefined : Caml_option.some(configV1Json), empty$2, (function (json) {
                  return convertFromV1(ValidationConfig.parse(json), withForcedBranchAudit);
                }));
  } else {
    return decode$13(configV2Json);
  }
}

function toggleSeverity(rule, toggle) {
  if (toggle) {
    var value = rule.value;
    return {
            value: typeof value === "object" && value.NAME === "nameComponents" ? ({
                  NAME: "nameComponents",
                  VAL: Belt_Array.map(value.VAL, (function (nameComponent) {
                          if (typeof nameComponent !== "object") {
                            return nameComponent;
                          }
                          if (nameComponent.NAME !== "nameBuildingBlock") {
                            return nameComponent;
                          }
                          var nameBuildingBlock = nameComponent.VAL;
                          var init = nameBuildingBlock.case;
                          var init$1 = nameBuildingBlock.allowedValues;
                          var init$2 = nameBuildingBlock.blockedValues;
                          return {
                                  NAME: "nameBuildingBlock",
                                  VAL: {
                                    name: nameBuildingBlock.name,
                                    case: {
                                      value: init.value,
                                      severity: "enforce"
                                    },
                                    allowedValues: {
                                      value: init$1.value,
                                      severity: "enforce"
                                    },
                                    blockedValues: {
                                      value: init$2.value,
                                      severity: "enforce"
                                    }
                                  }
                                };
                        }))
                }) : value,
            severity: "enforce"
          };
  }
  var value$1 = rule.value;
  return {
          value: typeof value$1 === "object" && value$1.NAME === "nameComponents" ? ({
                NAME: "nameComponents",
                VAL: Belt_Array.map(value$1.VAL, (function (nameComponent) {
                        if (typeof nameComponent !== "object") {
                          return nameComponent;
                        }
                        if (nameComponent.NAME !== "nameBuildingBlock") {
                          return nameComponent;
                        }
                        var nameBuildingBlock = nameComponent.VAL;
                        var init = nameBuildingBlock.case;
                        var init$1 = nameBuildingBlock.allowedValues;
                        var init$2 = nameBuildingBlock.blockedValues;
                        return {
                                NAME: "nameBuildingBlock",
                                VAL: {
                                  name: nameBuildingBlock.name,
                                  case: {
                                    value: init.value,
                                    severity: "warning"
                                  },
                                  allowedValues: {
                                    value: init$1.value,
                                    severity: "warning"
                                  },
                                  blockedValues: {
                                    value: init$2.value,
                                    severity: "warning"
                                  }
                                }
                              };
                      }))
              }) : value$1,
          severity: "warning"
        };
}

function toggleIsSet(rule) {
  var match = rule.severity;
  if (match === "warning" || match === "enforce") {
    return {
            value: rule.value,
            severity: "none"
          };
  } else {
    return {
            value: rule.value,
            severity: "warning"
          };
  }
}

var Utils = {
  convertSeverity: convertSeverity,
  enforceIfTrue: enforceIfTrue,
  convertFromV1: convertFromV1,
  parseWithV1: parseWithV1,
  toggleSeverity: toggleSeverity,
  toggleIsSet: toggleIsSet
};

export {
  ConfigItem ,
  RuleSeverity ,
  Value ,
  Rule ,
  NameComponent ,
  Events ,
  EventsV2 ,
  Properties ,
  PointOfBlock ,
  encode$10 as encode,
  decode$13 as decode,
  nameBuildingBlock ,
  emptyNameComponents ,
  empty$2 as empty,
  emptyStrict ,
  Utils ,
  
}
/* Value Not a pure module */
