// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function ProductTour__Modal(Props) {
  var closeOnClickOutside = Props.closeOnClickOutside;
  var delayOpt = Props.delay;
  var onStepActive = Props.onStepActive;
  var opened = Props.opened;
  var size = Props.size;
  var children = Props.children;
  var delay = delayOpt !== undefined ? delayOpt : 300;
  var match = React.useState(function () {
        return false;
      });
  var setDelayedOpened = match[1];
  var delayedOpened = match[0];
  React.useEffect((function () {
          var timeout = setTimeout((function (param) {
                  return Curry._1(setDelayedOpened, (function (param) {
                                return opened;
                              }));
                }), delay);
          return (function (param) {
                    clearTimeout(timeout);
                    
                  });
        }), [
        opened,
        delay
      ]);
  React.useEffect((function () {
          if (opened) {
            Belt_Option.forEach(onStepActive, (function (fn) {
                    return Curry._1(fn, undefined);
                  }));
          }
          
        }), [delayedOpened]);
  var tmp = {
    centered: true,
    opened: delayedOpened,
    overlayProps: {
      color: Styles.Color.toString(Styles.Color.setAlpha(Styles.Color.light12, 0.1))
    },
    radius: {
      NAME: "num",
      VAL: 10
    },
    shadow: Css_Legacy_Core.Shadow.toString(Styles.Shadow.standard),
    zIndex: Styles.ZIndex.aboveAll,
    children: children
  };
  if (closeOnClickOutside !== undefined) {
    tmp.closeOnClickOutside = Caml_option.valFromOption(closeOnClickOutside);
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  return React.createElement(Mantine.Modal.make, tmp);
}

var make = ProductTour__Modal;

export {
  make ,
  
}
/* react Not a pure module */
